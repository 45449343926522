import React, { useState } from 'react'
import Modals from '../Modals/Modals'
import './Table3.scss'
import { useEffect } from 'react'
import Modal from '../../Tools/Modal/Modal'
import AttachmentsPreview from '../AttachmentsPreview/AttachmentsPreview'

export default function Table3({ Titles = [], Content = [] }) {

  const [titles, setTitles] = useState([])
  const [data, setData] = useState([])
  const [modalShow, setModalShow] = useState(false)
  const [modalFiles, setModalFiles] = useState({})
  const [commentCollapse, setCommentCollapse] = useState({ current: null })
  const [breakPoint, setBreakPoint] = useState(33)

  useEffect(() => {
    setTitles(Titles)
    setData(Content)
    return () => {
      setTitles([])
      setData([])
    }
  }, [Titles])

  useEffect(() => {
    function screenWidthGetter(value) {
      let val = value
      switch (true) {
        case (val >= 1900): setBreakPoint(128); break;
        case (val >= 1800): setBreakPoint(114); break;
        case (val >= 1700): setBreakPoint(104); break;
        case (val >= 1600): setBreakPoint(94); break;
        case (val >= 1500): setBreakPoint(95); break;
        case (val >= 1400): setBreakPoint(80); break;
        case (val >= 1300): setBreakPoint(66); break;
        case (val >= 1201): setBreakPoint(48); break;
        case (val >= 1100): setBreakPoint(76); break;
        case (val >= 1000): setBreakPoint(55); break;
        case (val >= 900): setBreakPoint(40); break;
        default: setBreakPoint(40)
      }
    }
    screenWidthGetter(window.innerWidth)
    window.addEventListener('resize', (e) => screenWidthGetter(e.target.innerWidth))
    return () => window.removeEventListener('resize', (e) => screenWidthGetter(e.target.innerWidth))
  }, [])

  const AttachmentIcons = (files) => {
    return (
      files?.endpoints?.length > 0 ?
        <>
          <img src="/assets/icons/Eye-Open.svg" alt="" onClick={() => { setModalShow(true); setModalFiles(files) }} />
          <p>{files?.endpoints?.length}</p>
        </>
        : <img src="/assets/icons/Eye-close.svg" alt="" />
    )
  }

  const commentsHandler = (comment = '', index) => {
    return (
      <>
        {
          comment.length > breakPoint ?
            <span style={{ height: '50px', display: "inline-block", overflow: commentCollapse.current === index ? '' : 'hidden' }}>
              {comment}
              <img
                className={'read-more' + (commentCollapse.current === index ? ' up' : '')}
                onClick={() => setCommentCollapse((s) => ({ current: s.current === index ? null : index }))}
                src="/assets/icons/Icon ionic-ios-arrow-down tan.svg" alt="" />
            </span>
            : comment
        }
      </>
    )
  }

  return (
    <div id='Table3'>
      <table className="">
        <thead>
          <tr>
            {
              titles.map((o, i) => {
                return (
                  <th key={i} width={o.width}>{o.title}</th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            data.map((row, row_index) => {
              return (
                <tr key={row_index}>
                  {
                    titles.map((column, col_index) => {
                      return (
                        <td className={column.key} key={col_index}>
                          {
                            column.key === 'attachment' ?
                              AttachmentIcons(row.attachment)
                              : column.key === 'status' && row[column.key] ?
                                <span className={row.statusClass.toLowerCase().replace(' ', '') || ''}>{row?.status}</span>
                                : column.key === 'comment' ?
                                  commentsHandler(row.comment, row_index)
                                  : row[column.key]
                          }
                        </td>
                      )
                    })
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
      {
        data.length === 0 && titles.length !== 0 &&
        <p className='no__data'>No data available</p>
      }
      <Modal setShow={setModalShow} show={modalShow} backdropClose>
        {modalFiles && modalShow && <AttachmentsPreview data={modalFiles} />}
      </Modal>
    </div>
  )
}