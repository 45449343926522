export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : "http://localhost:8016/"
export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL ? process.env.REACT_APP_IMAGE_BASE_URL : "http://localhost:8016/"
export const SYNC_BASE_URL = process.env.REACT_APP_API_BASE_SYNC_URL ? process.env.REACT_APP_API_BASE_SYNC_URL : "http://10.10.12.61:8001/"

export const SUPER_ADMIN = "super_admin";
export const ADMIN = "admin";
export const INCIDENT_MANAGER = "incident_manager";
export const POOL_USER = "pool_user";
export const POOL_ADMIN = "pool_admin";
export const USER = "user";
export const VIEWER = "dashboard_view";

export const ASSIGN = "assign";
export const OPEN = "open";
export const FORWARD = "forward";
export const RETURN = "return";
export const PENDING = "pending";
export const PROCESSING = "processing";
export const CLOSE = "close";
export const SAVE_DRAFT = "save_draft";
export const RE_OPEN = "reopen";
export const DELETE = "delete";
export const SUBMIT_TASK = "submit_task";
export const CLOSE_TASK = "close_task";
export const REOPEN_TASK = "reopen_task";
export const ASSIGN_SUBTASK = "assign_subtask";

export const roles = {
    super_admin: "Super Admin",
    admin: "Admin",
    incident_manager: "Incident Manager",
    pool_admin: "Pool Admin",
    pool_user: "Pool User",
    user: "User",
    dashboard_view: "Dashboard Viewer"
}

