import React from 'react'
import './PersonalInformation.scss'

export default function PersonalInformation({personalData}) {
    return (
        <div id='PersonalInformation'>
            <div className='title'>
                <h2>Personal Information</h2>
            </div>
            <div className='content'>
                <article>
                    <span>
                        <p className='key'>Employee No</p>
                        <p className='value'>EMP201</p>
                    </span>
                    <span>
                        <p className='key'>First Name</p>
                        <p className='value'>John</p>
                    </span>
                    <span>
                        <p className='key'>Last Name</p>
                        <p className='value'>Doe</p>
                    </span>
                    <span>
                        <p className='key'>Email</p>
                        <p className='value'>{personalData?.[0]?.email}</p>
                    </span>
                    <span>
                        <p className='key'>Phone</p>
                        <p className='value'>+971 5555555544</p>
                    </span>
                    <span>
                        <p className='key'>Designation</p>
                        <p className='value'>Marketing Assistant</p>
                    </span>
                    <span>
                        <p className='key'>Department</p>
                        <p className='value'>Institutional Support</p>
                    </span>
                    <span>
                        <p className='key'>Section</p>
                        <p className='value'>Procurement</p>
                    </span>
                </article>
            </div>
        </div>
    )
}
