import React from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {
  department_details,
  employee_details,
  roles_details,
  section_details,
  value,
} from "../../../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUserRoles,
  getDepartment,
  getEmployeelist,
  getSection,
} from "../../../redux/commonActionCreator";
import { useEffect } from "react";
import { ADMIN, INCIDENT_MANAGER, SUPER_ADMIN, roles } from "../../../constants/configuration";
import useGetRole from "../../../utils/useGetRole";
import { customStyles } from '../../../utils/styles'
// import { translate } from "../../../utils/translate";
import useTrans from "../../../utils/useTrans";

function AdvancedSearch({
  page,
  users,
  setFilterData,
  filterData,
  clearFilter,
  applySearch,
  incidentStatusList,
  incidentTypeList,
  allUsers,
}) {
  const departmentLists = useSelector(department_details);
  const sectionLists = useSelector(section_details);
  const roleLists = useSelector(roles_details);
  const employeeLists = useSelector(employee_details);

  const dispatch = useDispatch();
  // const { role } = useGetRole();
  const { t, lang } = useTrans()
  const role = localStorage.getItem('user_type')

  const [startDate, setStartDate] = useState(null);
  const [selectInputs, setSelectInputs] = useState({})

  const userStatusList = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ];

  useEffect(() => {
    dispatch(getDepartment());
    if (role === SUPER_ADMIN) {
      dispatch(getAllUserRoles());
      let data = {
        department: "",
        section: "",
      };
      dispatch(getEmployeelist(data));
    }
  }, []);

  const [data, setData] = useState({
    user: "",
  });

  const changeDepartment = (value) => {
    setFilterData({ ...filterData, department: value });
    dispatch(getSection(value?._id));
    let data = {
      department: value?._id,
      section: "",
    };
    if (page === "users_listing" && filterData.userType) {
      data = {
        department: value?._id,
        section: "",
        userType: filterData.userType.value
      };
    }
    dispatch(getEmployeelist(data));
  };

  const changeUserType = (value) => {
    setFilterData({ ...filterData, userType: value });
    let data = {
      userType: value.value
    };
    if (filterData.department) {
      data = {
        department: filterData?.department?._id,
        userType: value.value
      };
    }
    dispatch(getEmployeelist(data));
  };

  const changeSection = (value) => {
    setFilterData({ ...filterData, section: value });
    let data = {
      department: filterData?.department?._id,
      section: value?._id,
    };
    dispatch(getEmployeelist(data));
  };

  const getUserTypes = () => {
    let userType = []

    switch (role) {
      case SUPER_ADMIN: {
        userType = ['admin', 'pool_admin', 'pool_user', 'dashboard_view'];
        break
      }
      case ADMIN: {
        userType = ['pool_admin', 'pool_user'];
        break;
      }
      case INCIDENT_MANAGER: {
        userType = ['pool_admin', 'pool_user'];
        break;
      }
      default: {
        userType = []
        break;
      }
    }

    return userType.map(function (element) {
      return ({ value: element, label: roles[element] })
    });
  }

  const handleClearFilter = () => {
    clearFilter()
    setSelectInputs({})
  }

  const handleSelectInputChange = (type, option) => {
    if (option.length > 1) {
      setSelectInputs({ ...selectInputs, [type]: true })
    } else {
      setSelectInputs({ ...selectInputs, [type]: false })
    }
  }


  return (
    <div className="advanced__search">
      <div className="card">
        <div className="card-body p-0">
          <div className="row justify-content-between align-items-end">
            {page === "users_listing" && (
              <>

                <div className="col">
                  <div className="mb-3 position-relative">
                    <label className="form-label rtl">{t('Department')}</label>
                    <Select
                      name="colors"
                      options={departmentLists}
                      getOptionLabel={(option) => option.department_name}
                      getOptionValue={(option) => option._id}
                      onChange={(e) =>
                        changeDepartment(e)
                      }
                      value={filterData?.department}
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder={t('Select') + '...'}
                    />
                  </div>
                </div>
                {/* <div className="col">
                  <div className="mb-3 position-relative">
                    <label className="form-label rtl">{t('Section')}</label>
                    <Select
                      name="colors"
                      options={filterData.department ? sectionLists: []}
                      getOptionLabel={(option) => option.section_name}
                      getOptionValue={(option) => option._id}
                      onChange={(e) =>
                        changeSection(e)
                      }
                      value={filterData?.section}
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder={t('Select Section')}
                      // placeholder={t('Select Department') + '...'}
                    />
                  </div>
                </div> */}
                <div className="col">
                  <div className="mb-3 position-relative">
                    <label className="form-label">{t('User Type')}</label>
                    {/* <Select
                      className="form-select"
                      onChange={(e) =>
                        setFilterData({
                          ...filterData,
                          userType: e.target.value,
                        })
                      }
                      value={filterData?.userType}
                    >
                      <option value="">select</option>
                      {role === SUPER_ADMIN && <option value="admin">Admin</option>}
                      {(role === SUPER_ADMIN || ADMIN) && <option value="incident_manager">Incident Manager</option>}
                      <option value="pool_admin">Pool Admin</option>
                      <option value="pool_user">PoolUser</option>
                    </Select> */}
                    <Select
                      name="colors"
                      options={getUserTypes()}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={(e) =>
                        // setFilterData({ ...filterData, userType: e })
                        changeUserType(e)
                      }
                      value={filterData?.userType}
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder={t('Select') + '...'}
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3 position-relative">
                    <label className="form-label rtl">{t('Employee Name')}</label>
                    <Select
                      name="colors"
                      options={employeeLists?.filter(item => item.role !== "super_admin")}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      onChange={(e) =>
                        setFilterData({ ...filterData, employee: e })
                      }
                      value={filterData?.employee}
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder={t('Select') + '...'}
                      onInputChange={(option) => handleSelectInputChange('employee', option)}
                    // styles={customStyles}
                    />
                  </div>
                </div>

              </>
            )}
            {page === "incidents_listing" && (
              <>
                <div className="col">
                  <div className="mb-3 position-relative">
                    <label className="form-label rtl">{t('Date')}</label>
                    <DatePicker
                      selected={filterData.createdDateFrom}
                      onChange={(date) =>
                        setFilterData({ ...filterData, createdDateFrom: date })
                      }
                      placeholderText={t("From")}
                      dateFormat="dd/MM/yyyy"
                      maxDate={
                        filterData.createdDateTo
                          ? filterData.createdDateTo
                          : new Date()
                      }
                    />
                    <img
                      className="form-icon"
                      src="/assets/icons/icon-calendar.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col pr-0">
                  <div className="mb-3 position-relative">
                    <DatePicker
                      selected={filterData.createdDateTo}
                      showIcon
                      onChange={(date) =>
                        setFilterData({ ...filterData, createdDateTo: date })
                      }
                      placeholderText={t("To")}
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      minDate={
                        filterData.createdDateFrom && filterData.createdDateFrom
                      }
                    />
                    <img
                      className="form-icon"
                      src="/assets/icons/icon-calendar.svg"
                      alt=""
                    />
                  </div>
                </div>

                <div className="col">
                  <div className="mb-3 position-relative">
                    <label className="form-label">{t('Incident Type')}</label>
                    <Select
                      name="colors"
                      options={incidentTypeList}
                      // getOptionLabel={(option) => option.incident_type}
                      getOptionLabel={(option) => option['incident_type' + (lang === 'ar' ? '_ar' : '')]}
                      getOptionValue={(option) => option._id}
                      onChange={(e) =>
                        setFilterData({ ...filterData, incidentType: e })
                      }
                      value={filterData?.incidentType}
                      className="basic-single"
                      classNamePrefix="select"
                      styles={customStyles}
                      placeholder={t('Select') + '...'}
                    />
                  </div>
                </div>
                {/* <div className="col">
                  <div className="mb-3 position-relative">
                    <label className="form-label">{t('Requested By')}</label>
                    <Select
                      name="colors"
                      options={allUsers}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      onChange={(e) =>
                        setFilterData({ ...filterData, requestedBy: e })
                      }
                      value={filterData?.requestedBy}
                      className="basic-single"
                      classNamePrefix="select"
                      // styles={customStyles}
                      placeholder={t('Select') + '...'}
                    />
                  </div>
                </div> */}
                {/* <div className="col">
                  <div className="mb-3 position-relative">
                    <label className="form-label">{t('Assigned To')}</label>
                    <Select
                      name="colors"
                      options={allUsers}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      onChange={(e) =>
                        setFilterData({ ...filterData, assignedTo: e })
                      }
                      value={filterData?.assignedTo}
                      className="basic-single"
                      classNamePrefix="select"
                      // styles={customStyles}
                      placeholder={t('Select') + '...'}
                    />
                  </div>
                </div> */}
                {/* <div className="col">
                  <div className="mb-3 position-relative">
                    <label className="form-label">{t('Status')}</label>
                    <Select
                      name="colors"
                      options={incidentStatusList}
                      getOptionLabel={(option) => option['incident_status_' + lang]}
                      getOptionValue={(option) => option._id}
                      onChange={(e) =>
                        setFilterData({ ...filterData, status: e })
                      }
                      value={filterData?.status}
                      className="basic-single"
                      classNamePrefix="select"
                      styles={customStyles}
                      placeholder={t('Select') + '...'}
                    />
                  </div>
                </div> */}
              </>
            )}
            {page === "incident_users_listing" && (
              <>
                <div className="col">
                  <div className="mb-3 position-relative">
                    <label className="form-label rtl">{t('Department')}</label>
                    <Select
                      name="colors"
                      options={departmentLists}
                      getOptionLabel={(option) => option.department_name}
                      getOptionValue={(option) => option._id}
                      onChange={(e) => changeDepartment(e)}
                      value={filterData?.department}
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder={t("Select")}
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3 position-relative">
                    <label className="form-label">{t('Section')}</label>
                    <div className="form-group">
                      <Select
                        name="colors"
                        options={sectionLists}
                        getOptionLabel={(option) => option.section_name}
                        getOptionValue={(option) => option._id}
                        value={filterData?.section}
                        onChange={(e) => changeSection(e)}
                        className="basic-single border-0"
                        classNamePrefix="select"
                        placeholder={t("Select")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3 position-relative">
                    <label className="form-label">{t('Employee Name')}</label>
                    <div className="form-group">
                      <Select
                        name="colors"
                        options={employeeLists}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option._id}
                        value={filterData?.employee_name}
                        onChange={(e) =>
                          setFilterData({ ...filterData, employee_name: e })
                        }
                        className="basic-single border-0"
                        classNamePrefix="select"
                        placeholder={t("Select")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3 position-relative">
                    <label className="form-label">{t('Role')}</label>
                    <div className="form-group">
                      <Select
                        name="colors"
                        options={roleLists}
                        getOptionLabel={(option) => option.value}
                        getOptionValue={(option) => option.key}
                        value={filterData?.role}
                        onChange={(e) =>
                          setFilterData({ ...filterData, role: e })
                        }
                        className="basic-single border-0"
                        classNamePrefix="select"
                        placeholder={t("Select")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3 position-relative">
                    <label className="form-label">{t('User Status')}</label>
                    <div className="form-group">
                      <Select
                        name="colors"
                        options={userStatusList}
                        getOptionLabel={(option) => t(option.label)}
                        getOptionValue={(option) => option.value}
                        value={filterData?.user_status}
                        onChange={(e) =>
                          setFilterData({ ...filterData, user_status: e })
                        }
                        className="basic-single border-0"
                        classNamePrefix="select"
                        placeholder={t("Select")}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="col">
              <div className="mb-3 position-relative d-flex gap-2">
                <button className="btn btn-primary w-95" onClick={applySearch} style={{ padding: '6px 50px', width: 'fit-content' }}>
                  {t('Search')}
                </button>
                {filterData?.employee ||
                  filterData?.userType ||
                  filterData?.department ||
                  filterData?.createdDateFrom ||
                  filterData?.createdDateTo ||
                  filterData?.incidentType ||
                  filterData?.requestedBy ||
                  filterData?.assignedTo ||
                  filterData?.status ||
                  filterData?.section ||
                  filterData?.employee_name ||
                  filterData?.role ||
                  filterData?.user_status ||
                  filterData?.keyword ||
                  filterData?.searchKey ? (
                  <div className="">
                    <div className=" position-relative">
                      <button
                        className="btn btn-primary btn-clear"
                        onClick={handleClearFilter}
                      >
                        <img src="./assets/icons/clear.svg" alt="X" />
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {filterData?.employee ||
              filterData?.userType ||
              filterData?.department ||
              filterData?.createdDateFrom ||
              filterData?.createdDateTo ||
              filterData?.incidentType ||
              filterData?.requestedBy ||
              filterData?.assignedTo ||
              filterData?.status ||
              filterData?.section ||
              filterData?.employee_name ||
              filterData?.role ||
              filterData?.user_status ||
              filterData?.keyword ||
              filterData?.searchKey ? (
              <div className="col-md-1">
                <div className="mb-3 position-relative">
                  <button
                    className="btn btn-primary btn-clear"
                    onClick={handleClearFilter}
                  >
                    <img src="./assets/icons/clear.svg" alt="X" />
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvancedSearch;
