import React, { useEffect, useState } from "react";
import Modal from "../../Tools/Modal/Modal";
import "./Modals.scss";
import { useDispatch, useSelector } from "react-redux";
import { user_list } from "../../redux/commonReducer";
import { getAllUsers } from "../../redux/commonActionCreator";
import { Link } from "react-router-dom";

export default function Modals({
  show,
  setShow,
  type,
  onAddRequestHandler,
  input,
  setInput,
  modalErr,
  setModalErr,
}) {

  const dispatch = useDispatch();

  const flag =
    type === "Approve"
      ? 1
      : type === "Forward"
        ? 2
        : type === "Return"
          ? 3
          : type === "Processing"
            ? 4
            : type === "Reject"
              ? 5
              : type === "Close"
                ? 6
                : type === "Success"
                  ? 7
                  : 0;

  const userList = useSelector(user_list);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [])

  return (
    <div id="Modals">
      <Modal show={show} setShow={(flag === 7 || flag === 8) && setShow}>
        {flag === 1 ? (
          <ApproveModal
            flag={flag}
            setShow={setShow}
            input={input}
            setInput={setInput}
            onAddRequestHandler={onAddRequestHandler}
            modalErr={modalErr}
            setModalErr={setModalErr}
          />
        ) : flag === 2 ? (
          <ForwardModal
            setShow={setShow}
            onAddRequestHandler={onAddRequestHandler}
            input={input}
            setInput={setInput}
            modalErr={modalErr}
            setModalErr={setModalErr}
            userList={userList}
          />
        ) : flag === 3 ? (
          <ReturnModal
            setShow={setShow}
            onAddRequestHandler={onAddRequestHandler}
            input={input}
            setInput={setInput}
            modalErr={modalErr}
            setModalErr={setModalErr}
          />
        ) : flag === 5 ? (
          <RejectModal
            setShow={setShow}
            onAddRequestHandler={onAddRequestHandler}
            input={input}
            setInput={setInput}
            modalErr={modalErr}
            setModalErr={setModalErr}
          />
        ) : flag === 6 ? (
          <CloseModal
            setShow={setShow}
            onAddRequestHandler={onAddRequestHandler}
            input={input}
            setInput={setInput}
            modalErr={modalErr}
            setModalErr={setModalErr}
          />
        ) : flag === 7 ? (
          <SuccessModal />
        ) : null}
      </Modal>
    </div>
  );
}

const ApproveModal = ({
  flag,
  setShow,
  input,
  setInput,
  onAddRequestHandler,
  modalErr,
  setModalErr,
}) => {
  const [nextPage, setNextPage] = useState("");
  // const [input, setInput] = useState({ file: '' })

  const nextPageHandler = () => {
    console.log(input.finalComment);
    if (input.finalComment?.trim()?.length != 0) {
      setNextPage("upload attachment");
    } else {
      setModalErr({ ...modalErr, finalComment: "Please Add Comment" });
    }
  };

  const onAddHandler = () => {
    if (input.file?.length != 0) {
      onAddRequestHandler();
    } else {
      setModalErr({ ...modalErr, file: "Please Upload Document" });
    }
  };

  return (
    <div className="modal-item">
      {nextPage !== "upload attachment" && flag === 1 && (
        <section>
          <h3 className="right">? Are you sure to Approve</h3>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            placeholder="Comment"
            onChange={(e) =>
              setInput({ ...input, finalComment: e.target.value })
            }
          ></textarea>
          <div className="text-danger">
            <small>{modalErr.finalComment}</small>
          </div>
          <div className="buttons">
            <button className="style-1" onClick={() => nextPageHandler()}>
              Confirm
            </button>
            <button
              className="style-1"
              onClick={() => (
                setInput({
                  file: "",
                  finalComment: "",
                  forwardUser: "",
                  returnComment: "",
                  rejectionComment: "",
                  closeComment: "",
                }),
                setShow(false)
              )}
            >
              Cancel
            </button>
          </div>
        </section>
      )}
      {nextPage === "upload attachment" && (
        <section
          className={"upload-attachment" + (input.file ? " file-active" : "")}
        >
          <h3>Upload certificate</h3>
          {input.file ? (
            <object
              data={URL.createObjectURL(input.file)}
              type="application/pdf"
            ></object>
          ) : (
            <label htmlFor="certificate-upload">
              <span className="button style-2">
                <input
                  type="file"
                  id="certificate-upload"
                  hidden
                  accept="application/pdf"
                  onClick={(e) => e.target.value = null}
                  onChange={(e) =>
                    setInput((s) => ({ ...s, file: e.target.files[0] }))
                  }
                />
                Add Attachments
                <i>
                  <img src="/assets/icons/Add Attachments.svg" alt="" />
                </i>
              </span>
              <div className="text-danger">
                <small>{modalErr.file}</small>
              </div>
            </label>
          )}
          <div className="buttons">
            <button className="style-1" onClick={() => onAddHandler()}>
              Confirm
            </button>
            <button
              className="style-1"
              onClick={() => (
                setInput({
                  file: "",
                  finalComment: "",
                  forwardUser: "",
                  returnComment: "",
                  rejectionComment: "",
                  closeComment: "",
                }),
                setShow(false)
              )}
            >
              Cancel
            </button>
          </div>
        </section>
      )}
    </div>
  );
};
const ForwardModal = ({
  setShow,
  onAddRequestHandler,
  input,
  setInput,
  modalErr,
  setModalErr,
  userList
}) => {
  const onAddHandler = () => {
    if (input.file?.length != 0) {
      onAddRequestHandler();
    } else {
      setModalErr({ ...modalErr, forwardUser: "Please Add User" });
    }
  };

  return (
    <div className="modal-item">
      <h3>Select the user you wish to forward</h3>
      <span className="select">
        <select
          name=""
          id=""
          onChange={(e) => setInput({ ...input, forwardUser: e.target.value })}
        >
          <option value="" selected hidden>
            Select User
          </option>
          {
            userList?.map((ele) => {
              return (
                <option value={ele._id} >{ele.name}</option>
              )
            })
          }
        </select>
        <i>
          <img src="/assets/icons/Drop-Down-Thick Black.svg" alt="" />
        </i>
      </span>
      <div className="text-danger">
        <small>{modalErr.forwardUser}</small>
      </div>
      <div className="buttons mt">
        <button className="style-1" onClick={() => onAddHandler()}>
          Confirm
        </button>
        <button
          className="style-1"
          onClick={() => (
            setInput({
              file: "",
              finalComment: "",
              forwardUser: "",
              returnComment: "",
              rejectionComment: "",
              closeComment: "",
            }),
            setShow(false)
          )}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
const ReturnModal = ({
  setShow,
  onAddRequestHandler,
  input,
  setInput,
  modalErr,
  setModalErr,
}) => {
  const onAddHandler = () => {
    if (input.file?.length != 0) {
      onAddRequestHandler();
    } else {
      setModalErr({ ...modalErr, returnComment: "Please Add Comment" });
    }
  };

  return (
    <div className="modal-item">
      <h3 className="right">? Reason for Return</h3>
      <textarea
        name=""
        id=""
        cols="30"
        rows="10"
        placeholder="Comment"
        onChange={(e) => setInput({ ...input, returnComment: e.target.value })}
      ></textarea>
      <div className="text-danger">
        <small>{modalErr.returnComment}</small>
      </div>
      <div className="buttons">
        <button className="style-1" onClick={() => onAddHandler()}>
          Confirm
        </button>
        <button
          className="style-1"
          onClick={() => (
            setInput({
              file: "",
              finalComment: "",
              forwardUser: "",
              returnComment: "",
              rejectionComment: "",
              closeComment: "",
            }),
            setShow(false)
          )}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
const RejectModal = ({
  setShow,
  onAddRequestHandler,
  input,
  setInput,
  modalErr,
  setModalErr,
}) => {
  const onAddHandler = () => {
    if (input.file?.length != 0) {
      onAddRequestHandler();
    } else {
      setModalErr({ ...modalErr, rejectionComment: "Please Add Comment" });
    }
  };

  return (
    <div className="modal-item">
      <h3 className="right">? Reason for Rejection</h3>
      <textarea
        name=""
        id=""
        cols="30"
        rows="10"
        placeholder="Comment"
        onChange={(e) =>
          setInput({ ...input, rejectionComment: e.target.value })
        }
      ></textarea>
      <div className="text-danger">
        <small>{modalErr.rejectionComment}</small>
      </div>
      <div className="buttons">
        <button className="style-1" onClick={() => onAddHandler()}>
          Confirm
        </button>
        <button
          className="style-1"
          onClick={() => (
            setInput({
              file: "",
              finalComment: "",
              forwardUser: "",
              returnComment: "",
              rejectionComment: "",
              closeComment: "",
            }),
            setShow(false)
          )}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
const CloseModal = ({
  setShow,
  onAddRequestHandler,
  input,
  setInput,
  modalErr,
  setModalErr,
}) => {
  const onAddHandler = () => {
    if (input.file?.length != 0) {
      onAddRequestHandler();
    } else {
      setModalErr({ ...modalErr, closeComment: "Please Add Comment" });
    }
  };

  return (
    <div className="modal-item">
      <h3 className="right">? Reason to close the Request</h3>
      <textarea
        name=""
        id=""
        cols="30"
        rows="10"
        placeholder="Comment"
        onChange={(e) => setInput({ ...input, closeComment: e.target.value })}
      ></textarea>
      <div className="text-danger">
        <small>{modalErr.closeComment}</small>
      </div>
      <div className="buttons">
        <button className="style-1" onClick={() => onAddHandler()}>
          Confirm
        </button>
        <button
          className="style-1"
          onClick={() => (
            setInput({
              file: "",
              finalComment: "",
              forwardUser: "",
              returnComment: "",
              rejectionComment: "",
              closeComment: "",
            }),
            setShow(false)
          )}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const SuccessModal = ({ }) => {
  return (
    <div className="modal-item SuccessModal">
      <i>
        <img src="/assets/icons/Success.svg" alt="" />
      </i>
      <h2>Submitted Successfully</h2>
    </div>
  );
};