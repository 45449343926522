import React, { useEffect, useRef, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/commonActionCreator";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { login_loading } from "../../redux/commonReducer";
import { USER, VIEWER } from "../../constants/configuration";
import useTrans from "../../utils/useTrans";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pwdInput = useRef()
  const { t, lang } = useTrans()

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const loading = useSelector(login_loading);

  const onEnterLogin = (e) => {
    if (e.key == "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      navigate('/admin/incidents/incident_requests')
    }
  }, [])

  const onUsernamekeyPress = (e) => {
    if (e.key == "Enter") {
      pwdInput.current.focus()
    }
  };

  const handleSubmit = () => {
    localStorage.setItem("last_activity", new Date());
    if (username?.trim() == "" && password?.trim() == "") {
      toast.error("Please enter username and password", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else if (username?.trim() == "") {
      toast.error("Please enter username", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else if (password?.trim() == "") {
      toast.error("Please enter password", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      const data = {
        username,
        password,
      };
      dispatch(
        login(data, (res) => {
          if (res.status == true) {
            localStorage.setItem("incidents_listing_pagination", 10)
            if (res.role === USER) {
              navigate("/");
            } else if (res.role === VIEWER) {
              navigate("/admin/incidents/dashboard");
            } else {
              navigate("/admin/incidents/incident_requests");
            }
            toast.success(t(res['message' + (lang === 'ar' ? '_ar' : '')]), {
              position: "bottom-center",
              autoClose: 3000,
            });
          }
        })
      );
    }
  };

  return (
    <>
      <section className="hr__login">
        <img className="logo" src="/assets/icons/Option-1.svg" alt="" />
        <div className="container">
          <div className="row vh-100 justify-content-center">
            <div className="col-md-6">
              <div className="login__form">
                <div className="mb-3">
                  <h3>Sign In</h3>
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="username"
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                    autoComplete="off"
                    onKeyDown={(e) => onUsernamekeyPress(e)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    type="password"
                    ref={pwdInput}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    autoComplete="off"
                    onKeyDown={(e) => onEnterLogin(e)}
                  />
                </Form.Group>
                <div className="mb-3">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? <Spinner size="sm"></Spinner> : "Login"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
