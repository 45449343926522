import { createSlice } from '@reduxjs/toolkit';

export const hrSlice = createSlice({
    name : 'hrReducer',
    initialState : {
        value: true,
        department_details : []
        
    },
    reducers : {
        updateHrRedux: (state , data) => {
            state[data.payload.key ] = data.payload.value
        },
    },
})
export const { updateHrRedux } = hrSlice.actions;

export const value = (state ) => state.hrReducer.value;
export const request_type_details = (state ) => state.hrReducer.request_type_details;
export const request_status_details = (state ) => state.hrReducer.request_status_details;
export const certificate_list = (state ) => state.hrReducer.certificate_list;
export const admin_certificate_path = (state ) => state.hrReducer.admin_certificate_path;
export const admin_certificate_pages = (state ) => state.hrReducer.admin_certificate_pages;
export const admin_certificate_page_status = (state ) => state.hrReducer.admin_certificate_page_status;
// export const value = (state ) => state.hrReducer.value;
// export const value = (state ) => state.hrReducer.value;
// export const value = (state ) => state.hrReducer.value;

export default hrSlice.reducer;