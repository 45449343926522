import React, { useState } from 'react'
import Modals from '../../components/Modals/Modals'
import SearchSelect, { Option } from '../../Tools/SearchSelect/SearchSelect'
import { useTranslation } from 'react-i18next'

export default function Dashboard({ role }) {

    const { t } = useTranslation()

    return (
        <div >
            <h1>{t(role)} {t('Dashboard')}</h1>
        </div >
    )
}
