import { OverlayTrigger } from "react-bootstrap";
import useTrans from "../../../utils/useTrans";

const AddAdditionalDocument = (props) => {

  const { t } = useTrans()

  const {
    page,
    addObj,
    onAdditionalChangeHandler,
    setAddErr,
    addErr,
    renderTooltip,
    setAddObj,
    editNew,
    onEditNewHandler,
    editOld,
    onEditOldAdditional,
    onAddHandler,
    additionalData,
    oldAttachments,
    fileViewHandler,
    onDeleteAddHandler,
    onEditAddHandler,
    oldFileViewHandler,
    onDeleteOldHandler,
    onEditOldHandler,
    onClearHandler
  } = props;
  return (

    <>
      <div className="col-md-12 addProof" id="AddAdditionalDocument">
        <div className="col-md-12 d-flex gap-2">
          <div className="col-md-6 d-flex gap-2" style={{ paddingLeft: '12px' }}>
            <div className="d-flex flex-column w-50">
              <div className="form-group w-100">
                <input
                  type="text"
                  className="form-control"
                  // style={{ width: "100%", marginRight: "10px" }}
                  placeholder={t("Enter Document Name")}
                  value={addObj.doc}
                  onChange={(e) => {
                    onAdditionalChangeHandler("doc", e.target.value);
                    setAddErr({ ...addErr, doc: "" });
                  }}
                />
              </div>
              <div className="text-danger mt-2">
                <small>{t(addErr?.doc)} </small>
              </div>
            </div>
            <div className="d-flex flex-column w-50">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  // style={{ width: "100%", marginRight: "10px" }}
                  placeholder={t("Enter Document Id")}
                  value={addObj.doc_id}
                  onChange={(e) => {
                    onAdditionalChangeHandler("doc_id", e.target.value);
                    setAddErr({ ...addErr, doc_id: "" });
                  }}
                />
              </div>
              <div className="text-danger">
                <small>{t(addErr?.doc_id)}</small>
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="col-md-6">
            <div className="align-center" style={{ alignItems: 'start' }}>
              <div className="d-flex">
                <div className="form-group">
                  <div className="attachments">
                    <div className="upload-btn-wrapper">
                      <label className="btn btn-attachment" htmlFor="addaditionaldocumentfile">
                        {t('Add Attachments')}
                        <img
                          src="/assets/icons/Icon-upload.svg"
                          alt=""
                          className="mr-2"
                        />{" "}
                      </label>
                      <input
                        type="file"
                        name="myfile"
                        id="addaditionaldocumentfile"
                        // value={addObj.file}
                        hidden
                        accept=".jpeg, .jpg, .png, .pdf"
                        onClick={(e) => e.target.value = null}
                        onChange={(e) => {
                          setAddObj({
                            ...addObj,
                            file: e.target.files[0],
                            filePath: "",
                            fileName: "",
                          });
                          setAddErr({ ...addErr, file: "" });
                        }}
                      />
                    </div>
                  </div>
                </div>
                {addObj.file ? (
                  <>
                    {addObj?.file?.type === "image/jpeg" ||
                      addObj?.file?.type === "image/jpg" ||
                      addObj?.file?.type === "image/webp" ||
                      addObj?.file?.type === "image/png" ? (
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip({
                          label: addObj?.file?.name,
                        })}
                      >
                        <span className="file-container" style={{ marginTop: page === 'addincident' ? '13px' : '' }}>
                          <img
                            className="file"
                            src={URL.createObjectURL(addObj?.file)}
                            alt=""
                            style={{ width: "100px" }}
                          />
                          <img
                            className="file-close"
                            src="/assets/icons/Group 11563.svg"
                            alt=""
                            onClick={() => {
                              onAdditionalChangeHandler("file", "");
                              setAddErr({ ...addErr, file: "" });
                            }}
                          />
                        </span>
                      </OverlayTrigger>
                    ) : addObj?.file?.type === "application/pdf" ? (
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip({
                          label: addObj?.file?.name,
                        })}
                      >
                        <span className="file-container" style={{ marginTop: page === 'addincident' ? '13px' : '' }}>
                          <a
                            href={`${URL.createObjectURL(addObj?.file)}`}
                            target="_blank"
                          >
                            <img
                              className="file"
                              src="/assets/icons/files/pdf.svg"
                              alt=""
                              style={{ height: '44px' }}
                            />
                          </a>
                          <img
                            className="file-close"
                            src="/assets/icons/Group 11563.svg"
                            alt=""
                            onClick={() => {
                              onAdditionalChangeHandler("file", "");
                              setAddErr({ ...addErr, file: "" });
                            }}
                          />
                        </span>
                      </OverlayTrigger>
                    ) : null}
                  </>
                ) : null}
                {addObj?.fileName && addObj?.filePath ? (
                  <>
                    {["pdf", "PDF"].includes(
                      addObj?.fileName?.split(".").reverse()[0]
                    ) ? (
                      <>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip({
                            label: addObj?.fileName,
                          })}
                        >
                          <span className="file-container">
                            <a href={addObj.filePath} target="_blank">
                              <img
                                className="file"
                                src="/assets/icons/files/pdf.svg"
                                alt=""
                                style={{ width: "100px" }}
                              />
                            </a>
                            <img
                              className="file-close"
                              src="/assets/icons/Group 11563.svg"
                              alt=""
                              onClick={() => {
                                setAddObj({
                                  ...addObj,
                                  file: "",
                                  filePath: "",
                                  fileName: "",
                                });
                                setAddErr({ ...addErr, file: "" });
                              }}
                            />
                          </span>
                        </OverlayTrigger>
                      </>
                    ) : ["jpg", "jpeg", "webp", "png"].includes(
                      addObj?.fileName?.split(".").reverse()[0]
                    ) ? (
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip({
                          label: addObj?.fileName,
                        })}
                      >
                        <span className="file-container" >
                          <img
                            className="file"
                            src={addObj?.filePath}
                            alt=""
                            style={{ width: "100px" }}
                          />
                          <img
                            className="file-close"
                            src="/assets/icons/Group 11563.svg"
                            alt=""
                            onClick={() => {
                              setAddObj({
                                ...addObj,
                                file: "",
                                filePath: "",
                                fileName: "",
                              });
                              setAddErr({ ...addErr, file: "" });
                            }}
                          />
                        </span>
                      </OverlayTrigger>
                    ) : null}
                  </>
                ) : null}


              </div>


              <div className="form-group d-flex">
                <button
                  className="btn btn-add"
                  style={{ marginRight: '20px', height: '41px' }}
                  onClick={() => {
                    if (editNew != null) {
                      onEditNewHandler();
                    } else if (editOld != null) {
                      onEditOldAdditional();
                    } else {
                      onAddHandler();
                    }
                  }}
                >
                  +
                </button>
                {addObj.doc?.trim()?.length != 0 ||
                  addObj.doc_id?.trim()?.length != 0 ||
                  addObj?.file != "" ? (
                  <button
                    className="btn btn-add"
                    style={{
                      marginRight: "7px",
                      backgroundColor: "#0a0f31",
                      border: "none",
                    }}
                    onClick={() => onClearHandler()}
                  >
                    <img src="./assets/icons/clear.svg" height={15} alt="" />
                  </button>
                ) : null}
              </div>
            </div>
            <div className="text-danger">
              <small>{t(addErr?.file)} </small>
            </div>
            <p className="supported__formats">
              <img style={{ height: '20px', marginLeft: '5px' }} src="/assets/icons/info.svg" alt="" />
              {t("Supported formats : jpeg, jpg, png, pdf")}. {t("Please click on '+' button to add documents")}
            </p>
          </div>
        </div>
        {additionalData?.length || oldAttachments?.length ? (
          <>
            <div className="card-header">
              <div className="row w-100">
                <div className="col-md-3">
                  <h6>{t('Sl.No')}</h6>
                </div>
                <div className="col-md-2">
                  <h6>{t('Additional Data')}</h6>
                </div>
                <div className="col-md-2">
                  <h6>{t('Value')}</h6>
                </div>
                <div className="col-md-2">
                  <h6>{t('Attachments')}</h6>
                </div>
                <div className="col-md-3">
                  <h6>{t('Action')}</h6>
                </div>
              </div>
            </div>
            {additionalData?.map((ele, i) => {
              return (
                <div className="row p-0-25">
                  <div className="col-md-3">
                    <div className="personal__details">
                      <div className="align-center">
                        <h4>{i + 1}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="personal__details">
                      <div className="align-center justify-content-between">
                        <h4>{ele.doc}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="personal__details">
                      <h4>{ele.doc_id}</h4>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="personal__details">
                      <span className="file-container">
                        <img
                          className="file eye-open"
                          src="/assets/icons/Eye-Open.svg"
                          alt=""
                          onClick={() => fileViewHandler(ele)}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="personal__details">
                      <span className="file-container">
                        <img
                          src="/assets/icons/Edit.svg"
                          height={25}
                          alt=""
                          onClick={() => onEditAddHandler(ele, i)}
                          style={{ cursor: 'pointer' }}
                        />
                        <img
                          className="mr-2"
                          src="/assets/icons/delete.svg"
                          alt=""
                          onClick={() => onDeleteAddHandler(i)}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
            {oldAttachments?.map((ele, i) => {
              return (
                <div className="row w-100 p-0-25">
                  <div className="col-md-3">
                    <div className="personal__details">
                      <div className="align-center">
                        <h4>{additionalData?.length + i + 1}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="personal__details">
                      <div className="align-center justify-content-between">
                        <h4>{ele.attachment_type}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="personal__details">
                      <h4>{ele.attachment_number}</h4>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="personal__details">
                      <span className="file-container">
                        <img
                          className="file eye-open"
                          src="/assets/icons/Eye-Open.svg"
                          alt=""
                          onClick={() => {
                            if (ele?.file) {
                              fileViewHandler(ele);
                            } else {
                              oldFileViewHandler(ele);
                            }
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="personal__details">
                      <span className="file-container">
                        <img
                          src="/assets/icons/Edit.svg"
                          alt=""
                          height={25}
                          onClick={() => onEditOldHandler(ele)}
                        />
                        <img
                          className="mr-2"
                          src="/assets/icons/delete.svg"
                          alt=""
                          onClick={() => onDeleteOldHandler(ele)}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : null}
      </div >
    </>
  );
};
export default AddAdditionalDocument;
