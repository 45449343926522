import React, { useEffect, useState } from 'react'
import './Button1.scss'

export default function Button1({ Text, Icon, onClick, disabled, reverse, style }) {

  const [text, setText] = useState(null)
  const [icon, setIcon] = useState(null)

  useEffect(() => {
    setText(Text)
  }, [Text])

  useEffect(() => {
    setIcon(Icon)
  }, [Icon])

  return (
    <button style={style} id='Button1' className={reverse ? 'reverse' : ''}
      onClick={(e) => onClick && onClick()} disabled={disabled}>
      {text}
      {icon && <img src={icon} alt="" />}
    </button>
  )
}
