import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./NavBarHeader.scss";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import PermissionGate from '../../utils/PermissionsGate'
import { ADMIN, INCIDENT_MANAGER, POOL_ADMIN, SUPER_ADMIN } from '../../constants/configuration'
import useGetRole from "../../utils/useGetRole";
import { LangSwitch } from "../../App";
import useTrans from "../../utils/useTrans";

export default function NavBarHeader({ setSelected }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentLanguage, changeLanguage } = useContext(LangSwitch)


  const [role, setRole] = useState("User");
  const [language, setLanguage] = useState("en");
  let current = localStorage.getItem("lang");

  const { t } = useTrans()

  const { role: userRole } = useGetRole()

  const [lang, setLang] = useState('En')
  useEffect(() => {
    let lg = localStorage.getItem("lang")
    if (!lg) {
      localStorage.setItem("lang", "ar");
      setLanguage("ar");
    } else {
      setLanguage(lg)
    }
  }, []);

  useEffect(() => {
    // i18n.changeLanguage(current);
    changeLanguage(current)
  }, [current]);
  useEffect(() => {
    // i18n.changeLanguage(language);
    changeLanguage(language)
  }, [language]);


  function langHandler(lang) {
    localStorage.setItem("lang", lang);
    setLanguage(lang);
    // i18n.changeLanguage(lang);
    changeLanguage(lang)

  }

  function roleHandler() {
    setSelected({ item: 1, subItem: 0 });
    if (role === "Admin") {
      setRole("User");
      navigate("/");
    } else if (role === "User") {
      setRole("Admin");
      navigate("/admin");
    }
  }

  useEffect(() => {
    const path = location.pathname;
    path.split("/")[1] === "admin" ? setRole("Admin") : setRole("User");
    // console.log(location)
  }, []);

  const isPoolUserOrNormalUser = () => {
    let flag = true
    if (userRole === SUPER_ADMIN || userRole === ADMIN || userRole === INCIDENT_MANAGER || userRole === POOL_ADMIN) {
      flag = false
    }
    return flag
  }

  return (
    <div id="NavBarHeader">
      <div className="logo">
        <img src="/assets/icons/Option-1.svg" alt="" />
      </div>
      <div className="options">
        {/* <span onClick={() => langHandler()}>{language}</span> */}
        {currentLanguage == "en" ? (
          <span onClick={() => langHandler("ar")}><em>ع</em></span>
        ) : (
          <span onClick={() => langHandler("en")}>En</span>
        )}
        {/* <span onClick={() => roleHandler()} style={{ visibility: isPoolUserOrNormalUser() ? 'hidden' : '' }}>
          {t(`Switch to ${role === "Admin" ? "User" : "Admin"}`)}
        </span> */}
      </div>
    </div>
  );
}
