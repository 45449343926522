import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Modal,
  Table,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Form,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  assignTaskToPoolDepartment,
  assignTaskToPoolUser,
  changeIncidentStatus,
  closeIncident,
  closeIncidentTask,
  deleteDraftedIncident,
  forwardIncident,
  forwardIncidentFromPoolAdmin,
  getAttachmentList,
  getIncidentDetails,
  getIncidentSource,
  getIncidentStatusList,
  getIncidentType,
  getSerachUsers,
  insertIncident,
  openIncident,
  reOpenIncident,
  reopenIncident,
  returnIncident,
  updateIncident,
} from "../../../redux/incidentActionCreator";
import {
  incident_add_change_loading,
  incident_attachment_list,
  incident_details,
  incident_path,
  incident_source_details,
  incident_status_change_loading,
  incident_status_change_success,
  incident_status_details,
  incident_type_details,
  searchuser_list,
  updateIncidentRedux,
} from "../../../redux/incidentReducer";
import { getDepartment, getSection, getDepartmentHavingUsers } from "../../../redux/commonActionCreator";
import {
  department_details,
  section_details,
  department_details_having_users
} from "../../../redux/commonReducer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ADMIN,
  ASSIGN,
  DELETE,
  CLOSE,
  FORWARD,
  INCIDENT_MANAGER,
  OPEN,
  PENDING,
  POOL_ADMIN,
  POOL_USER,
  PROCESSING,
  RETURN,
  RE_OPEN,
  SUPER_ADMIN,
  SAVE_DRAFT,
  SUBMIT_TASK,
  API_BASE_URL,
  CLOSE_TASK,
  REOPEN_TASK,
  ASSIGN_SUBTASK,
  IMAGE_BASE_URL,
} from "../../../constants/configuration";
import useGetRole from "../../../utils/useGetRole";
import FileViewModal from "./fileViewModal";
import IncidentModal from "./IncidentModal";
import { toast } from "react-toastify";
import AddAdditionalDocument from "./AddAdditionalDocument";
import { customStyles } from '../../../utils/styles'
import { translate } from "../../../utils/translate";
import useTrans from "../../../utils/useTrans";

function AddIncident() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const language = localStorage.getItem("lang") || "en"

  const { t, lang } = useTrans()
  const { id, displaytype, taskno } = useParams();
  const { role } = useGetRole();
  const userID = localStorage.getItem("user_id");

  const incidentTypeList = useSelector(incident_type_details);
  const departmentList = useSelector(department_details);
  const attachmentList = useSelector(incident_attachment_list);
  const incidentStatusList = useSelector(incident_status_details);
  const incidentSourceList = useSelector(incident_source_details);
  const incidentAddLoading = useSelector(incident_add_change_loading);
  const incidentStatusChangeLoading = useSelector(
    incident_status_change_loading
  );
  const incident_data = useSelector(incident_details);
  const url = useSelector(incident_path);
  const sectionLists = useSelector(section_details);
  const searchUsers = useSelector(searchuser_list);
  const incidentStatusChangeSuccess = useSelector(
    incident_status_change_success
  );
  const departmentListHavingUsers = useSelector(department_details_having_users);

  const [forward, setForward] = useState(false);
  const [actionreturn, setReturn] = useState(false);
  const [status, setStatus] = useState("");
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    country_code: "971+",
    entity: "",
    date: "",
    reported_elsewhere: "",
    description: "",
    source: "",
    other_reason: "",
    source_file: [],
    // first_name: "test",
    // last_name: "test",
    // email: "test@gmail.com",
    // phone_number: "741258963",
    // country_code: "971+",
    // entity: "test",
    // date: "",
    // reported_elsewhere: "",
    // description: "test",
    // source: "",
    // other_reason: "",
    // source_file: [],
  });
  const [returnComment, setReturnComment] = useState("");
  const [additionalData, setAdditionalData] = useState([]);
  const [addObj, setAddObj] = useState({
    doc: "",
    doc_id: "",
    file: "",
  });
  const [addErr, setAddErr] = useState([]);
  const [incidentType, setIncidentType] = useState("");
  const [forwardDept, setForwardDept] = useState("");
  const [err, setErr] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    incidentType: "",
    entity: "",
    date: "",
    reported_elsewhere: "",
    description: "",
    status: "",
    final: "",
  });
  const [modalshow, setModalShow] = useState(false);
  const [fileView, setFileView] = useState(null);
  const [oldAttachments, setOldAttachments] = useState([]);
  const [oldFileView, setOldFileView] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [fileNumber, setFileNumber] = useState(null);
  const [delOldAttachmentValue, setDelOldAttachmentValue] = useState(null);
  const [modalType, setModalType] = useState("");
  const [delOldAttachmentIds, setDelOldAttachmentIds] = useState([]);
  const [forwardType, setForwardType] = useState("");
  const [assignData, setAssignData] = useState({});
  const [forwardData, setForwardData] = useState({});
  const [userSearchParams, setUserSearchParams] = useState({});
  const [delNewAttachmentValue, setDelNewAttachmentValue] = useState(null);
  const [editNew, setEditNew] = useState(null);
  const [editOldIds, setEditOldIds] = useState([]);
  const [editOld, setEditOld] = useState(null);
  const [oldSourceFiles, setOldSourceFiles] = useState([]);
  const [oldSourceDeleteFiles, setOldSourceDeleteFiles] = useState([]);

  useEffect(() => {
    if (role === POOL_ADMIN) {
      setUserSearchParams({
        ...userSearchParams,
        department: JSON.parse(localStorage.getItem("department")),
      });
    }
    if (role === POOL_USER) {
      setUserSearchParams({
        ...userSearchParams,
        department: JSON.parse(localStorage.getItem("department")),
      });
    }
  }, [role]);

  useEffect(() => {
    if (userSearchParams.department) {
      dispatch(getSection(userSearchParams.department._id));
    }
  }, [userSearchParams]);

  useEffect(() => {
    let formData = new FormData();
    userSearchParams.department &&
      formData.append("department[0]", userSearchParams.department?._id);
    userSearchParams.section &&
      formData.append("section[0]", userSearchParams.section?._id);
    formData.append("is_pool_user", 1);
    dispatch(getSerachUsers(formData));
  }, [modalType, userSearchParams]);

  useEffect(() => {
    if (id) {
      dispatch(getIncidentDetails(id, displaytype, taskno));
    } else {
      dispatch(updateIncidentRedux({ key: "incident_details", value: {} }));
    }
  }, [id]);

  useEffect(() => {
    dispatch(getIncidentType());
    dispatch(getDepartment());
    dispatch(getAttachmentList());
    dispatch(getIncidentStatusList());
    dispatch(getIncidentSource());
    dispatch(getDepartmentHavingUsers());
  }, []);

  useEffect(() => {
    if (incident_data && id) {
      let obj = {
        first_name: incident_data?.first_name ?? "",
        last_name: incident_data?.last_name ?? "",
        email: incident_data?.email ?? "",
        phone_number: incident_data?.phone
          ? incident_data?.phone?.replace("971", "")
          : "",
        country_code: "971+",
        entity: incident_data?.incident_entity ?? "",
        date: incident_data?.incident_date
          ? new Date(incident_data?.incident_date)
          : "",
        reported_elsewhere: incident_data?.is_reported_elsewhere
          ? incident_data?.is_reported_elsewhere == 1
            ? "yes"
            : "no"
          : "",
        description: incident_data?.incident_description ?? "",
        source: incident_data?.incident_source ?? "",
        other_reason: incident_data?.incident_source_other_reason ?? "",
        source_file: [],
      };
      setData({ ...obj });
      setIncidentType(incident_data?.incident_type ?? "");
      setOldAttachments(
        incident_data?.attachments ? incident_data?.attachments : []
      );
      setOldSourceFiles(
        incident_data?.incident_attachment
          ? incident_data?.incident_attachment
          : []
      );
    }
  }, [incident_data]);

  useEffect(() => {
    if (incidentStatusChangeSuccess) {
      dispatch(
        updateIncidentRedux({
          key: "incident_status_change_success",
          value: false,
        })
      );
      dispatch(updateIncidentRedux({ key: "incident_details", value: {} }));
      setData({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        incidentType: "",
        entity: "",
        date: "",
        reported_elsewhere: "",
        description: "",
        status: "",
        final: "",
      });
      navigate("/admin/incidents/incident_requests");
    }
  }, [incidentStatusChangeSuccess]);

  const heading = useMemo(() => {
    return id ? "Update Incident" : "Add Incident";
  }, [id]);

  const handleKeyDown = (event) => {
    const keyCode = event.keyCode || event.which;
    const forbiddenKeys = [69, 187, 189,]; // Key codes for 'e', '+', '-', and '*'

    if (forbiddenKeys.includes(keyCode)) {
      event.preventDefault();
    }
  };

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    if (key === "phone_number") {
      const cleanedValue = value.replace(/[^0-9]/g, ''); // Remove 'e', '-', and '+'
      const phoneNumber = cleanedValue.replace(/e/gi, '').slice(0, 9); // Restrict to maximum 9 digits
      setData({
        ...data,
        [key]: phoneNumber,
      });

    }
  };

  const onAdditionalChangeHandler = (key, value) => {
    setAddObj({
      ...addObj,
      [key]: value,
    });
  };

  const validateAddObj = () => {
    let temp = {};
    if (!addObj.file) {
      temp = { ...temp, file: "Please select file" };
    }
    if (addObj.file) {
      const extension = addObj.file.name.split(".").pop().toLowerCase();

      // Define the allowed file formats
      const allowedFormats = ["jpeg", "jpg", "png", "webp", "pdf"];

      // Check if the file format is valid
      if (!allowedFormats.includes(extension)) {
        temp = { ...temp, file: "Please select a valid file format" };
      }
    }
    // if (addObj.doc_id?.length == 0) {
    //   temp = { ...temp, doc_id: "Please enter document Id" };
    // }
    if (addObj?.doc?.trim()?.length == 0) {
      temp = { ...temp, doc: "Please enter document type" };
    }
    if (Object.values(temp).length != 0) {
      setAddErr(temp);
      return false;
    } else {
      return true;
    }
  };

  const onAddHandler = () => {
    if (validateAddObj()) {
      setAdditionalData([...additionalData, addObj]);
      setAddObj({
        doc: "",
        doc_id: "",
        file: "",
      });
      setErr({ ...err, additionalData: "" });
    }
  };

  const onClearHandler = () => {
    setAddObj({
      doc: "",
      doc_id: "",
      file: "",
    });
    setAddErr({});
  };

  const onEditNewHandler = () => {
    if (validateAddObj()) {
      let arr = [...additionalData];
      arr[editNew] = addObj;
      setAdditionalData([...arr]);
      setAddObj({
        doc: "",
        doc_id: "",
        file: "",
      });
      setEditNew(null);
    }
  };

  const onEditAddHandler = (ele, i) => {
    setEditNew(i);
    setAddObj(ele);
  };

  const onEditOldHandler = (ele) => {
    setEditOld(ele._id);
    setAddObj({
      doc: ele.attachment_type,
      doc_id: ele.attachment_number,
      file: "",
      fileName: ele.attachment_name,
      filePath: `${IMAGE_BASE_URL}${url}/${ele.attachment_name}`,
    });
  };

  const onEditOldAdditional = () => {
    if (validateAddObj()) {
      let arr = oldAttachments?.map((ele) => {
        if (ele._id == editOld) {
          return {
            ...ele,
            attachment_name: addObj?.fileName ? addObj?.fileName : null,
            attachment_number: addObj.doc_id,
            attachment_type: addObj.doc,
            file: addObj.file ?? "",
          };
        } else {
          return ele;
        }
      });
      setOldAttachments(arr);
      setAddObj({
        doc: "",
        doc_id: "",
        file: "",
      });
      setEditOldIds(
        editOldIds?.includes(editOld) ? editOldIds : [...editOldIds, editOld]
      );
      setEditOld(null);
    }
  };

  const onDeleteAddHandler = (i) => {
    setDelNewAttachmentValue(i);
    setModalType("del_new_attachment");
  };

  // const onDeleteAddHandler = (i) => {
  //   setAdditionalData(additionalData.filter((ele, index) => index != i));
  // };

  const onSourseFileChangeHandler = (e) => {
    let temp = [];
    let size = 0;
    [...e.target.files]?.forEach((ele) => {
      const file = ele;
      const fileType = file.name
        .substr(file.name.lastIndexOf(".") + 1)
        .toLowerCase();
      size = size + ele.size;
      if (["jpeg", "jpg", "png", "pdf", "PDF", "mp4", "docx", "mov", "doc"].includes(fileType)) {
        setErr({ ...err, source_file: "" });
      } else {
        temp.push(ele);
        setErr({
          ...err,
          source_file:
            "Please upload files with jpeg, jpg, png, pdf, mp4, docx, mov, doc formats",
        });
      }
    });
    let prevDataSize = 0;
    if (data.source_file?.length != 0) {
      data.source_file?.forEach((ele) => {
        prevDataSize = prevDataSize + ele.size;
      });
    }
    let totalFileSize = prevDataSize + size;
    const fileSize = Math.round(totalFileSize / 1024);
    if (fileSize > 100 * 1024) {
      setErr({
        ...err,
        source_file: "File too Big, please select files less than 100mb",
      });
    }
    if (fileSize <= 100 * 1024 && temp?.length == 0) {
      setData({
        ...data,
        source_file: [...data.source_file, ...e.target.files],
      });
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.label}
    </Tooltip>
  );

  const fileViewHandler = (ele) => {
    setFileView(ele.file);
    setFileType(ele.doc);
    setFileNumber(ele.doc_id);
    setModalShow(true);
  };

  const oldFileViewHandler = (ele) => {
    setOldFileView(ele.attachment_name);
    setFileType(ele.attachment_type);
    setFileNumber(ele.attachment_number);
    setModalShow(true);
  };

  const fileUploadViewHandler = (ele) => {
    setFileView(ele);
    setFileType("");
    setFileNumber("");
    setModalShow(true);
  };

  const oldFileUploadViewHandler = (ele) => {
    setOldFileView(ele);
    setFileType("");
    setFileNumber("");
    setModalShow(true);
  };

  const onDeleteOldHandler = (ele) => {
    setDelOldAttachmentValue(ele);
    // setDelOldAttachmentModal(true);
    setModalType("del_old_attachment");
  };

  const onDeleteOldAttachment = () => {
    setOldAttachments(
      oldAttachments.filter((ele) => ele._id != delOldAttachmentValue._id)
    );
    setEditOldIds(
      editOldIds?.filter((item) => item._id == delOldAttachmentValue._id)
    );
    setDelOldAttachmentIds([...delOldAttachmentIds, delOldAttachmentValue._id]);
    setDelOldAttachmentValue(null);
    // setDelOldAttachmentModal(false);
    hideModal();
  };

  const onDeleteNewAttachment = () => {
    setAdditionalData(
      additionalData.filter((ele, index) => index != delNewAttachmentValue)
    );
    // setDelNewAttachmentModal(false);
    setDelNewAttachmentValue(null);
    hideModal();
  };

  const saveDraftValidateHandler = () => {
    let temp = {};
    if (
      data.first_name?.trim()?.length != 0 ||
      data.last_name?.trim()?.length != 0 ||
      data.email?.trim()?.length != 0 ||
      data.phone_number?.trim()?.length != 0 ||
      incidentType?.length != 0 ||
      data.entity?.trim()?.length != 0 ||
      data.date?.length != 0 ||
      data.reported_elsewhere?.trim()?.length != 0 ||
      data.description?.trim()?.length != 0 ||
      data?.source?.length != 0
    ) {
      if (data.first_name?.trim()?.length != 0) {
        if (!/^[a-zA-Zء-ي ]*$/.test(data.first_name)) {
          temp = { ...temp, first_name: ("First name should have only alphabets") };
        }
      }else{
        temp = { ...temp, first_name: ("Please add first name") };
      }
      if (data.last_name?.trim()?.length != 0) {
        if (!/^[a-zA-Zء-ي ]*$/.test(data.last_name)) {
          temp = { ...temp, last_name: ("Last name should have only alphabets") };
        }
      }
      if (data.email?.trim()?.length != 0) {
        if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/.test(
            data.email
          )
        ) {
          temp = { ...temp, email: ("invalid email format") };
        }
      }
      if (data.phone_number?.trim()?.length != 0) {
        if (!/^[0-9]{9}$/.test(data.phone_number)) {
          temp = { ...temp, phone_number: ("Invalid phone number") };
        }
      }
      // if (data.description?.trim()?.length != 0) {
      //   if (!/^[a-zA-Z0-9ء-ي .؟،,-:!"?()'\n]+$/.test(data.description)) {
      //     temp = { ...temp, description: t("Invalid characters") };
      //   }
      // }
      if (
        data?.source?.length != 0 &&
        data?.source?.source_name_en == "Other"
      ) {
        if (data.other_reason?.trim()?.length == 0) {
          temp = {
            ...temp,
            other_reason: ("Please enter reason"),
          };
        }
      }
      if (incidentType?.length == 0) {
        temp = { ...temp, incidentType: ("Please select incident type") };
      }
      if (Object.values(temp).length != 0) {
        setErr(temp);
        return false;
      } else {
        return true;
      }
    } else {
      if (data.first_name?.trim()?.length == 0) {
        temp = { ...temp, first_name: ("Please add first name") };
      }
      temp = { ...temp, incidentType: ("Please select incident type") };
      setErr(temp);
      return false;
    }
  };

  const fullValidationHandler = () => {
    let temp = {};
    if (data.first_name?.trim()?.length == 0) {
      temp = { ...temp, first_name: ("Please add first name") };
    } else {
      if (!/^[a-zA-Zء-ي ]*$/.test(data.first_name)) {
        temp = { ...temp, first_name: ("First name should have only alphabets") };
      }
    }
    if (data.last_name?.trim()?.length == 0) {
      temp = { ...temp, last_name: ("Please add last name") };
    } else {
      if (!/^[a-zA-Zء-ي ]*$/.test(data.last_name)) {
        temp = { ...temp, last_name: ("Last name should have only alphabets") };
      }
    }
    if (data.email?.trim()?.length == 0) {
      temp = { ...temp, email: ("Please add email") };
    } else {
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/.test(
          data.email
        )
      ) {
        temp = { ...temp, email: ("invalid email format") };
      }
    }
    if (data.phone_number?.trim()?.length == 0) {
      temp = { ...temp, phone_number: ("Please add phone number") };
    } else {
      if (!/^[0-9]{9}$/.test(data.phone_number)) {
        temp = { ...temp, phone_number: ("Invalid phone number") };
      }
    }
    if (incidentType?.length == 0) {
      temp = { ...temp, incidentType: ("Please select incident type") };
    }
    if (data.entity?.trim()?.length == 0) {
      temp = { ...temp, entity: ("Please add entity") };
    }
    if (data.reported_elsewhere?.trim()?.length == 0) {
      temp = { ...temp, reported_elsewhere: ("Please select an option") };
    }
    if (data.description?.trim()?.length == 0) {
      temp = { ...temp, description: ("Please add description") };
    } else {
      // if (data.description?.trim()?.length != 0) {
      //   if (!/^[a-zA-Z0-9ء-ي .؟،,-:!"?()'\n]+$/.test(data.description)) {
      //     temp = { ...temp, description: t("Invalid characters") };
      //   }
      // }
    }
    if (status?.length == 0) {
      temp = { ...temp, status: ("Please select action") };
    }
    if (data?.source?.length == 0) {
      temp = {
        ...temp,
        source: ("Please select incident source"),
      };
    }
    if (data?.source?.length != 0 && data?.source?.source_name_en == "Other") {
      if (data.other_reason?.trim()?.length == 0) {
        temp = {
          ...temp,
          other_reason: ("Please enter reason")
        };
      }
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      return true;
    }
  };

  const validate = () => {
    if ((id && status == "") || status?.handle_key == "save_draft") {
      return saveDraftValidateHandler();
    } else {
      if(status === ""){
        let temp = {}
        temp = { ...temp, status: ("Please select action") };
        setErr(temp)
        return
      }
      return saveDraftValidateHandler();
    }
  };

  const updateIncidentHandler = () => {
    let hideSuccess;
    if (status == "") {
      hideSuccess = false;
    } else {
      hideSuccess = true;
    }
    let formData = new FormData();
    formData.append("id", incident_data._id);
    formData.append("first_name", data?.first_name ?? "");
    formData.append("last_name", data?.last_name ?? "");
    formData.append("email", data?.email ?? "");
    formData.append("phone", data?.phone_number ?? "");
    formData.append("incident_entity", data?.entity ?? "");
    formData.append("incident_date", data?.date ?? "");
    formData.append(
      "is_reported_elsewhere",
      data.reported_elsewhere ? (data.reported_elsewhere === "yes" ? 1 : 0) : ""
    );
    formData.append("incident_source", data?.source?._id ?? "");
    formData.append(
      "status_id",
      status?._id ?? incident_data?.incident_status?._id
    );
    formData.append("is_status_changed", status == "" ? 0 : 1);
    formData.append("comment", data.description ?? "");
    formData.append("incident_description", data.description ?? "");
    formData.append("incident_source_other_reason", data.other_reason ?? "");
    additionalData?.forEach((item, i) => {
      formData.append(`attachments_data[${i}][attachment_type]`, item.doc);
      formData.append(`attachments_data[${i}][attachment_number]`, item.doc_id);
      formData.append(`attachments_${i}`, item.file);
    });
    formData.append(`attachments_count`, additionalData?.length);
    formData.append(
      "incident_type",
      incidentType ? incidentType?._id : incident_data?.incident_type?._id ?? ""
    );
    delOldAttachmentIds?.forEach((item, i) =>
      formData.append(`attachment_delete[${i}]`, item)
    );
    data?.source_file?.forEach((item, i) =>
      formData.append(`incident_attachment`, item)
    );
    oldSourceDeleteFiles?.forEach((item, i) =>
      formData.append(`incident_attachment_delete[${i}]`, item)
    );
    formData.append(`attachments_edit_count`, editOldIds?.length);
    oldAttachments?.forEach((item, i) => {
      if (editOldIds?.includes(item._id)) {
        formData.append(
          `attachments_edit[${i}][attachment_type]`,
          item.attachment_type
        );
        formData.append(
          `attachments_edit[${i}][attachment_number]`,
          item.attachment_number
        );
        formData.append(`attachments_edit_${i}`, item.file ?? "");
        formData.append(`attachments_edit[${i}][id]`, item._id);
      }
    });
    console.log(formData, "updateIncident");
    dispatch(
      updateIncident(formData, (res) => {
        if (!hideSuccess) {
          dispatch(
            updateIncidentRedux({
              key: "responseModal",
              value: { ...res, show: true },
            })
          );
        }
      })
    );
  };

  const incidentStatusSave = () => {
    let formData = new FormData();
    switch (status.handle_key) {
      //re-open
      case OPEN: {
        formData.append("id", incident_data._id);
        formData.append("status_id", status._id);
        formData.append("incident_comment", data?.description);
        data?.source_file?.forEach((item, i) =>
          formData.append(`attachments`, item)
        );
        console.log(formData);
        if (incident_data?.incident_status?.handle_key == "save_draft") {
          dispatch(openIncident(formData));
        } else {
          dispatch(reOpenIncident(formData));
        }
        break;
      }
      //forward
      case FORWARD: {
        if (modalType === "pool_forward" || role === POOL_USER) {
          if (forwardType === "") {
            toast.error(t("Please choose a forward type"), {
              position: "bottom-center",
              autoClose: 3000,
            });
            return;
          }
          formData.append("id", incident_data._id);
          formData.append("incident_comment", data?.description);
          formData.append("status_id", status._id);
          data?.source_file?.forEach((item, i) =>
            formData.append(`attachments`, item)
          );
          if (forwardType === "Pool User") {
            formData.append("user", forwardData.user._id);
            formData.append("to_department", 0);
          } else if (forwardType === "Other Department") {
            formData.append("department", forwardData.department._id);
            formData.append("to_department", 1);
          } else if (forwardType === "Admin") {
            formData.append("forward_to", "admin");
          } else if (forwardType === "Incident Managers") {
            formData.append("forward_to", "incident_manager");
          } else if (forwardType === "Pool Admin") {
            formData.append(
              "department",
              JSON.parse(localStorage.getItem("department"))._id
            );
            formData.append("to_department", 1);
            formData.append("forward_to ", "");
          }
          dispatch(forwardIncidentFromPoolAdmin(formData));
        } else {
          formData.append("id", incident_data._id);
          formData.append("department", forwardDept._id);
          formData.append("incident_comment", data?.description);
          data?.source_file?.forEach((item, i) =>
            formData.append(`attachments`, item)
          );
          formData.append("status_id", status._id);
          console.log(formData);
          dispatch(forwardIncident(formData));
        }
        break;
      }
      //return
      case RETURN: {
        formData.append("id", incident_data._id);
        formData.append("status_id", status._id);
        formData.append("incident_comment", data?.description);
        data?.source_file?.forEach((item, i) =>
          formData.append(`attachments`, item)
        );
        console.log(formData);
        dispatch(returnIncident(formData));
        break;
      }
      //Pending
      case PENDING: {
        formData.append("id", incident_data._id);
        formData.append("status", status._id);
        formData.append("incident_comment", data?.description);
        data?.source_file?.forEach((item, i) =>
          formData.append(`attachments`, item)
        );
        console.log(formData);
        dispatch(changeIncidentStatus(formData));
        break;
      }
      //Processing
      case PROCESSING: {
        formData.append("id", incident_data._id);
        formData.append("status", status._id);
        formData.append("incident_comment", data?.description);
        data?.source_file?.forEach((item, i) =>
          formData.append(`attachments`, item)
        );
        console.log(formData);
        dispatch(changeIncidentStatus(formData));
        break;
      }
      //close
      case CLOSE: {
        if (incident_data?.is_task_assigned === 1) {
          formData.append("id", incident_data._id);
          formData.append("status_id", status._id);
          formData.append("incident_comment", data?.description);
          let currentTaskNo = incident_data?.sub_task[0].task_no;
          formData.append("task_no", currentTaskNo);
          data?.source_file?.forEach((item, i) =>
            formData.append(`attachments`, item)
          );
          dispatch(closeIncidentTask(formData));
        } else {
          formData.append("id", incident_data._id);
          formData.append("status_id", status._id);
          formData.append("incident_comment", data?.description);
          data?.source_file?.forEach((item, i) =>
            formData.append(`attachments`, item)
          );
          dispatch(closeIncident(formData));
        }
        break;
      }
      //assign
      case ASSIGN: {
        formData.append("id", incident_data._id);
        formData.append("status_id", status._id);
        formData.append("incident_comment", data?.description);
        data?.source_file?.forEach((item, i) =>
          formData.append(`attachments`, item)
        );
        if (assignData.type === "Pool User") {
          formData.append("to_department", 0);
          formData.append("assigned_to", assignData.user._id);
          console.log(formData);
          dispatch(assignTaskToPoolUser(formData));
        } else {
          formData.append("to_department", 1);
          formData.append("department", assignData.department._id);
          console.log(formData);
          dispatch(assignTaskToPoolDepartment(formData));
        }
        break;
      }
      //reopen
      case RE_OPEN: {
        formData.append("id", incident_data._id);
        formData.append("status_id", status._id);
        formData.append("incident_comment", data?.description);
        data?.source_file?.forEach((item, i) =>
          formData.append(`attachments`, item)
        );
        console.log(formData);
        dispatch(reopenIncident(formData));
        break;
      }
      case DELETE: {
        const value = {
          id: id,
        };
        dispatch(deleteDraftedIncident(value));
      }
      default: {
        console.log("default");
        break;
      }
    }
  };

  const saveHandler = () => {
    if (status && status.handle_key === DELETE) {
      incidentStatusSave();
    } else {
      if (validate()) {
        if (id) {
          updateIncidentHandler();
          if (status != "") {
            incidentStatusSave();
          }
        } else {
          console.log(forwardData)
          let formData = new FormData();
          formData.append("first_name", data?.first_name);
          formData.append("last_name", data?.last_name);
          formData.append("phone", data?.phone_number);
          formData.append("email", data?.email);
          additionalData?.forEach((item, i) => {
            formData.append(
              `attachments_data[${i}][attachment_type]`,
              item.doc
            );
            formData.append(
              `attachments_data[${i}][attachment_number]`,
              item.doc_id
            );
            formData.append(`attachments_${i}`, item.file);
          });
          formData.append(`attachments_count`, additionalData?.length);
          formData.append("incident_type", incidentType?._id ?? "");
          formData.append("incident_entity", data?.entity);
          formData.append("incident_date", data?.date);
          formData.append(
            "is_reported_elsewhere",
            data.reported_elsewhere === "yes" ? 1 : 0
          );
          formData.append("incident_description", data.description);

          formData.append("incident_source", data?.source?._id ?? "");
          formData.append("incident_source_other_reason", data?.other_reason);
          data?.source_file?.forEach((item, i) =>
            formData.append(`incident_attachment`, item)
          );
          formData.append("incident_status", status._id);
          if (status.handle_key == "forward") {
            // formData.append(
            //   "forwarded_to",
            //   role === POOL_USER
            //     ? JSON.parse(localStorage.getItem("department"))._id
            //     : forwardData.department._id
            // );
            if (forwardType === "Pool User") {
              formData.append("user", forwardData.user._id);
              formData.append("to_department", 0);
              formData.append("forwarded_to", forwardData.user._id);
            } else if (forwardType === "Other Department") {
              formData.append("department", forwardData.department._id);
              formData.append("to_department", 1);
            } else if (forwardType === "Admin") {
              formData.append("forward_to", "admin");
            } else if (forwardType === "Incident Managers") {
              formData.append("forward_to", "incident_manager");
            } else if (forwardType === "Pool Admin") {
              formData.append(
                "department",
                JSON.parse(localStorage.getItem("department"))._id
              );
              formData.append("to_department", 1);
              formData.append("forward_to ", "");
            } else {
              formData.append("department", forwardDept._id);
            }
          }
          if (status.handle_key == ASSIGN) {
            if (assignData.type === "Pool User") {
              formData.append("to_department", 0);
              formData.append(`sub_task[assigned_to]`, assignData.user._id);
            } else {
              formData.append("to_department", 1);
              formData.append(`sub_task[assigned_dept]`, assignData.department._id);
            }
          }
          console.log(formData, "incidentIncident");
          dispatch(
            insertIncident(formData, () => {
              if (status.handle_key == "forward") setForward(false);
              if (status.handle_key == "return") setReturn(false);
              navigate("/admin/incidents/incident_requests");
            })
          );
        }
      }
    }
  };

  const onCancelHandler = () => {
    setData({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      country_code: "971+",
      entity: "",
      date: "",
      reported_elsewhere: "",
      description: "",
      source: "",
      other_reason: "",
      source_file: "",
    });
    setReturnComment("");
    setErr({});
    setAdditionalData([]);
    setAddObj({
      doc: "",
      doc_id: "",
      file: "",
    });
    setAddErr({});
    setIncidentType("");
    setForwardDept("");
    if (id) {
      dispatch(getIncidentDetails(id, displaytype, taskno));
    }
    // dispatch(
    //   updateIncidentRedux({
    //     key: "incident_details",
    //     value: {},
    //   })
    // );
    // navigate("/admin/incidents/incident_requests");
  };

  const activeStatus = incidentStatusList?.filter(
    (item) =>
      item.handle_key !== RE_OPEN &&
      item.handle_key !== RETURN &&
      item.handle_key !== ASSIGN
  );

  const hideModal = () => {
    setErr({});
    setForwardData({});
    setAssignData({});
    setUserSearchParams({ ...userSearchParams, section: "" });
    setModalType("");
    setStatus("");
  };

  const activeActionsList = () => {
    let list = incidentStatusList?.filter(
      (item) => item.handle_key !== RE_OPEN && item.handle_key !== RETURN
    );

    if (!(role === POOL_ADMIN || role === POOL_USER)) {
      list = list?.filter((item) => item.handle_key !== ASSIGN);
    }
    if (!id) {
      list = list?.filter((item) => item.handle_key !== DELETE);
    }
    if (id) {
      list = list?.filter((item) => item.handle_key !== SAVE_DRAFT);
    }

    list = list?.filter((item) => item.handle_key !== SUBMIT_TASK && item.handle_key !== CLOSE_TASK && item.handle_key !== ASSIGN_SUBTASK && item.handle_key !== REOPEN_TASK);
    return list;
  };

  useEffect(() => {
    if (status != "") {
      if (status.handle_key === FORWARD) {
        if (
          role === SUPER_ADMIN ||
          role === ADMIN ||
          role === INCIDENT_MANAGER
        ) {
          setModalType("admin_user_forward");
        } else if (role === POOL_ADMIN) {
          setModalType("pool_forward");
        } else {
          setForwardType("Pool Admin");
        }
      } else if (status.handle_key === ASSIGN) {
        setModalType("assign");
      } else if (status.handle_key === RETURN) {
        setModalType("return");
      } else if (status.handle_key === DELETE) {
        setModalType("delete")
      }
      // else {
      //   saveHandler();
      // }
    }

    //validation
    if (
      status != "" &&
      status.handle_key !== SAVE_DRAFT &&
      status.handle_key !== DELETE
    ) {
      if (validate() === false) {
        setStatus("");
        setModalType("");
      }
    }
  }, [status]);

  return (
    (
      <>
        <IncidentModal
          modalType={modalType}
          onClose={hideModal}
          onHide={hideModal}
          departmentList={departmentList}
          forwardDept={forwardDept}
          setForwardDept={setForwardDept}
          setErr={setErr}
          err={err}
          saveHandler={saveHandler}
          departmentListHavingUsers={departmentListHavingUsers}
          incidentStatusChangeLoading={incidentAddLoading}
          forwardType={forwardType}
          setForwardType={setForwardType}
          returnComment={returnComment}
          setReturnComment={setReturnComment}
          forwardData={forwardData}
          setForwardData={setForwardData}
          sectionLists={sectionLists}
          searchUsers={searchUsers}
          onDeleteOldAttachment={onDeleteOldAttachment}
          onDeleteNewAttachment={onDeleteNewAttachment}
          assignData={assignData}
          setAssignData={setAssignData}
          userSearchParams={userSearchParams}
          setUserSearchParams={setUserSearchParams}
          assignExcludeList={[]}
        />
        {/* FILE VIEW */}
        <FileViewModal
          modalshow={modalshow}
          setModalShow={setModalShow}
          fileView={fileView}
          oldFileView={oldFileView}
          url={url}
          fileType={fileType}
          fileNumber={fileNumber}
          setOldFileView={setOldFileView}
          setFileView={setFileView}
          setFileNumber={setFileNumber}
          setFileType={setFileType}
        />
        <div className="add_incidents request__details">
          <div className="container-fluid">
            <div className="section__header">
              <div className="breadcrumbs">
                <h1>{t(heading)}</h1>
                <p>
                  <Link to={"/admin/incidents/incident_requests"}>{t('Home')}</Link> /{" "}
                  {t(heading)}
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="card-header form-header">
                  <h4>{translate('Personal Information of Incident Reporter')}</h4>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"*" + t('First Name')}
                        value={data.first_name}
                        onChange={(e) => {
                          onChangeHandler("first_name", e.target.value);
                          setErr({ ...err, first_name: "" });
                        }}
                      />
                    </div>
                    <div className="text-danger">
                      <small>{t(err?.first_name)}</small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t('Last Name')}
                        value={data.last_name}
                        onChange={(e) => {
                          onChangeHandler("last_name", e.target.value);
                          setErr({ ...err, last_name: "" });
                        }}
                      />
                    </div>
                    <div className="text-danger">
                      <small>{t(err?.last_name)}</small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t('Email')}
                        value={data.email}
                        onChange={(e) => {
                          onChangeHandler("email", e.target.value);
                          setErr({ ...err, email: "" });
                        }}
                      />
                    </div>
                    <div className="text-danger">
                      <small>{t(err?.email)}</small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="align-center">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="971+"
                          disabled
                          value={data.country_code}
                          style={{ width: "100px", marginLeft: "10px" }}
                          onChange={(e) => {
                            onChangeHandler("country_code", e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group w-100">
                        <input
                          type="number"
                          maxLength={9}
                          className="form-control w-100 no-arrows"
                          placeholder={t('Phone No')}
                          onKeyDown={handleKeyDown}
                          value={data.phone_number}
                          onChange={(e) => {
                            onChangeHandler("phone_number", e.target.value);
                            setErr({ ...err, phone_number: "" });
                          }}
                        />
                      </div>
                    </div>
                    <div className="text-danger">
                      <small>{t(err?.phone_number)}</small>
                    </div>
                  </div>
                  <AddAdditionalDocument
                    page='addincident'
                    addObj={addObj}
                    onAdditionalChangeHandler={onAdditionalChangeHandler}
                    setAddErr={setAddErr}
                    addErr={addErr}
                    renderTooltip={renderTooltip}
                    setAddObj={setAddObj}
                    editNew={editNew}
                    onEditNewHandler={onEditNewHandler}
                    editOld={editOld}
                    onEditOldAdditional={onEditOldAdditional}
                    onAddHandler={onAddHandler}
                    additionalData={additionalData}
                    oldAttachments={oldAttachments}
                    fileViewHandler={fileViewHandler}
                    onDeleteAddHandler={onDeleteAddHandler}
                    onEditAddHandler={onEditAddHandler}
                    oldFileViewHandler={oldFileViewHandler}
                    onDeleteOldHandler={onDeleteOldHandler}
                    onEditOldHandler={onEditOldHandler}
                    onClearHandler={onClearHandler}
                  />
                </div>
                <div className="card-header mt-5 form-header">
                  <h4>{t("Details of the Incident")}</h4>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Select
                        name="colors"
                        options={incidentTypeList}
                        value={incidentType}
                        // getOptionLabel={(option) => option.incident_type}
                        getOptionLabel={(option) => option['incident_type' + (lang === 'ar' ? '_ar' : '')]}
                        getOptionValue={(option) => option._id}
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder={"*" + t('Incident Type')}
                        onChange={(e) => {
                          setIncidentType(e);
                          setErr({ ...err, incidentType: "" });
                        }}
                      />
                    </div>
                    <div className="text-danger">
                      <small>{t(err?.incidentType)}</small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t('Name of Entity')}
                        value={data.entity}
                        onChange={(e) => {
                          onChangeHandler("entity", e.target.value);
                          setErr({ ...err, entity: "" });
                        }}
                      />
                    </div>
                    <div className="text-danger">
                      <small>{t(err?.entity)}</small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group date__picker__group">
                      <DatePicker
                        selected={data.date}
                        onChange={(date) => {
                          onChangeHandler("date", date);
                          setErr({ ...err, date: "" });
                        }}
                        maxDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                        className=""
                        placeholderText={t(`Date of Incident`)}
                      />
                      <img
                        className="calendar"
                        style={{ left: "0px" }}
                        src="/assets/icons/calendar.svg"
                        alt=""
                      />
                    </div>
                    <div className="text-danger">
                      <small>{t(err?.date)}</small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="reported-date">
                      <div className="form-group mt-0">
                        <h6 className="m-0" style={{ opacity: 0.62 }}>
                          {t('?Is this incident reported elsewhere')}
                        </h6>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input mt-0"
                          type="radio"
                          name="exampleRadios"
                          id="yes"
                          value={t("yes")}
                          checked={data.reported_elsewhere == "yes"}
                          onChange={(e) => {
                            onChangeHandler(
                              "reported_elsewhere",
                              e.target.value
                            );
                            setErr({ ...err, reported_elsewhere: "" });
                          }}
                        />
                        <label className="form-check-label" for="yes">
                          {t('Yes')}
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input mt-0"
                          type="radio"
                          name="exampleRadios"
                          id="no"
                          value={t("no")}
                          checked={data.reported_elsewhere == "no"}
                          onChange={(e) => {
                            onChangeHandler(
                              "reported_elsewhere",
                              e.target.value
                            );
                            setErr({ ...err, reported_elsewhere: "" });
                          }}
                        />
                        <label className="form-check-label" for="no">
                          {t('No')}
                        </label>
                      </div>
                    </div>
                    <div className="text-danger">
                      <small>{t(err?.reported_elsewhere)}</small>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        name=""
                        rows="3"
                        value={data.description}
                        className="form-control"
                        placeholder={t('Description')}
                        onChange={(e) => {
                          onChangeHandler("description", e.target.value);
                          setReturnComment(e.target.value);
                          setErr({ ...err, description: "" });
                        }}
                      ></textarea>
                    </div>
                    <div className="text-danger">
                      <small>{t(err?.description)}</small>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <Select
                        name="colors"
                        options={incidentSourceList?.filter((ele, i) => ele?.handle_key != "web")}
                        // getOptionLabel={(option) => option.source_name_en}
                        getOptionLabel={(option) => option['source_name' + (lang === 'ar' ? '_ar' : '_en')]}
                        getOptionValue={(option) => option._id}
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder={t('Source')}
                        onChange={(e) => {
                          onChangeHandler("source", e);
                          setErr({ ...err, source: "" });
                        }}
                        value={data?.source}
                      />
                    </div>
                    <div className="text-danger">
                      <small>{t(err?.source)}</small>
                    </div>
                  </div>
                  {data?.source?.source_name_en == "Other" ? (
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("Enter Other")}
                          value={data.other_reason}
                          onChange={(e) => {
                            onChangeHandler("other_reason", e.target.value);
                            setErr({ ...err, other_reason: "" });
                          }}
                        />
                        <div className="text-danger">
                          <small>{t(err?.other_reason)}</small>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-6"></div>
                  )}

                  <div className="col-md-3">
                    <div className="form-group" dir="ltr">
                      <div className="upload-btn-wrapper mt-2">
                        <label
                          className="btn btn-upload"
                          style={{ fontWeight: "600" }}
                          htmlFor="addincidentfileinput"
                        >
                          {t('Add Attachments')}
                          <img
                            src="/assets/icons/icon-upload-blue.svg"
                            alt=""
                            className="mr-2"
                          />{" "}
                        </label>
                        <input
                          type="file"
                          name="myfile"
                          multiple
                          hidden
                          id="addincidentfileinput"
                          accept=".jpeg, .jpg, .png, .pdf, .PDF, .mp4, .docx, .doc, .mov"
                          onClick={(e) => e.target.value = null}
                          onChange={(e) => onSourseFileChangeHandler(e)}
                        />
                      </div>
                      <p className="supported__formats">
                        {t('Supported formats : jpeg, jpg, png, pdf, mp4, docx, doc, mov and Size upto 100 MB')}
                      </p>
                    </div>
                  </div>
                </div>
                {!!(data.source_file.length || oldSourceFiles.length) && <div className="attachment-files small-screen-right">
                  {data.source_file &&
                    data.source_file?.map((file, i) => {
                      return (
                        <>
                          {file.type === "image/jpeg" ||
                            file.type === "image/jpg" ||
                            file.type === "image/webp" ||
                            file.type === "image/png" ? (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip({
                                label: file.name,
                              })}
                            >
                              <span className="file-container">
                                <img
                                  className="file"
                                  src={URL.createObjectURL(file)}
                                  alt=""
                                  onClick={() => fileUploadViewHandler(file)}
                                />
                                <img
                                  className="file-close"
                                  src="/assets/icons/Group 11563.svg"
                                  alt=""
                                  onClick={() => {
                                    setData({
                                      ...data,
                                      source_file: data.source_file.filter(
                                        (item) => file != item
                                      ),
                                    });
                                    setErr({
                                      ...err,
                                      source_file: "",
                                    });
                                  }}
                                />
                              </span>
                            </OverlayTrigger>
                          ) : file.type === "application/pdf" ? (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip({
                                label: file.name,
                              })}
                            >
                              <span className="file-container">
                                <a
                                  href={`${URL.createObjectURL(file)}`}
                                  target="_blank"
                                >
                                  <img
                                    className="file"
                                    src="/assets/icons/files/pdf.svg"
                                    alt=""
                                  />
                                </a>
                                <img
                                  className="file-close"
                                  src="/assets/icons/Group 11563.svg"
                                  alt=""
                                  onClick={() => {
                                    setData({
                                      ...data,
                                      source_file: data.source_file.filter(
                                        (item) => file != item
                                      ),
                                    });
                                    setErr({
                                      ...err,
                                      source_file: "",
                                    });
                                  }}
                                />
                              </span>
                            </OverlayTrigger>
                          ) : file.type === "video/mp4" ? (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip({
                                label: file.name,
                              })}
                            >
                              <span className="file-container">
                                <img
                                  className="file"
                                  src="/assets/icons/files/mp4.svg"
                                  alt=""
                                  onClick={() => fileUploadViewHandler(file)}
                                />
                                <img
                                  className="file-close"
                                  src="/assets/icons/Group 11563.svg"
                                  alt=""
                                  onClick={() => {
                                    setData({
                                      ...data,
                                      source_file: data.source_file.filter(
                                        (item) => file != item
                                      ),
                                    });
                                    setErr({
                                      ...err,
                                      source_file: "",
                                    });
                                  }}
                                />
                              </span>
                            </OverlayTrigger>
                          ): file.type === "video/quicktime" ? (
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip({
                                  label: file.name,
                                })}
                              >
                                <span className="file-container">
                                  <img
                                    className="file"
                                    src="/assets/icons/files/mov.svg"
                                    alt=""
                                    onClick={() => fileUploadViewHandler(file)}
                                  />
                                  <img
                                    className="file-close"
                                    src="/assets/icons/Group 11563.svg"
                                    alt=""
                                    onClick={() => {
                                      setData({
                                        ...data,
                                        source_file: data.source_file.filter(
                                          (item) => file != item
                                        ),
                                      });
                                      setErr({
                                        ...err,
                                        source_file: "",
                                      });
                                    }}
                                  />
                                </span>
                              </OverlayTrigger>
                            ): file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                file.type === 'application/msword' ? (
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip({
                                    label: file.name,
                                  })}
                                >
                                  <span className="file-container">
                                    <a
                                      href={`${URL.createObjectURL(file)}`}
                                      target="_blank"
                                    >
                                      <img
                                        className="file"
                                        src={
                                          file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? "/assets/icons/files/docx.svg"
                                            : file.type === 'application/msword' ? "/assets/icons/files/doc.svg" : ''
                                        }
                                        alt=""
                                      />
                                    </a>
                                    <img
                                      className="file-close"
                                      src="/assets/icons/Group 11563.svg"
                                      alt=""
                                      onClick={() => {
                                        setData({
                                          ...data,
                                          source_file: data.source_file.filter(
                                            (item) => file != item
                                          ),
                                        });
                                        setErr({
                                          ...err,
                                          source_file: "",
                                        });
                                      }}
                                    />
                                  </span>
                                </OverlayTrigger>
                              ) : null}
                        </>
                      );
                    })}
                  {oldSourceFiles &&
                    oldSourceFiles?.map((file, i) => {
                      return (
                        <>
                          {["jpg", "jpeg", "webp", "png"].includes(file?.split(".")?.reverse()[0]) ? (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip({
                                label: file,
                              })}
                            >
                              <span className="file-container">
                                <img
                                  className="file"
                                  src={`${IMAGE_BASE_URL}${url}/${file}`}
                                  alt=""
                                  onClick={() => oldFileUploadViewHandler(file)}
                                />
                                <img
                                  className="file-close"
                                  src="/assets/icons/Group 11563.svg"
                                  alt=""
                                  onClick={() => {
                                    setOldSourceFiles(oldSourceFiles.filter(
                                      (item) => file != item
                                    ));
                                    setOldSourceDeleteFiles([...oldSourceDeleteFiles, file]);
                                  }}
                                />
                              </span>
                            </OverlayTrigger>
                          ) : ["pdf", "PDF"].includes(file?.split(".")?.reverse()[0]) ? (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip({
                                label: file,
                              })}
                            >
                              <span className="file-container">
                                <a
                                  href={`${IMAGE_BASE_URL}${url}/${file}`}
                                  target="_blank"
                                >
                                  <img
                                    className="file"
                                    src="/assets/icons/files/pdf.svg"
                                    alt=""
                                  />
                                </a>
                                <img
                                  className="file-close"
                                  src="/assets/icons/Group 11563.svg"
                                  alt=""
                                  onClick={() => {
                                    setOldSourceFiles(oldSourceFiles.filter(
                                      (item) => file != item
                                    ));
                                    setOldSourceDeleteFiles([...oldSourceDeleteFiles, file]);
                                  }}
                                />
                              </span>
                            </OverlayTrigger>
                          ) : ["mp4"].includes(file?.split(".")?.reverse()[0]) ? (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip({
                                label: file,
                              })}
                            >
                              <span className="file-container">
                                <img
                                  className="file"
                                  src="/assets/icons/files/mp4.svg"
                                  alt=""
                                  onClick={() => oldFileUploadViewHandler(file)}
                                />
                                <img
                                  className="file-close"
                                  src="/assets/icons/Group 11563.svg"
                                  alt=""
                                  onClick={() => {
                                    setOldSourceFiles(oldSourceFiles.filter(
                                      (item) => file != item
                                    ));
                                    setOldSourceDeleteFiles([...oldSourceDeleteFiles, file]);
                                  }}
                                />
                              </span>
                            </OverlayTrigger>
                          ) : ["mov"].includes(file?.split(".")?.reverse()[0]) ? (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip({
                                label: file,
                              })}
                            >
                              <span className="file-container">
                                <img
                                  className="file"
                                  src="/assets/icons/files/mov.svg"
                                  alt=""
                                  onClick={() => oldFileUploadViewHandler(file)}
                                />
                                <img
                                  className="file-close"
                                  src="/assets/icons/Group 11563.svg"
                                  alt=""
                                  onClick={() => {
                                    setOldSourceFiles(oldSourceFiles.filter(
                                      (item) => file != item
                                    ));
                                    setOldSourceDeleteFiles([...oldSourceDeleteFiles, file]);
                                  }}
                                />
                              </span>
                            </OverlayTrigger>
                          ) : ["docx"].includes(file?.split(".")?.reverse()[0]) ? (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip({
                                label: file,
                              })}
                            >
                              <span className="file-container">
                                <a
                                  href={`${IMAGE_BASE_URL}${url}/${file}`}
                                  target="_blank"
                                >
                                  <img
                                    className="file"
                                    src="/assets/icons/files/docx.svg"
                                    alt=""
                                  />
                                </a>
                                <img
                                  className="file-close"
                                  src="/assets/icons/Group 11563.svg"
                                  alt=""
                                  onClick={() => {
                                    setOldSourceFiles(oldSourceFiles.filter(
                                      (item) => file != item
                                    ));
                                    setOldSourceDeleteFiles([...oldSourceDeleteFiles, file]);
                                  }}
                                />
                              </span>
                            </OverlayTrigger>
                          ) : ['doc'].includes(file?.split(".")?.reverse()[0]) ? (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip({
                                label: file,
                              })}
                            >
                              <span className="file-container">
                                <a
                                  href={`${IMAGE_BASE_URL}${url}/${file}`}
                                  target="_blank"
                                >
                                  <img
                                    className="file"
                                    src="/assets/icons/files/doc.svg"
                                    alt=""
                                  />
                                </a>
                                <img
                                  className="file-close"
                                  src="/assets/icons/Group 11563.svg"
                                  alt=""
                                  onClick={() => {
                                    setOldSourceFiles(oldSourceFiles.filter(
                                      (item) => file != item
                                    ));
                                    setOldSourceDeleteFiles([...oldSourceDeleteFiles, file]);
                                  }}
                                />
                              </span>
                            </OverlayTrigger>
                          ) : null}
                        </>
                      );
                    })}
                </div>}
                <div className="text-danger small-screen-right">
                  <small>{t(err?.source_file)}</small>
                </div>
                <div className="row mb-5 pb-5">
                  <div className="footer__btns mb-5">
                    <div className="back-btns">
                      <button
                        className="btn btn-outline-primary"
                        onClick={() =>
                          navigate("/admin/incidents/incident_requests")
                        }
                      >
                        {" "}
                        <img src="/assets/icons/Back.svg" alt="" /> {t('Back')}
                      </button>
                    </div>
                    <div
                      className="flex flex-column"
                      style={{ minWidth: "400px" }}
                    >
                      <div
                        className="btn-actions"
                        style={{ minWidth: "400px", alignItems: "flex-end" }}
                      >
                        <div className="action" style={{ width: 140 }}>
                          <label htmlFor="" style={{ opacity: 0.58 }}>
                            {t('Action')}
                          </label>
                          <div className="status-select">
                            <Select
                              // menuIsOpen
                              name="colors"
                              options={activeActionsList()}
                              value={status}
                              getOptionLabel={(option) =>
                                option['incident_status_' + lang]
                              }
                              getOptionValue={(option) => option._id}
                              className="basic-single"
                              classNamePrefix="select"
                              placeholder={t("Select")}
                              onChange={(e) => {
                                setStatus(e);
                                setErr({ ...err, status: "" });
                              }}
                              styles={customStyles}
                              isDisabled={id && incident_data?.created_by?._id !== userID}
                            />
                          </div>
                          <div
                            className="text-danger"
                            style={{ position: "absolute" }}
                          >
                            <small>{t(err?.status)}</small>
                          </div>
                        </div>
                        <button
                          className="btn btn-outline-primary"
                          onClick={saveHandler}
                          disabled={
                            incidentAddLoading || incidentStatusChangeLoading
                            || (id && incident_data?.created_by?._id !== userID)
                          }
                        >
                          {incidentAddLoading || incidentStatusChangeLoading ? (
                            <Spinner
                              animation="border"
                              role="status"
                              size="sm"
                            ></Spinner>
                          ) : (
                            t("Save")
                          )}
                        </button>
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => onCancelHandler()}
                        >
                          {t('Cancel')}
                        </button>
                      </div>
                      {/* <div className="text-danger align-center justify-content-center">
                        <small>{err?.final}</small>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default AddIncident;
