import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name : 'commonReducer',
    initialState : {
        value: true,
        department_details : [],
        log_sort_column : "createdAt" ,
        log_sort_ascending : false,
        log_page : 0,
        log_per_page : 10,
        log_from_date : "",
        log_to_date : "",
        logout_loader : false
    },
    reducers : {
        updateRedux: (state , data) => {
            state[data.payload.key ] = data.payload.value
        },
    },
})
export const { updateRedux } = commonSlice.actions;

export const value = (state ) => state.commonReducer.value;
export const user_role = (state ) => state.commonReducer.user_role;
export const user_list = (state ) => state.commonReducer.user_list;
export const normal_users_list = (state ) => state.commonReducer.normal_users_list;
export const normal_users_list_page_count = (state ) => state.commonReducer.normal_users_list_page_count;
export const pool_users_list_page_count = (state ) => state.commonReducer.pool_users_list_page_count;
export const pool_users_list_page_status = (state ) => state.commonReducer.pool_users_list_page_status;
export const incident_list_page_status = (state ) => state.commonReducer.incident_list_page_status;
export const pool_users_list = (state ) => state.commonReducer.pool_users_list;
export const section_details = (state ) => state.commonReducer.section_details;
export const department_details = (state ) => state.commonReducer.department_details;
export const pool_users_list_count = (state ) => state.commonReducer.pool_users_list_count;
export const pool_users_list_loading = (state ) => state.commonReducer.pool_users_list_loading;
export const normal_users_list_count = (state ) => state.commonReducer.normal_users_list_count;
export const pool_normal_users_list_loading = (state ) => state.commonReducer.pool_normal_users_list_loading;
export const delete_user_privilege_loading = (state ) => state.commonReducer.delete_user_privilege_loading;
export const delete_user_privilege_success = (state ) => state.commonReducer.delete_user_privilege_success;
export const users_list_loading = (state ) => state.commonReducer.users_list_loading;
export const users_list = (state ) => state.commonReducer.users_list;
export const users_list_total_count = (state ) => state.commonReducer.users_list_total_count;
export const users_list_page_count = (state ) => state.commonReducer.users_list_page_count;
export const users_list_page_status = (state ) => state.commonReducer.users_list_page_status;
export const roles_details = (state ) => state.commonReducer.roles_details;
export const employee_details = (state ) => state.commonReducer.employee_details;
export const log_list_loading = (state ) => state.commonReducer.log_list_loading;
export const activity_log_list = (state ) => state.commonReducer.activity_log_list;
export const activity_log_total_count = (state ) => state.commonReducer.activity_log_total_count;
export const activity_log_page_count = (state ) => state.commonReducer.activity_log_page_count;
export const activity_log_page_status = (state ) => state.commonReducer.activity_log_page_status;
export const session_log_list = (state ) => state.commonReducer.session_log_list;
export const session_log_total_count = (state ) => state.commonReducer.session_log_total_count;
export const session_log_page_count = (state ) => state.commonReducer.session_log_page_count;
export const session_log_page_status = (state ) => state.commonReducer.session_log_page_status;
export const log_from_date = (state ) => state.commonReducer.log_from_date;
export const log_to_date = (state ) => state.commonReducer.log_to_date;
export const log_sort_column = (state ) => state.commonReducer.log_sort_column;
export const log_sort_ascending = (state ) => state.commonReducer.log_sort_ascending;
export const log_page = (state ) => state.commonReducer.log_page;
export const log_per_page = (state ) => state.commonReducer.log_per_page;
export const login_loading = (state ) => state.commonReducer.login_loading;
export const task_selected = (state ) => state.commonReducer.task_selected;
export const logout_loader = (state ) => state.commonReducer.logout_loader;
export const log_user_data = (state ) => state.commonReducer.log_user_data;
export const notifications = (state ) => state.commonReducer.notifications;
export const clear_all_notification_loading = (state ) => state.commonReducer.clear_all_notification_loading;
export const notifications_count = (state ) => state.commonReducer.notifications_count; 
export const department_details_having_users = (state ) => state.commonReducer.department_details_having_users;
export const section_details_having_users = (state ) => state.commonReducer.section_details_having_users;

export default commonSlice.reducer;