import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequestType } from "../../redux/hrActionCreator";
import { request_type_details } from "../../redux/hrReducer";
import SearchSelect, { Option } from "../../Tools/SearchSelect/SearchSelect";
import "./RequestDetailsForm.scss";

export default function RequestDetailsForm({
  data,
  onChangeHandler,
  certificate,
  setCerificate,
  language,
  setLanguage,
  err,
}) {
  const dispatch = useDispatch();

  const requestTypeList = useSelector(request_type_details);

  useEffect(() => {
    dispatch(getRequestType());
  }, []);

  return (
    <div id="RequestDetailsForm">
      <div className="title">
        <h2>Request Details</h2>
      </div>
      <div className="inputs">
        <div className="selects">
          <div style={{ width: "100%" }}>
            <span>
              {requestTypeList?.length ? (
                <>
                  <SearchSelect
                    title="Select Certificate*"
                    onChange={setCerificate}
                    value={certificate}
                  >
                    {/* <Option value="Salary Certificate">Salary Certificate</Option>
              <Option value="Detail Salary Certificate">
                Detail Salary Certificate
              </Option>
              <Option value="Employment Letter">Employment Letter</Option> */}
                    {requestTypeList?.length &&
                      requestTypeList?.map((ele, i) => {
                        return (
                          <Option value={ele._id} key={i}>
                            {ele.request_type}
                          </Option>
                        );
                      })}
                  </SearchSelect>
                  <i>
                    <img
                      src="/assets/icons/Icon ionic-ios-arrow-down.svg"
                      alt=""
                    />
                  </i>
                </>
              ) : null}
            </span>
            <div className="text-danger">
              <small>{err.certificate}</small>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <span>
              <SearchSelect
                title="Select Certificate Language*"
                searchInput={false}
                onChange={setLanguage}
                value={language}
              >
                <Option value="Arabic">Arabic</Option>
                <Option value="English">English</Option>
              </SearchSelect>
              <i>
                <img src="/assets/icons/Icon ionic-ios-arrow-down.svg" alt="" />
              </i>
            </span>
            <div className="text-danger">
              <small>{err.language}</small>
            </div>
          </div>
        </div>
        <div>
          <input
            type="text"
            placeholder="Enter Addressee*"
            onChange={(e) => onChangeHandler("address", e.target.value)}
            value={data.address}
          />
          <div className="text-danger">
            <small>{err.address}</small>
          </div>
        </div>
        <div>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            placeholder="Comments"
            onChange={(e) => onChangeHandler("comment", e.target.value)}
            value={data.comment}
          ></textarea>
          <div className="text-danger">
            <small>{err.comment}</small>
          </div>
        </div>
      </div>
    </div>
  );
}
