import axios from 'axios';
import { toast } from 'react-toastify';
import { API_BASE_URL, SYNC_BASE_URL } from "../constants/configuration";
import axiosInstance from "../utils/axiosInterceptor";
import axiosFormInstance from '../utils/axiosFormInterceptor';
import { updateRedux } from './commonReducer';

export const login = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "login_loading", value: true }))
  axios.post(`${API_BASE_URL}admin/checkLogin`, data).then(res => {
    dispatch(updateRedux({ key: "login_loading", value: false }))
    if (res.data.status == true) {
      localStorage.setItem('token', res.data.accessToken)
      localStorage.setItem('user_type', res.data.role)
      localStorage.setItem('username', res.data.username)
      localStorage.setItem('user', JSON.stringify(res.data))
      localStorage.setItem('user_id', res.data._id)
      res.data.department && res.data.department.length && localStorage.setItem('department', JSON.stringify(res.data.department[0]))
      dispatch(updateRedux({ key: "user_role", value: res.data.role }))
      if (callback) {
        callback(res.data)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "login_loading", value: false }))
    console.log(err)
  })
}


export const getAllUsers = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getAllUsers`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "user_list", value: res.data.user_list }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getAllNormalUsers = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "pool_normal_users_list_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/get-normal-users`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "normal_users_list", value: res.data.data.users }))
      dispatch(updateRedux({ key: "normal_users_list_page_count", value: res.data.data.pages }))
      dispatch(updateRedux({ key: "pool_normal_users_list_loading", value: false }))
      dispatch(updateRedux({ key: "incident_list_page_status", value: res.data.data.page_status }))

    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      dispatch(updateRedux({ key: "pool_normal_users_list_loading", value: false }))
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getPoolUsers = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "pool_users_list_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/get-poolPrivilage-users`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "pool_users_list", value: res.data.data.users }))
      dispatch(updateRedux({ key: "pool_users_list_count", value: res.data.data.total_count }))
      dispatch(updateRedux({ key: "pool_users_list_page_count", value: res.data.data.pages }))
      dispatch(updateRedux({ key: "pool_users_list_page_status", value: res.data.data.page_status }))
      dispatch(updateRedux({ key: "pool_users_list_loading", value: false }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      dispatch(updateRedux({ key: "pool_users_list_loading", value: false }))

    }
  }).catch((err) => {
    console.log(err)
  })
}

export const deletePoolPrivilegeFromUser = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "delete_user_privilege_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/deletePoolPrivillageFromUsers`, data).then((res) => {
    dispatch(updateRedux({ key: "delete_user_privilege_loading", value: false }))
    if (res.data.status) {
      callback && callback({ status: true, message: res.data.message })
      dispatch(updateRedux({ key: "delete_user_privilege_success", value: true }))
    } else {
      callback && callback({ status: false, message: res.data.message })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "delete_user_privilege_loading", value: false }))
    console.log(err)
  })
}

export const getSearchAllNormalUsers = (params) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/get-normal-users${params}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "normal_users_list", value: res.data.data.users }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getDepartment = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getDepartment`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "department_details", value: res.data.department_details }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getSection = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getSection/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "section_details", value: res.data.section_details }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getDepartmentHavingUsers = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getDepartment?listingOnly=true`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "department_details_having_users", value: res.data.department_details }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getSectionHavingUsers = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getSection/${id}?listingOnly=true`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "section_details_having_users", value: res.data.section_details }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getAllIncidentUsers = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "users_list_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/getAllIncidentUsers`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "users_list", value: res.data.users_list }))
      dispatch(updateRedux({ key: "users_list_total_count", value: res.data.pages }))
      dispatch(updateRedux({ key: "users_list_page_count", value: res.data.page }))
      dispatch(updateRedux({ key: "users_list_page_status", value: res.data.page_status }))
      dispatch(updateRedux({ key: "users_list_loading", value: false }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      dispatch(updateRedux({ key: "users_list_loading", value: false }))

    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getUserActivityLog = (data) => (dispatch) => {
  // dispatch(updateRedux({ key: "log_list_loading", value: true }))
  axiosInstance.post(`${API_BASE_URL}admin/user-activity-logs`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "activity_log_list", value: res.data.data.logs }))
      dispatch(updateRedux({ key: "activity_log_total_count", value: res.data.data.pages }))
      dispatch(updateRedux({ key: "activity_log_page_count", value: res.data.data.page }))
      dispatch(updateRedux({ key: "activity_log_page_status", value: res.data.data.page_status }))
      dispatch(updateRedux({ key: "log_user_data", value: res.data.data.user }))
      dispatch(updateRedux({ key: "log_list_loading", value: false }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      dispatch(updateRedux({ key: "log_list_loading", value: false }))

    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getUserSessionLog = (data) => (dispatch) => {
  console.log(data)
  // dispatch(updateRedux({ key: "log_list_loading", value: true }))
  axiosInstance.post(`${API_BASE_URL}admin/getSessionLogs`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "session_log_list", value: res.data.data }))
      dispatch(updateRedux({ key: "session_log_total_count", value: res.data.pages }))
      dispatch(updateRedux({ key: "session_log_page_count", value: res.data.page }))
      dispatch(updateRedux({ key: "session_log_page_status", value: res.data.page_status }))
      dispatch(updateRedux({ key: "log_user_data", value: res.data.user }))
      dispatch(updateRedux({ key: "log_list_loading", value: false }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      dispatch(updateRedux({ key: "log_list_loading", value: false }))

    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getAllUserRoles = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getAllUserRoles`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "roles_details", value: res.data.user_roles }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getEmployeelist = (data) => (dispatch) => {
  console.log("111")
  axiosInstance.post(`${API_BASE_URL}admin/getAllEmployeeList`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "employee_details", value: res.data.user_list }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const logout = (callback) => (dispatch) => {
  let data = {
    accessToken: localStorage.getItem("token")
  }
  dispatch(updateRedux({ key: "logout_loader", value: true }))
  axiosInstance.post(`${API_BASE_URL}admin/logout`, data).then((res) => {
    dispatch(updateRedux({ key: "logout_loader", value: false }))
    if (res.data.status) {
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "logout_loader", value: false }))
    console.log(err)
  })
}


export const getNotification = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getNotification`).then((res) => {
    // console.log('Aslam',res.data.notifications)
    if (res.data.status) {
      dispatch(updateRedux({ key: "notifications", value: res.data.notifications }))
      dispatch(updateRedux({ key: "notifications_count", value: res.data.count }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const deleteNotification = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}admin/deleteNotification`, data).then((res) => {
    if (res.data.status) {
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const deleteAllNotification = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "clear_all_notification_loading", value: true }))
  axiosInstance.post(`${API_BASE_URL}admin/deleteAllNotification`).then((res) => {
    dispatch(updateRedux({ key: "clear_all_notification_loading", value: false }))
    if (res.data.status) {
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "clear_all_notification_loading", value: false }))
    console.log(err)
  })
}

export const syncAd = (callback) => (dispatch) => {
  axiosInstance.post(`${SYNC_BASE_URL}admin/activeDirectorySync`).then((res) => {
    if (res.data.status) {
      if(callback)callback()
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}