import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useTrans from '../../utils/useTrans'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'

export default function Test() {

    const { t } = useTrans()

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(()=> setLoading(false), 1000);
        return () => {
            setLoading(true)
        }
    }, [])


    return (
        loading ? 
        <></>:
        <div className='d-flex justify-content-center align-items-end' style={{ height: '40vh' }}>
            <h1 style={{ fontSize: '34px' }}>!{t('Page Not Found')}</h1>
        </div>
    )
}
