import React from "react";
import { useNavigate } from "react-router-dom";
import useGetRole from "../../../utils/useGetRole";
import {
    ADMIN,
    CLOSE,
    CLOSE_TASK,
    INCIDENT_MANAGER,
    POOL_ADMIN,
    POOL_USER,
    SUBMIT_TASK,
    SUPER_ADMIN,
} from "../../../constants/configuration";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { customStyles } from "../../../utils/styles";
import { task_selected } from "../../../redux/commonReducer";
import { useSelector } from "react-redux";
import useTrans from "../../../utils/useTrans";

const IncidentDetailsFooterSections = ({
    handleReOpen,
    incident_data,
    editPermission,
    incidentStatusChangeLoading,
    incidentStatusChangeSuccess,
    activeActionsList,
    status,
    setStatus,
    comment,
    err,
    setErr,
    saveHandler,
    onCancelHandler,
    handleCloseTask,
    handleReOpenTask,
    handleSubmitTask,
    receivedTask,
    isEligibleToAssign,
    incidentTaskStatusChangeLoading,
    closeOrSubmitTaskOrCloseIncident,
    taskno
}) => {
    const navigate = useNavigate();
    const { role } = useGetRole();
    const { t, lang } = useTrans()

    const selectedTask = useSelector(task_selected);
    const isTaskSelected = () => {
        // console.log({ isTaskSelected: selectedTask && !(selectedTask.item.title.includes("Main Request") || selectedTask.item.title.includes("Main Task")) })
        return selectedTask && !(selectedTask?.item.title.includes(t("Main Request")) || (incident_data?.is_task_assigned ? (selectedTask?.item.title.includes(t("Main Task"))) : !(selectedTask?.item.details)))
    }

    // console.log({ selectedTask })
    const assignedTaskSubmitted = () => {
        // console.log({ receivedTask })
        return incident_data?.is_task_assigned && (receivedTask?.status?.handle_key === SUBMIT_TASK || receivedTask?.status?.handle_key === CLOSE_TASK)
    }

    const selecedTaskParentNotSubmitted = () => {
        if (selectedTask) {
            let parentTask = selectedTask?.item?.details?.task?.parent_sub_task && incident_data?.sub_task?.find(item => item.task_no === selectedTask?.item?.details?.task?.parent_sub_task)
            if (parentTask && (parentTask.status?.handle_key === SUBMIT_TASK || parentTask.status?.handle_key === CLOSE_TASK)) {
                return false
            }
            return true
        }
        return true
    }


    const showReOpenIncidentButton = () => {
        let flag = false;
        if (
            (incident_data?.incident_status?.handle_key === CLOSE &&
                (role === SUPER_ADMIN ||
                    role === ADMIN ||
                    role === INCIDENT_MANAGER)) &&
            !(isTaskSelected())
        ) {
            flag = true;
        }
        return flag;
    };

    const showActionsSelect = () => {
        let flag = false;
        if (incident_data?.incident_status?.handle_key !== CLOSE && incident_data?.can_act == true && ((role === SUPER_ADMIN || role === INCIDENT_MANAGER || role === ADMIN) ? true : (incident_data?.is_incident_assigned ? incident_data?.department_active !== 0 : true)) && !(isTaskSelected()) && (!(assignedTaskSubmitted()))) {
            flag = true;
        }
        if (incident_data?.is_task_assigned && !(isEligibleToAssign) && role === POOL_ADMIN) {
            flag = false
        }
        if (showSubmitTaskButton() || showCloseTaskButton()) {
            flag = false
        }
        // console.log(!(isTaskSelected()))
        return flag;
    };

    const showCancelButton = () => {
        let flag = false;
        if (
            (
                showActionsSelect() ||
                showReOpenIncidentButton() ||
                showCloseTaskButton() ||
                showReopenTaskButton() ||
                showSubmitTaskButton() ||
                (incident_data?.is_task_assigned &&
                    role === POOL_ADMIN &&
                    incident_data?.is_closed !== true &&
                    !(assignedTaskSubmitted())
                ) ||
                ((role === ADMIN || role === SUPER_ADMIN || role === INCIDENT_MANAGER) && isTaskSelected() ? (selectedTask?.item.details?.task?.is_consider !== false) : true)
            ) &&
            (selectedTask ? selectedTask?.item?.details?.task?.exit_task !== "1" : true) &&
            editPermission &&
            (isTaskSelected() ? (selecedTaskParentNotSubmitted() === true && selectedTask?.item?.details?.task?.is_consider && incident_data?.incident_status?.handle_key !== CLOSE) : true)
        ) {
            flag = true;
        }
        return flag;
    };
    console.log({ selectedTask })

    const showSaveButton = () => {
        // console.log({ selectedTask })
        let flag = false;
        if (
            (
                showActionsSelect() ||
                showCloseTaskButton() ||
                showSubmitTaskButton() ||
                (incident_data?.is_task_assigned &&
                    role === POOL_ADMIN &&
                    receivedTask && receivedTask.is_transfered !== "1" &&
                    incident_data?.is_closed !== true &&
                    !(assignedTaskSubmitted()) &&
                    (selectedTask ? selectedTask?.item.details?.task.exit_task !== "1" : true)
                )
                ||
                ((role === ADMIN || role === SUPER_ADMIN || role === INCIDENT_MANAGER) &&
                    (isTaskSelected() ? (selectedTask?.item.details?.task.status.handle_key !== CLOSE_TASK && selectedTask?.item.details?.task.status.handle_key !== SUBMIT_TASK) : true) &&
                    incident_data?.incident_status?.handle_key !== CLOSE
                )
            )
            &&
            editPermission
        ) {
            flag = true;
        }
        return flag;
    };

    const showCloseTaskButton = () => {
        let flag = false;
        if (
            (role === POOL_USER || role === POOL_ADMIN || role === ADMIN || role === SUPER_ADMIN || role === INCIDENT_MANAGER) &&
            incident_data?.incident_status?.handle_key !== CLOSE &&
            (isTaskSelected() || (role === POOL_ADMIN && receivedTask && receivedTask.is_transfered === "1")) &&
            selectedTask?.item.details?.task.status.handle_key !== SUBMIT_TASK &&
            selectedTask?.item.details?.task.status.handle_key !== CLOSE_TASK &&
            incident_data?.can_act == true &&
            ((role === SUPER_ADMIN || role === INCIDENT_MANAGER || role === ADMIN) ? true : (incident_data?.is_incident_assigned ? incident_data?.department_active !== 0 : true))
            &&
            !(assignedTaskSubmitted())
        ) {
            flag = true
        }
        return flag;
    };

    const showReopenTaskButton = () => {
        let flag = false;
        if (
            (role === POOL_USER || role === POOL_ADMIN || role === ADMIN || role === SUPER_ADMIN || role === INCIDENT_MANAGER) &&
            incident_data?.incident_status?.handle_key !== CLOSE &&
            (isTaskSelected() || (role === POOL_ADMIN && receivedTask && receivedTask.is_transfered === "1")) &&
            (selectedTask?.item.details?.task.status.handle_key === SUBMIT_TASK || selectedTask?.item.details?.task.status.handle_key === CLOSE_TASK) &&
            incident_data?.can_act == true &&
            ((role === SUPER_ADMIN || role === INCIDENT_MANAGER || role === ADMIN) ? true : (incident_data?.is_incident_assigned ? incident_data?.department_active !== 0 : true)) &&
            !(assignedTaskSubmitted()) &&
            (selectedTask?.item?.details?.task?.exit_task !== "1") &&
            selecedTaskParentNotSubmitted() &&
            selectedTask?.item.details?.task?.is_consider !== false
        ) {
            flag = true
        }
        return flag;
    };

    const showSubmitTaskButton = () => {
        let currentTask
        if (role === POOL_USER) {
            currentTask = incident_data?.current_user_tasks && incident_data?.current_user_tasks[0]
        } else {
            let departmentId = JSON.parse(localStorage.getItem("department"))?._id;
            currentTask = incident_data?.sub_task?.length && incident_data?.sub_task.find(item => item.task_no === taskno)
        }
        // let currentTask = incident_data?.current_user_tasks && incident_data?.current_user_tasks[0]
        let counterObject = currentTask && incident_data?.sub_task_counter && incident_data?.sub_task_counter[currentTask?.task_no]
        // console.log({currentTask, counterObject})
        let flag = false;
        if (
            (incident_data?.is_task_assigned && counterObject && (counterObject.is_eligible === false || counterObject.count > 2)) &&
            incident_data?.incident_status?.handle_key !== CLOSE &&
            receivedTask?.status.handle_key !== SUBMIT_TASK &&
            receivedTask?.status.handle_key !== CLOSE_TASK &&
            incident_data?.can_act == true &&
            ((role === SUPER_ADMIN || role === INCIDENT_MANAGER || role === ADMIN) ? true : (incident_data?.is_incident_assigned ? incident_data?.department_active !== 0 : true)) &&
            !(assignedTaskSubmitted()) &&
            !(isTaskSelected()) &&
            ((role === POOL_ADMIN && incident_data?.is_task_assigned && receivedTask) ? receivedTask.is_transfered !== "1" : true)
        ) {
            flag = true
        }
        return flag;
    };



    const getFooterStyle = () => {
        let style = { minWidth: "400px", alignItems: "flex-end" };
        if (
            (role === SUPER_ADMIN || role === INCIDENT_MANAGER || role === ADMIN) &&
            incident_data?.incident_status?.handle_key === CLOSE
        ) {
            style = { minWidth: "400px", alignItems: "flex-end" };
        } else if (isTaskSelected()) {
            style = { minWidth: "420px", alignItems: "flex-end" };
        }
        if (!(showSaveButton())) {
            style = { minWidth: "300px", alignItems: "flex-end" };
        }
        if (showSubmitTaskButton()) {
            style = { minWidth: "420px", alignItems: "flex-end" };
        }
        return style;
    };

    const isSubTask = () => {
        let flag = false
        if (isTaskSelected() && selectedTask) {
            let taskNo = selectedTask?.item.details?.task.task_no;
            let taskObject = incident_data?.sub_task?.find(item => item.task_no === taskNo)
            if (taskObject && taskObject.parent_sub_task != null) {
                flag = true
            }
        }
        return flag
    }

    const tooltip = (
        comment?.trim()?.length == 0 ?
            <Tooltip id="tooltip" style={{ marginTop: 10, fontSize: 13 }}>
                <strong>{t('Please add comment to select Action')}</strong>
            </Tooltip> : <></>
    );

    // console.log({ selectedTask })

    return (
        <div className="row mb-5 pb-5">
            <div className="footer__btns mb-5">
                <div className="back-btns">
                    <button
                        className="btn btn-outline-primary"
                        onClick={() => navigate("/admin/incidents/incident_requests", { state: { fromIncidentDetailsPage: true } })}
                        disabled={incidentStatusChangeLoading}
                    >
                        {" "}
                        <img src="/assets/icons/Back.svg" alt="" /> {t('Back')}
                    </button>
                </div>
                <div className="btn-actions" style={getFooterStyle()}>
                    {showReOpenIncidentButton() && (
                        <button
                            className="btn btn-outline-primary"

                            onClick={() => handleReOpen()}
                            disabled={
                                incidentStatusChangeLoading
                                //  ||
                                // (editPermission ? false : true)
                            }
                        >
                            {incidentStatusChangeLoading ? (
                                <Spinner animation="border" role="status" size="sm"></Spinner>
                            ) : (
                                t("Reopen")
                            )}
                        </button>
                    )}
                    {showReopenTaskButton() && (
                        <button
                            className="btn btn-outline-primary"

                            onClick={() => handleReOpenTask()}
                            disabled={
                                incidentTaskStatusChangeLoading
                                // ||
                                // (editPermission ? false : true)
                            }
                        >
                            {incidentTaskStatusChangeLoading ? (
                                <Spinner animation="border" role="status" size="sm"></Spinner>
                            ) : (
                                t(isSubTask() ? "Reopen Subtask" : "Reopen Task")
                            )}
                        </button>
                    )}
                    {showCloseTaskButton() && (
                        <button
                            className="btn btn-outline-primary"

                            onClick={() => {
                                closeOrSubmitTaskOrCloseIncident()
                            }}
                            disabled={
                                incidentTaskStatusChangeLoading
                                // ||
                                // (editPermission ? false : true)
                            }
                        >
                            {incidentTaskStatusChangeLoading ? (
                                <Spinner animation="border" role="status" size="sm"></Spinner>
                            ) : (
                                t(isSubTask() ? "Close Subtask" : "Close Task")
                            )}
                        </button>
                    )}

                    {showSubmitTaskButton() && (
                        <button
                            className="btn btn-outline-primary"

                            onClick={() => {
                                if (incident_data?.is_task_assigned && !(incident_data?.sub_task_counter[receivedTask?.task_no].is_eligible)) {
                                    handleSubmitTask()
                                } else {
                                    closeOrSubmitTaskOrCloseIncident(handleSubmitTask)
                                }
                            }}
                            disabled={
                                incidentTaskStatusChangeLoading
                                // ||
                                // (editPermission ? false : true)
                            }
                        >
                            {incidentTaskStatusChangeLoading ? (
                                <Spinner animation="border" role="status" size="sm"></Spinner>
                            ) : (
                                t(incident_data?.is_task_assigned && (incident_data?.sub_task_counter[taskno]?.is_eligible) ? "Submit Task" : "Submit Subtask")
                            )}
                        </button>
                    )}

                    {showActionsSelect() && (
                        <>
                            <OverlayTrigger placement="bottom" overlay={tooltip}>
                                <div className="action" style={{ fontSize: "16px" }}>
                                    <label
                                        htmlFor=""
                                        style={{
                                            opacity: 0.58,
                                            fontSize: "16px",
                                            // fontWeight: 600,
                                        }}
                                    >
                                        {t('Action')}
                                    </label>
                                    <div className="status-select">
                                        <Select
                                            name="colors"
                                            // options={incidentStatusList?.filter(
                                            //   (item) => item.handle_key !== "save_draft"
                                            // )}
                                            isDisabled={incidentStatusChangeLoading || comment?.trim()?.length == 0}
                                            options={activeActionsList()}
                                            value={status}
                                            getOptionLabel={(option) => option['incident_status_' + lang]}
                                            getOptionValue={(option) => option._id}
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder={t("Select")}
                                            onChange={(e) => {
                                                if (comment?.trim()?.length == 0) {
                                                    setErr({
                                                        ...err,
                                                        comment: t("Please enter comment"),
                                                    });
                                                } else {
                                                    // if (!/^[a-zA-Z0-9ء-ي .؟،,-:!"?()'\n]+$/.test(comment)) {
                                                    //     setErr({
                                                    //         ...err,
                                                    //         comment: t("Invalid characters"),
                                                    //     });
                                                    // } else {
                                                    setStatus(e);
                                                    setErr({ ...err, status: "" });
                                                    // }
                                                }
                                            }}
                                            // isDisabled={editPermission ? false : true}
                                            styles={customStyles}
                                        />
                                    </div>
                                    <div className="text-danger" style={{ position: "absolute" }}>
                                        <small>{err?.status}</small>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </>
                    )}

                    {showSaveButton() &&
                        <button
                            className="btn btn-outline-primary"

                            // onClick={saveAction}
                            onClick={() => {
                                if (status && (status.handle_key === SUBMIT_TASK || status.handle_key === CLOSE)) {
                                    closeOrSubmitTaskOrCloseIncident(saveHandler)
                                } else {
                                    saveHandler()
                                }
                            }}
                            disabled={
                                incidentStatusChangeLoading || incidentTaskStatusChangeLoading
                                // ||
                                // (editPermission ? false : true)
                            }
                        >
                            {incidentStatusChangeLoading ? (
                                <Spinner animation="border" role="status" size="sm"></Spinner>
                            ) : (
                                t("Save")
                            )}
                        </button>}

                    {showCancelButton() && (
                        <button
                            className="btn btn-outline-primary"
                            onClick={() => onCancelHandler()}
                            disabled={incidentStatusChangeLoading}
                        >
                            {t('Cancel')}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default IncidentDetailsFooterSections;
