import React, { useEffect, useState } from "react";
import "../style.scss";
import "./incidents.scss";
import { Form, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getIncidentList,
  getIncidentStatusList,
  getIncidentType,
  getIncidentTypeForFilter,
  getSerachUsers,
} from "../../../redux/incidentActionCreator";
import {
  incident_list,
  incident_list_full_data,
  incident_list_loading,
  incident_list_page_count,
  incident_list_page_status,
  incident_status_details,
  incident_type_details,
  incident_type_details_for_filter,
  searchuser_list,
  updateIncidentRedux,
} from "../../../redux/incidentReducer";
import AdvancedSearch from "../EducationAllowance/AdvancedSearch";
import moment from "moment/moment";
import { Capitalize, formartDate } from "../../../utils/dateFormat";
import Pagination from "../../../components/Pagination/Pagination";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import {
  ASSIGN,
  DELETE,
  POOL_ADMIN,
  POOL_USER,
  SUBMIT_TASK,
  VIEWER,
} from "../../../constants/configuration";
import { getDepartment } from "../../../redux/commonActionCreator";
import { department_details } from "../../../redux/commonReducer";
import useGetRole from "../../../utils/useGetRole";
import useTrans from "../../../utils/useTrans";
import { getUserName } from "../../../utils/helpers";
import ReportCounts from "../../../components/ReportCounts/ReportCounts";

function IncidentRequests() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useGetRole();
  const location = useLocation()

  const { t } = useTrans();
  const lang = localStorage.getItem("lang") || "en";

  const getPreviousFilterData = () => {
    let finalData = false
    if (location.state?.fromIncidentDetailsPage && localStorage.getItem("incident_filter")) {
      finalData = JSON.parse(localStorage.getItem("incident_filter"))
      if (finalData.createdDateFrom) {
        finalData = { ...finalData, createdDateFrom: new Date(finalData.createdDateFrom) }
      }
      if (finalData.createdDateTo) {
        finalData = { ...finalData, createdDateTo: new Date(finalData.createdDateTo) }
      }
    }
    return finalData
  }

  const incident_data = useSelector(incident_list);
  const pageCount = useSelector(incident_list_page_count);
  const incidentsLoading = useSelector(incident_list_loading);
  const pageStatus = useSelector(incident_list_page_status);
  const incidentStatusList = useSelector(incident_status_details);
  const incidentTypeList = useSelector(incident_type_details);
  const allUsers = useSelector(searchuser_list);
  const departmentLists = useSelector(department_details);
  const fullData = useSelector(incident_list_full_data);
  const incidentTypeListForFilter = useSelector(incident_type_details_for_filter);

  const [entry, setEntry] = useState(10);
  const [page, setPage] = useState(0);
  const [sortColumn, setSortColumn] = useState((location.state?.fromIncidentDetailsPage && localStorage.getItem("sortColumn")) ? localStorage.getItem("sortColumn") : "updatedAt");
  const [sortAscending, setSortAscending] = useState((location.state?.fromIncidentDetailsPage) ? (localStorage.getItem("sortAscending")) === "true" : true);
  const [filterData, setFilterData] = useState({
    status: "",
    assignedTo: "",
    requestedBy: "",
    incidentType: "",
    createdDateFrom: "",
    createdDateTo: "",
    keyword: "",
    entry: 10
  });
  const [assignedList, setAssignedList] = useState([]);
  const [restart, setRestart] = useState(false)
  const [data, setData] = useState([
    { title: "Total", count: 0 },
    { title: "Open", count: 0 },
    { title: "Processing", count: 0 },
    { title: "Pending", count: 0 },
    { title: "Returned", count: 0 },
    { title: "Closed", count: 0 },
    { title: "Reopened", count: 0 },
  ]);

  // useEffect(() => {
  //   if(allUsers || departmentLists){
  //     let arr = []
  //     allUsers?.forEach(ele => {
  //       arr.push({
  //         _id : ele._id,
  //         name : ele.name
  //       })
  //     });
  //     departmentLists?.forEach(ele => {
  //       arr.push({
  //         _id : ele._id,
  //         name : ele.department_name
  //       })
  //     });
  //     console.log(arr,"arr")
  //     setAssignedList([...arr])
  //   }
  // }, [allUsers,departmentLists])

  useEffect(() => {
    dispatch(updateIncidentRedux({ key: "incident_details", value: {} }));
  }, []);

  useEffect(() => {
    if (fullData) {
      setData([
        { title: "Total", count: fullData?.total_status_count },
        { title: "Open", count: fullData?.open_count },
        { title: "Processing", count: fullData?.process_count },
        { title: "Pending", count: fullData?.pending_count },
        { title: "Returned", count: fullData?.return_count },
        { title: "Closed", count: fullData?.close_count },
        { title: "Reopened", count: fullData?.reopen_count },
      ]);
    }
  }, [fullData]);

  // const data = [
  //   { title: "Total", count: 10 },
  //   { title: "Open", count: 20 },
  //   { title: "Processing", count: 50 },
  //   { title: "Pending", count: 40 },
  //   { title: "Returned", count: 50 },
  //   // { title: "Forwarded", count: 30 },
  //   { title: "Closed", count: 10 },
  //   { title: "Reopen", count: 10 },
  // ];

  const onEnterSearch = (e) => {
    if (e.key == "Enter") {
      applySearch();
    }
  };

  const applySearch = () => {
    //filter date validation
    if (filterData.createdDateFrom && filterData.createdDateTo) {
      if (
        moment(filterData.createdDateTo) < moment(filterData.createdDateFrom)
      ) {
        toast.error("From date should not be after to date", {
          position: "bottom-center",
          autoClose: 3000,
        });
        return;
      }
    }
    let formData = new FormData();
    formData.append("page", 0);
    formData.append("perPage", entry);
    formData.append("status", filterData.status ? filterData.status?._id : "");
    formData.append("assignedTo", filterData.assignedTo ? filterData.assignedTo?._id : "");
    formData.append("requestedBy", filterData.requestedBy ? filterData.requestedBy._id : "");
    filterData.incidentType &&
      formData.append("incidentType", filterData.incidentType._id);
    formData.append(
      "createdDateFrom",
      filterData.createdDateFrom
        ? new Date(filterData.createdDateFrom).toISOString()
        : ""
    );
    formData.append(
      "createdDateTo",
      filterData.createdDateTo
        ? new Date(filterData.createdDateTo).toISOString()
        : ""
    );
    formData.append("keyword", filterData?.keyword.trim());
    sortColumn && formData.append("sort_key", sortColumn);
    sortColumn && formData.append("sort_order", !sortAscending ? 1 : -1);
    let obj = { ...filterData, entry: entry }
    localStorage.setItem('incidentlistingfilter', JSON.stringify(obj))
    dispatch(getIncidentList(formData));
    setPage(0);
    setRestart(!restart)
  };

  const clearFilter = () => {
    setFilterData({
      status: "",
      assignedTo: "",
      requestedBy: "",
      incidentType: "",
      createdDateFrom: "",
      createdDateTo: "",
      keyword: "",
    });
    let formData = new FormData();
    formData.append("page", 0);
    formData.append("perPage", entry);
    sortColumn && formData.append("sort_key", sortColumn);
    sortColumn && formData.append("sort_order", !sortAscending ? 1 : -1);
    localStorage.setItem('incidentlistingfilter', null)
    dispatch(getIncidentList(formData));
    setRestart(!restart)
  };

  useEffect(() => {
    let newFilterData = JSON.parse(localStorage.getItem('incidentlistingfilter')) || filterData
    if (newFilterData) {
            setFilterData(s => ({
        ...s,
        incidentType: newFilterData?.incidentType || '',
        createdDateTo: newFilterData?.createdDateTo ? new Date(newFilterData?.createdDateTo) : '',
        createdDateFrom: newFilterData?.createdDateFrom ? new Date(newFilterData?.createdDateFrom) : '',
        keyword: newFilterData?.keyword || ''
      }))
      setEntry(newFilterData?.entry)
    }
    let formData = new FormData();
    formData.append("page", newFilterData.page || 0);
    formData.append("perPage", newFilterData ? newFilterData?.entry: entry);
    formData.append("status", newFilterData.status ? newFilterData.status._id : "");
    formData.append("assignedTo", newFilterData.assignedTo);
    formData.append("requestedBy", newFilterData.requestedBy);
    newFilterData.incidentType &&
      formData.append("incidentType", newFilterData.incidentType._id);
    formData.append(
      "createdDateFrom",
      newFilterData.createdDateFrom
        ? new Date(newFilterData.createdDateFrom).toISOString()
        : ""
    );
    formData.append(
      "createdDateTo",
      newFilterData.createdDateTo
        ? new Date(newFilterData.createdDateTo).toISOString()
        : ""
    );
    formData.append("keyword", newFilterData?.keyword?.trim());
    console.log(localStorage.getItem("sortColumn"))
    console.log(localStorage.getItem("sortAscending"))
    sortColumn && formData.append("sort_key", (location.state?.fromIncidentDetailsPage && localStorage.getItem("sortColumn")) ? localStorage.getItem("sortColumn") : sortColumn);
    sortColumn && formData.append("sort_order", location.state?.fromIncidentDetailsPage ? (localStorage.getItem("sortAscending") === "false" ? 1 : -1) : (!sortAscending ? 1 : -1));
    dispatch(getIncidentList(formData, (status, data) => {
      setPage(data.data.page)
    }));
    dispatch(getIncidentStatusList());
    dispatch(getIncidentType());
    dispatch(getIncidentTypeForFilter())
    dispatch(getSerachUsers());
    dispatch(getDepartment());
    setRestart(!restart)
  }, []);

  const handlePageChange = (e) => {
    localStorage.setItem("stored_page_number", Number(e.selected))
    let obj = filterData
    obj.page = e.selected
    obj.entry = entry
    localStorage.setItem('incidentlistingfilter', JSON.stringify(obj))
    setPage(e.selected);
    let formData = new FormData();
    formData.append("page", e.selected);
    formData.append("perPage", entry);
    formData.append("status", filterData.status ? filterData.status._id : "");
    formData.append("assignedTo", filterData.assignedTo);
    formData.append("requestedBy", filterData.requestedBy);
    filterData.incidentType &&
      formData.append("incidentType", filterData.incidentType._id);
    formData.append(
      "createdDateFrom",
      filterData.createdDateFrom
        ? new Date(filterData.createdDateFrom).toISOString()
        : ""
    );
    formData.append(
      "createdDateTo",
      filterData.createdDateTo
        ? new Date(filterData.createdDateTo).toISOString()
        : ""
    );
    formData.append("keyword", filterData?.keyword.trim());
    sortColumn && formData.append("sort_key", sortColumn);
    sortColumn && formData.append("sort_order", !sortAscending ? 1 : -1);
    dispatch(getIncidentList(formData));
    setRestart(!restart)
  };

  const entryChange = (value) => {
    let formData = new FormData();
    formData.append("page", 0);
    formData.append("perPage", value);
    formData.append("status", filterData.status ? filterData.status._id : "");
    formData.append("assignedTo", filterData.assignedTo);
    formData.append("requestedBy", filterData.requestedBy);
    filterData.incidentType &&
      formData.append("incidentType", filterData.incidentType._id);
    formData.append(
      "createdDateFrom",
      filterData.createdDateFrom
        ? new Date(filterData.createdDateFrom).toISOString()
        : ""
    );
    formData.append(
      "createdDateTo",
      filterData.createdDateTo
        ? new Date(filterData.createdDateTo).toISOString()
        : ""
    );
    formData.append("keyword", filterData?.keyword.trim());
    sortColumn && formData.append("sort_key", sortColumn);
    sortColumn && formData.append("sort_order", !sortAscending ? 1 : -1);
    dispatch(getIncidentList(formData));
    setPage(0);
    setRestart(!restart)
  };

  const handleEntry = (e) => {
    // if (e.target.value === "") return;
    // if (e.target.value == 0 || !e.target.value) {
    //   toast.error(t("Please choose a valid entry"), {
    //     position: "bottom-center",
    //     autoClose: 3000,
    //   });
    //   return;
    // }
    if (Number(e.target.value) > 100) {
      toast.error(t("Entry should not be greater than 100"), {
        position: "bottom-center",
        autoClose: 3000,
      });
      return;
    }
    localStorage.setItem("incidents_listing_pagination", e.target.value)
    setEntry(e.target.value);
    entryChange(e.target.value);
    let obj = { ...filterData, entry: e.target.value }
    localStorage.setItem('incidentlistingfilter', JSON.stringify(obj))
  };

  const formatInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const getSortingClassName = (title) => {
    let className = "";
    if (sortColumn === title) {
      if (sortAscending) {
        className = "active";
      } else {
        className = "inactive";
      }
    }
    return className;
  };

  const tableHeaders = [
    { name: t("Incident No"), sort_key: "incident_no" },
    { name: t("Web Incident No"), sort_key: "web_incident_no" },
    { name: t("Incident Type"), sort_key: "incident_type" },
    { name: t("Requested By"), sort_key: "requested_by" },
    { name: t("Assigned To"), sort_key: "assigned_to" },
    { name: t("Created Date"), sort_key: "created_date" },
    { name: t("Last Update"), sort_key: "updatedAt" },
    { name: t("Status") },
  ];

  const sortHandler = (key) => {
    setSortColumn(key);
    setSortAscending(!sortAscending);
    let formData = new FormData();
    formData.append("page", 0);
    formData.append("perPage", entry);
    formData.append("status", filterData.status ? filterData.status._id : "");
    formData.append("assignedTo", filterData.assignedTo);
    formData.append("requestedBy", filterData.requestedBy);
    filterData.incidentType &&
      formData.append("incidentType", filterData.incidentType._id);
    formData.append(
      "createdDateFrom",
      filterData.createdDateFrom
        ? new Date(filterData.createdDateFrom).toISOString()
        : ""
    );
    formData.append(
      "createdDateTo",
      filterData.createdDateTo
        ? new Date(filterData.createdDateTo).toISOString()
        : ""
    );
    formData.append("keyword", filterData?.keyword.trim());
    formData.append("sort_key", key);
    formData.append("sort_order", sortAscending ? 1 : -1);
    dispatch(getIncidentList(formData));
    setPage(0);
    setRestart(!restart)
  };

  const onViewHandler = (ele) => {
    // console.log(ele);
    localStorage.setItem("stored_page_number", page)
    localStorage.setItem("incident_filter", JSON.stringify(filterData))
    localStorage.setItem("sortColumn", sortColumn)
    localStorage.setItem("sortAscending", sortAscending)
        if (ele?.incident_status?.handle_key == "save_draft") {
      navigate(`/admin/incidents/update_incident/${ele._id}`);
    } else {
      navigate(
        `/admin/incidents/incident_details/${ele._id}/${ele.displaytype}/${ele.taskno}`
      );
    }
  };

  // function statusNaming(status) {
  //   switch (status) {
  //     case "Assign Task":
  //       return "Task Assigned";
  //     case "Forward":
  //       return "Forwarded";
  //     case "Return":
  //       return "Returned";
  //     case "Close":
  //       return "Closed";
  //     case "Reopen":
  //       return "Reopened";
  //     case "Save Draft":
  //       return "Draft";
  //     default:
  //       return status;
  //   }
  // }
  function statusNaming(status, is_task, is_sub_task) {
    if (!status) return;
    switch (status?.handle_key) {
      case "assign":
        return lang == "en" ? "Task Assigned" : "المهمة الموكلة";
      case "assign_subtask":
        return lang == "en" ? "Subtask Assigned" : "تم تعيين مهمة فرعية";
      case "forward":
        return lang == "en" ? "Forwarded" : "تم إرسالها";
      case "return":
        return lang == "en" ? "Returned" : "عاد";
      case "close":
        return lang == "en" ? "Closed" : "مغلق";
      case "reopen":
        return lang == "en" ? "Reopened" : "أعيد فتحه";
      case "save_draft":
        return lang == "en" ? "Draft" : "مسودة";
      case "close_task":
        return lang == "en"
          ? is_sub_task
            ? "Subtask Closed"
            : "Task Closed"
          : is_sub_task
            ? "تم إغلاق المهمة الفرعية"
            : "مسودة";
      case "reopen_task":
        return lang == "en"
          ? is_sub_task
            ? "Subtask Reopened"
            : "Task Reopened"
          : is_sub_task
            ? "تمت إعادة فتح المهمة الفرعية"
            : "مسودة";
      case "open":
        return is_sub_task && lang == "en"
          ? "Subtask Open"
          : is_sub_task && lang == "ar"
            ? "مهمة فرعية مفتوحة"
            : is_task && lang == "en"
              ? "Task Open"
              : is_task && lang == "ar"
                ? "المهمة مفتوحة"
                : status["incident_status_" + lang];
      default:
        return status["incident_status_" + lang];
    }
  }

  const activeActionsList = () => {
    let list = incidentStatusList;
    list = list?.filter((item) => item.handle_key !== DELETE);
    // list = list?.filter((item) => item.handle_key !== SUBMIT_TASK);
    // list = list?.filter((item) => item.handle_key !== ASSIGN);
    if (!(role === POOL_ADMIN || role === POOL_USER)) {
      list = list?.filter((item) => item.handle_key !== ASSIGN);
      list = list?.filter((item) => item.handle_key !== SUBMIT_TASK);
    }
    let keyNames = {
      save_draft: "Draft",
      forward: "Forwarded",
      close: "Closed",
      return: "Returned",
      assign: "Task Assigned",
      reopen: "Reopened",
      submit_task: "Task Closed",
    };

    let arr = list?.map((ele, i) => {
      if (Object.keys(keyNames)?.includes(ele?.handle_key)) {
        let temp = list.find((e) => ele.handle_key == e.handle_key);
        return {
          ...ele,
          incident_status_en: keyNames[temp.handle_key],
        };
      } else {
        return ele;
      }
    });
    return arr;
  };

  function getAssignedTo(ele) {
    return ele?.displaytype === "incident" ? (
      ele.returned_to_user ? (
        ele.returned_to_user
      ) : ele?.forwarded_to_role === "admin" ? (
        "Admin Pool"
      ) : ele?.forwarded_to_role === "incident_manager" ? (
        "Incident Pool"
      ) : ele.incident_status?.handle_key == "reopen" ||
        ele.incident_status?.handle_key == "close" ? (
        <span className="space">-</span>
      ) : ele?.forwarded_to ? (
        Capitalize(ele?.forwarded_to?.name)
      ) : ele?.forwarded_dept_to ? (
        Capitalize(ele?.forwarded_dept_to?.department_name)
      ) : role === POOL_USER ? (
        Capitalize(getUserName())
      ) : (
        <span className="space">-</span>
      )
    ) : (
      Capitalize(getUserName())
    );
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.label}
    </Tooltip>
  );

  useEffect(() => {
    const intervalCall = setInterval(() => {
      localStorage.getItem("user_type") === VIEWER && applySearch();
    }, 60000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [restart]);


  return (
    <div className="container-fluid">
      <div className="manage__incidents">
        <div className="section__header mb-4">
          <div className="breadcrumbs">
            <h1>{t("Requests")}</h1>
            <p>
              <Link to={"/admin/incidents/incident_requests"}>{t("Home")}</Link>{" "}
              / {t("Requests")}
            </p>
          </div>
          {role != VIEWER ? (
            <div className="header-btn">
              <Link to="/admin/incidents/add_incident">
                <button className="btn-incident">
                  <img src="/assets/icons/add-incident.svg" alt="" />
                  {t("Add Incident")}
                </button>
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
        <section className="common__table pt-0">
          <AdvancedSearch
            page="incidents_listing"
            filterData={filterData}
            setFilterData={setFilterData}
            clearFilter={clearFilter}
            applySearch={applySearch}
            incidentStatusList={activeActionsList()}
            incidentTypeList={incidentTypeListForFilter}
            allUsers={allUsers}
          />
          {role == VIEWER ? (
            <>
              <section className="graph">
                {data.map((datom, i) => (
                  <div className="count__card">
                    <p className="key">{t(datom.title)}</p>
                    <p className={`count ${datom.title}`}>{datom.count}</p>
                  </div>
                ))}
              </section>
            </>
          ) : (
            <></>
          )}

          <div className="search__options mt-4">
            <div className="search">
              <Form.Control
                type="text"
                placeholder={t("Search")}
                value={filterData.keyword}
                onChange={(e) =>
                  setFilterData({ ...filterData, keyword: e.target.value })
                }
                onKeyDown={(e) => onEnterSearch(e)}
              />
              <button className="btn btn-search" onClick={applySearch}>
                <img src="/assets/icons/Search1.svg" alt="" />
              </button>
            </div>
            <div className="show">
              <span>{t("Entries")}</span>
              <Form.Control
                // disabled={incidentsLoading}
                type="number"
                min={1}
                // style={{ direction: "ltr", width: "65px" }}
                style={{ width: "65px" }}
                onKeyDown={formatInput}
                value={entry}
                onChange={handleEntry}
              />
              <span>{t("Show")}</span>
            </div>
          </div>
          {incidentsLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Table bordered hover>
                <thead>
                  <tr>
                    {tableHeaders.map((header) => (
                      <th
                        style={{ paddingRight: "22px" }}
                        class={` ${header.sort_key ? "sorting cursor-pointer" : ""
                          } ${getSortingClassName(header.sort_key)}`}
                        onClick={() =>
                          header.sort_key && sortHandler(header.sort_key)
                        }
                      >
                        {header.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {incident_data?.map((ele, i) => {
                    return (
                      <tr>

                        <td
                          className={
                            role == VIEWER ? "" : "incidentCursorStyle"
                          }
                          onClick={() =>
                            role == VIEWER ? {} : onViewHandler(ele)
                          }
                        >
                          {ele.incident_no}
                        </td>

                        <td
                          className={
                            role == VIEWER
                              ? ""
                              : ele?.web_incident_no
                                ? "incidentCursorStyle"
                                : "cursor"
                          }
                          onClick={() =>
                            role == VIEWER ? {} : onViewHandler(ele)
                          }
                        >
                          {ele?.web_incident_no ?? (
                            <span className="space">-</span>
                          )}
                        </td>


                        {/* <td style={{ whiteSpace: "inherit" }}>
                        <Link to="/admin/incidents/incident_details">
                          {ele.incident_type.incident_type}
                        </Link>
                      </td> */}
                        <td style={{ whiteSpace: "inherit" }}>
                          <span
                            className={
                              role == VIEWER ? "" : "incidentCursorStyle"
                            }
                            onClick={() =>
                              role == VIEWER ? {} : onViewHandler(ele)
                            }
                          >
                            {ele?.incident_type?.[
                              "incident_type" + (lang === "ar" ? "_ar" : "")
                            ] ?? "View Draft"}
                          </span>
                        </td>
                        <td
                          className={
                            role == VIEWER
                              ? ""
                              : ele?.requested_by
                                ? "incidentCursorStyle"
                                : "cursor"
                          }
                          onClick={() =>
                            role == VIEWER ? {} : onViewHandler(ele)
                          }
                        >
                          {ele?.requested_by ?? (
                            <span className="space">-</span>
                          )}
                        </td>
                        <td
                          className={
                            role == VIEWER
                              ? ""
                              : ele.assigned_to
                                ? "incidentCursorStyle"
                                : "cursor"
                          }
                          onClick={() =>
                            role == VIEWER ? {} : onViewHandler(ele)
                          }
                        >
                          {/* {getAssignedTo(ele) === ele?.requested_by
                            ? '-'
                            : getAssignedTo(ele)} */}
                          {/* {getAssignedTo(ele)} */}
                          {ele.assigned_to ?? <span className="space">-</span>}
                        </td>
                        {/* <td>{formartDate(ele.created_date)}</td> */}
                        <td
                          dir="ltr"
                          style={{
                            textAlign: "right",
                          }}
                          className={
                            role == VIEWER ? "" : "incidentCursorStyle"
                          }
                          onClick={() =>
                            role == VIEWER ? {} : onViewHandler(ele)
                          }
                        >
                          {moment.utc(ele.created_date).format("DD MMM YYYY")}
                        </td>
                        <td
                          dir="ltr"
                          style={{
                            textAlign: "right",
                          }}
                          className={
                            role == VIEWER ? "" : "incidentCursorStyle"
                          }
                          onClick={() =>
                            role == VIEWER ? {} : onViewHandler(ele)
                          }
                        >
                          {formartDate(
                            // ele.actions[ele.actions.length - 1]?.last_update
                            ele.updatedAt
                          )}
                        </td>
                        <td>
                          <button
                            style={{ cursor: role == VIEWER ? "auto" : "pointer" }}
                            className={`btn btn-sm
                                    btn-${ele.incident_status?.handle_key == "open"
                                ? ele?.displaytype === "subtask"
                                  ? "subtask-open"
                                  : ele?.displaytype === "task"
                                    ? "task-open"
                                    : "open"
                                : ele.incident_status?.handle_key ==
                                  "return"
                                  ? "returned"
                                  : ele.incident_status?.handle_key ==
                                    "forward"
                                    ? "forward"
                                    : ele.incident_status?.handle_key ==
                                      "pending"
                                      ? "pending"
                                      : ele.incident_status?.handle_key ==
                                        "processing"
                                        ? "processing"
                                        : ele.incident_status?.handle_key ==
                                          "assign"
                                          ? "assign"
                                          : ele.incident_status?.handle_key ==
                                            "close"
                                            ? "closed"
                                            : ele.incident_status?.handle_key ==
                                              "reopen"
                                              ? "reopen"
                                              : ele?.incident_status?.handle_key ==
                                                "save_draft"
                                                ? "draft"
                                                : ele?.incident_status?.handle_key ==
                                                  "close_task"
                                                  ? ele?.displaytype === "subtask"
                                                    ? "subtask_closed"
                                                    : "close_task"
                                                  : ele?.incident_status?.handle_key ==
                                                    "reopen_task"
                                                    ? "reopen_task"
                                                    : ""
                              }`}
                            // onClick={() => onViewHandler(ele)}
                            onClick={() =>
                              role == VIEWER ? {} : onViewHandler(ele)
                            }
                          >
                            {/* {statusNaming(
                              ele.incident_status[`incident_status_${lang}`]
                            )} */}
                            {/* {console.log(ele.incident_status)} */}
                            {statusNaming(
                              ele?.incident_status,
                              ele?.displaytype === "task",
                              ele?.displaytype === "subtask"
                            )}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {incident_data?.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h6>{t("No Data")}</h6>
                </div>
              )}
            </>
          )}
          {!incidentsLoading && !!incident_data?.length && (
            <div
              className="align-between"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p style={{ direction: "ltr" }}>{pageStatus}</p>
              <Pagination
                page={page}
                pageCount={pageCount}
                handlePageChange={handlePageChange}
              />
            </div>
          )}
        </section>
      </div>
      {/* <div className="row">
        <div className="d-flex flex-column col">
          <button style={{ backgroundColor: '#d08f2e', color: 'white' }}>draft</button>
          <button style={{ backgroundColor: '#F56F6F', color: 'white' }}>pending</button>
          <button style={{ backgroundColor: '#EBB970', color: 'white' }}>processing</button>
          <button style={{ backgroundColor: '#7782E2', color: 'white' }}>forwarded</button>
          <button style={{ backgroundColor: '#B98772', color: 'white' }}>returned</button>
          <button style={{ backgroundColor: '#4f9274', color: 'white' }}>open</button>
          <button style={{ backgroundColor: '#573a64', color: 'white' }}>closed</button>
          '#0A0F31'
          <button style={{ backgroundColor: '#7c7b3b', color: 'white' }}>reopened</button>
          <hr />
          <button style={{ backgroundColor: '#3c8b86', color: 'white' }}>taskopen</button>
          <button style={{ backgroundColor: '#4c6b86', color: 'white' }}>taskassigned</button>
          <button style={{ backgroundColor: '#1c4b86', color: 'white' }}>taskclosed</button>
          <button style={{ backgroundColor: '#acab86', color: 'white' }}>taskreopened</button>
          <hr />
          <button style={{ backgroundColor: '#1c7b86', color: 'white' }}>subtaskopen</button>
          <button style={{ backgroundColor: '#3c5b86', color: 'white' }}>subtaskassigned</button> //#40619b
          <button style={{ backgroundColor: '#4c3b86', color: 'white' }}>subtaskclosed</button>
          <button style={{ backgroundColor: '#ac7b86', color: 'white' }}>subtaskreopened</button>
        </div>
        <div className="d-flex flex-column col">
          <button style={{ backgroundColor: '#d08f2e', color: "white" }}> draft</button>
          <button style={{ backgroundColor: '#f56f6f', color: "white" }}> pending</button>
          <button style={{ backgroundColor: '#ebb970', color: "white" }}> processing</button>
          <button style={{ backgroundColor: '#7782e2', color: "white" }}> forward</button>
          <button style={{ backgroundColor: '#b98772', color: "white" }}> returned</button>
          <button style={{ backgroundColor: '#4f9274', color: "white" }}> open</button>
          <button style={{ backgroundColor: '#573a64', color: "white" }}> closed</button>
          #0A0F31
          <button style={{ backgroundColor: '#7c7b3b', color: "white" }}> reopen</button>
          <hr />
          <button style={{ backgroundColor: '#3c8b86', color: "white" }}> task-open</button>
          <button style={{ backgroundColor: '#1c7b86', color: "white" }}> subtask-open</button>
          <button style={{ backgroundColor: '#1c4b86', color: "white" }}> close_task</button>
          <button style={{ backgroundColor: '#4c3b86', color: "white" }}> subtask closed</button>
        </div>
      </div> */}
    </div>
  );
}

export default IncidentRequests;
