import React from 'react'
import PaginationSearch from '../../../components/PaginationSearch/PaginationSearch'
import ReportCounts from '../../../components/ReportCounts/ReportCounts'
import Search from '../../../components/Search/Search'
import Table1 from '../../../components/Table1/Table1'
import TitleAndPath from '../../../components/TitleAndPath/TitleAndPath'

export default function CertificateRequests_admin_report() {

    const titles = [
        { title: 'Request No', key: 'request_no', sort: true },
        { title: 'Employee', key: 'employee', sort: true },
        { title: 'Department', key: 'department', sort: true },
        { title: 'Request Type', key: 'request_type', sort: true },
        { title: 'Requested Date', key: 'requested_date', sort: true },
        { title: 'Last Update', key: 'last_update', sort: true },
        { title: 'Resolution Time', key: 'resolution_time', sort: true },
        { title: 'Status', key: 'status' },
        { title: 'Action', key: 'action' }
    ]

    const table = [
        {
            request_no: '09',
            employee: 'John Doe',
            department: 'Institutional Support',
            request_type: 'Salary Certificate',
            requested_date: '10 Feb 2023',
            last_update: '13 Feb 2023',
            resolution_time: 'Mona',
            status: 'Open'
        },
        {
            request_no: '07',
            employee: 'John Doe',
            department: 'Institutional Support',
            request_type: 'Salary Certification',
            requested_date: '10 Feb 2023',
            last_update: '13 Feb 2023',
            resolution_time: 'Mona',
            status: 'Approved'
        },
        {
            request_no: '10',
            employee: 'John Doe',
            department: 'Institutional Support',
            request_type: 'Salary Certification',
            requested_date: '10 Feb 2023',
            last_update: '13 Feb 2023',
            resolution_time: 'Mona',
            status: 'Approved'
        },
        {
            request_no: '05',
            employee: 'John Doe',
            department: 'Institutional Support',
            request_type: 'Salary Certification',
            requested_date: '10 Feb 2023',
            last_update: '13 Feb 2023',
            resolution_time: 'Mona',
            status: 'Approved'
        },
        {
            request_no: '06',
            employee: 'John Doe',
            department: 'Institutional Support',
            request_type: 'Salary Certification',
            requested_date: '10 Feb 2023',
            last_update: '13 Feb 2023',
            resolution_time: 'Mona',
            status: 'Approved'
        },
        {
            request_no: '08',
            employee: 'John Doe',
            department: 'Institutional Support',
            request_type: 'Salary Certification',
            requested_date: '10 Feb 2023',
            last_update: '13 Feb 2023',
            resolution_time: 'Mona',
            status: 'Approved'
        },
    ]
    return (
        <div id='CertificateRequests_admin_report'>
            <section>
                <TitleAndPath title={'Human Resource'} path={'Home / HR / Reports'} />
            </section>
            <section>
                <Search currentPage={'CertificateRequests_admin_report'} />
            </section>
            <section>
                <ReportCounts />
            </section>
            <section>
                <PaginationSearch />
            </section>
            <section className='table'>
                <Table1 currentPage={'CertificateRequests_admin_report'} titles={titles} data={table} />
            </section>
        </div>
    )
}
