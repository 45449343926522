import React, { useEffect, useState } from 'react'
import './Settings.scss'
import TitleAndPath from '../../../components/TitleAndPath/TitleAndPath'
import TitleBar from '../../../components/TitleBar/TitleBar'
import Button1 from '../../../components/Button1/Button1'
import TextArea1 from '../../../components/TextArea1/TextArea1'
import { DownloadFolder, DownloadReturnData, getReturnedCount, transferZipFileToServer, uploadFolder } from '../../../redux/incidentActionCreator'
import { useDispatch } from 'react-redux'
import SubTitleBar from '../../../components/SubTitleBar/SubTitleBar'
import useTrans from '../../../utils/useTrans'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import IncidentModal from '../../admin/ManageIncidents/IncidentModal'
import Modals from '../../../components/Modals/Modals'

export default function Settings() {

    const dispatch = useDispatch()
    const { t } = useTrans()

    const [loader1, setLoader1] = useState(false)
    const [loader2, setLoader2] = useState(false)
    const [loader3, setLoader3] = useState(false)
    const [showUploader, setShowUploader] = useState(false)
    const [file, setFile] = useState()
    const [modalErr, setModalErr] = useState("")
    const [count, setCount] = useState(0)

    useEffect(() => {
        dispatch(getReturnedCount((status, count) => {
            setCount(count)
        }))
    }, [])

    function returnDataDownloadHandler() {
        setLoader2(true)
        dispatch(DownloadReturnData((status, res) => {
            status && dispatch(getReturnedCount((countStatus, count) => {
                if(countStatus){
                    setCount(count)
                }
            }))
            status && downloader(res, 'ReturnedIncidents.zip')
            status && toast.success(t("Downloaded successfully"), {
                position: "bottom-center",
                autoClose: 3000
            })
            !status && toast.error(t(res), {
                position: "bottom-center",
                autoClose: 3000
            })
            setLoader2(false)
        }))
    }

    function folderDownloadHandler() {
        setLoader1(true)
        dispatch(DownloadFolder((status, res) => {
            status && downloader(res, 'incidents.zip')
            status && toast.success(t("Downloaded successfully"), {
                position: "bottom-center",
                autoClose: 3000
            })
            !status && toast.error(t(res), {
                position: "bottom-center",
                autoClose: 3000
            })
            setLoader1(false)
        }))
    }

    function folderUploadHandler() {
        setLoader3(true)
        dispatch(uploadFolder((status, res) => {
            status && toast.success(t("Synced successfully"), {
                position: "bottom-center",
                autoClose: 3000
            })
            !status && toast.error(t(res.message), {
                position: "bottom-center",
                autoClose: 3000
            })
            setLoader3(false)
        }))
    }

    function downloader(data, fileName) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            fileName,
        );
        document.body.appendChild(link);
        link.click();
    }

    const UploadZIpFile = () => {
        if (file) {
            if (!(file.type.includes("zip"))) {
                setModalErr(t("File type not supported"))
                return
            }
            let formData = new FormData()
            formData.append("incident_files", file)
            dispatch(transferZipFileToServer(formData, (status, msg) => {
                status ? toast.success(t(msg), { position: "bottom-center", autoClose: 3000 })
                    : toast.error(t(msg), { position: "bottom-center", autoClose: 3000 })
                hideModal()
            }))
        } else {
            setModalErr(t("No file selected"))
        }
    }

    const hideModal = () => {
        setShowUploader("");
        setFile("");
        setModalErr("")
    }

    return (
        <div id='Settings'>
            {/* <TitleAndPath title={t('Settings')} path={t('Home') + '/' + t('Settings')} /> */}
            <div className="section__header">
                <div className="breadcrumbs">
                    <h1>{t("Settings")}</h1>
                    <p>
                        <Link to={"/admin/incidents/incident_requests"}>{t('Home')}</Link> /{" "}
                        {t('Settings')}
                    </p>
                    {/* <p>{t("Home")} / {t("Settings")}</p> */}
                </div>
            </div>
            {/* <section>
                <TitleBar Title={t('Updated Incidents')} Top />
                <span className='box-design'>
                    <Button1 Text={t('Download')} Icon={'/assets/icons/Download1.svg'} reverse
                        onClick={folderDownloadHandler}
                    />
                    {loader1 && <img src="/assets/icons/loader.svg" alt="" />}
                </span>
            </section> */}
            <section>
                <TitleBar dir='ltr' Title={(count ? `( ${count} ) ` : '') + t('Return Incidents')} Top />
                <span className='box-design'>
                    <Button1 Text={t('Download')} Icon={'/assets/icons/Download1.svg'} reverse
                        onClick={returnDataDownloadHandler} disabled={!count}
                    />
                    {loader2 && <img src="/assets/icons/loader.svg" alt="" />}
                </span>
            </section>
            <section>
                <TitleBar Title={t('Sync Incidents')} />
                <span className='box-design'>
                    <Button1 Text={t('Sync')} Icon={'/assets/icons/sync.svg'} reverse
                        onClick={folderUploadHandler}
                    />
                    {loader3 && <img src="/assets/icons/loader.svg" alt="" />}
                </span>
            </section>
            <section>
                <TitleBar Title={t('Upload zip file to server')} />
                <span className='box-design'>
                    <Button1 Text={t('Upload')} Icon={'/assets/icons/upload.svg'} reverse
                        onClick={() => setShowUploader("upload_zip_file")}
                    />
                    {/* {loader3 && <img src="/assets/icons/loader.svg" alt="" />} */}
                </span>
            </section>
            {/* <section>
                <TitleBar Title={'Return SMS'} />
                <SubTitleBar SubTitle='English' />
                <TextArea1 />
                <SubTitleBar SubTitle='Arabic' />
                <TextArea1 />
            </section>
            <section>
                <TitleBar Title={'OTP SMS'} />
                <SubTitleBar SubTitle='English' />
                <TextArea1 />
                <SubTitleBar SubTitle='Arabic' />
                <TextArea1 />
            </section> */}
            {/* <div className='buttons'>
                <Button1 Text={'Cancel'} />
                <Button1 Text={'Submit'} />
            </div> */}
            <IncidentModal
                file={file}
                setFile={setFile}
                modalErr={modalErr}
                setModalErr={setModalErr}
                onConfirm={UploadZIpFile}
                modalType={showUploader}
                onClose={hideModal}
                onHide={hideModal}
            />
        </div>
    )
}
