import React, { useEffect, useState } from "react";
import "./FooterButtons.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { request_status_details } from "../../redux/hrReducer";
import Select from "react-select";

export default function FooterButtons({
  backPath,
  setModalShow,
  setCertificateStatus,
  certificateStatus,
  onSaveHandler,
  err,
}) {
  const [role, setRole] = useState("User");

  const requestStatus = useSelector(request_status_details);

  useEffect(() => {
    const path = document.location.pathname;
    path.split("/")[1] === "admin" ? setRole("Admin") : setRole("User");
  }, []);

  return (
    <div id="FooterButtons">
      <Link to={backPath}>
        <button className="style-1">
          <i>
            <img src="/assets/icons/Back.svg" alt="" />
          </i>
          <span>Back</span>
        </button>
      </Link>
      <div>
        {role === "Admin" && (
          <>
            {/* < button className='style-1 select'>
              <select name="" id="" defaultValue={'default'} onChange={(e) => setCertificateStatus(e.target.value)}>
              <option value="default" hidden >Select</option>
                <option value="Approve">Approve</option>
                <option value="Forward">Forward</option>
                <option value="Return">Return</option>
                <option value="Processing">Processing</option>
                <option value="Reject">Reject</option>
                <option value="Close">Close</option>
                {requestStatus?.map((ele) => {
                  return(
                    <option value={ele._id}>{ele.request_status}</option>
                  )
                })}
              </select>
              <i><img src="/assets/icons/Drop-Down-Thick Black.svg" alt="" /></i>
            </button> */}
            <div>
              <Select
                name="Status"
                options={requestStatus}
                hideSelectedOptions={false}
                value={certificateStatus}
                getOptionLabel={(option) => option.action}
                getOptionValue={(option) => option._id}
                className="basic-multi-select"
                classNamePrefix="select"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#31D68F",
                    primary: "#0D7B58",
                  },
                })}
                onChange={(e) => setCertificateStatus(e)}
              />
              <div className="text-danger">
                <small>{err?.certificateStatus}</small>
              </div>
            </div>
            <button className="style-1" onClick={() => onSaveHandler()}>
              Save
            </button>
          </>
        )}
        {role !== "Admin" && (
          <button className="style-1" onClick={() => onSaveHandler()}>
            Submit
          </button>
        )}
        <button className="style-1">Cancel</button>
      </div>
    </div>
  );
}
