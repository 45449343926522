import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Dropdown,
  DropdownButton,
  Modal,
  OverlayTrigger,
  Table,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import {
  assignTaskToPoolDepartment,
  assignTaskToPoolUser,
  changeIncidentStatus,
  closeIncident,
  closeIncidentTask,
  forwardIncident,
  forwardIncidentFromPoolAdmin,
  getAttachmentList,
  getIncidentDetails,
  getIncidentStatusList,
  getIncidentType,
  getSerachUsers,
  openIncident,
  reOpenIncident,
  reopenIncident,
  reopenIncidentTask,
  returnIncident,
  submitIncidentTask,
  updateIncident,
  transferIncidentTaskToPoolUser
} from "../../../redux/incidentActionCreator";
import {
  incident_attachment_list,
  incident_details,
  incident_details_loading,
  incident_path,
  incident_status_change_loading,
  incident_status_change_success,
  incident_status_details,
  incident_task_status_change_loading,
  incident_type_details,
  searchuser_list,
  updateIncidentRedux,
} from "../../../redux/incidentReducer";
import moment from "moment";
import { getDepartment, getDepartmentHavingUsers, getSection, getSectionHavingUsers } from "../../../redux/commonActionCreator";
import {
  department_details,
  department_details_having_users,
  section_details,
  section_details_having_users,
  task_selected,
} from "../../../redux/commonReducer";
// import IncidentHistory from "./IncidentHistory";
// import Table2 from "../../../components/Table2/Table2";
import IncidentModal from "./IncidentModal";
import {
  ADMIN,
  API_BASE_URL,
  ASSIGN,
  ASSIGN_SUBTASK,
  CLOSE,
  CLOSE_TASK,
  DELETE,
  FORWARD,
  IMAGE_BASE_URL,
  INCIDENT_MANAGER,
  OPEN,
  PENDING,
  POOL_ADMIN,
  POOL_USER,
  PROCESSING,
  REOPEN_TASK,
  RETURN,
  RE_OPEN,
  SAVE_DRAFT,
  SUBMIT_TASK,
  SUPER_ADMIN,
} from "../../../constants/configuration";
import IncidentPersonalSection from "./incidentPersonalSection";
import IncidentDetailSection from "./incidentDetailSection";
import IncidentCommentSection from "./incidentCommentSection";

// import { getDepartment } from "../../../redux/commonActionCreator";
// import { department_details } from "../../../redux/commonReducer";
// import Table2 from "../../../components/Table2/Table2";
// import IncidentModal from "./IncidentModal";
import Table3 from "../../../components/Table3/Table3";
import TitleBar from "../../../components/TitleBar/TitleBar";
import HistoryMenu from "../../../components/HistoryMenu/HistoryMenu";
import SubTitleBar from "../../../components/SubTitleBar/SubTitleBar";
import CommentContent from "../../../components/CommentContent/CommentContent";
import AttachmentConent from "../../../components/AttachmentContent/AttachmentContent";
import AttachmentContent from "../../../components/AttachmentContent/AttachmentContent";
import { DateFormatter } from "../../../utils/Date";
import useGetRole from "../../../utils/useGetRole";
import IncidentHistory from "./IncidentHistory";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import FileViewModal from "./fileViewModal";
import { customStyles } from "../../../utils/styles";
import { useTranslation } from "react-i18next";
import { translate } from "../../../utils/translate";
import IncidentDetailsFooterSections from "./IncidentDetailsFooterSections";
import useTrans from "../../../utils/useTrans";

// import ResponseModal from "../../../components/ResponseModal/ResponseModal";
function IncidentDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, displaytype, taskno } = useParams();
  const { role } = useGetRole();
  const { t } = useTrans();
  const lang = localStorage.getItem("lang");
  let userId = localStorage.getItem("user_id");

  const incident_data = useSelector(incident_details);
  const url = useSelector(incident_path);
  const attachmentList = useSelector(incident_attachment_list);
  const incidentStatusList = useSelector(incident_status_details);
  const sectionLists = useSelector(section_details);
  const sectionListsHavingusers = useSelector(section_details_having_users);
  const departmentList = useSelector(department_details);
  const departmentListHavingUsers = useSelector(department_details_having_users);
  const incidentTypeList = useSelector(incident_type_details);
  const incidentStatusChangeLoading = useSelector(
    incident_status_change_loading
  );
  const incidentStatusChangeSuccess = useSelector(
    incident_status_change_success
  );
  const searchUsers = useSelector(searchuser_list);
  const loading = useSelector(incident_details_loading);
  const selectedTask = useSelector(task_selected);
  const incidentTaskStatusChangeLoading = useSelector(incident_task_status_change_loading)

  const [type, setType] = useState(false);
  const [proof, setProof] = useState(false);
  const [forward, setForward] = useState(false);
  const [actionreturn, setReturn] = useState(false);
  const [action, setAction] = useState("");
  const [title, setTitle] = useState("");
  const [additionalData, setAdditionalData] = useState([]);
  const [addObj, setAddObj] = useState({
    doc: "",
    doc_id: "",
    file: "",
  });
  const [addErr, setAddErr] = useState([]);
  const [status, setStatus] = useState("");
  const [err, setErr] = useState({});
  const [forwardDept, setForwardDept] = useState("");
  const [returnComment, setReturnComment] = useState("");
  const [modalshow, setModalShow] = useState(false);
  const [fileView, setFileView] = useState(null);
  const [comment, setComment] = useState("");
  const [incidentType, setIncidentType] = useState("");
  const [inciType, setInciType] = useState("");
  const [modalType, setModalType] = useState("");
  const [forwardType, setForwardType] = useState("");
  const [forwardData, setForwardData] = useState({});
  const [assignData, setAssignData] = useState({});
  const [delNewAttachmentModal, setDelNewAttachmentModal] = useState(false);
  const [delNewAttachmentValue, setDelNewAttachmentValue] = useState(null);
  const [oldAttachments, setOldAttachments] = useState([]);
  const [delOldAttachmentModal, setDelOldAttachmentModal] = useState(false);
  const [delOldAttachmentValue, setDelOldAttachmentValue] = useState(null);
  const [delOldAttachmentIds, setDelOldAttachmentIds] = useState([]);
  const [oldFileView, setOldFileView] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [fileNumber, setFileNumber] = useState(null);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [userSearchParams, setUserSearchParams] = useState({});
  const [editPermission, setEditPermission] = useState(true);
  const [editNew, setEditNew] = useState(null);
  const [editOldIds, setEditOldIds] = useState([]);
  const [editOld, setEditOld] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(getIncidentDetails(id, displaytype, taskno, (data, status) => {
        if (!status) {
          toast.error(t(data.message), {
            position: "bottom-center",
            autoClose: 3000
          })
        }
        if (!data?.is_valid_user) {
          if ([
            SUPER_ADMIN,
            ADMIN,
            INCIDENT_MANAGER,
            POOL_ADMIN,
            POOL_USER,
          ].includes(data?.user_role)) {
            navigate("/admin/*")
          } else {
            navigate("/*")
          }
        }
      }));
    }
  }, [id]);

  useEffect(() => {
    if (role === POOL_ADMIN || role === POOL_USER) {
      if (
        incident_data?.incident_status?.handle_key == CLOSE ||
        ((incident_data?.is_incident_assigned && incident_data?.department_active == 0)) ||
        incident_data?.can_act == false
      ) {
        setEditPermission(false);
      } else {
        setEditPermission(true);
      }
    } else if (incident_data?.can_act == false) {
      setEditPermission(false);
    } else {
      setEditPermission(true);
    }
  }, [incident_data, role]);

  useEffect(() => {
    if (role === POOL_ADMIN) {
      setUserSearchParams({
        ...userSearchParams,
        department: JSON.parse(localStorage.getItem("department")),
      });
    }
    if (role === POOL_USER) {
      setUserSearchParams({
        ...userSearchParams,
        department: JSON.parse(localStorage.getItem("department")),
      });
    }
  }, [role]);

  useEffect(() => {
    if (incident_data) {
      setInciType(incident_data?.incident_type);
      setOldAttachments(incident_data?.attachments);
    }
  }, [incident_data]);

  useEffect(() => {
    dispatch(getIncidentType());
    dispatch(getDepartment());
    dispatch(getDepartmentHavingUsers());
    dispatch(getAttachmentList());
    dispatch(getIncidentStatusList());
  }, []);

  const selectActions = (e) => {
    setTitle(e);
    setAction(e);
  };

  useEffect(() => {
    if (status != "") {
      if (status.handle_key === FORWARD) {
        if (
          role === SUPER_ADMIN ||
          role === ADMIN ||
          role === INCIDENT_MANAGER
        ) {
          setModalType("admin_user_forward");
        } else if (role === POOL_ADMIN) {
          setModalType("pool_forward");
        } else {
          setForwardType("Pool Admin");
        }
      } else if (status.handle_key === ASSIGN) {
        if (role === POOL_ADMIN && incident_data?.is_task_assigned) {
          setModalType("other_department_assign")
        } else {
          setModalType("assign");
        }
      } else if (status.handle_key === RETURN) {
        setModalType("return");
      } else if (status.handle_key === ASSIGN_SUBTASK) {
        setModalType("assign_subtask");
      }
      // else if (status.handle_key === SUBMIT_TASK) {
      //   setModalType("submit");
      // }
      // else {
      //   saveHandler();
      // }
    }
  }, [status]);

  // const saveAction = () => {
  //   if (status.handle_key === FORWARD) {
  //     if (role === SUPER_ADMIN || role === ADMIN || role === INCIDENT_MANAGER) {
  //       setModalType("admin_user_forward");
  //     } else {
  //       setModalType("pool_forward");
  //     }
  //   } else if (status.handle_key === ASSIGN) {
  //     setModalType("assign");
  //   } else if (status.handle_key === RETURN) {
  //     setModalType("return");
  //   } else {
  //     saveHandler();
  //   }
  // };

  const updateIncidentHandler = () => {
    let hideSuccess;
    if (status == "") {
      hideSuccess = false;
    } else {
      hideSuccess = true;
    }
    // if (!/^[a-zA-Z0-9ء-ي .؟،,-:!"?()'\n]+$/.test(comment)) {
    //   setErr({
    //     ...err,
    //     comment: t("Invalid characters"),
    //   });
    //   return;
    // }
    let formData = new FormData();
    formData.append("id", incident_data._id);
    formData.append("first_name", incident_data?.first_name);
    formData.append("last_name", incident_data?.last_name);
    formData.append("email", incident_data?.email);
    formData.append("phone", incident_data?.phone);
    formData.append("incident_source_other_reason", incident_data.incident_source_other_reason);
    formData.append("incident_entity", incident_data?.incident_entity);
    formData.append("incident_date", incident_data?.incident_date ?? "");
    formData.append(
      "is_reported_elsewhere",
      incident_data?.is_reported_elsewhere
    );
    formData.append("incident_source", incident_data?.incident_source ? incident_data?.incident_source?._id : "");
    // formData.append(
    //   "status_id",
    //   status?._id ?? incident_data?.incident_status?._id
    // );

    formData.append("is_status_changed", status == "" ? (modalType === "return" ? 1: 0) : 1);
    formData.append("comment", comment);
    formData.append("display_type", displaytype)

    additionalData?.forEach((item, i) => {
      formData.append(`attachments_data[${i}][attachment_type]`, item.doc);
      formData.append(`attachments_data[${i}][attachment_number]`, item.doc_id);
      formData.append(`attachments_${i}`, item.file);
    });
    formData.append(`attachments_count`, additionalData?.length);
    formData.append(
      "incident_type",
      incidentType._id ?? incident_data?.incident_type?._id
    );
    delOldAttachmentIds?.forEach((item, i) =>
      formData.append(`attachment_delete[${i}]`, item)
    );
    formData.append(`attachments_edit_count`, editOldIds?.length);

    if (incident_data?.is_task_assigned) {
      if (selectedTask && selectedTask?.item?.title !== t("Main Request") && selectedTask?.item?.title !== t("Main Task")) {
        let taskNo = selectedTask?.item.details.task.task_no;
        formData.append(`task_no`, taskNo);
        formData.append(
          "status_id",
          // status?._id ?? selectedTask?.item?.details?.task.status._id
          status?._id ?? incident_data?.incident_status?._id
        );
      } else {
        if (role === POOL_USER) {
          let currentTask = incident_data?.current_user_tasks && incident_data?.current_user_tasks[0]
          formData.append(`task_no`, currentTask.task_no);
          formData.append(
            "status_id",
            // status?._id ?? currentTask.status._id
            status?._id ?? incident_data?.incident_status?._id
          );
        } else {
          let currentTask = incident_data?.sub_task.length && incident_data?.sub_task.find(item => item.task_no === taskno)
          formData.append(`task_no`, currentTask.task_no);
          formData.append(
            "status_id",
            // status?._id ?? currentTask.status._id
            status?._id ?? incident_data?.incident_status?._id
          );
        }
      }
    } else {
      if (selectedTask && selectedTask?.item?.title !== t("Main Request")) {
        let taskNo = selectedTask?.item.details.task.task_no;
        formData.append(`task_no`, taskNo);
        formData.append(
          "status_id",
          // status?._id ?? selectedTask?.item?.details?.task.status._id
          status?._id ?? incident_data?.incident_status?._id
        );
      } else {
        formData.append(
          "status_id",
          // status?._id ?? incident_data?.actions[0]?.status?._id
          status?._id ?? incident_data?.incident_status?._id
        );
      }
    }
    oldAttachments?.forEach((item, i) => {
      if (editOldIds?.includes(item._id)) {
        formData.append(
          `attachments_edit[${i}][attachment_type]`,
          item.attachment_type
        );
        formData.append(
          `attachments_edit[${i}][attachment_number]`,
          item.attachment_number
        );
        formData.append(`attachments_edit_${i}`, item.file ?? "");
        formData.append(`attachments_edit[${i}][id]`, item._id);
      }
    });
    if (status == "") {
      uploadFiles?.forEach((item, i) => formData.append(`attachments`, item));
    }
    // console.log(formData, "updateIncident");
    dispatch(
      updateIncident(formData, (res) => {
        if (!hideSuccess) {
          dispatch(
            updateIncidentRedux({
              key: "responseModal",
              value: { ...res, show: true },
            })
          );
        }
      })
    );
  };

  const saveHandler = () => {
    console.log(((status == "" && uploadFiles?.length) ||
      additionalData?.length != 0 ||
      delOldAttachmentIds?.length != 0 ||
      incidentType?.length != 0 ||
      comment?.trim()?.length != 0 ||
      editOldIds?.length != 0),
      (!(status.handle_key === SUBMIT_TASK) &&
        !(status.handle_key === CLOSE)), "123")
    if (
      ((status == "" && uploadFiles?.length) ||
        additionalData?.length != 0 ||
        delOldAttachmentIds?.length != 0 ||
        incidentType?.length != 0 ||
        comment?.trim()?.length != 0 ||
        editOldIds?.length != 0) &&
      (!(status.handle_key === SUBMIT_TASK) &&
        !(status.handle_key === CLOSE))
    ) {
      // console.log("1477")
      if (comment.trim() === '') {
        setErr({
          ...err,
          comment: t("Please enter comment"),
        });
        return;
      }
      updateIncidentHandler();
    }
    if (comment.trim() === '') {
      setErr({
        ...err,
        comment: t("Please enter comment"),
      });
      return;
    }
    // alert('check here')
    localStorage.setItem('incidentlistingfilter', null)
    if (status != "") {
      // if (!/^[a-zA-Z0-9ء-ي .؟،,-:!"?()'\n]+$/.test(comment)) {
      //   setErr({
      //     ...err,
      //     comment: t("Invalid characters"),
      //   });
      //   return;
      // }
      let formData = new FormData();
      switch (status.handle_key) {
        //re-open
        case OPEN: {
          formData.append("id", incident_data._id);
          formData.append("status_id", status._id);
          formData.append("incident_comment", comment);
          uploadFiles?.forEach((item, i) =>
            formData.append(`attachments`, item)
          );
          console.log(formData);
          if (incident_data?.incident_status?.handle_key == "save_draft") {
            dispatch(openIncident(formData));
          } else {
            dispatch(reOpenIncident(formData));
          }
          break;
        }
        //forward
        case FORWARD: {
          if (modalType === "pool_forward" || role === POOL_USER) {
            if (forwardType === "") {
              toast.error(t("Please choose a forward type"), {
                position: "bottom-center",
                autoClose: 3000,
              });
              return;
            }
            formData.append("id", incident_data._id);
            formData.append("incident_comment", comment);
            formData.append("status_id", status._id);
            uploadFiles?.forEach((item, i) =>
              formData.append(`attachments`, item)
            );
            if (forwardType === "Pool User") {
              formData.append("user", forwardData.user._id);
              formData.append("to_department", 0);
            } else if (forwardType === "Other Department") {
              formData.append("department", forwardData.department._id);
              formData.append("to_department", 1);
            } else if (forwardType === "Admin") {
              formData.append("forward_to", "admin");
            } else if (forwardType === "Incident Managers") {
              formData.append("forward_to", "incident_manager");
            } else if (forwardType === "Pool Admin") {
              formData.append(
                "department",
                JSON.parse(localStorage.getItem("department"))._id
              );
              formData.append("to_department", 1);
              formData.append("forward_to ", "");
            }
            dispatch(forwardIncidentFromPoolAdmin(formData));
          } else {
            formData.append("id", incident_data._id);
            formData.append("department", forwardDept._id);
            formData.append("incident_comment", comment);
            uploadFiles?.forEach((item, i) =>
              formData.append(`attachments`, item)
            );
            formData.append("status_id", status._id);
            console.log(formData);
            dispatch(forwardIncident(formData));
          }
          break;
        }
        //return
        case RETURN: {
          formData.append("id", incident_data._id);
          formData.append("status_id", status._id);
          formData.append("incident_comment", comment);
          uploadFiles?.forEach((item, i) =>
            formData.append(`attachments`, item)
          );
          console.log(formData);
          dispatch(returnIncident(formData));
          break;
        }
        //Pending
        case PENDING: {
          formData.append("id", incident_data._id);
          formData.append("status", status._id);
          formData.append("incident_comment", comment);
          uploadFiles?.forEach((item, i) =>
            formData.append(`attachments`, item)
          );
          // console.log(formData);
          dispatch(changeIncidentStatus(formData));
          break;
        }
        //Processing
        case PROCESSING: {
          formData.append("id", incident_data._id);
          formData.append("status", status._id);
          formData.append("incident_comment", comment);
          uploadFiles?.forEach((item, i) =>
            formData.append(`attachments`, item)
          );
          // console.log(formData);
          dispatch(changeIncidentStatus(formData));
          break;
        }
        //close
        case CLOSE: {
          if (incident_data?.is_task_assigned === 1) {
            // formData.append("id", incident_data._id);
            // formData.append("status_id", status._id);
            // formData.append("incident_comment", comment);
            // let currentTaskNo = incident_data?.sub_task[0].task_no;
            // formData.append("task_no", currentTaskNo);
            // uploadFiles?.forEach((item, i) =>
            //   formData.append(`attachments`, item)
            // );
            // dispatch(closeIncidentTask(formData));
          } else {
            console.log("close incident");
            formData.append("id", incident_data._id);
            formData.append("status_id", status._id);
            formData.append("incident_comment", comment);
            uploadFiles?.forEach((item, i) =>
              formData.append(`attachments`, item)
            );
            dispatch(closeIncident(formData));
          }
          break;
        }
        //assign
        case ASSIGN: {
          if (incident_data?.is_task_assigned && role === POOL_ADMIN) {
            formData.append("id", incident_data._id);
            formData.append("task_no", taskno);
            formData.append("assigned_to", assignData.user._id);
            formData.append("incident_comment", comment);
            uploadFiles?.forEach((item, i) =>
              formData.append(`attachments`, item)
            );
            dispatch(transferIncidentTaskToPoolUser(formData));
          } else {
            formData.append("id", incident_data._id);
            formData.append("status_id", status._id);
            formData.append("incident_comment", comment);
            uploadFiles?.forEach((item, i) =>
              formData.append(`attachments`, item)
            );
            if (assignData.type === "Pool User") {
              formData.append("to_department", 0);
              formData.append("assigned_to", assignData.user._id);
              if (incident_data?.is_task_assigned) {
                formData.append("task_no", taskno);
              }
              dispatch(assignTaskToPoolUser(formData));
            } else {
              formData.append("to_department", 1);
              formData.append("department", assignData.department._id);
              if (incident_data?.is_task_assigned) {
                formData.append("task_no", taskno);
              }
              console.log(formData);
              dispatch(assignTaskToPoolDepartment(formData));
            }
          }
          break;
        }
        case ASSIGN_SUBTASK: {
          formData.append("id", incident_data._id);
          formData.append("status_id", status._id);
          formData.append("incident_comment", comment);
          uploadFiles?.forEach((item, i) =>
            formData.append(`attachments`, item)
          );
          formData.append("to_department", 0);
          formData.append("assigned_to", assignData.user._id);
          if (incident_data?.is_task_assigned) {
            formData.append("task_no", taskno);
          }
          dispatch(assignTaskToPoolUser(formData));
          break;
        }
        //reopen
        case RE_OPEN: {
          formData.append("id", incident_data._id);
          formData.append("status_id", status._id);
          formData.append("incident_comment", comment);
          uploadFiles?.forEach((item, i) =>
            formData.append(`attachments`, item)
          );
          console.log(formData);
          dispatch(reopenIncident(formData));
          break;
        }
        case SUBMIT_TASK: {
          alert("submit")
          // return
          console.log(incident_data?.current_user_tasks[0], incident_data, incident_data?.sub_task)
          let currentTask = receivedTask
          const subTasks = incident_data?.sub_task.filter(item => item.parent_sub_task === currentTask.task_no)
          console.log(subTasks.filter(item => item.status.handle_key !== SUBMIT_TASK && item.status.handle_key !== CLOSE_TASK))
          return
          formData.append("id", incident_data._id);
          formData.append("status_id", status._id);
          formData.append("incident_comment", comment);
          uploadFiles?.forEach((item, i) =>
            formData.append(`attachments`, item)
          );
          // let task = getAllTasks()[0];
          let task = incident_data?.current_user_tasks[0];
          formData.append("task_no", task.task_no);
          dispatch(submitIncidentTask(formData));
          break;
        }
        default: {
          console.log("default");
          break;
        }
      }
    }
    if (status == "" && incident_data?.resend_return_link && incident_data?.incident_status?.handle_key === "return") {
      let formData = new FormData();
      formData.append("id", incident_data._id);
      formData.append("status_id", incidentStatusList?.find(item=> item.handle_key === "return")?._id);
      formData.append("incident_comment", comment);
      uploadFiles?.forEach((item, i) =>
        formData.append(`attachments`, item)
      );
      dispatch(returnIncident(formData));
    }
  };

  const selectType = () => {
    setType(!type);
  };
  const addProof = () => {
    setProof(!proof);
  };

  const onAdditionalChangeHandler = (key, value) => {
    setAddObj({
      ...addObj,
      [key]: value,
    });
  };

  const validateAddObj = () => {
    let temp = {};
    if (!addObj.file && !addObj.fileName) {
      temp = { ...temp, file: t("Please select file") };
    }
    if (addObj.file) {
      const extension = addObj.file.name.split(".").pop().toLowerCase();

      // Define the allowed file formats
      const allowedFormats = ["jpeg", "jpg", "png", "webp", "pdf"];

      // Check if the file format is valid
      if (!allowedFormats.includes(extension)) {
        temp = { ...temp, file: t("Please select a valid file format") };
      }
    }
    // if (addObj.doc_id?.length == 0) {
    //   temp = { ...temp, doc_id: "Please enter document Id" };
    // }
    if (addObj?.doc?.trim()?.length == 0) {
      temp = { ...temp, doc: t("Please enter document type") };
    }
    if (Object.values(temp).length != 0) {
      setAddErr(temp);
      return false;
    } else {
      return true;
    }
  };

  const onAddHandler = () => {
    if (validateAddObj()) {
      setAdditionalData([...additionalData, addObj]);
      setAddObj({
        doc: "",
        doc_id: "",
        file: "",
      });
    }
  };

  const onClearHandler = () => {
    setAddObj({
      doc: "",
      doc_id: "",
      file: "",
    });
    setAddErr({});
  };

  const onEditNewHandler = () => {
    if (validateAddObj()) {
      let arr = [...additionalData];
      arr[editNew] = addObj;
      setAdditionalData([...arr]);
      setAddObj({
        doc: "",
        doc_id: "",
        file: "",
      });
      setEditNew(null);
    }
  };

  const onEditOldHandler = (ele) => {
    console.log(ele);
    setEditOld(ele._id);
    setAddObj({
      doc: ele.attachment_type,
      doc_id: ele.attachment_number,
      file: "",
      fileName: ele.attachment_name,
      filePath: `${IMAGE_BASE_URL}${url}/${ele.attachment_name}`,
    });
  };

  const onEditOldAdditional = () => {
    if (validateAddObj()) {
      let arr = oldAttachments?.map((ele) => {
        if (ele._id == editOld) {
          return {
            ...ele,
            attachment_name: addObj?.fileName ? addObj?.fileName : null,
            attachment_number: addObj.doc_id,
            attachment_type: addObj.doc,
            file: addObj.file ?? "",
          };
        } else {
          return ele;
        }
      });
      setOldAttachments(arr);
      setAddObj({
        doc: "",
        doc_id: "",
        file: "",
      });
      setEditOldIds(
        editOldIds?.includes(editOld) ? editOldIds : [...editOldIds, editOld]
      );
      setEditOld(null);
    }
  };

  const onDeleteAddHandler = (i) => {
    // console.log(additionalData.splice(i, 1), i);
    // setAdditionalData(additionalData.filter((ele, index) => index != i));
    setDelNewAttachmentValue(i);
    // setDelNewAttachmentModal(true);
    setModalType("del_new_attachment");
  };

  const onDeleteNewAttachment = () => {
    setAdditionalData(
      additionalData.filter((ele, index) => index != delNewAttachmentValue)
    );
    // setDelNewAttachmentModal(false);
    setDelNewAttachmentValue(null);
    hideModal();
  };

  const onEditAddHandler = (ele, i) => {
    console.log(ele);
    setEditNew(i);
    setAddObj(ele);
  };

  const onDeleteOldHandler = (ele) => {
    setDelOldAttachmentValue(ele);
    // setDelOldAttachmentModal(true);
    setModalType("del_old_attachment");
  };

  const onDeleteOldAttachment = () => {
    setOldAttachments(
      oldAttachments.filter((ele) => ele._id != delOldAttachmentValue._id)
    );
    setEditOldIds(
      editOldIds?.filter((item) => item._id == delOldAttachmentValue._id)
    );
    setDelOldAttachmentIds([...delOldAttachmentIds, delOldAttachmentValue._id]);
    setDelOldAttachmentValue(null);
    // setDelOldAttachmentModal(false);
    hideModal();
  };

  const onCancelHandler = () => {
    setErr({});
    setAdditionalData([]);
    setAddObj({
      doc: "",
      doc_id: "",
      file: "",
    });
    setAddErr({});
    setForwardDept("");
    setUploadFiles([]);
    setIncidentType("");
    setComment("");
    setInciType(incident_data?.incident_type);
    // dispatch(
    //   updateIncidentRedux({
    //     key: "incident_details",
    //     value: {},
    //   })
    // );
    // navigate("/admin/incidents/incident_requests");
  };

  const hideModal = () => {
    setErr({});
    setForwardData({});
    setAssignData({});
    setUserSearchParams({ ...userSearchParams, section: "" });
    dispatch(
      updateIncidentRedux({
        key: "incident_status_change_loading",
        value: false,
      })
    );
    setForwardDept("");
    setForwardType("");
    setModalType("");
    setStatus("");
    // setComment("")
    // setReturnComment("")
  };

  useEffect(() => {
    if (incidentStatusChangeSuccess) {
      setModalShow(false);
      setModalType();
      dispatch(
        updateIncidentRedux({
          key: "incident_status_change_success",
          value: false,
        })
      );
      dispatch(updateIncidentRedux({ key: "incident_details", value: {} }));
      setStatus("");
      setAdditionalData([]);
      setUploadFiles([]);
      setComment("");
      setForwardData({});
      setAssignData({});
      dispatch(getIncidentDetails(id, displaytype, taskno, (data, status) => {
        if (!status) {
          toast.error(t(data.message), {
            position: "bottom-center",
            autoClose: 3000
          })
        }
      }));

    }
    if (incidentStatusChangeSuccess) {
      navigate("/admin/incidents/incident_requests");
    }
  }, [incidentStatusChangeSuccess]);

  useEffect(() => {
    if (userSearchParams.department) {
      dispatch(getSection(userSearchParams.department._id));
      dispatch(getSectionHavingUsers(userSearchParams.department._id))
    }
  }, [userSearchParams]);

  useEffect(() => {
    dispatch(updateIncidentRedux({ key: "searchuser_list", value: [] }))
    let formData = new FormData();
    userSearchParams.department &&
      formData.append("department[0]", userSearchParams.department?._id);
    userSearchParams.section &&
      formData.append("section[0]", userSearchParams.section?._id);
    formData.append("is_pool_user", 1);
    dispatch(getSerachUsers(formData));
  }, [modalType, userSearchParams]);

  const fileViewHandler = (ele) => {
    setFileView(ele.file);
    setFileType(ele.doc);
    setFileNumber(ele.doc_id);
    setModalShow(true);
  };

  const oldFileViewHandler = (ele) => {
    setOldFileView(ele.attachment_name);
    setFileType(ele.attachment_type);
    setFileNumber(ele.attachment_number);
    setModalShow(true);
  };

  const oldIncidentAttachmentViewHandler = (ele) => {
    setOldFileView(ele);
    setFileType("");
    setFileNumber("");
    setModalShow(true);
  };

  const fileUploadViewHandler = (ele) => {
    console.log(ele);
    setFileView(ele);
    setFileType("");
    setFileNumber("");
    setModalShow(true);
  };

  const onFileChangeHandler = (e) => {
    let temp = [];
    let size = 0;
    [...e.target.files]?.forEach((ele) => {
      const file = ele;
      const fileType = file.name
        .substr(file.name.lastIndexOf(".") + 1)
        .toLowerCase();
      size = size + ele.size;
      if (
        ["jpeg", "jpg", "png", "pdf", "PDF", "mp4", "docx", "doc", "mov"].includes(
          fileType
        )
      ) {
        setErr({ ...err, source_file: "" });
      } else {
        temp.push(ele);
        setErr({
          ...err,
          uploadFiles:
            t("Please upload files with jpeg, jpg, png, pdf, mp4, docx, mov, doc formats"),
        });
      }
    });
    let prevDataSize = 0;
    if (uploadFiles?.length != 0) {
      uploadFiles?.forEach((ele) => {
        prevDataSize = prevDataSize + ele.size;
      });
    }
    let totalFileSize = prevDataSize + size;
    const fileSize = Math.round(totalFileSize / 1024);
    if (fileSize > 100 * 1024) {
      setErr({
        ...err,
        uploadFiles: t(
          "File too Big, please select files less than 100mb"
        ),
      });
    }
    if (fileSize <= 100 * 1024 && temp?.length == 0) {
      setUploadFiles([...uploadFiles, ...e.target.files]);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.label}
    </Tooltip>
  );

  const activeActionsList = () => {
    // console.log(incident_data?.incident_status?.handle_key)
    let list = incidentStatusList;

    switch (incident_data?.incident_status?.handle_key) {
      case CLOSE: {
        list = incidentStatusList?.filter(
          (item) => item.handle_key === RE_OPEN
        );
        break;
      }
      case SAVE_DRAFT: {
        list = incidentStatusList?.filter(
          (item) => item.handle_key === SAVE_DRAFT || item.handle_key === OPEN
        );
        break;
      }
      default: {
        list = incidentStatusList?.filter(
          (item) =>
            item.handle_key !== SAVE_DRAFT &&
            item.handle_key !== OPEN &&
            item.handle_key !== RE_OPEN &&
            item.handle_key !== ASSIGN_SUBTASK
        );
        break;
      }
    }

    if (incident_data?.current_task_count > 2) {
      list = list?.filter((item) => item.handle_key !== ASSIGN);
    }

    if (incident_data?.incident_source?.handle_key !== "web") {
      list = list?.filter((item) => item.handle_key !== RETURN);
    }

    if (incident_data?.incident_status?.handle_key == "return" && incident_data?.resend_return_link === false) {
      list = list?.filter((item) => item.handle_key !== RETURN);
    }

    if (!(role === POOL_ADMIN || role === POOL_USER)) {
      list = list?.filter((item) => item.handle_key !== ASSIGN);
    }

    list = list?.filter((item) => item.handle_key !== DELETE);
    list = list?.filter(
      (item) =>
        item.handle_key !== SUBMIT_TASK &&
        item.handle_key !== CLOSE_TASK &&
        item.handle_key !== REOPEN_TASK &&
        item.handle_key !== ASSIGN_SUBTASK
    );

    if (incident_data?.is_task_assigned) {
      let isEligible
      if (role === POOL_USER) {
        let currentTask = incident_data?.current_user_tasks && incident_data?.current_user_tasks[0]
        let counterObject = incident_data?.sub_task_counter[currentTask?.task_no]
        isEligible = counterObject && counterObject?.is_eligible && counterObject?.count <= 2;

        list = incidentStatusList?.filter(
          (item) =>
            (isEligible && item?.handle_key === ASSIGN_SUBTASK) ||
            item.handle_key === SUBMIT_TASK
        );
      } else {
        let counterObject = incident_data?.sub_task_counter[taskno]
        isEligible = counterObject && counterObject?.is_eligible && counterObject?.count <= 2;
        let isTransferred = incident_data?.sub_task.find(item => item.task_no === taskno)?.is_transfered
        let handledByAdmin = incident_data?.sub_task.find(item => item.parent_sub_task === taskno)?.handler_role === POOL_ADMIN
        list = incidentStatusList?.filter(
          (item) =>
            (isEligible && item?.handle_key === ASSIGN_SUBTASK && isTransferred === "0") ||
            item.handle_key === SUBMIT_TASK ||
            (item.handle_key === ASSIGN && !handledByAdmin)
        );
      }



    }

    if (role === POOL_ADMIN && !(incident_data?.is_task_assigned)) {
      if (incident_data?.forwarded_to) {
        list = list?.filter(item => item.handle_key !== ASSIGN)
      }
    }

    return list;
  };

  // function statusNaming(status) {
  //   console.log(status,incident_data?.is_task)
  //   switch (status) {
  //     case "Assign Task":
  //       return "Task Assigned";
  //     case "Forward":
  //       return "Forwarded";
  //     case "Return":
  //       return "Returned";
  //     case "Close":
  //       return "Closed";
  //     case "Reopen":
  //       return "Reopened";
  //     case "Save Draft":
  //       return "Draft";
  //     case "Save Draft":
  //       return "Draft";
  //     default:
  //       return status;
  //   }
  // }
  function statusNaming(status) {
    let taskNo = role === POOL_USER && incident_data?.current_user_tasks && incident_data?.current_user_tasks[0]?.task_no
    let taskObject = incident_data?.sub_task?.find(item => item.task_no === taskNo)
    let isSubTask = incident_data?.is_sub_task
    switch (status?.handle_key) {
      case "assign":
        return lang == "en" ? "Task Assigned" : "المهمة الموكلة";
      case "forward":
        return lang == "en" ? "Forwarded" : "تم إرسالها";
      case "return":
        return lang == "en" ? "Returned" : "عاد";
      case "close":
        return lang == "en" ? "Closed" : "مغلق";
      case "reopen":
        return lang == "en" ? "Reopened" : "أعيد فتحه";
      case "save_draft":
        return lang == "en" ? "Draft" : "مسودة";
      case "close_task":
        return lang == "en" ? (isSubTask ? "Subtask Closed" : "Task Closed") : (isSubTask ? "تم إغلاق المهمة الفرعية" : "مسودة");
      case "reopen_task":
        return lang == "en" ? (isSubTask ? "Subtask Reopened" : "Task Reopened") : (isSubTask ? "تمت إعادة فتح المهمة الفرعية" : "مسودة");
      case "assign_subtask":
        return lang == "en" ? "Subtask Assigned" : "تم تعيين مهمة فرعية";
      // case "open":
      //   return incident_data?.is_task && lang == "en"
      //     ? "Task Open"
      //     : incident_data?.is_task && lang == "ar"
      //       ? "المهمة مفتوحة"
      //       : status["incident_status_" + lang];
      case "open":
        return isSubTask && lang == "en"
          ? "Subtask Open"
          : isSubTask && lang == "ar"
            ? "مهمة فرعية مفتوحة"
            : incident_data?.is_task && lang == "en"
              ? "Task Open"
              : incident_data?.is_task && lang == "ar"
                ? "المهمة مفتوحة"
                : status["incident_status_" + lang];
      default:
        return status["incident_status_" + lang];
    }
  }

  const getAllTasks = () => {
    let departmentId = JSON.parse(localStorage.getItem("department"))?._id;
    if (incident_data?.incident_departments?.length === 0) {
      return incident_data.sub_task;
    } else {
      const departmentTasks = incident_data?.incident_departments?.find(
        (item) => item.department._id === departmentId
      )
        ? incident_data?.incident_departments
          ?.find((item) => item.department._id === departmentId)
          .tasks.map((item) => item.task)
        : [];
      return departmentTasks;
    }
  };

  const enableEdit = () => {
    if (incident_data?.is_task_assigned) {
      let taskStatus = getAllTasks()[0].status.handle_key;
      if (taskStatus === SUBMIT_TASK) {
        return true;
      }
    }
    return false;
  };

  // console.log({ selectedTask })
  // console.log(getAllTasks())

  const handleReOpen = () => {
    console.log("reopen");
    if (comment?.trim()?.length == 0) {
      console.log("11");
      setErr({
        ...err,
        comment: t("Please enter comment"),
      });
    } else {
      if (comment?.trim()?.length != 0) {
        console.log("22");
        // if (!/^[a-zA-Z0-9ء-ي .؟،,-:!"?()'\n]+$/.test(comment)) {
        //   console.log("comment validation");
        //   setErr({
        //     ...err,
        //     comment: t("Invalid characters"),
        //   });
        // } else {
        console.log("44");
        let formData = new FormData();
        let reopenStatusId = incidentStatusList?.find(
          (item) => item.handle_key === RE_OPEN
        )?._id;
        formData.append("id", incident_data._id);
        formData.append("status_id", reopenStatusId);
        formData.append("incident_comment", comment);
        uploadFiles?.forEach((item, i) =>
          formData.append(`attachments`, item)
        );
        console.log(formData);
        dispatch(reopenIncident(formData));
        // }
      }
    }
  };

  const getForwardBy = () => {
    let forwardedBy = "";

    let forwardAction = incident_data?.actions?.find(
      (item) => item.forwarded_dept_to
    );
    forwardedBy = forwardAction ? forwardAction.handler_role : "";

    return forwardedBy;
  };

  const getFooterStyle = () => {
    let style = { minWidth: "400px", alignItems: "flex-end" };
    if (
      (role === SUPER_ADMIN || role === INCIDENT_MANAGER || role === ADMIN) &&
      incident_data?.incident_status?.handle_key === CLOSE
    ) {
      style = { minWidth: "270px", alignItems: "flex-end" };
    }
    return style;
  };

  // console.log({ selectedTask });

  const handleReOpenTask = () => {
    if (comment?.trim()?.length == 0) {
      setErr({
        ...err,
        comment: t("Please enter comment"),
      });
      return;
    }

    let formData = new FormData();
    let reopenStatusId = incidentStatusList?.find(
      (item) => item.handle_key === REOPEN_TASK
    )?._id;
    formData.append("id", incident_data._id);
    formData.append("status_id", reopenStatusId);
    formData.append("incident_comment", comment);
    let taskNo = selectedTask?.item.details.task.task_no;
    formData.append("task_no", taskNo);
    uploadFiles?.forEach((item, i) => formData.append(`attachments`, item));
    console.log(formData);
    console.log(incidentStatusList);
    dispatch(reopenIncidentTask(formData));
  };

  const handleCloseTask = () => {
    if (comment?.trim()?.length == 0) {
      setErr({
        ...err,
        comment: t("Please enter comment"),
      });
      return;
    }

    let formData = new FormData();
    let closeTaskStatusId = incidentStatusList?.find(
      (item) => item.handle_key === CLOSE_TASK
    )?._id;
    formData.append("id", incident_data._id);
    formData.append("status_id", closeTaskStatusId);
    formData.append("incident_comment", comment);
    let taskNo = selectedTask?.item.details.task.task_no;
    formData.append("task_no", taskNo);
    uploadFiles?.forEach((item, i) => formData.append(`attachments`, item));
    console.log(formData);
    dispatch(closeIncidentTask(formData));
  };

  const handleSubmitTask = () => {
    if (comment?.trim()?.length == 0) {
      setErr({
        ...err,
        comment: t("Please enter comment"),
      });
      return;
    }
    let submitTaskId = incidentStatusList?.find(item => item.handle_key === SUBMIT_TASK)?._id
    let formData = new FormData();
    formData.append("id", incident_data._id);
    formData.append("status_id", submitTaskId);
    formData.append("incident_comment", comment);
    uploadFiles?.forEach((item, i) =>
      formData.append(`attachments`, item)
    );
    let task = incident_data?.current_user_tasks[0];
    formData.append("task_no", taskno);
    dispatch(submitIncidentTask(formData));
  }

  const isEligibleToAssign = () => {
    let isEligible;
    if (role === POOL_USER) {
      let currentTask = incident_data?.current_user_tasks && incident_data?.current_user_tasks?.length && incident_data?.current_user_tasks[0]
      let counterObject = currentTask && incident_data?.sub_task_counter[currentTask?.task_no]
      isEligible = counterObject && counterObject?.is_eligible && counterObject?.count <= 3
    }

    if (role === POOL_ADMIN) {
      let currentTask = incident_data?.sub_task?.length && incident_data?.sub_task.find(item => item.task_no === taskno)
      let counterObject = currentTask && incident_data?.sub_task_counter[taskno]
      isEligible = counterObject && counterObject?.is_eligible && counterObject?.count <= 3
    }
    return isEligible
  }

  const showCommentSection = () => {
    // console.log({ selectedTask })
    let flag = false;
    if (
      (showActionsSelect() ||
        showReOpenIncidentButton() ||
        showCloseTaskButton() ||
        showReopenTaskButton() ||
        showSubmitTaskButton() ||
        (incident_data?.is_task_assigned &&
          role === POOL_ADMIN &&
          incident_data?.is_closed !== true &&
          !(assignedTaskSubmitted())
        ) ||
        (role === ADMIN || role === SUPER_ADMIN || role === INCIDENT_MANAGER) && isTaskSelected() ? selectedTask?.item.details?.task?.is_consider !== false : true) &&
      (selectedTask ? selectedTask?.item?.details?.task?.exit_task !== "1" : true) &&
      editPermission &&
      (isTaskSelected() ? (selecedTaskParentNotSubmitted() === true && selectedTask?.item?.details?.task?.is_consider && incident_data?.incident_status?.handle_key !== CLOSE) : true)
    ) {
      flag = true;
    }
    return flag;
  };

  const showActionsSelect = () => {
    let flag = false;
    if (incident_data?.incident_status?.handle_key !== CLOSE && incident_data?.can_act == true && (incident_data?.is_incident_assigned ? incident_data?.department_active !== 0 : true) && !(isTaskSelected()) && (!(assignedTaskSubmitted()))) {
      flag = true;
    }
    if (incident_data?.is_task_assigned && !(isEligibleToAssign) && role === POOL_ADMIN) {
      flag = false
    }
    if (showSubmitTaskButton() || showCloseTaskButton()) {
      flag = false
    }
    // console.log({ flag })
    return flag;
  };

  const showSaveButton = () => {
    let flag = false;
    if (showActionsSelect() ||
      // showReOpenIncidentButton() ||
      showCloseTaskButton() ||
      showSubmitTaskButton() ||
      // showReopenTaskButton() ||
      (incident_data?.is_task_assigned &&
        role === POOL_ADMIN &&
        incident_data?.is_closed !== true &&
        !(assignedTaskSubmitted())
      ) ||
      ((role === ADMIN || role === SUPER_ADMIN || role === INCIDENT_MANAGER) && incident_data?.incident_status?.handle_key !== CLOSE)
    ) {
      flag = true;
    }
    return flag;
  };

  const showCloseTaskButton = () => {
    let flag = false;
    if (
      (role === POOL_USER || role === POOL_ADMIN || role === ADMIN || role === SUPER_ADMIN || role === INCIDENT_MANAGER) &&
      incident_data?.incident_status?.handle_key !== CLOSE &&
      isTaskSelected() &&
      selectedTask.item.details?.task.status.handle_key !== SUBMIT_TASK &&
      selectedTask.item.details?.task.status.handle_key !== CLOSE_TASK &&
      incident_data?.can_act == true &&
      !(assignedTaskSubmitted())
    ) {
      flag = true
    }
    return flag;
  };

  const showReopenTaskButton = () => {
    let flag = false;
    if (
      (role === POOL_USER || role === POOL_ADMIN || role === ADMIN || role === SUPER_ADMIN || role === INCIDENT_MANAGER) &&
      incident_data?.incident_status?.handle_key !== CLOSE &&
      isTaskSelected() &&
      (selectedTask.item.details?.task.status.handle_key === SUBMIT_TASK || selectedTask.item.details?.task.status.handle_key === CLOSE_TASK) &&
      incident_data?.can_act == true &&
      !(assignedTaskSubmitted()) &&
      (selectedTask?.item?.details?.task?.exit_task !== "1") &&
      selecedTaskParentNotSubmitted() &&
      selectedTask?.item.details?.task?.is_consider !== false
    ) {
      flag = true
    }
    return flag;
  };

  const showSubmitTaskButton = () => {
    let counterObject = taskno && incident_data?.sub_task_counter && incident_data?.sub_task_counter[taskno]
    let flag = false;
    if (
      (incident_data?.is_task_assigned && counterObject && (counterObject.is_eligible === false || counterObject.count > 2)) &&
      incident_data?.incident_status?.handle_key !== CLOSE &&
      receivedTask?.status.handle_key !== SUBMIT_TASK &&
      receivedTask?.status.handle_key !== CLOSE_TASK &&
      incident_data?.can_act == true &&
      !(assignedTaskSubmitted()) &&
      !(isTaskSelected()) &&
      ((role === POOL_ADMIN && incident_data?.is_task_assigned && receivedTask) ? receivedTask.is_transfered !== "1" : true)
    ) {
      flag = true
    }
    return flag;
  };

  const isTaskSelected = () => {
    // console.log(selectedTask)
    // console.log({ isTaskSelected: selectedTask && !(selectedTask.item.title.includes("Main Request") || selectedTask.item.title.includes("Main Task")) })
    return selectedTask && !(selectedTask.item.title.includes(t("Main Request")) || (incident_data?.is_task_assigned ? (selectedTask.item.title.includes(t("Main Task"))) : !(selectedTask.item.details)))
  }

  const selecedTaskParentNotSubmitted = () => {
    if (selectedTask) {
      let parentTask = selectedTask?.item?.details?.task?.parent_sub_task && incident_data?.sub_task?.find(item => item.task_no === selectedTask?.item?.details?.task?.parent_sub_task)
      if (parentTask && (parentTask.status?.handle_key === SUBMIT_TASK || parentTask.status?.handle_key === CLOSE_TASK)) {
        return false
      }
      return true
    }
    return true
  }

  let receivedTask = incident_data?.current_user_tasks &&
    incident_data?.current_user_tasks[0]

  if (incident_data?.is_task_assigned && role === POOL_ADMIN && taskno) {
    receivedTask = incident_data?.sub_task.find(item => item.task_no === taskno)
  }

  const assignedTaskSubmitted = () => {
    return incident_data?.is_task_assigned && (receivedTask?.status?.handle_key === SUBMIT_TASK || receivedTask?.status?.handle_key === CLOSE_TASK)
  }

  const showReOpenIncidentButton = () => {
    let flag = false;
    if (
      (incident_data?.incident_status?.handle_key === CLOSE &&
        (role === SUPER_ADMIN ||
          role === ADMIN ||
          role === INCIDENT_MANAGER)) &&
      !(isTaskSelected())
    ) {
      flag = true;
    }
    return flag;
  };

  const closeOrSubmitTaskOrCloseIncident = () => {
    if (isTaskSelected()) {
      let currentTask = selectedTask?.item?.details?.task
      const subTasks = incident_data?.sub_task.filter(item => item.parent_sub_task === currentTask?.task_no)
      console.log({ subTasks, tasks: incident_data?.sub_task, selectedTask })
      let unfinishedTasks = subTasks.find(item => item.status.handle_key !== SUBMIT_TASK && item.status.handle_key !== CLOSE_TASK)
      if (unfinishedTasks) {
        setModalType("closeincident_with_unfinished_tasks")
      } else {
        handleCloseTask()
      }
    } else {
      if (incident_data?.is_task_assigned) {
        let currentTask = receivedTask
        const subTasks = incident_data?.sub_task.filter(item => item.parent_sub_task === currentTask?.task_no)
        let unfinishedTasks = subTasks.find(item => item.status.handle_key !== SUBMIT_TASK && item.status.handle_key !== CLOSE_TASK)
        if (unfinishedTasks) {
          setModalType("closeincident_with_unfinished_tasks")
        } else {
          handleSubmitTask()
        }
      } else {
        const subTasks = incident_data?.sub_task
        let unfinishedTasks = subTasks.find(item => item.status.handle_key !== SUBMIT_TASK && item.status.handle_key !== CLOSE_TASK)
        if (unfinishedTasks) {
          setModalType("closeincident_with_unfinished_tasks")
        } else {
          saveHandler()
        }
      }
    }
  }

  const currentTaskSubTasks = () => {
    if (incident_data?.is_task_assigned) {
      let currentTask = receivedTask
      let subTasks = []
      subTasks = incident_data?.sub_task?.filter(item => item.parent_sub_task === currentTask?.task_no && item.status.handle_key !== SUBMIT_TASK && item.status.handle_key !== CLOSE_TASK)
      // console.log({subTasks})
      return subTasks
    } else {
      if (isTaskSelected()) {
        let currentTask = selectedTask?.item?.details?.task
        const subTasks = incident_data?.sub_task?.filter(item => item.parent_sub_task === currentTask?.task_no)
        let unfinishedTasks = subTasks?.filter(item => item.status.handle_key !== SUBMIT_TASK && item.status.handle_key !== CLOSE_TASK)
        return unfinishedTasks
      }
      let subTasks = []
      subTasks = incident_data?.sub_task?.filter(item => item.status.handle_key !== SUBMIT_TASK && item.status.handle_key !== CLOSE_TASK)
      // console.log({subTasks})
      return subTasks
    }
  }

  const onReloadHandler = () => {
    let lastReturnComment = ""
    lastReturnComment = incident_data?.actions?.find(item => item.comment !== "System update: Link expired" && item?.status?.handle_key === "return")?.comment
    setReturnComment(lastReturnComment);
    setComment(lastReturnComment);
    setModalType("return")
    setErr({ ...err, return_comment: "" });
  }

  const timeDiff = (date) => {
    let diff = Math.abs(new Date() - new Date(date));
    let minutes = Math.floor(diff / 1000 / 60);
    // console.log(diff, minutes);
    return minutes;
  };

  return (
    <>
      <IncidentModal
        modalType={modalType}
        onClose={hideModal}
        onHide={hideModal}
        departmentList={departmentList}
        departmentListHavingUsers={departmentListHavingUsers}
        sectionListsHavingusers={sectionListsHavingusers}
        forwardDept={forwardDept}
        setForwardDept={setForwardDept}
        setErr={setErr}
        err={err}
        saveHandler={saveHandler}
        incidentStatusChangeLoading={incidentStatusChangeLoading}
        forwardType={forwardType}
        setForwardType={setForwardType}
        returnComment={returnComment}
        setReturnComment={setReturnComment}
        forwardData={forwardData}
        setForwardData={setForwardData}
        sectionLists={sectionLists}
        searchUsers={searchUsers}
        onDeleteOldAttachment={onDeleteOldAttachment}
        onDeleteNewAttachment={onDeleteNewAttachment}
        assignData={assignData}
        setAssignData={setAssignData}
        userSearchParams={userSearchParams}
        setUserSearchParams={setUserSearchParams}
        assignExcludeList={incident_data?.task_assigned_users}
        // assignExcludeList={[]}
        tasks={getAllTasks()}
        isAssigned={incident_data?.is_task_assigned}
        forwardedBy={getForwardBy()}
        currentTaskSubTasks={currentTaskSubTasks()}
        incident_data={incident_data}
        handleSubmitTask={handleSubmitTask}
        isTaskSelected={isTaskSelected()}
        handleCloseTask={handleCloseTask}
        receivedTask={receivedTask}
        taskno={taskno}
      />
      {/* FILE VIEW */}
      <FileViewModal
        modalshow={modalshow}
        setModalShow={setModalShow}
        fileView={fileView}
        oldFileView={oldFileView}
        url={url}
        fileType={fileType}
        fileNumber={fileNumber}
        setOldFileView={setOldFileView}
        setFileView={setFileView}
        setFileNumber={setFileNumber}
        setFileType={setFileType}
      />
      {loading ? (
        <LoadingSpinner height="600px" />
      ) : (
        <div className="request__details">
          <div className="section__header">
            <div className="breadcrumbs">
              <h1>{t("Requests")}</h1>
              <p>
                <Link to={"/admin/incidents/incident_requests"}>
                  {t("Home")}
                </Link>{" "}
                /{t("Request Detail")}
              </p>
            </div>
            <div className="">
              <div className="personal__details buttons-box-status">
                <span className="d-flex gap-2">
                  {incident_data?.incident_status?.handle_key == "return" &&
                    timeDiff(incident_data?.updatedAt) > 2 ? (
                    <>
                      <img
                        src="/assets/icons/reload.svg"
                        alt=""
                        onClick={() => onReloadHandler()}
                      />
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip({
                          label: "Please click on the refresh icon to resend the link",
                        })}
                      >
                        <img src="/assets/icons/info_return.svg" alt="" />
                      </OverlayTrigger>
                    </>
                  ) : null}
                </span>
                <button
                  style={{ cursor: "auto" }}
                  className={`btn btn-sm btn-${incident_data?.incident_status
                    ?.handle_key == "open"
                    ? displaytype === "subtask"
                      ? "subtask-open"
                      : displaytype === "task"
                        ? "task-open"
                        : "open"
                    : incident_data?.incident_status
                      ?.handle_key == "return"
                      ? "returned"
                      : incident_data?.incident_status
                        ?.handle_key == "forward"
                        ? "forward"
                        : incident_data?.incident_status
                          ?.handle_key == "pending"
                          ? "pending"
                          : incident_data?.incident_status
                            ?.handle_key == "processing"
                            ? "processing"
                            : incident_data?.incident_status
                              ?.handle_key == "assign"
                              ? "assign"
                              : incident_data?.incident_status
                                ?.handle_key == "close"
                                ? "closed"
                                : incident_data?.incident_status
                                  ?.handle_key == "reopen"
                                  ? "reopen"
                                  : incident_data?.incident_status
                                    ?.handle_key == "close_task"
                                    ? displaytype === "subtask"
                                      ? "subtask_closed"
                                      : "close_task"
                                    : incident_data?.incident_status
                                      ?.handle_key == "reopen_task"
                                      ? "reopen_task"
                                      : incident_data?.incident_status
                                        ?.handle_key == "save_draft"
                                        ? "draft"
                                        : ""
                    }`}
                >
                  {/* {statusNaming(incident_data?.incident_status?.['incident_status_' + lang])} */}
                  {incident_data?.incident_status &&
                    statusNaming(incident_data?.incident_status)}
                </button>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <TitleBar Title='Personal Information' ToggleButton DefaultToggle className='titlebar-box'>

                <IncidentPersonalSection
                  incident_data={incident_data}
                  addProof={addProof}
                  proof={proof}
                  attachmentList={attachmentList}
                  onAdditionalChangeHandler={onAdditionalChangeHandler}
                  setAddErr={setAddErr}
                  addErr={addErr}
                  addObj={addObj}
                  onAddHandler={onAddHandler}
                  additionalData={additionalData}
                  oldAttachments={oldAttachments}
                  fileViewHandler={fileViewHandler}
                  onDeleteAddHandler={onDeleteAddHandler}
                  oldFileViewHandler={oldFileViewHandler}
                  onDeleteOldHandler={onDeleteOldHandler}
                  statusNaming={statusNaming}
                  editPermission={editPermission}
                  onEditOldHandler={onEditOldHandler}
                  onEditAddHandler={onEditAddHandler}
                  renderTooltip={renderTooltip}
                  editNew={editNew}
                  onEditNewHandler={onEditNewHandler}
                  editOld={editOld}
                  onEditOldAdditional={onEditOldAdditional}
                  setAddObj={setAddObj}
                  onClearHandler={onClearHandler}
                  onReloadHandler={onReloadHandler}
                />
              </TitleBar>
              <TitleBar Title='Incident Details' DefaultToggle ToggleButton className='titlebar-box incident_detail_section'>
                <IncidentDetailSection
                  incident_data={incident_data}
                  type={type}
                  inciType={inciType}
                  selectType={selectType}
                  incidentTypeList={incidentTypeList}
                  incidentType={incidentType}
                  setIncidentType={setIncidentType}
                  setInciType={setInciType}
                  setErr={setErr}
                  err={err}
                  oldIncidentAttachmentViewHandler={
                    oldIncidentAttachmentViewHandler
                  }
                  url={url}
                  editPermission={editPermission}
                  renderTooltip={renderTooltip}
                />

              </TitleBar>
              <IncidentHistory incident_data={incident_data} taskno={taskno} />

              {editPermission && (showCommentSection()) ? (
                <IncidentCommentSection
                  comment={comment}
                  setComment={setComment}
                  setReturnComment={setReturnComment}
                  setErr={setErr}
                  err={err}
                  onFileChangeHandler={onFileChangeHandler}
                  uploadFiles={uploadFiles}
                  renderTooltip={renderTooltip}
                  fileUploadViewHandler={fileUploadViewHandler}
                  setUploadFiles={setUploadFiles}
                />
              ) : null}

              <IncidentDetailsFooterSections
                getFooterStyle={getFooterStyle}
                handleReOpen={handleReOpen}
                incident_data={incident_data}
                editPermission={editPermission}
                incidentStatusChangeLoading={incidentStatusChangeLoading}
                incidentStatusChangeSuccess={incidentStatusChangeSuccess}
                activeActionsList={activeActionsList}
                status={status}
                setStatus={setStatus}
                comment={comment}
                setComment={setComment}
                err={err}
                setErr={setErr}
                saveHandler={saveHandler}
                onCancelHandler={onCancelHandler}
                handleCloseTask={handleCloseTask}
                handleReOpenTask={handleReOpenTask}
                receivedTask={receivedTask}
                isEligibleToAssign={isEligibleToAssign()}
                incidentTaskStatusChangeLoading={incidentTaskStatusChangeLoading}
                handleSubmitTask={handleSubmitTask}
                closeOrSubmitTaskOrCloseIncident={closeOrSubmitTaskOrCloseIncident}
                taskno={taskno}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default IncidentDetails;
