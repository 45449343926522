import React from "react";
import Select from "react-select";
import moment from "moment";
import { API_BASE_URL, IMAGE_BASE_URL } from "../../../constants/configuration";
import {
  ADMIN,
  INCIDENT_MANAGER,
  POOL_ADMIN,
  SUPER_ADMIN,
} from "../../../constants/configuration";
import PermissionsGate from "../../../utils/PermissionsGate";
import { OverlayTrigger } from "react-bootstrap";
import useTrans from "../../../utils/useTrans";
import IncidentIPSection from "./incidentIpSection";
import useGetRole from "../../../utils/useGetRole";

const IncidentDetailSection = ({
  incident_data,
  type,
  inciType,
  selectType,
  incidentTypeList,
  incidentType,
  setIncidentType,
  setInciType,
  setErr,
  err,
  oldIncidentAttachmentViewHandler,
  url,
  editPermission,
  renderTooltip,
}) => {
  const { t, lang } = useTrans();
  const { role } = useGetRole();

  return (
    <>
      <div className="card-header">
        <div className="row w-100">
          <div className="col-md-4">
            <h6>{t("Incident Type")}</h6>
          </div>
          <div className="col-md-2">
            <h6>{t("Name of Entity")}</h6>
          </div>
          <div className="col-md-2">
            <h6>{t("Date of Incident")}</h6>
          </div>
          <div className="col-md-2">
            <h6>
              <span dir="ltr">{t("Is this incident reported elsewhere?")}</span>
            </h6>
          </div>
          <div className="col-md-2">
            <h6>{t("Source")}</h6>
          </div>
          {/* {incident_data?.incident_source?.source_name_en == "Other" ? (
            <div className="col-md-2">
              <h6>Reason</h6>
            </div>
          ) : null} */}
        </div>
      </div>
      <div className=" row w-100 p-0-25">
        <div className="col-md-4">
          <div className="personal__details">
            <div className="align-center justify-content-between">
              {!type && (
                <span className="d-flex align-items-start">
                  <h4>
                    {inciType?.["incident_type" + (lang === "ar" ? "_ar" : "")]}
                  </h4>
                  {/* <img
                    src="/assets/icons/edit-type.svg"
                    onClick={selectType}
                    className="pointer mr-2"
                    alt=""
                  /> */}
                  {[SUPER_ADMIN, ADMIN, INCIDENT_MANAGER, POOL_ADMIN].includes(
                    role
                  ) &&
                    incident_data?.is_task_assigned === 0 && (
                      <PermissionsGate
                        PermissionsGate
                        permittedUsers={[
                          SUPER_ADMIN,
                          ADMIN,
                          INCIDENT_MANAGER,
                          POOL_ADMIN,
                        ]}
                      >
                        {editPermission ? (
                          <img
                            src="/assets/icons/edit-type.svg"
                            onClick={selectType}
                            className="pointer mr-2"
                            alt=""
                          />
                        ) : null}
                      </PermissionsGate>
                    )}
                </span>
              )}
              {type && (
                <Select
                  name="colors"
                  options={incidentTypeList}
                  value={incidentType}
                  getOptionLabel={(option) =>
                    option["incident_type" + (lang === "ar" ? "_ar" : "")]
                  }
                  getOptionValue={(option) => option._id}
                  className="basic-single w-100"
                  classNamePrefix="select"
                  placeholder={t("Incident Type")}
                  onChange={(e) => {
                    setIncidentType(e);
                    setInciType(e);
                    setErr({ ...err, incidentType: "" });
                    selectType();
                  }}
                />
              )}
              {/* <PermissionsGate
                permittedUsers={[
                  SUPER_ADMIN,
                  ADMIN,
                  INCIDENT_MANAGER,
                  POOL_ADMIN,
                ]}
              >
                {(editPermission && type) ? (
                  <img
                    src="/assets/icons/edit-type.svg"
                    onClick={selectType}
                    className="pointer mr-2"
                    alt=""
                  />
                ) : null}
              </PermissionsGate> */}
              {[SUPER_ADMIN, ADMIN, INCIDENT_MANAGER, POOL_ADMIN].includes(
                role
              ) &&
                incident_data?.is_task_assigned === 0 && (
                  <PermissionsGate
                    PermissionsGate
                    permittedUsers={[
                      SUPER_ADMIN,
                      ADMIN,
                      INCIDENT_MANAGER,
                      POOL_ADMIN,
                    ]}
                  >
                    {(editPermission && type) ? (
                      <img
                        src="/assets/icons/edit-type.svg"
                        onClick={selectType}
                        className="pointer mr-2"
                        alt=""
                      />
                    ) : null}
                  </PermissionsGate>
                )}
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="personal__details">
            <div className="align-center justify-content-between">
              <h4>{incident_data?.incident_entity ? incident_data?.incident_entity : <span className="space">-</span>}</h4>
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="personal__details">
            <h4>
              <span dir="ltr">
                {incident_data?.incident_date ? (
                  moment(incident_data?.incident_date).format("DD MMM YYYY")
                ) : (
                  <span className="pe-5">__</span>
                )}
              </span>
            </h4>
          </div>
        </div>
        <div className="col-md-2">
          <div className="personal__details">
            <h4>{t(incident_data?.is_reported_elsewhere == 1 ? "Yes" : "No")}</h4>
          </div>
        </div>
        <div className="col-md-2">
          <div className="personal__details justify-content-start">
            <h4>{incident_data?.incident_source ? incident_data?.incident_source?.['source_name_' + lang] : <span className="space">-</span>}</h4>
            &nbsp;
            {incident_data?.incident_source?.source_name_en == "Other" &&
              incident_data?.incident_source_other_reason && (
                <h4>{`( ${incident_data?.incident_source_other_reason} )`}</h4>
              )}
          </div>
        </div>
        {/* {incident_data?.incident_source?.source_name_en == "Other" ? (
          <div className="col-md-2">
            <div className="personal__details">
              <h4>
                {incident_data?.incident_source_other_reason}
              </h4>
            </div>
          </div>
        ) : null} */}
      </div>
      <PermissionsGate permittedUsers={[SUPER_ADMIN]}>
        {incident_data?.web_incident_no ? (
          <IncidentIPSection incident_data={incident_data} />
        ) : null}
      </PermissionsGate>
      <div className="row w-100 p-0-25">
        <div className="col-md-10">
          <div
            className="personal__details"
            style={{
              flexDirection: "column",
              alignItems: "start",
              minHeight: "30px",
              marginTop: '0px',
              marginBottom: '20px'
            }}
          >
            <h6 style={{ fontSize: "1rem", opacity: "1", marginTop: "20px" }}>
              <strong>{t("Description")}</strong>
            </h6>
            {/* <h4>{incident_data?.incident_description}</h4> */}
            <p style={{ whiteSpace: 'pre-line' }} className="description_text">
              {incident_data?.incident_description ? incident_data?.incident_description : <span className="space">-</span>}
            </p>
          </div>
        </div>
        {incident_data?.incident_attachment?.length ? (
          <div className="col-md-12">
            <div className="personal__details d-block">
              <div className="col-md-12">
                {/* {incident_data?.incident_attachment?.length ? ( */}
                <h6 className="mt-3" style={{ fontSize: "1rem", opacity: "1" }}>
                  <strong>{t("Attachments")}</strong>
                </h6>
                {/* // ) : null} */}
              </div>
              <div className="attachment__lists mb-4">
                <>
                  {incident_data?.incident_attachment?.map((ele, i) => {
                    return (
                      <>
                        {["pdf", "PDF"].includes(
                          ele.split(".").reverse()[0]
                        ) ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip({
                                label: ele,
                              })}
                            >
                              <div className="grid__item">
                                <div className="image-preview">
                                  <a
                                    href={`${IMAGE_BASE_URL}${url}/${ele}`}
                                    target="_blank"
                                  >
                                    <img
                                      src="/assets/icons/files/pdf.svg"
                                      alt=""
                                    />
                                  </a>
                                </div>
                              </div>
                            </OverlayTrigger>
                          </>
                        ) : ["jpg", "jpeg", "webp", "png"].includes(
                          ele.split(".").reverse()[0]
                        ) ? (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip({
                              label: ele,
                            })}
                          >
                            <div className="grid__item">
                              <div>
                                <div
                                  className="image-preview"
                                  onClick={() =>
                                    oldIncidentAttachmentViewHandler(ele)
                                  }
                                >
                                  {/* <img
                                    className="view"
                                    src={`${IMAGE_BASE_URL}${url}/${ele}`}
                                    alt=""
                                  /> */}
                                  <img
                                    src={`${IMAGE_BASE_URL}${url}/${ele}`}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </OverlayTrigger>
                        ) : ["mp4"].includes(ele.split(".").reverse()[0]) ? (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip({
                              label: ele,
                            })}
                          >
                            <div className="grid__item">
                              <div
                                className="image-preview"
                                onClick={() =>
                                  oldIncidentAttachmentViewHandler(ele)
                                }
                              >
                                <img src="/assets/icons/files/mp4.svg" alt="" />
                              </div>
                            </div>
                          </OverlayTrigger>
                        ) : ["mov"].includes(ele.split(".").reverse()[0]) ? (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip({
                              label: ele,
                            })}
                          >
                            <div className="grid__item">
                              <div
                                className="image-preview"
                                onClick={() =>
                                  oldIncidentAttachmentViewHandler(ele)
                                }
                              >
                                <img src="/assets/icons/files/mov.svg" alt="" />
                              </div>
                            </div>
                          </OverlayTrigger>
                        ) : ["docx", "doc"].includes(
                          ele.split(".").reverse()[0]
                        ) ? (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip({
                              label: ele,
                            })}
                          >
                            <div className="grid__item">
                              <div className="image-preview">
                                <a
                                  href={`${IMAGE_BASE_URL}${url}/${ele}`}
                                  target="_blank"
                                >
                                  <img
                                    src={
                                      ele.split(".").reverse()[0] === "docx"
                                        ? "/assets/icons/files/docx.svg"
                                        : ele.split(".").reverse()[0] === "doc"
                                          ? "/assets/icons/files/doc.svg"
                                          : ""
                                    }
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                          </OverlayTrigger>
                        ) : null}
                      </>
                    );
                  })}
                </>
                {(incident_data?.incident_attachment && incident_data?.incident_attachment?.length === 1 && incident_data?.incident_attachment[0] === "") ? <p style={{ whiteSpace: 'nowrap' }}>{t('No Attachments')}</p> : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default IncidentDetailSection;
