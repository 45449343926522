import { useState, useEffect } from 'react';

const useGetRole = () => {
    const [role, setRole] = useState('VIEWER');
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchRole = () => {
        try {
          const userRole = localStorage.getItem('user_type');
          setRole(userRole);
          setLoading(false);
        } catch (error) {
          // Handle error
        }
      };
  
      fetchRole();
    }, []);
  
    return { role, loading };
  };
  
  export default useGetRole;