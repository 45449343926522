import React, { useEffect, useState } from "react";
import "../style.scss";
import "../sms_report.scss";
import "../ManageIncidents/incidents.scss";
import { useDispatch, useSelector } from "react-redux";
import useTrans from "../../../utils/useTrans";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { getSmsReport } from "../../../redux/incidentActionCreator";
import { getDepartment } from "../../../redux/commonActionCreator";
import {
  sms_list_loading,
  sms_report,
  sms_report_page_count,
  sms_report_page_status,
} from "../../../redux/incidentReducer";
import moment from "moment";
import Pagination from "../../../components/Pagination/Pagination";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

function SmsReport() {
  const dispatch = useDispatch();
  const { t, lang } = useTrans();

  const report_data = useSelector(sms_report);
  const pageCount = useSelector(sms_report_page_count);
  const pageStatus = useSelector(sms_report_page_status);
  const loader = useSelector(sms_list_loading);

  const [data, setData] = useState({
    // smsStatus: { value: "", label: t("All") },
  });
  const [sortAscending, setSortAscending] = useState(false);
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [entry, setEntry] = useState(10);

  const smsTypeList = [
    { value: "", label: t("All") },
    { value: "Processing", label: t("Processing") },
    { value: "Failed", label: t("Failed") },
    { value: "Delivered", label: t("Delivered") },
  ];

  useEffect(() => {
    setData({ smsStatus: { value: "", label: t("All") } })
  }, [t('All')]);

  useEffect(() => {
    dispatch(getSmsReport());
  }, []);


  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const onEnterSearch = (e) => {
    if (e.key == "Enter") {
      onSearchHandler();
    }
  };

  const onSearchHandler = () => {
    setPage(0);
    let formData = new FormData();
    formData.append("from_date", data?.fromDate ?? "");
    formData.append("to_date", data?.toDate ?? "");
    formData.append("keyword", data?.searchKey?.trim() ?? "");
    formData.append("page", 0);
    formData.append("perPage", entry);
    sortColumn && formData.append("sort_key", sortColumn);
    sortColumn && formData.append("sort_order", !sortAscending ? 1 : -1);
    dispatch(getSmsReport(formData));
  };

  const handleClearFilter = () => {
    setPage(0);
    setData({
      fromDate: "",
      toDate: "",
      searchKey: "",
      smsStatus: { value: "", label: t("All") },
    });
    setSortAscending(false);
    setSortColumn("createdAt");
    let formData = new FormData();
    formData.append("from_date", "");
    formData.append("to_date", "");
    formData.append("keyword", "");
    formData.append("page", 0);
    formData.append("perPage", entry);
    formData.append("status", "");
    sortColumn && formData.append("sort_key", "createdAt");
    sortColumn && formData.append("sort_order", -1);
    dispatch(getSmsReport(formData));
  };

  const onSortHandler = (type) => {
    setSortAscending(!sortAscending);
    setSortColumn(type);
    let formData = new FormData();
    formData.append("from_date", data?.fromDate ?? "");
    formData.append("to_date", data?.toDate ?? "");
    formData.append("keyword", data?.searchKey?.trim() ?? "");
    formData.append("status", data?.smsStatus?.value);
    formData.append("page", page);
    formData.append("perPage", entry);
    sortColumn && formData.append("sort_key", type);
    sortColumn && formData.append("sort_order", sortAscending ? 1 : -1);
    dispatch(getSmsReport(formData));
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    let formData = new FormData();
    formData.append("from_date", data?.fromDate ?? "");
    formData.append("to_date", data?.toDate ?? "");
    formData.append("keyword", data?.searchKey?.trim() ?? "");
    formData.append("status", data?.smsStatus?.value);
    formData.append("page", e.selected);
    formData.append("perPage", entry);
    sortColumn && formData.append("sort_key", sortColumn);
    sortColumn && formData.append("sort_order", !sortAscending ? 1 : -1);
    dispatch(getSmsReport(formData));
  };

  const onTypeChangeHandler = (e) => {
    onChangeHandler("smsStatus", e);
    setPage(0);
    let formData = new FormData();
    formData.append("from_date", data?.fromDate ?? "");
    formData.append("to_date", data?.toDate ?? "");
    formData.append("keyword", data?.searchKey?.trim() ?? "");
    formData.append("status", e?.value);
    formData.append("page", 0);
    formData.append("perPage", entry);
    sortColumn && formData.append("sort_key", sortColumn);
    sortColumn && formData.append("sort_order", !sortAscending ? 1 : -1);
    dispatch(getSmsReport(formData));
  };

  const formatInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const handleEntry = (e) => {
    // if (e.target.value === "") return;
    // if (e.target.value == 0 || !e.target.value) {
    //   toast.error(t("Please choose a valid entry"), {
    //     position: "bottom-center",
    //     autoClose: 3000,
    //   });
    //   return;
    // }
    if (Number(e.target.value) > 100) {
      toast.error(t("Entry should not be greater than 100"), {
          position: "bottom-center",
          autoClose: 3000,
      });
      return;
  }
    setEntry(e.target.value);
    entryChange(e.target.value);
  };

  const entryChange = (value) => {
    let formData = new FormData();
    formData.append("from_date", data?.fromDate ?? "");
    formData.append("to_date", data?.toDate ?? "");
    formData.append("keyword", data?.searchKey?.trim() ?? "");
    formData.append("page", 0);
    formData.append("perPage", value);
    sortColumn && formData.append("sort_key", sortColumn);
    sortColumn && formData.append("sort_order", !sortAscending ? 1 : -1);
    dispatch(getSmsReport(formData));
  };

  return (
    <div className="container-fluid" id="sms_report">
      <div className="manage__incidents">
        <div className="section__header">
          <div className="breadcrumbs">
            <h1>{t("SMS Report")}</h1>
            <p>
              <Link to={"/admin/incidents/incident_requests"}>{t('Home')}</Link> /{" "}
              {t('Sms Reports')}
            </p>
            {/* <p>
              {t("Home")} / {t("Sms Reports")}
            </p> */}
          </div>
        </div>
        <section>
          <p className="key">{t("Date")}</p>
          <div className="dates d-flex gap-4">
            <span>
              <DatePicker
                selected={data.fromDate}
                onChange={(date) => {
                  onChangeHandler("fromDate", date);
                }}
                maxDate={data.toDate ? data.toDate : new Date()}
                dateFormat="dd/MM/yyyy"
                className=""
                placeholderText={t("From")}
              />
              <img src="assets/icons/calendar.svg" alt="" />
            </span>
            <span>
              <DatePicker
                selected={data.toDate}
                onChange={(date) => {
                  onChangeHandler("toDate", date);
                }}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
                className=""
                placeholderText={t("To")}
                minDate={data.fromDate && data.fromDate}
              />
              <img src="assets/icons/calendar.svg" alt="" />
            </span>
            <div className="col-md-2">
              <div className="position-relative">
                <button
                  className="btn btn-primary w-95"
                  onClick={() => onSearchHandler()}
                >
                  {t("Search")}
                </button>
              </div>
            </div>
            {data?.fromDate || data?.toDate || data.searchKey ? (
              <div className="col-md-1">
                <div className="position-relative">
                  <button
                    className="btn btn-primary btn-clear"
                    onClick={() => handleClearFilter()}
                  >
                    <img src="./assets/icons/clear.svg" alt="X" />
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </section>
        <section className="graph">
          <div className="count__card">
            <p className="key">{t("Total")}</p>
            <p className="count total">{report_data?.total_count}</p>
          </div>
          <div className="count__card">
            <p className="key">{t("Processing")}</p>
            <p className="count processing">
              {report_data?.processing_percent}%
            </p>
          </div>
          <div className="count__card">
            <p className="key">{t("Failed")}</p>
            <p className="count failed">{report_data?.failed_percent}%</p>
          </div>
          <div className="count__card">
            <p className="key">{t("Delivered")}</p>
            <p className="count delivered">{report_data?.delivered_percent}%</p>
          </div>
        </section>
        <section className="">
          <div className="search__options mt-3">
            <div className="d-flex gap-2">
              <div className="search">
                <Form.Control
                  type="text"
                  placeholder={t("Search")}
                  value={data?.searchKey}
                  onChange={(e) => onChangeHandler("searchKey", e.target.value)}
                  onKeyDown={(e) => onEnterSearch(e)}
                />
                <button
                  className="btn btn-search"
                  onClick={() => onSearchHandler()}
                >
                  <img src="/assets/icons/Search1.svg" alt="" />
                </button>
              </div>
              <div className="sms-type-style">
                <Select
                  name="colors"
                  options={smsTypeList}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => onTypeChangeHandler(e)}
                  value={data?.smsStatus}
                  className="basic-single sms-report-filter"
                  classNamePrefix="select"
                  placeholder={t("Select")}
                />
              </div>
            </div>

            <div className="show">
              <span>{t("Entries")}</span>
              <Form.Control
                // disabled={incidentsLoading}
                type="number"
                min={1}
                style={{ width: "65px" }}
                // style={{ direction: "ltr", width: "65px" }}
                onKeyDown={formatInput}
                value={entry}
                onChange={handleEntry}
              />
              <span>{t("Show")}</span>
            </div>
          </div>
        </section>
        {loader ? (
          <LoadingSpinner />
        ) : (
          <>
            {/* <section className="search">
              <div className="show">
                <span>{t("Entries")}</span>
                <Form.Control
                  // disabled={incidentsLoading}
                  type="number"
                  min={1}
                  style={{ direction: "ltr", width: "65px" }}
                  onKeyDown={formatInput}
                  value={entry}
                  onChange={handleEntry}
                />
                <span>{t("Show")}</span>
              </div>
              <div className="sms-type-style">
                <Select
                  name="colors"
                  options={smsTypeList}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => onTypeChangeHandler(e)}
                  value={data?.smsStatus}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder={t("Select")}
                />
              </div>
              <span className="searchInput">
                <input
                  type="text"
                  placeholder={t("Search")}
                  value={data?.searchKey}
                  onChange={(e) => onChangeHandler("searchKey", e.target.value)}
                  onKeyDown={(e) => onEnterSearch(e)}
                />
                <img
                  src="/assets/icons/Search1.svg"
                  alt=""
                  onClick={() => onSearchHandler()}
                  className="cursor-pointer"
                />
              </span>
            </section> */}

            <section className="table">
              <table>
                <thead>
                  <tr>
                    <th
                      onClick={() => onSortHandler("reference_id")}
                      className={`cursor-pointer 
                          ${sortColumn == "reference_id" &&
                          sortAscending == true
                          ? "active"
                          : sortColumn == "reference_id" &&
                            sortAscending == false
                            ? "inactive"
                            : ""
                        }`}
                    >
                      {t("Reference ID")}
                    </th>
                    <th
                      onClick={() => onSortHandler("phone")}
                      className={`cursor-pointer 
                          ${sortColumn == "phone" && sortAscending == true
                          ? "active"
                          : sortColumn == "phone" && sortAscending == false
                            ? "inactive"
                            : ""
                        }`}
                    >
                      {t("Phone Number")}
                    </th>
                    <th
                      onClick={() => onSortHandler("sent_date")}
                      className={`cursor-pointer 
                          ${sortColumn == "sent_date" && sortAscending == true
                          ? "active"
                          : sortColumn == "sent_date" &&
                            sortAscending == false
                            ? "inactive"
                            : ""
                        }`}
                    >
                      {t("Sent Date")}
                    </th>
                    <th
                      onClick={() => onSortHandler("status")}
                      className={`cursor-pointer 
                          ${sortColumn == "status" && sortAscending == true
                          ? "active"
                          : sortColumn == "status" && sortAscending == false
                            ? "inactive"
                            : ""
                        }`}
                    >
                      {t("Status")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {report_data?.result?.map((ele, i) => {
                    return (
                      <tr>
                        <td>{ele?.reference_id}</td>
                        <td style={{ textAlign: "right" }}>
                          <span dir="ltr">{ele?.phone}</span>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <span dir="ltr">
                            {moment(ele?.sent_date).format("DD MMM YYYY")}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`btnStyle ${ele?.status == "Processing"
                              ? "processing"
                              : ele?.status == "Delivered"
                                ? "delivered"
                                : ele?.status == "Failed"
                                  ? "failed"
                                  : ""
                              }`}
                          >
                            {t(ele?.status)}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {report_data?.result?.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "13px"
                  }}
                >
                  <h6>{t("No Data")}</h6>
                </div>
              )}
              {report_data?.result?.length ? (
                <div
                  className="align-between"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "13px",
                  }}
                >
                  <p style={{ direction: "ltr" }}>{pageStatus}</p>
                  <Pagination
                    page={page}
                    pageCount={pageCount}
                    handlePageChange={handlePageChange}
                  />
                </div>
              ) : (
                <></>
              )}
            </section>
          </>
        )}
      </div>
    </div>
  );
}

export default SmsReport;
