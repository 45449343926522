import React from "react";
import Select from "react-select";
import moment from "moment";
import PermissionsGate from "../../../utils/PermissionsGate";
import {
  ADMIN,
  INCIDENT_MANAGER,
  POOL_ADMIN,
  POOL_USER,
  SUPER_ADMIN,
} from "../../../constants/configuration";
import { OverlayTrigger } from "react-bootstrap";
import AddAdditionalDocument from "./AddAdditionalDocument";
import useTrans from "../../../utils/useTrans";
import useGetRole from "../../../utils/useGetRole";
import { roles } from "aria-query";
import { useParams } from "react-router-dom";

const IncidentIPSection = ({
  incident_data
}) => {
  const { t } = useTrans();
  const lang = localStorage.getItem("lang");
  const { id, displaytype, taskno } = useParams();
  const { role } = useGetRole();

  const timeDiff = (date) => {
    let diff = Math.abs(new Date() - new Date(date));
    let minutes = Math.floor(diff / 1000 / 60);
    console.log(diff, minutes);
    return minutes;
  };

  return (
    <>
      <div className="card-header">
        <div className="row w-100">
          <div className="col-md-2">
            <h6>{t("Reported IP Address")}</h6>
          </div>
          <div className="col-md-2">
            <h6>{t("Reported Date")}</h6>
          </div>
          <div className="col-md-2">
            <h6>{t("Reported Time")}</h6>
          </div>
          {/* <div className="col-md-2">
            <h6>{t("Reported Browser Name")}</h6>
          </div> */}
          <div className="col-md-2">
            <h6>{t("Reported Os")}</h6>
          </div>
        </div>
      </div>
      <div className=" row w-100 p-0-25">
        <div className="col-md-2">
          <div className="personal__details">
            <h4><span dir="ltr">{incident_data?.incident_reporter_ip}</span></h4>
          </div>
        </div>
        <div className="col-md-2">
          <div className="personal__details">
            <h4>
              <span dir="ltr">
                {incident_data?.created_date
                  ? moment.utc(incident_data?.created_date).format("DD MMM YYYY")
                  : ""}
              </span>
            </h4>
          </div>
        </div>
        <div className="col-md-2">
          <div className="personal__details">
            <h4>
              <span dir="ltr">
                {incident_data?.created_date
                  ?
                  moment.utc(incident_data?.created_date).format("hh : mm A")
                  : ""}
              </span>
            </h4>
          </div>
        </div>
        {/* <div className="col-md-2">
          <div className="personal__details">
            <h4>{incident_data?.incident_reporter_browser}</h4>
          </div>
        </div> */}
        <div className="col-md-2">
          <div className="personal__details">
            <h4>{incident_data?.incident_reporter_os}</h4>
          </div>
        </div>
      </div>
    </>
  );
};
export default IncidentIPSection;
