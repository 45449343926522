import React from 'react'
import './ReportCounts.scss'

export default function ReportCounts({data}) {

  return (
    <div id='ReportCounts'>
      {
        data.map((datom, i) => (
          <span key={i} className={datom.title}>
            <p>{datom.title}</p>
            <p>{datom.count}</p>
          </span>
        ))
      }
    </div>
  )
}
