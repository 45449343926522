import React from "react";
import { useNavigate } from "react-router";
import "./Header.scss";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAllNotification, deleteNotification, getNotification, logout } from "../../redux/commonActionCreator";
import { clear_all_notification_loading, logout_loader, notifications, notifications_count } from "../../redux/commonReducer";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { roles } from "../../constants/configuration";
import useTrans from "../../utils/useTrans";
import { Capitalize } from "../../utils/dateFormat";
import { Dropdown, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";

export default function Header({ setNavToggle }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, lang } = useTrans()
  const logoutLoading = useSelector(logout_loader)
  const notificationItems = useSelector(notifications)
  const clearAllLoading = useSelector(clear_all_notification_loading)
  const notificationCount = useSelector(notifications_count)
  const [loading, setLoading] = useState(false);

  const [user, setUSer] = useState({
    username: "",
    userType: "",
    department: ""
  });
  const [showModal, setShowModal] = useState(false);
  const [showNotification, setShowNotifications] = useState(false);

  useEffect(() => {
    setUSer({
      username: localStorage.getItem("username"),
      user: JSON.parse(localStorage.getItem("user")),
      userType: localStorage.getItem("user_type"),
      department: JSON.parse(localStorage.getItem('department'))?.department_name
    });
    dispatch(getNotification())
  }, []);

  const logoutHandler = () => {
    dispatch(
      logout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        localStorage.removeItem("user");
        localStorage.removeItem("user_type");
        // localstorage.removeItem("lang");
        window.location.replace('/login')
        navigate("/login");
      })
    );
  };


  const handleDelete = (id) => {
    const data = {
      id
    }
    dispatch(deleteNotification(data, () => {
      setLoading({ [id]: true })
      dispatch(getNotification("", () => {
        setLoading(false)
      }))
    }))
  }

  const tooltip = (
    <Tooltip id="tooltip" style={{ marginTop: 10, fontSize: 12 }}>
      <strong>{t('Clear')}</strong>
    </Tooltip>
  );

  const handleClearAllNotification = () => {
    dispatch(deleteAllNotification('', () => {
      dispatch(getNotification())
    }))
  }

  const onViewHandler = (ele) => {
    if (ele.display_type === "incident") {
      navigate(`/admin/incidents/incident_details/${ele.incident_id?._id}/${ele.display_type}`)
    } else {
      navigate(`/admin/incidents/incident_details/${ele.incident_id?._id}/${ele.display_type}/${ele.task_no}`)
    }
    handleDelete(ele?._id)
    setShowNotifications(false)
  };

  const handleNotification = () => {
    setShowNotifications(!showNotification)
  }

  useEffect(() => {
    window.document.addEventListener('click', (e) => {
      let notification_viewer = document.getElementById('notification_wrap')
      let bell_icon = document.getElementById('notification_bell')
      if (notification_viewer) {
        console.log(!(notification_viewer.contains(e.target)), e.target !== bell_icon, notification_viewer, e.target, bell_icon)
        if (!notification_viewer.contains(e.target) && e.target !== bell_icon) {
          setShowNotifications(false)
        }
      }
    })
  }, [])

  return (
    <div id="Header">
      <i className="hamburger" onClick={() => setNavToggle((s) => !s)}>
        <img src="/assets/icons/hamburger.svg" alt="" />
      </i>
      <div className="user">

        <div className="notification">
          {
            notificationItems?.length !== 0 &&

            <span className="count"></span>

          }

          <i className="bell">
            <img id="notification_bell" src="/assets/icons/Icon.svg" alt="" onClick={handleNotification} />
          </i>

          {showNotification && notificationItems?.length !== 0 && (
            <>
              <div className="notification__lists" id="notification_wrap">
                <div className="close" style={{ filter: 'inherit' }}>
                  <img src={"/assets/icons/close.svg"} alt="" onClick={handleNotification} />
                  <p>{t("Notifications")} ({notificationItems?.length})</p>
                </div>
                <div className="content">

                  <ul>
                    {notificationItems?.map((ele, i) => {
                      return (
                        <li key={i}>
                          <span onClick={() => onViewHandler(ele)}>{lang === "Ar" ? ele?.title : ele?.title}</span>
                          {
                            loading?.[ele._id] ? <div className="spinner-border" role="status">
                              <span className="sr-only"></span>
                            </div> : <img onClick={() => handleDelete(ele?._id)} src={"/assets/icons/Close_circle.svg"} alt="" />
                          }

                        </li>
                      );
                    })}
                  </ul>
                  {
                    notificationItems &&
                    <div className="clear">
                      <p onClick={handleClearAllNotification}>{t("Clear All")}</p>
                    </div>
                  }
                </div>

              </div>
            </>
          )}


        </div>
        <div>
          <p className="name">{Capitalize(user?.user?.firstname + ' ' + user?.user?.lastname)}</p>
          <p className="designation">{roles[localStorage.getItem("user_type")]}</p>
          <p>{Capitalize(user.department)}</p>
        </div>
        <i className="logout" onClick={() => setShowModal(true)}>
          <img src="/assets/icons/logout-svgrepo-com.svg" alt="" />
        </i>
      </div>
      <ConfirmationDialog
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleSubmit={() => logoutHandler()}
        title={t("Confirm Logout")}
        description={t("Are you sure you want to logout?")}
        submitLoading={logoutLoading}
        modalHeader={t('Logout')}
      />
    </div>
  );
}
