import React, { useEffect, useState } from "react";
import "../style.scss";
import "../ManageIncidents/incidents.scss";
import { Form, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIncidentList, getSerachUsers, translateText, updateUserRole } from "../../../redux/incidentActionCreator";
import { incident_list, poolUser_list, searchuser_list } from "../../../redux/incidentReducer";
import AdvancedSearch from "../EducationAllowance/AdvancedSearch";
import moment from "moment/moment";
import { delete_user_privilege_loading, delete_user_privilege_success, pool_users_list, pool_users_list_count, pool_users_list_loading, pool_users_list_page_count, pool_users_list_page_status, updateRedux, user_list } from "../../../redux/commonReducer";
import { deletePoolPrivilegeFromUser, getEmployeelist, getPoolUsers } from "../../../redux/commonActionCreator";
import Pagination from "../../../components/Pagination/Pagination";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { toast } from "react-toastify";
import { roles } from "../../../constants/configuration";
import { useTranslation } from "react-i18next";
import useTrans from "../../../utils/useTrans";
// import Modal from "../../../Tools/Modal/Modal";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import Button1 from "../../../components/Button1/Button1";

function RolesListing() {

    const dispatch = useDispatch();

    const { t } = useTrans()
    const userType = localStorage.getItem('user_type')

    const incident_data = useSelector(incident_list);
    const poolUsersList = useSelector(pool_users_list);
    const allUsers = useSelector(searchuser_list)
    const poolUersCount = useSelector(pool_users_list_count)
    const loading = useSelector(pool_users_list_loading)
    const submitLoading = useSelector(delete_user_privilege_loading)
    const deleteUserSuccess = useSelector(delete_user_privilege_success)
    const pageCount = useSelector(pool_users_list_page_count)
    const pageStatus = useSelector(pool_users_list_page_status)

    const [filterData, setFilterData] = useState({
        department: '',
        userType: '',
        employee: '',
        searchKey: ''
    })

    const [page, setPage] = useState(0)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [userToDelete, setUserToDelete] = useState()
    const [reload, setReload] = useState(false)
    const [entry, setEntry] = useState(10)
    const [editModal, setEditModal] = useState(false)
    const [userToEdit, setUserToEdit] = useState(null)
    const [newRole, setNewRole] = useState(null)

    const handlePageChange = (e) => {
        setPage(e.selected)
        let params = ""
        if (filterData.department) {
            params = `${params}department=${filterData.department._id}&`
        }
        if (filterData.employee) {
            params = `${params}employee=${filterData.employee._id}&`
        }
        if (filterData.userType) {
            params = `${params}userType=${filterData.userType}&`
        }
        if (filterData.searchKey) {
            params = `${params}searchKey=${filterData.searchKey}&`
        }
        let formData = new FormData()
        formData.append('page', e.selected)
        formData.append('perPage', entry)
        filterData.department && formData.append('department', filterData.department._id)
        filterData.employee && formData.append('employee', filterData.employee._id)
        filterData.userType && formData.append('userType', filterData.userType.value)
        filterData.searchKey && formData.append('searchKey', filterData.searchKey)
        dispatch(getPoolUsers(formData));
    }

    const clearFilter = () => {
        setFilterData({
            department: '',
            userType: '',
            employee: '',
            searchKey: '',
            section: ''
        })
        setPage(0)
        let formData = new FormData()
        formData.append('page', 0)
        formData.append('perPage', entry)
        dispatch(getPoolUsers(formData));
        setPage(0)
        let data = {
            department: "",
            section: "",
        };
        dispatch(getEmployeelist(data));
    }

    const applySearch = () => {
        setPage(0)
        let formData = new FormData()
        formData.append('page', 0)
        formData.append('perPage', entry)
        filterData.department && formData.append('department', filterData.department._id)
        filterData.employee && formData.append('employee', filterData.employee._id)
        filterData.userType && formData.append('userType', filterData.userType.value)
        filterData.searchKey && formData.append('searchKey', filterData.searchKey)
        dispatch(getPoolUsers(formData));
    }

    const entryChange = (value) => {
        let formData = new FormData()
        formData.append('page', 0)
        formData.append('perPage', value)
        dispatch(getPoolUsers(formData));
        setPage(0)
    }

    useEffect(() => {
        let formData = new FormData()
        formData.append('page', 0)
        formData.append('perPage', entry)
        dispatch(getPoolUsers(formData));
        setPage(0)
    }, [reload]);

    useEffect(() => {
        dispatch(getSerachUsers())
    }, [])


    const handleConfirmDelete = (user) => {
        setShowConfirmDialog(true)
        setUserToDelete(user)
    }

    const deletePoolPrivilege = () => {
        const formData = new FormData()
        formData.append(`ids[${0}]`, userToDelete._id)
        dispatch(deletePoolPrivilegeFromUser(formData, ({ status, message }) => {
            if (status) {
                toast.success(t(message), {
                    position: "bottom-center",
                    autoClose: 3000
                })
            } else {
                toast.error(t(message), {
                    position: "bottom-center",
                    autoClose: 3000
                })
            }
        }))
    }

    useEffect(() => {
        if (deleteUserSuccess) {
            setUserToDelete()
            setShowConfirmDialog(false)
            // setReload(!reload)
            clearFilter()
            dispatch(updateRedux({ key: "delete_user_privilege_success", value: false }))
        }
    }, [deleteUserSuccess])

    const handleEntry = e => {
        // if (e.target.value === '') return
        // if (e.target.value == 0 || !e.target.value) {
        //     toast.error(t('Please choose a valid entry'), {
        //         position: "bottom-center",
        //         autoClose: 3000
        //     })
        //     return
        // }
        if (Number(e.target.value) > 100) {
            toast.error(t("Entry should not be greater than 100"), {
                position: "bottom-center",
                autoClose: 3000,
            });
            return;
        }
        setEntry(e.target.value)
        entryChange(e.target.value)
    }

    const formatInput = (e) => {
        // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
        let checkIfNum;
        if (e.key !== undefined) {
            // Check if it's a "e", ".", "+" or "-"
            checkIfNum = e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
        }
        else if (e.keyCode !== undefined) {
            // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
            checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
        }
        return checkIfNum && e.preventDefault();
    }

    function roleEditHandler(e) {
        setUserToEdit(e)
        setEditModal(true)
        setNewRole({ label: roleManager(e.role), value: e.role })
    }
    function roleManager(role) {
        switch (role) {
            case 'pool_admin': return 'Pool Admin'
            case 'pool_user': return 'Pool User'
            case 'incident_manager': return 'Incident Manager'
            case 'admin': return 'Admin'
            case 'dashboard_view': return 'Dashboard Viewer'
            default: return role
        }
    }
    function editSubmitHandler() {
        let formdata = new FormData()
        formdata.append('id', userToEdit._id)
        formdata.append('role', newRole.value)
        updateUserRole(formdata, ({ status, message }) => {
            setEditModal(false)
            setUserToEdit(null)
            setNewRole(null)
            let formData = new FormData()
            formData.append('page', 0)
            formData.append('perPage', entry)
            dispatch(getPoolUsers(formData));
            status && toast.success(t(message), {
                position: "bottom-center",
                autoClose: 3000
            })
        })
    }

    const tableHeaders = [
        { name: t("Sl No"), },
        { name: t("Employee No"), },
        { name: t("Employee Name"), },
        { name: t("Section"), },
        { name: t("Department"), },
        { name: t("User Type"), },
        { name: t("Action"), },
    ];

    return (
        <div className="container-fluid">
            <div className="manage__incidents">
                <div className="mb-4">

                    <div className="section__header">
                        <div className="breadcrumbs">
                            <h1>{t("Roles")}</h1>
                            <p>
                                <Link to={"/admin/incidents/incident_requests"}>{t('Home')}</Link> /{" "}
                                {t('Roles')}
                            </p>
                            {/* <p>{t("Home")} / {t("Settings")}/ {t("Roles")}</p> */}
                        </div>
                        <div className="header-btn">
                            <Link to="/admin/incidents/manage_users">
                                <button className="btn-incident">
                                    <img src="/assets/icons/users_add.svg" alt="" /> {t("Add User Roles")}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <section className="common__table pt-0">
                    <AdvancedSearch page="users_listing" users={allUsers} filterData={filterData} setFilterData={setFilterData} clearFilter={clearFilter} applySearch={applySearch} />
                    <div className="search__options mt-4">
                        <div className="search">
                            <Form.Control type="text" placeholder={t("Search")} value={filterData.searchKey} onChange={e => setFilterData({ ...filterData, searchKey: e.target.value })} />
                            <button className="btn btn-search">
                                <img src="/assets/icons/Search1.svg" alt="" onClick={applySearch} />
                            </button>
                        </div>
                        <div className="show">
                            <span>{t("Entries")}</span>
                            <Form.Control type="number" style={{ width: '70px' }} min={1} value={entry} onKeyDown={formatInput} onChange={handleEntry} />
                            <span>{t("Show")}</span>
                        </div>
                    </div>
                    {loading ?
                        <LoadingSpinner /> :
                        <>
                            <Table bordered hover>
                                <thead>
                                    <tr>
                                        {tableHeaders.map(item => (
                                            <th>{item.name}</th>)
                                        )}
                                    </tr>
                                </thead>

                                <tbody>
                                    {poolUsersList?.map((ele, i) => {
                                        return (
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td style={{ whiteSpace: "inherit" }}>
                                                    {/* <Link to="/admin/incidents/incident_details"> */}
                                                    {ele.employee_id}
                                                    {/* </Link> */}
                                                </td>
                                                <td>{`${ele.name}`}</td>
                                                <td>{ele.section.length ? ele.section[0]?.section_name : '-'}</td>
                                                <td>{ele.department && ele.department.length ? ele.department[0]?.department_name : '-'}</td>
                                                <td>{roles[ele.role]}</td>
                                                <td>
                                                    <img src="/assets/icons/user_edit.svg" alt="" style={{ cursor: 'pointer' }} onClick={() => roleEditHandler(ele)} />
                                                    <img src="/assets/icons/user_delete.svg" alt="" style={{ cursor: 'pointer' }} onClick={() => handleConfirmDelete(ele)} />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>

                            {poolUsersList?.length === 0 &&
                                <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                    <h6>{t("No Data")}</h6>
                                </div>
                            }
                        </>
                    }
                </section>
                {!loading && !!(poolUsersList?.length) &&
                    <div className="align-between" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p style={{ direction: "ltr", color: "#0d7b58" }}>{pageStatus}</p>
                        <Pagination
                            page={page}
                            pageCount={pageCount}
                            handlePageChange={handlePageChange}
                        />
                    </div>}
                <ConfirmationDialog
                    show={showConfirmDialog}
                    handleClose={() => setShowConfirmDialog(false)}
                    handleSubmit={() => deletePoolPrivilege()}
                    title={t("Confirm")}
                    description={t("confirm delete")}
                    submitLoading={submitLoading}
                    modalHeader={t('Delete Role')}
                />
            </div>
            <Modal show={editModal} onHide={setEditModal} centered buttonClose >
                <Modal.Body>

                    {userToEdit &&
                        <div className="role__edit__modal mt-4">
                            <p className='modal-head'>{t('Edit Role')}</p>
                            <p className="key">{t('Employee Name')} </p>
                            <p className="value">{userToEdit.firstname + ' ' + userToEdit.lastname}</p>
                            <Select
                                name="roleEdit"
                                options={[
                                    { label: 'Admin', value: 'admin' },
                                    // { label: 'Incident Manager', value: 'incident_manager' },
                                    { label: 'Pool Admin', value: 'pool_admin' },
                                    { label: 'Pool User', value: 'pool_user' },
                                    { label: 'Dashboard Viewer', value: 'dashboard_view' },
                                ].filter(item => {
                                    if (userType === 'incident_manager') {
                                        return (item.value === 'incident_manager' || item.value === 'admin') ? false : true
                                    } else if (userType === 'admin') {
                                        return (item.value === 'admin' || item.value === 'dashboard_view') ? false : true
                                    } else return true
                                })}
                                value={newRole}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                onChange={(e) => setNewRole(e)}
                            />
                            <div className="buttons">
                                <button className="btn btn-confirm" onClick={() => editSubmitHandler()} >{t('Submit')}</button>
                                <button className="btn btn-confirm" onClick={() => setEditModal(false)} >{t('Cancel')}</button>
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default RolesListing;
