import React, { useEffect } from 'react'
import './DynamicTexts.scss'
import Modal from '../../Tools/Modal/Modal'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getDynamicTexts, importDynamicExcel, updateDynamicTexts } from '../../redux/incidentActionCreator'
import useTrans from '../../utils/useTrans'
import { toast } from 'react-toastify'
// import { Modal } from 'react-bootstrap'

export default function DynamicTexts() {

    const dispatch = useDispatch()
    const { t } = useTrans()

    const [isModalShow, setModalShow] = useState(false)
    const [itemToEdit, setItemToEdit] = useState(null)
    const [data, setData] = useState(null)
    const [inputs, setInputs] = useState({ english: '', arabic: '' })
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    function getData() {
        setData(null)
        setLoader(true)
        dispatch(getDynamicTexts((texts) => {
            setLoader(false)
            setData(texts.map((text) => {
                return {
                    id: text._id,
                    key: text.title,
                    arabic: text.arabic_text,
                    english: text.english_text
                }
            }))
        }))
    }

    function EditHandler(item) {
        setModalShow(true)
        setItemToEdit(item)
        setInputs({ arabic: item.arabic, english: item.english })
    }

    function updateHandler() {
        if (!inputs) return
        let obj = {
            id: itemToEdit.id,
            title: itemToEdit.key,
            english_text: inputs.english,
            arabic_text: inputs.arabic
        }
        dispatch(updateDynamicTexts(obj, ({ status, message, data }) => {
            status && toast.success(t(message), {
                position: "bottom-center",
                autoClose: 3000
            })
            !status && toast.error(t(message), {
                position: "bottom-center",
                autoClose: 3000
            })
            getData()
            setModalShow(false)
        }))
    }

    function onChangeHandler(e, name) {
        setInputs(s => ({ ...s, [name]: e.target.value }))
    }

    // console.log(data, 'data')
    return (
        <div id='DynamicTexts'>
            <h1>{t('Dynamic Texts')}</h1>
            <table>
                <thead>
                    <tr>
                        <th width='80px'>{t('Sl No')}</th>
                        <th width='200px'>{t('Key')}</th>
                        <th width='500px'>{t("Arabic")}</th>
                        <th width='500px'>{t("English")}</th>
                        <th width='70px'></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.map((datom, i) => {
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{datom.key}</td>
                                    <td className='arabic'>{datom.arabic}</td>
                                    <td className='english'>{datom.english}</td>
                                    <td><img onClick={() => EditHandler(datom)} className='edit' src="/assets/icons/Edit.svg" alt="" /></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {
                loader &&
                <img className='loader' src="/assets/icons/loader.svg" alt="" />
            }
            <Modal show={isModalShow} setShow={setModalShow} buttonClose>
                <div className='modal__content'>
                    <h5>{t('Dynamic Text')}</h5>
                    <i>"{itemToEdit?.key}"</i>
                    <label htmlFor=""> عربي <input type="text" value={inputs.arabic} onChange={(e) => onChangeHandler(e, 'arabic')} /> </label>
                    <label htmlFor=""> English <input type="text" value={inputs.english} onChange={(e) => onChangeHandler(e, 'english')} /> </label>
                    <div className="buttons">
                        <button className='cancel' onClick={() => setModalShow(false)} >{t('Cancel')}</button>
                        <button className='update' onClick={updateHandler}>{t('Update')}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}


export const ImportDynamicText = () => {
    const dispatch = useDispatch()
    function onChangeHandler(e) {
        if (e.target.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") return
        const formdata = new FormData()
        formdata.append('user_list', e.target.files[0])
        dispatch(importDynamicExcel(formdata))
    }
    return (
        <div className='d-flex gap-4 my-4'>
            <label className='btn bg-primary px-4 py-2 text-light' htmlFor='input-file'>
                <input type="file" name="" id="input-file" hidden onClick={(e) => e.target.value = null} onChange={onChangeHandler} />
                Import
            </label>
            {/* <button className='bg-primary px-4 py-2 text-light'>Export</button> */}
        </div>
    )
}