import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FooterButtons from "../../../components/FooterButtons/FooterButtons";
import PersonalInformation from "../../../components/PersonalInformation/PersonalInformation";
import RequestDetailsForm from "../../../components/RequestDetailsForm/RequestDetailsForm";
import SelectEmployee from "../../../components/SelectEmployee/SelectEmployee";
import TitleAndPath from "../../../components/TitleAndPath/TitleAndPath";
import { addAdminRequest, getRequestStatus, getSection } from "../../../redux/hrActionCreator";
import { user_list } from "../../../redux/commonReducer";
import Modals from "../../../components/Modals/Modals";

export default function AddRequest_admin() {
  const dispatch = useDispatch();
  const userList = useSelector(user_list);

  const [department, setDepartment] = useState("");
  const [section, setSection] = useState("");
  const [user, setUser] = useState("");
  const [data, setData] = useState({
    address: "",
    comment: "",
    personalData: {},
  });
  const [certificate, setCerificate] = useState("");
  const [language, setLanguage] = useState("");
  const [certificateStatus, setCertificateStatus] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [err, setErr] = useState({
    // department: "err",
    // section: "err",
    // user: "err",
    // certificate: "err",
    // language: "err",
    // address: "err",
    // comment: "err",
    // certificateStatus: "err",
  });
  const [input, setInput] = useState({
    file: "",
    finalComment: "",
    forwardUser: "",
    returnComment: "",
    rejectionComment: "",
    closeComment: "",
  });
  const [modalErr, setModalErr] = useState({});

  useEffect(() => {
    dispatch(getRequestStatus());
  }, []);

  useEffect(() => {
    if (Object.keys(user)?.length) {
      onChangeHandler(
        "personalData",
        userList?.filter((ele) => ele._id == user?.value)
      );
    }
  }, [user]);

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const validate = () => {
    let temp = {};
    if (data.address?.trim()?.length == 0) {
      temp = { ...temp, address: "Please Add Address" };
    }
    if (data.comment?.trim()?.length == 0) {
      temp = { ...temp, comment: "Please Add Comment" };
    }
    if (department?.trim().length == 0) {
      temp = { ...temp, department: "Please Select Department" };
    }
    if (section?.trim().length == 0) {
      temp = { ...temp, section: "Please Select Section" };
    }
    if (user?.trim().length == 0) {
      temp = { ...temp, user: "Please Select User" };
    }
    if (certificate?.trim().length == 0) {
      temp = { ...temp, certificate: "Please Select Certificate" };
    }
    if (language?.trim().length == 0) {
      temp = { ...temp, language: "Please Select Language" };
    }
    if (certificateStatus?.length == 0) {
      temp = { ...temp, certificateStatus: "Please Select CertificateStatus" };
    }
    if (Object.values(temp).length != 0) {
      console.log(err);
      setErr(temp);
      return false;
    } else {
      return true;
    }
  };

  const onSaveHandler = () => {
    // if (validate()) {
    if (certificateStatus && certificateStatus.action !== "Processing") {
      setModalShow(true);
    } else {
      onAddRequestHandler();
    }
    // }
  };

  const onAddRequestHandler = () => {
    let formData = new FormData();
    formData.append("user_id", user);
    formData.append("request_type", certificate);
    formData.append("certificate_langage", language);
    formData.append("certificate_addressee", data.address);
    formData.append("request_comment", data.comment);
    formData.append("status", certificateStatus._id);
    if (certificateStatus.action == "Approve") {
      formData.append("forwarded_to", "");
      formData.append("comment", input.finalComment);
      formData.append("attachment", input.file);
    } else if (certificateStatus.action == "Forward") {
      formData.append("forwarded_to", input.forwardUser);
      formData.append("comment", "");
      formData.append("attachment", "");
    } else if (certificateStatus.action == "Return") {
      formData.append("forwarded_to", "");
      formData.append("comment", input.returnComment);
      formData.append("attachment", "");
    } else if (certificateStatus.action == "Reject") {
      formData.append("forwarded_to", "");
      formData.append("comment", input.rejectionComment);
      formData.append("attachment", "");
    } else if (certificateStatus.action == "Close") {
      formData.append("forwarded_to", "");
      formData.append("comment", input.closeComment);
      formData.append("attachment", "");
    } else {
      formData.append("forwarded_to", "");
      formData.append("comment", "");
      formData.append("attachment", "");
    }
    console.log(formData)
    dispatch(addAdminRequest(formData, () => {

    }))
  };

  return (
    (
      <div id="AddRequest_admin">
        <section className="heading">
          <TitleAndPath
            title={"Add Request"}
            path={"Home / HR / Certificate Requests"}
          />
        </section>
        <section>
          <SelectEmployee
            department={department}
            setDepartment={setDepartment}
            section={section}
            setSection={setSection}
            user={user}
            setUser={setUser}
            err={err}
          />
        </section>
        <section>
          <PersonalInformation personalData={data.personalData} />
        </section>
        <section>
          <RequestDetailsForm
            data={data}
            onChangeHandler={onChangeHandler}
            certificate={certificate}
            setCerificate={setCerificate}
            language={language}
            setLanguage={setLanguage}
            err={err}
          />
        </section>
        <section className="buttons">
          <FooterButtons
            backPath={"/admin/human_resource/certificate_requests"}
            setCertificateStatus={setCertificateStatus}
            certificateStatus={certificateStatus}
            err={err}
            setModalShow={setModalShow}
            onSaveHandler={onSaveHandler}
          />
        </section>
        <Modals
          show={modalShow}
          setShow={setModalShow}
          type={certificateStatus.action}
          onAddRequestHandler={onAddRequestHandler}
          input={input}
          setInput={setInput}
          modalErr={modalErr}
          setModalErr={setModalErr}
        />
      </div>
    )
  );
}
