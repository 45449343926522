import React, { useState } from 'react'
import Modals from '../Modals/Modals'
import './Table2.scss'

export default function Table2({ currentPage, data }) {

  const [modalShow, setModalShow] = useState(false)
  const [commentCollapse, setCommentCollapse] = useState({ index: null })

  const page =
    currentPage === 'RequestDetails_admin' ? 1 :
      currentPage === 'RequestDetails' ? 2 :
        0


  return (
    <div id='Table2'>
      {
        page === 1 ?
          <div className="title">
            <h2>History</h2>
          </div> :
          page === 2 ? <p>Update</p> :
            null
      }
      <table>
        <thead className={(page === 1 ? ' history' : '')}>
          <tr>
            <th>Sl No</th>
            <th>Received Date</th>
            <th>Last Update</th>
            <th>Handled by</th>
            <th>Assigned to</th>
            <th>Attachments</th>
            <th>Status</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((datom, i) => {
              return (
                <tr key={i}>
                  <td>{datom.sl_no}</td>
                  <td>{datom.received_date}</td>
                  <td>{datom.last_update}</td>
                  <td>{datom.handled_by}</td>
                  <td>{datom.assigned_to}</td>
                  <td className='attachments'>{
                    datom.attachments ?
                      <> <i onClick={() => setModalShow(true)}><img src="/assets/icons/Eye-open.svg" alt="" /></i><p>2</p></>
                      : <i><img src="/assets/icons/Eye-close.svg" alt="" /></i>
                  }</td>
                  <td><button className={"style-3" + (' ' + datom.status)}>{datom.status}</button></td>
                  {/* {console.log(datom.comments.length)} */}
                  <td className='comments'>
                    {datom.comments.slice(0, commentCollapse.index === i ? -1 : 46)}
                    {datom.comments.length >= 47 &&
                      <i className={'read-more' + (commentCollapse.index === i ? ' up' : '')} onClick={() => setCommentCollapse((s) => ({ index: s.index === i ? null : i }))}>
                        <img src="/assets/icons/Icon ionic-ios-arrow-down tan.svg" alt="" />
                      </i>
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <Modals setShow={setModalShow} show={modalShow} type='ViewAttachment' />
    </div >
  )
}
