import React from "react";
import Select from "react-select";
import moment from "moment";
import PermissionsGate from "../../../utils/PermissionsGate";
import {
  ADMIN,
  INCIDENT_MANAGER,
  POOL_ADMIN,
  POOL_USER,
  SUPER_ADMIN,
} from "../../../constants/configuration";
import { OverlayTrigger } from "react-bootstrap";
import AddAdditionalDocument from "./AddAdditionalDocument";
import useTrans from "../../../utils/useTrans";
import useGetRole from "../../../utils/useGetRole";
import { roles } from "aria-query";
import { useParams } from "react-router-dom";

const IncidentPersonalSection = ({
  incident_data,
  addProof,
  proof,
  attachmentList,
  onAdditionalChangeHandler,
  setAddErr,
  addErr,
  addObj,
  onAddHandler,
  additionalData,
  oldAttachments,
  fileViewHandler,
  onDeleteAddHandler,
  oldFileViewHandler,
  onDeleteOldHandler,
  statusNaming,
  editPermission,
  onEditOldHandler,
  onEditAddHandler,
  renderTooltip,
  editNew,
  onEditNewHandler,
  editOld,
  onEditOldAdditional,
  setAddObj,
  onClearHandler,
  onReloadHandler,
}) => {
  const { t } = useTrans();
  const lang = localStorage.getItem("lang");
  const { id, displaytype, taskno } = useParams();
  const { role } = useGetRole();

  return (
    <>
      <div className="card-header">
        <div className="row w-100">
          <div className="col-md-2">
            <h6>{t("Incident No")}</h6>
          </div>
          <div className="col-md-2">
            <h6>{t("Reporter Name")}</h6>
          </div>
          <div className="col-md-2">
            <h6>{t("Created Date")}</h6>
          </div>
          <div className="col-md-2">
            <h6>{t("Email")}</h6>
          </div>
          <div className="col-md-2">
            <h6>{t("Phone")}</h6>
          </div>
          {/* <div className="col-md-2">
            <h6>{t("Current Status")}</h6>
          </div> */}
        </div>
      </div>
      <div className=" row w-100 p-0-25">
        <div className="col-md-2">
          <div className="personal__details" style={{ display: 'flex', flexDirection: 'column' }}>
            {incident_data?.web_incident_no ? (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip({
                  label: `Web Incident Number - ${incident_data?.web_incident_no}`,
                })}
              >
                <h4 className='pointer'>{incident_data?.incident_no}</h4>
              </OverlayTrigger>
            ) : (
              <h4>{incident_data?.incident_no}</h4>
            )}
          </div>
        </div>
        <div className="col-md-2">
          <div className="personal__details">
            <div className="d-flex align-items-start">
              <h4 className="m-0">
                {incident_data?.first_name} {incident_data?.last_name}
              </h4>
              {[
                SUPER_ADMIN,
                ADMIN,
                INCIDENT_MANAGER,
                POOL_ADMIN,
                POOL_USER,
              ].includes(role) &&
                incident_data?.is_task_assigned === 0 && (
                  <PermissionsGate
                    PermissionsGate
                    permittedUsers={[
                      SUPER_ADMIN,
                      ADMIN,
                      INCIDENT_MANAGER,
                      POOL_ADMIN,
                      POOL_USER,
                    ]}
                  >
                    {editPermission ? (
                      <div className="add pointer mr-2" style={{ position: 'relative', top: '-4px' }} onClick={addProof}>
                        +
                      </div>
                    ) : null}
                  </PermissionsGate>
                )}
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="personal__details">
            <h4>
              <span dir="ltr">
                {incident_data?.created_date
                  ? moment.utc(incident_data?.created_date).format("DD MMM YYYY")
                  : ""}
              </span>
            </h4>
          </div>
        </div>
        <div className="col-md-2">
          <div className="personal__details">
            <h4>{incident_data?.email ? incident_data?.email : <span className="space">-</span>}</h4>
          </div>
        </div>
        <div className="col-md-2">
          <div className="personal__details">
            <h4>{incident_data?.phone ? incident_data?.phone + "+" : <span className="space">-</span>}</h4>
          </div>
        </div>
        <div className="col-md-2">
          <div className="personal__details" style={{justifyContent: 'space-between', alignItems: 'center'}}>
            <button
              style={{ cursor: "auto",  }}
              className={`btn btn-sm 
                                    btn-${incident_data?.incident_status
                  ?.handle_key == "open"
                  ? displaytype === "subtask"
                    ? "subtask-open"
                    : displaytype === "task"
                      ? "task-open"
                      : "open"
                  : incident_data?.incident_status
                    ?.handle_key == "return"
                    ? "returned"
                    : incident_data?.incident_status
                      ?.handle_key == "forward"
                      ? "forward"
                      : incident_data?.incident_status
                        ?.handle_key == "pending"
                        ? "pending"
                        : incident_data?.incident_status
                          ?.handle_key == "processing"
                          ? "processing"
                          : incident_data?.incident_status
                            ?.handle_key == "assign"
                            ? "assign"
                            : incident_data?.incident_status
                              ?.handle_key == "close"
                              ? "closed"
                              : incident_data?.incident_status
                                ?.handle_key == "reopen"
                                ? "reopen"
                                : incident_data?.incident_status
                                  ?.handle_key == "close_task"
                                  ? displaytype === "subtask"
                                    ? "subtask_closed"
                                    : "close_task"
                                  : incident_data?.incident_status
                                    ?.handle_key == "reopen_task"
                                    ? "reopen_task"
                                    : incident_data?.incident_status
                                      ?.handle_key == "save_draft"
                                      ? "draft"
                                      : ""
                }`}
            >
              {/* {statusNaming(incident_data?.incident_status?.['incident_status_' + lang])} */}
              {incident_data?.incident_status &&
                statusNaming(incident_data?.incident_status)}
            </button>
            {(incident_data?.incident_status?.handle_key == "return" && incident_data?.resend_return_link) ? (
              <>
                <img
                  style={{cursor: 'pointer', }}
                  src="/assets/icons/reload.svg"
                  alt=""
                  onClick={() => onReloadHandler()}
                />
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip({
                    label: "Please click on the refresh icon to resend the link",
                  })}
                >
                  <img src="/assets/icons/info_return.svg" alt="" />
                </OverlayTrigger>
              </>
            ) : null}
          </div>
        </div>
        {proof && (
          <AddAdditionalDocument
            addObj={addObj}
            onAdditionalChangeHandler={onAdditionalChangeHandler}
            setAddErr={setAddErr}
            addErr={addErr}
            renderTooltip={renderTooltip}
            setAddObj={setAddObj}
            editNew={editNew}
            onEditNewHandler={onEditNewHandler}
            editOld={editOld}
            onEditOldAdditional={onEditOldAdditional}
            onAddHandler={onAddHandler}
            additionalData={additionalData}
            oldAttachments={oldAttachments}
            fileViewHandler={fileViewHandler}
            onDeleteAddHandler={onDeleteAddHandler}
            onEditAddHandler={onEditAddHandler}
            oldFileViewHandler={oldFileViewHandler}
            onDeleteOldHandler={onDeleteOldHandler}
            onEditOldHandler={onEditOldHandler}
            onClearHandler={onClearHandler}
          />
        )}
      </div>
    </>
  );
};
export default IncidentPersonalSection;
