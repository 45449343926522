import React, { useState } from 'react'
import CommentsWithAttachments from '../../../components/CommentsWithAttachments/CommentsWithAttachments'
import FooterButtons from '../../../components/FooterButtons/FooterButtons'
import Modals from '../../../components/Modals/Modals'
import PersonalInformation from '../../../components/PersonalInformation/PersonalInformation'
import RequestDetailsData from '../../../components/RequestDetailsData/RequestDetailsData'
import StatusHeaderButton from '../../../components/StatusHeaderButton/StatusHeaderButton'
import TitleAndPath from '../../../components/TitleAndPath/TitleAndPath'
import Table2 from '../../../components/Table2/Table2'

export default function RequestDetails_admin() {

    const [modalShow, setModalShow] = useState(false)
    const [certificateStatus, setCertificateStatus] = useState('')

    const data = [
        {
            sl_no: '01',
            received_date: 'Tue Mar 28 2023	',
            last_update: 'Tue Mar 28 2023	',
            handled_by: 'Mona',
            assigned_to: '',
            attachments: false,
            status: 'Need Info',
            comments: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facere inventore vero quos quis odit, consectetur accusantium cumque. Quia, dicta. Itaque commodi facilis voluptas natus quos debitis deserunt doloremque. Hic quis voluptates suscipit, unde ducimus aspernatur tenetur illo repellat deleniti, est earum odit molestiae explicabo sequi rem. Omnis exercitationem eveniet animi?'
        },
        {
            sl_no: '02',
            received_date: 'Tue Mar 28 2023	',
            last_update: 'Tue Mar 28 2023	',
            handled_by: 'Munna',
            assigned_to: '',
            attachments: true,
            status: 'Processing',
            comments: 'Please mention why you '
        },
        {
            sl_no: '03',
            received_date: 'Tue Mar 28 2023	',
            last_update: 'Tue Mar 28 2023	',
            handled_by: 'Mona',
            assigned_to: '',
            attachments: true,
            status: 'Approved',
            comments: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae, iste? Maxime ad, cumque vitae est officia, suscipit at voluptates dolor veritatis explicabo voluptatem. Hic tempore libero, tenetur perferendis velit pariatur adipisci iusto vero quae incidunt, in dolore laborum error modi nam repellat officiis enim dolorum rerum est earum id voluptas eaque! Debitis, quas eos? Corporis voluptatibus mollitia cum placeat, saepe, commodi quasi alias eos dicta molestiae pariatur suscipit possimus accusantium beatae aperiam illo iusto dolorem eius minima sed natus voluptatum. Illo sit ipsum enim officia ad hic quibusdam odio. Labore dolor est expedita reiciendis veniam hic modi sequi quidem quibusdam?'
        },
        {
            sl_no: '04',
            received_date: 'Tue Mar 28 2023	',
            last_update: 'Tue Mar 28 2023	',
            handled_by: 'Mona',
            assigned_to: '',
            attachments: false,
            status: 'Closed',
            comments: 'Please mention why you need salary'
        },
    ]

    return (
        <div id='RequestDetails_admin'>
            <section className='heading'>
                <TitleAndPath title={'Request Details'} path={'Home / HR / Certificate Requests'} />
                <StatusHeaderButton />
            </section>
            <section className="details">
                <PersonalInformation />
            </section>
            <section>
                <RequestDetailsData />
            </section>
            <section>
                <Table2 currentPage={'RequestDetails_admin'} data={data} />
            </section>
            <section>
                <CommentsWithAttachments />
            </section>
            <section className='buttons'>
                <FooterButtons
                    backPath={'/admin/human_resource/certificate_requests'}
                    setModalShow={setModalShow}
                    setCertificateStatus={setCertificateStatus}
                    certificateStatus={certificateStatus} />
            </section>
            <Modals show={modalShow} setShow={setModalShow} type={certificateStatus} />
        </div>
    )
}