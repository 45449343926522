import React, { useEffect } from 'react'
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import './ResponseModal.css'
import { useDispatch, useSelector } from 'react-redux';
import { response_modal_data, updateIncidentRedux } from '../../redux/incidentReducer';
import useTrans from '../../utils/useTrans';

const ResponseModal = ({ }) => {
    const dispatch = useDispatch()
    const responseData = useSelector(response_modal_data)
    const { t } = useTrans()


    const clearResponseData = () => {
        dispatch(updateIncidentRedux({
            key: 'responseModal', value: {
                show: false,
                message: '',
                status: ''
            }
        }))
        dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
    }

    useEffect(() => {
        if (responseData.show) {
            setTimeout(() => clearResponseData(), 3000)
        }
    }, [responseData.show])


    return (
        <Modal show={responseData.show} onHide={clearResponseData} centered >
            <Modal.Body>
                <div className="actions__modals response-modal-container">
                    {responseData.show && <img src={`/assets/img/${responseData.status ? 'icons8-tick-mark' : 'icons8-close'}.svg`} className='response-modal-image' />}
                    <h2 className="mb-3" style={{ color: "#474747" }}>
                        {t(responseData.message)}
                    </h2>

                    {/* <div className="footer__btns" style={{ display: 'flex', justifyContent: 'center' }}>
                        <button
                            className="btn btn-confirm"
                        >
                            {'ok'}
                        </button>

                    </div> */}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ResponseModal