import { configureStore } from '@reduxjs/toolkit';
import hrReducer from './redux/hrReducer';
import incidentReducer from './redux/incidentReducer';
import commonReducer from './redux/commonReducer';

export default configureStore({
  reducer: {
    commonReducer : commonReducer,
    hrReducer: hrReducer,
    incidentReducer : incidentReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
});