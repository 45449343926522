import React from 'react'
import AddRequestButton from '../../../components/AddRequestButton/AddRequestButton'
import PaginationSearch from '../../../components/PaginationSearch/PaginationSearch'
import Search from '../../../components/Search/Search'
import Table1 from '../../../components/Table1/Table1'
import TitleAndPath from '../../../components/TitleAndPath/TitleAndPath'

export default function HumanResource_admin() {

    const titles = [
        { title: 'Request No', key: 'request_no', sort: true, width: '90px' },
        { title: 'Employee', key: 'employee', sort: true, width: '80px' },
        { title: 'Department', key: 'department', sort: true, width: '100px' },
        { title: 'Request Type', key: 'request_type', sort: true, width: '100px' },
        { title: 'Requested Date', key: 'requested_date', sort: true, width: '110px' },
        { title: 'Last Update', key: 'last_update', sort: true, width: '90px' },
        { title: 'Handled By', key: 'handled_by', sort: true, width: '90px' },
        { title: 'Status', key: 'status', width: '0px' },
        { title: 'Action', key: 'action', width: '85px' }
    ]

    const table = [
        {
            request_no: 'HR23010',
            employee: 'John Doe',
            department: 'Institutional Support',
            request_type: 'Salary Certificate',
            requested_date: '10 Feb 2023',
            last_update: '13 Feb 2023',
            handled_by: 'Mona',
            status: 'Processing'
        },
        {
            request_no: 'HR23009',
            employee: 'John Doe',
            department: 'Institutional Support',
            request_type: 'Salary Certificate',
            requested_date: '10 Feb 2023',
            last_update: '13 Feb 2023',
            handled_by: 'Mona',
            status: 'Need Info'
        },
        {
            request_no: 'HR23008',
            employee: 'John Doe',
            department: 'Institutional Support',
            request_type: 'Salary Certificate',
            requested_date: '10 Feb 2023',
            last_update: '13 Feb 2023',
            handled_by: 'Mona',
            status: 'Forwarded'
        },
        {
            request_no: 'HR23007',
            employee: 'John Doe',
            department: 'Institutional Support',
            request_type: 'Salary Certificate',
            requested_date: '10 Feb 2023',
            last_update: '13 Feb 2023',
            handled_by: 'Mona',
            status: 'Approved'
        },
        {
            request_no: 'HR23006',
            employee: 'John Doe',
            department: 'Institutional Support',
            request_type: 'Salary Certificate',
            requested_date: '10 Feb 2023',
            last_update: '13 Feb 2023',
            handled_by: 'Mona',
            status: 'Open'
        },
        {
            request_no: 'HR23005',
            employee: 'John Doe',
            department: 'Institutional Support',
            request_type: 'Salary Certificate',
            requested_date: '10 Feb 2023',
            last_update: '13 Feb 2023',
            handled_by: 'Mona',
            status: 'Processing'
        },
        {
            request_no: 'HR23004',
            employee: 'John Doe',
            department: 'Institutional Supporting',
            request_type: 'Salary Certificate',
            requested_date: '10 Feb 2023',
            last_update: '13 Feb 2023',
            handled_by: 'Mona',
            status: 'Rejected'
        },
        {
            request_no: 'HR23003',
            employee: 'John Doe',
            department: 'Institutional Support',
            request_type: 'Salary Certificate',
            requested_date: '10 Feb 2023',
            last_update: '13 Feb 2023',
            handled_by: 'Mona',
            status: 'Closed'
        },
        {
            request_no: 'HR23002',
            employee: 'John Doe',
            department: 'Institutional Support',
            request_type: 'Salary Certificate',
            requested_date: '10 Feb 2023',
            last_update: '13 Feb 2023',
            handled_by: 'Mona',
            status: 'Processing'
        },
    ]
    return (
        <div id='HumanResource_admin'>
            <section className='heading'>
                <TitleAndPath title={'Human Resource'} path={'Home / HR / Certificate Requests'} />
                <AddRequestButton />
            </section>
            <section className='search'>
                <Search currentPage={'HumanResource_admin'} />
            </section>
            <section className='pagination'>
                <PaginationSearch />
            </section>
            <section className='table'>
                <Table1 currentPage={'HumanResource_admin'} titles={titles} data={table} />
            </section>
        </div>
    )
}
