import React, { useContext } from 'react'
import store from '../store'
import { useEffect } from 'react'
import { useState } from 'react'
import { LangSwitch } from '../App'


export default function useTrans() {

    const { currentLanguage } = useContext(LangSwitch)
    // const [key, setKey] = useState('english_text')

    const translateDetails = store.getState().incidentReducer.dynamic_text_list

    // useEffect(() => {
    //     if (currentLanguage === 'ar') setKey('arabic_text')
    //     else setKey('english_text')
    // }, [currentLanguage])

    function keyGetter() {
        if (currentLanguage === 'ar') return 'arabic_text'
        else return 'english_text'
    }


    function t(value) {
        let key = keyGetter()
        if (translateDetails && key) {
            return (
                translateDetails?.filter((ele) => ele.title == value)?.length
                    ? translateDetails?.find((ele) => ele.title == value)[key]
                    : value
            )
        } else {
            return value
        }
    }


    return { t, lang: currentLanguage }
}