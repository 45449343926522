import React from 'react'
import './AttachmentContent.scss'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";

export default function AttachmentContent({ Files = [{ type: '', name: '', imageUrl: '', file: null }], BaseUrl, CloseButton = false, onClose }) {

  const [files, setFiles] = useState([])

  useEffect(() => {
    setFiles(Files)
    return () => setFiles([])
  }, [Files])

  function fileCloseHandler(fileToDelete) {
    let result = files.filter(file => file !== fileToDelete)
    onClose && onClose(result.map(item => item.file))
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.label}
    </Tooltip>
  );

  return (
    <div id='AttachmentContent'>
      {files.map((file, i) => {
        return (
          <span key={i} className={(['jpeg', 'jpg', 'png', 'webp'].includes(file.type) && file.imageUrl) ? 'image' : ''}>
            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip({ label: file.name, })} >
              <Link to={BaseUrl + file.file} target='_blank'>
                {
                  file.type === 'pdf' ?
                    <img className='file' src="/assets/icons/files/pdf.svg" alt="" />
                    : file.type === 'jpeg' ?
                      <img className='file' src={file.imageUrl ? file.imageUrl : "/assets/icons/attachment-img.png"} alt="" />
                      : file.type === 'jpg' ?
                        <img className='file' src={file.imageUrl ? file.imageUrl : "/assets/icons/attachment-img.png"} alt="" />
                        : file.type === 'webp' ?
                          <img className='file' src={file.imageUrl ? file.imageUrl : "/assets/icons/attachment-img.png"} alt="" />
                          : file.type === 'png' ?
                            <img className='file' src={file.imageUrl ? file.imageUrl : "/assets/icons/attachment-img.png"} alt="" />
                            : file.type === 'doc' ?
                              <img className='file' src="/assets/icons/files/doc.svg" alt="" />
                              : file.type === 'docx' ?
                                <img className='file' src="/assets/icons/files/docx.svg" alt="" />
                                : file.type === 'mp4' ?
                                  <img className='file' src="/assets/icons/files/mp4.svg" alt="" />
                                  : null
                }
              </Link>
            </OverlayTrigger>
            {CloseButton && <img onClick={() => fileCloseHandler(file)} className='close' src="/assets/icons/Group 11563.svg" alt="" />}
          </span>
        )
      })}
    </div >
  )
}