import { createSlice } from '@reduxjs/toolkit';

export const incidentSlice = createSlice({
    name : 'incidentReducer',
    initialState : {
        value: true,
        responseModal: {
            show: false,
            message: '',
            status: '',
            incident_details : {}
        }
    },
    reducers : {
        updateIncidentRedux: (state , data) => {
            state[data.payload.key ] = data.payload.value
        },
    },
})
export const { updateIncidentRedux } = incidentSlice.actions;

export const value = (state ) => state.incidentReducer.value;
export const incident_list = (state ) => state.incidentReducer.incident_list;
export const incident_details_loading = (state ) => state.incidentReducer.incident_details_loading;
export const incident_list_count = (state ) => state.incidentReducer.incident_list_count;
export const incident_list_page_count = (state ) => state.incidentReducer.incident_list_page_count;
export const incident_list_page_status = (state ) => state.incidentReducer.incident_list_page_status;
export const incident_type_details = (state ) => state.incidentReducer.incident_type_details;
// export const value = (state ) => state.incidentReducer.value;
export const poolUser_list = (state ) => state.incidentReducer.poolUser_list;
export const poolAdmin_list = (state ) => state.incidentReducer.poolAdmin_list;
export const add_user_privilege_loading = (state ) => state.incidentReducer.add_user_privilege_loading;
export const incidentManager_list = (state ) => state.incidentReducer.incidentManager_list;
export const incident_attachment_list = (state ) => state.incidentReducer.incident_attachment_list;
export const incident_status_details = (state ) => state.incidentReducer.incident_status_details;
export const incident_source_details = (state ) => state.incidentReducer.incident_source_details;
export const incident_details = (state ) => state.incidentReducer.incident_details;
export const incident_path = (state ) => state.incidentReducer.incident_path;
export const incident_list_loading = (state ) => state.incidentReducer.incident_list_loading;
export const incident_status_change_loading = (state ) => state.incidentReducer.incident_status_change_loading;
export const incident_task_status_change_loading = (state ) => state.incidentReducer.incident_task_status_change_loading;
export const incident_status_change_success = (state ) => state.incidentReducer.incident_status_change_success;
export const incident_add_change_loading = (state ) => state.incidentReducer.incident_add_change_loading;
export const searchuser_list = (state ) => state.incidentReducer.searchuser_list;
export const response_modal_data = (state ) => state.incidentReducer.responseModal;
export const dynamic_text_list = (state ) => state.incidentReducer.dynamic_text_list;
export const poolUser_list_loading = (state ) => state.incidentReducer.poolUser_list_loading;
export const sms_report = (state ) => state.incidentReducer.sms_report;
export const sms_report_page_count = (state ) => state.incidentReducer.sms_report_page_count;
export const sms_report_page_status = (state ) => state.incidentReducer.sms_report_page_status;
export const sms_list_loading = (state ) => state.incidentReducer.sms_list_loading;
export const upload_zip_loading = (state ) => state.incidentReducer.upload_zip_loading;
export const incident_list_full_data = (state ) => state.incidentReducer.incident_list_full_data;
export const incident_type_details_for_filter = (state ) => state.incidentReducer.incident_type_details_for_filter;

export default incidentSlice.reducer;