import React, { useState } from 'react'
import TitleAndPath from '../../../components/TitleAndPath/TitleAndPath'
import ReactDatePicker from 'react-datepicker';
import Select from 'react-select';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import RequestDetails from './RequestDetails';
import { Link } from 'react-router-dom';

function EduAddRequest() {
  const [show, setShow] = useState(false)
  const [startDate, setStartDate] = useState(new Date());
  const addChild = () => {
    setShow(!show)
  }

  const options = [
    { value: '1', label: 'Sep 2023-June 2024' },
    { value: '2', label: 'Sep 2023-June 2024' },
    { value: '3', label: 'Sep 2023-June 2024' },
    { value: '4', label: 'Sep 2023-June 2024' },
    { value: '5', label: 'Sep 2023-June 2024' },
  ];
  const students = [
    { value: '1', label: 'Jane Doe' },
    { value: '2', label: 'Jake Doe' },
    { value: '3', label: 'Jhon Doe' },
  ];
  return (
    <section className='education__allowance__addRequest'>
      <div className="container-fluid">
        <TitleAndPath title={'Add Request'} path={'Home / HR / Add Request'} />
        <div className="request__details">
          <h3>Request Details</h3>
          <button className='btn btn-primary' onClick={addChild}>add child +</button>
        </div>
        {
          show &&
          <div className="add__details">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label className="form-label">Child Name*</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="mb-3">
                      <label className="form-label rtl">Date of Birth*</label>
                      <ReactDatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="mb-3">
                      <label className="form-label">Gender</label>
                      <select className="form-select">
                        <option selected>select</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                        <option value="3">Other</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="d-flex align-items-end justify-content-between">
                      <div className="mb-3">
                        <label className="form-label">Emirates ID*</label>
                        <input type="text" className="form-control w-220" />
                      </div>
                      <div className="mb-2 mr-3">
                        <div className="upload-btn-wrapper">
                          <button className="btn btn-upload"><img src="/assets/icons/icon-upload-blue.svg" alt="" /> Upload</button>
                          <input type="file" name="myfile" onClick={(e) => e.target.value = null} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex align-items-end justify-content-between">
                      <div className="mb-3">
                        <label className="form-label">Passport No*</label>
                        <input type="text" className="form-control w-220" />
                      </div>
                      <div className="mb-2" style={{ marginRight: "15px" }}>
                        <div className="upload-btn-wrapper">
                          <button className="btn btn-upload"><img src="/assets/icons/icon-upload-blue.svg" alt="" /> Upload</button>
                          <input type="file" name="myfile" onClick={(e) => e.target.value = null} />
                        </div>
                      </div>
                      <div className="mb-3" style={{ marginRight: "15px" }}>
                        <button className='btn btn-primary w-100'>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        }

        <div className="row">
          <div className="academic__section">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label className="form-label">Academic Year</label>
                      <Select
                        name="colors"
                        options={options}
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder='Sep 2023-June 2024'
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <OverlayTrigger
                        key='left'
                        className='tooltip-content'
                        placement='left'
                        overlay={
                          <Tooltip id={`tooltip-left`} className='tooltip-content'>
                            A letter from the wife/husband's employer stating that no tuition fees will be paid to him.
                          </Tooltip>
                        }
                      >
                        <Button className='tolltip'>Letter From Parent</Button>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <div className="upload-btn-wrapper">
                        <button className="btn btn-upload"><img src="/assets/icons/icon-upload-blue.svg" alt="" /> Upload</button>
                        <input type="file" name="myfile" onClick={(e) => e.target.value = null} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-4">
                    <div className="d-flex align-items-end justify-content-between">
                      <div className="mb-3 w-280">
                        <label className="form-label">Select Children</label>
                        <Select
                          name="colors"
                          options={students}
                          className="basic-single"
                          classNamePrefix="select"
                          placeholder='Jane Doe'
                        />
                      </div>
                      <button className='btn btn-add mb-3'>+</button>
                    </div>
                  </div>
                  <RequestDetails />
                </div>

                <div className="row">
                  <div className="mb-5 mt-4">
                    <label className="form-label">Comment</label>
                    <textarea class="form-control" placeholder='Comment here' rows="3"></textarea>
                  </div>
                  <div class="form-check">

                    <label class="form-check-label" for="defaultCheck1">
                      I am aware that when a husband and wife meet to work in one of the federal government departments / local bodies, the two are not entitled to enjoy together the tuition fee allowance. In this context, I declare that my wife/husband does not receive tuition fees from the employer.
                    </label>
                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="footer__btns">
                    <div className="btn">
                      <Link to='/human_resource/educational_allowance'>
                        <button className='btn btn-outline-primary'> <img src="/assets/icons/Back.svg" alt="" /> Back</button>
                      </Link>

                    </div>
                    <div className="btn-actions">
                      <button className='btn btn-outline-primary'>Submit</button>
                      <button className='btn btn-outline-primary'>Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default EduAddRequest