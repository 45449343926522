import moment from 'moment'
import React from 'react'

export const DateFormatter = (date) => {
    return (
        <span dir="ltr">
            {moment(date).format('DD MMM YYYY')}<br />
            {moment(date).format('hh:mm a')}
        </span >
    )
}
