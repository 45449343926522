import React, { useEffect, useState } from 'react'
import './Layout.scss'
import { Outlet } from 'react-router-dom'
import Header from '../Header/Header'
import NavBarHeader from '../NavBarHeader/NavBarHeader'
import NavItemsListing from '../NavItemsListing/NavItemsListing'
import { logout_loader } from '../../redux/commonReducer'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { getDynamicTexts } from '../../redux/incidentActionCreator'

export default function Layout() {

    const [navToggle, setNavToggle] = useState(true)
    const [selected, setSelected] = useState({ item: 4, subItem: 0 })

    const loading = useSelector(logout_loader);

    const dispatch = useDispatch()

    useEffect(() => {
        window.addEventListener('load', (e) => e.currentTarget.innerWidth <= 1200 ? setNavToggle(false) : setNavToggle(true))
        window.addEventListener('resize', (e) => e.target.innerWidth <= 1200 ? setNavToggle(false) : setNavToggle(true))
    })

    useEffect(() => {
        dispatch(getDynamicTexts())
    }, [])


    return (
        <div id='Layout'>
            <main className={'main' + (navToggle ? ' show' : ' hide')}>
                <Header setNavToggle={setNavToggle} />
                {
                    loading ? <LoadingSpinner height="600px" /> :
                        <div className='outlet' dir='rtl'>
                            <Outlet />
                        </div>
                }
            </main>
            <aside className={'navigation' + (navToggle ? ' show' : ' hide')} dir='rtl'>
                <section className='nav__header'>
                    <NavBarHeader setSelected={setSelected} />
                </section>
                <section className='nav__body'>
                    <NavItemsListing selected={selected} setSelected={setSelected} />
                </section>
            </aside >
        </div >
    )
}
