import axios from 'axios';
import { toast } from 'react-toastify';
import { API_BASE_URL, RETURN } from "../constants/configuration";
import axiosInstance from "../utils/axiosInterceptor";
import axiosFormInstance from '../utils/axiosFormInterceptor';
import { updateIncidentRedux } from './incidentReducer';
import { updateRedux } from './commonReducer';

export const getIncidentList = (data, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_list_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/get-incidents`, data).then((res) => {
    dispatch(updateIncidentRedux({ key: "incident_list_loading", value: false }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_list", value: res.data.data.incidents }))
      dispatch(updateIncidentRedux({ key: "incident_list_page_count", value: res.data.data.pages }))
      dispatch(updateIncidentRedux({ key: "incident_list_page_status", value: res.data.data.page_status }))
      dispatch(updateIncidentRedux({ key: "incident_list_full_data", value: res.data.data }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      callback && callback(true, res.data)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_list_loading", value: false }))
    console.log(err)
  })
}

export const getSerachUsers = (data) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "poolUser_list_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/getSerachUsers`, data).then((res) => {
    dispatch(updateIncidentRedux({ key: "poolUser_list_loading", value: false }))
    if (res.data.status) {
      // console.log()
      dispatch(updateIncidentRedux({ key: "searchuser_list", value: res.data.data }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "poolUser_list_loading", value: false }))
    console.log(err)
  })
}

export const addPoolUser = (data) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/addPoolUser`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "poolUser_list", value: res.data }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const addPoolAdmin = (data) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/addPoolAdmin`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "poolAdmin_list", value: res.data }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const addPoolPrivilegeToUsers = (data, navigate, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "add_user_privilege_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/addPoolPrivillageToUsers`, data).then((res) => {
    dispatch(updateIncidentRedux({ key: "add_user_privilege_loading", value: false }))
    if (res.data.status) {
      callback && callback({ status: true, message: res.data.message })
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      navigate('/admin/incidents/roles')
    } else {
      callback && callback({ status: true, message: res.data.message })
    }
  }).catch((err) => {
    console.log(err)
    dispatch(updateIncidentRedux({ key: "add_user_privilege_loading", value: false }))
  })
}

export const getIncidentType = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getIncidentType`).then(res => {
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_type_details", value: res.data.incident_type_details }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getIncidentTypeForFilter = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getIncidentTypeForFilter`).then(res => {
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_type_details_for_filter", value: res.data.incident_type_details }))
      if(res.data.user_role){
        localStorage.setItem('user_type', res.data.user_role)
        dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const insertIncident = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_add_change_loading", value: true }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
  axiosFormInstance.post(`${API_BASE_URL}admin/insertIncident`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: "incident_add_change_loading", value: false }))
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))
    if (res.data.status) {
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      if (callback) {
        callback()
      }
    } else {
      dispatch(updateIncidentRedux({ key: "incident_add_change_loading", value: false }))
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getAttachmentList = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/attachment-type`).then((res) => {
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_attachment_list", value: res.data.data }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getIncidentStatusList = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getIncidentStatus`).then((res) => {
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_details", value: res.data.incident_status_details }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getIncidentSource = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getIncidentSource`).then((res) => {
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_source_details", value: res.data.incident_source_details }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getIncidentDetails = (id, displaytype, taskno, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_details_loading", value: true }))
  let obj = {
    id: id,
    display_type: displaytype,
    task_no: taskno || null,
  }
  axiosInstance.post(`${API_BASE_URL}admin/incidentDetails`, obj).then((res) => {
    dispatch(updateIncidentRedux({ key: "incident_details_loading", value: false }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_details", value: res.data.incident_data }))
      dispatch(updateIncidentRedux({ key: "incident_path", value: res.data.path }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
    } else {
      if (callback) {
        callback(res.data, false)
      }
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_details_loading", value: false }))
    // console.log(err.response, "6")
  })
}

export const closeIncident = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: true }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
  axiosFormInstance.put(`${API_BASE_URL}admin/close-incident`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      if (callback) {
        callback()
      }
    } else {
      // toast.error(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    console.log(err)
  })
}

export const reopenIncidentTask = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_task_status_change_loading", value: true }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))

  axiosFormInstance.post(`${API_BASE_URL}admin/reopenIncidentTask`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: "incident_task_status_change_loading", value: false }))
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      if (callback) {
        callback()
      }
    } else {
      // toast.error(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_task_status_change_loading", value: false }))
    console.log(err)
  })
}

export const reOpenIncident = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: true }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
  axiosFormInstance.put(`${API_BASE_URL}admin/reopen-incident`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))

    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      if (callback) {
        callback()
      }
    } else {
      // toast.error(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    console.log(err)
  })
}

export const returnIncident = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: true }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
  axiosFormInstance.put(`${API_BASE_URL}admin/return-incident`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      if (callback) {
        callback()
      }
    } else {
      // toast.error(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    console.log(err)
  })
}

export const forwardIncident = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/forwardIncident`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      if (callback) {
        callback()
      }
    } else {
      // toast.error(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    console.log(err)
  })
}

export const forwardIncidentFromPoolAdmin = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/forwardFromPoolAdmin`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      if (callback) {
        callback()
      }
    } else {
      // toast.error(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    console.log(err)
  })
}

export const changeIncidentStatus = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: true }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
  axiosFormInstance.post(`${API_BASE_URL}admin/changeIncidentStatus`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      if (callback) {
        callback()
      }
    } else {
      // toast.error(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    console.log(err)
  })
}

export const assignTaskToPoolUser = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: true }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
  axiosFormInstance.post(`${API_BASE_URL}admin/assignIncidentTaskToPoolUser`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    console.log(err)
  })
}

export const transferIncidentTaskToPoolUser = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: true }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
  axiosFormInstance.post(`${API_BASE_URL}admin/transferIncidentTaskToPoolUser`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    console.log(err)
  })
}

export const assignTaskToPoolDepartment = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: true }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
  axiosFormInstance.post(`${API_BASE_URL}admin/assignIncidentTaskToDepartment`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    console.log(err)
  })
}

export const openIncident = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: true }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
  axiosFormInstance.post(`${API_BASE_URL}admin/openIncident`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    console.log(err)
  })
}

export const updateIncident = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: true }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
  axiosFormInstance.post(`${API_BASE_URL}admin/updateIncident`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      if (callback) {
        callback(res.data)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    console.log(err)
  })
}

export const getDynamicTexts = (callback) => (dispatch) => {
  axiosFormInstance.get(`${API_BASE_URL}admin/getTexts`).then(res => {
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "dynamic_text_list", value: res.data.translate_details }))
      if (callback) {
        callback(res.data.translate_details)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const updateDynamicTexts = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}admin/updateText`, data).then(res => {
    if (res.data.status) {
      callback && callback({ status: true, message: res.data.message, data: res.data })
    } else {
      callback && callback({ status: false, message: res.data.message })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const importDynamicExcel = (formdata, callback) => (dispatch) => {
  // console.log(data, "data of excel")
  axiosFormInstance.post(`${API_BASE_URL}admin/importDynamicExcel`, formdata
  ).then(res => {
    if (res.data.status) {
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const DownloadReturnData = (callback) => async (dispatch) => {
  try {
    let response = await axios.get(`${API_BASE_URL}admin/download-returnData`, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    console.log(response)
    if (response.status === 200) {
      if (callback) {
        callback(true, response.data)
      }
    } else {
      callback && callback(false, "No valid data available to download")
    }
  } catch (error) {
    console.log(error)
  }
}

// export const DownloadFolder = (callback) => (dispatch) => {
//   axios.get(`${API_BASE_URL}admin/download-folder`,{
//     // responseType: 'blob',
//     headers: {
//       'Authorization': `Bearer ${localStorage.getItem("token")}`
//     },
//   }).then(res => {
//     console.log(res,"res")
//     if (res.status === 200) {
//       if (callback) {
//         callback(res.data)
//       }
//     } else {
//       toast.error(res.data.message, {
//         position: "bottom-center",
//         autoClose: 3000
//       })
//     }
//   }).catch((err) => {
//     console.log(err,"147")
//   })
// }

export const DownloadFolder = (callback) => async (dispatch) => {
  try {
    let response = await axios.get(`${API_BASE_URL}admin/download-folder`, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    // console.log(response)
    if (response.status === 200) {
      if (callback) {
        callback(true, response.data)
      }
    } else {
      callback && callback(false, "No valid data available to download")
    }
  } catch (error) {
    console.log(error)
  }
}
export const uploadFolder = (callback) => (dispatch) => {
  // console.log(data, "data of excel")
  axiosInstance.post(`${API_BASE_URL}admin/upload-folder`).then(res => {
    if (res.data.status) {
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      dispatch(
        updateIncidentRedux({
          key: "responseModal",
          value: { ...res.data, show: true },
        })
      );
      if (callback) {
        callback(true, res.data)
      }
    } else {
      if (callback) {
        callback(false, res.data)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const transferZipFileToServer = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "upload_zip_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/transferZipFileToServer`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: "upload_zip_loading", value: false }))
    if (res.data.status) {
      if (callback) {
        callback(true, res.data.message)
      }
    } else {
      if (callback) {
        callback(false, res.data.message)
      }
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "upload_zip_loading", value: false }))
    console.log(err)
  })
}

export const reopenIncident = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: true }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
  axiosFormInstance.put(`${API_BASE_URL}admin/reopen-incident`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    console.log(err)
  })
}


export const submitIncidentTask = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_task_status_change_loading", value: true }))
  // setTimeout(()=>  dispatch(updateIncidentRedux({ key: "incident_task_status_change_loading", value: false })), 5000)
  // return
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
  axiosFormInstance.post(`${API_BASE_URL}admin/submitIncidentTask`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))
    dispatch(updateIncidentRedux({ key: "incident_task_status_change_loading", value: false }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_task_status_change_loading", value: false }))
    console.log(err)
  })
}

export const closeIncidentTask = (formData, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_task_status_change_loading", value: true }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: false }))
  axiosFormInstance.post(`${API_BASE_URL}admin/closeIncidentTask`, formData).then(res => {
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))
    dispatch(updateIncidentRedux({ key: "incident_task_status_change_loading", value: false }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_task_status_change_loading", value: false }))
    console.log(err)
  })
}


export const deleteDraftedIncident = (data, callback) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: true }))
  dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
  axiosInstance.post(`${API_BASE_URL}admin/deleteDraftedIncident`, data).then(res => {
    dispatch(updateIncidentRedux({ key: 'responseModal', value: { ...res.data, show: true } }))
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "incident_status_change_success", value: true }))
      localStorage.setItem('user_type', res.data.user_role)
      dispatch(updateRedux({ key: "user_role", value: res.data.user_role }))
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateIncidentRedux({ key: "incident_status_change_loading", value: false }))
    console.log(err)
  })
}

export const updateUserRole = (data, callback) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateUserRole`, data).then(res => {
    if (res.data.status) {
      callback && callback({ status: true, message: 'Role updated successfully' })
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch(err => {
    console.log(err)
  })
}

export const getSmsReport = (formData) => (dispatch) => {
  dispatch(updateIncidentRedux({ key: "sms_list_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/getSmsReport`, formData).then((res) => {
    dispatch(updateIncidentRedux({ key: "sms_list_loading", value: false }))
    if (res.data.status) {
      dispatch(updateIncidentRedux({ key: "sms_report", value: res.data.data }))
      dispatch(updateIncidentRedux({ key: "sms_report_page_count", value: res.data.data.pages }))
      dispatch(updateIncidentRedux({ key: "sms_report_page_status", value: res.data.data.page_status }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getReturnedCount = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/get-returned-count`).then((res) => {
    if (res.data.status) {
      console.log(res.data)
      callback && callback(true, res.data.data)
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => {
    console.log(err)
  })
}