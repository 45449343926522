import React from 'react'
import './RequestDetailsData.scss'

export default function RequestDetailsData() {
    return (
        <div id='RequestDetailsData'>
            <div className='title'>
                <h2>Request Details</h2>
            </div>
            <div className="content">
                <article>
                    <span>
                        <p className="key">Select Certificate</p>
                        <p className='value'>Salary Certificate</p>
                    </span>
                    <span>
                        <p className="key">Certificate Language</p>
                        <p className='value'>Arabic</p>
                    </span>
                </article>
                <article>
                    <span>
                        <p className="key">Addressee</p>
                        <p className='value'>Emirates NBD Bank</p>
                    </span>
                </article>
                <article>
                    <span>
                        <p className="key">Comment</p>
                        <p className='value'>Dear Team, Kindly share my salary Certificate for the month of January</p>
                    </span>
                </article>
            </div>
        </div>
    )
}
