import React from 'react'
import { Link } from 'react-router-dom'
import './AddRequestButton.scss'

export default function AddRequestButton() {
  return (
    <div id='AddRequestButton'>
      <Link to={'add_request'}>
        <button>
          <img src="/assets/icons/Add Request.svg" alt="" />
          Add Request
        </button>
      </Link>
    </div>
  )
}
