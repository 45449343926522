import React from 'react'
import './CommentContent.scss'
import { useEffect } from 'react'
import { useState } from 'react'

export default function CommentContent({ Content = '' }) {

  const [content, setContent] = useState('')

  useEffect(() => {
    setContent(Content)
    return () => setContent('')
  }, [Content])

  return (
    <p id='CommentContent'>
      {content}
    </p>
  )
}
