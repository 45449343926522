import React from 'react'
import TitleAndPath from '../../components/TitleAndPath/TitleAndPath'
import AddRequestButton from '../../components/AddRequestButton/AddRequestButton'
import Search from '../../components/Search/Search'
import PaginationSearch from '../../components/PaginationSearch/PaginationSearch'
import Table1 from '../../components/Table1/Table1'
import { Link } from 'react-router-dom'
import PermissionsGate from '../../utils/PermissionsGate'
import { SCOPES } from '../../utils/permissions-maps'

export default function CertificateRequests() {

  const titles = [
    { title: 'Request No', key: 'request_no', sort: true },
    { title: 'Request Type', key: 'request_type', sort: true },
    { title: 'Requested Date', key: 'requested_date', sort: true },
    { title: 'Last Update', key: 'last_update', sort: true },
    { title: 'Handled By', key: 'handled_by', sort: true },
    { title: 'Status', key: 'status', sort: true },
    { title: 'Action', key: 'action' }
  ]

  const table = [
    {
      request_no: 'HR23008',
      request_type: 'Detailed Salary Certificate',
      requested_date: '03 Feb 2023',
      last_update: '03 Feb 2023',
      handled_by: 'Maitha',
      status: 'Open'
    },
    {
      request_no: 'HR23008',
      request_type: 'Detailed Salary Certificate',
      requested_date: '03 Feb 2023',
      last_update: '03 Feb 2023',
      handled_by: 'Maitha',
      status: 'Approved'
    },
    {
      request_no: 'HR23008',
      request_type: 'Detailed Salary Certificate',
      requested_date: '03 Feb 2023',
      last_update: '03 Feb 2023',
      handled_by: 'Maitha',
      status: 'Processing'
    },
    {
      request_no: 'HR23008',
      request_type: 'Detailed Salary Certificate',
      requested_date: '03 Feb 2023',
      last_update: '03 Feb 2023',
      handled_by: 'Maitha',
      status: 'Need Info'
    },
    {
      request_no: 'HR23008',
      request_type: 'Detailed Salary Certificate',
      requested_date: '03 Feb 2023',
      last_update: '03 Feb 2023',
      handled_by: 'Maitha',
      status: 'Rejected'
    },
  ]


  return (
    <div id='CertificateRequests'>
      <section className='heading'>
        <TitleAndPath title={'Request Certificate'} path={'Home / HR / Certificate Requests'} />
        <PermissionsGate
          scopes={[SCOPES.canEdit]}
          errorProps={{ disabled: true }}
        >
          <AddRequestButton />
        </PermissionsGate>
      </section>
      <section className='search'>
        <Search currentPage={'CertificateRequests'} />
      </section>
      <section className='pagination'>
        <PaginationSearch />
      </section>
      <section className='table'>
        <Table1 currentPage={'CertificateRequests'} titles={titles} data={table} />
      </section>
    </div>
  )
}
