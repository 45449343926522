import React from 'react'
import './TitleBar.scss'
import { useState } from 'react'
import { useEffect } from 'react'

export default function TitleBar({ Title, ToggleButton = false, DefaultToggle = false, Top, dir, children, className }) {

  const [title, setTitle] = useState('')
  const [expandToggle, setExpandToggle] = useState(DefaultToggle)

  useEffect(() => {
    setTitle(Title)
    return () => setTitle('')
  }, [Title])

  return (
    <>
      <div id='TitleBar' className={className || '' + (Top ? 'top' : '')}>
        <h3 dir='ltr'>{title}</h3>
        {ToggleButton &&
          <img className={expandToggle ? 'tb__expand' : 'tb__collapse'}
            onClick={() => setExpandToggle(s => !s)}
            src="/assets/icons/Icon ionic-ios-arrow-down tan.svg" alt="" />
        }
      </div>
      {ToggleButton && children &&
        <section className={'box-design expandable__section' + (expandToggle ? ' tb__show' : ' tb__hide')}>
          {children}
        </section >
      }
    </>
  )
}