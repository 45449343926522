import React from 'react'
import './TitleAndPath.scss'

export default function TitleAndPath({ title, path }) {
    return (
        <div id='TitleAndPath'>
            <h1>{title}</h1>
            <p>{path}</p>
        </div>
    )
}
