import store from '../store'

export const translate = (text) => {
    const translateDetails = store.getState().incidentReducer.dynamic_text_list

    const selectedLanguage = localStorage.getItem("lang");
    let key = "english_text"
    if (selectedLanguage && selectedLanguage === "ar") {
        key = "arabic_text"
    }

    if (translateDetails) {
        return (translateDetails?.filter((ele) => ele.title == text)
            ?.length
            ? translateDetails?.find(
                (ele) => ele.title == text
            )[key]
            : text)
    }
    
    return text
}