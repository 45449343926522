import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Table1.scss'

export default function Table1({ currentPage, titles, data }) {

  const [windowSize, setWindowSize] = useState(0)

  const page =
    currentPage === 'CertificateRequests' ? 1 :
      currentPage === 'HumanResource_admin' ? 2 :
        currentPage === 'CertificateRequests_admin_report' ? 3 : 0

  function onPages(pages) {
    let status = false
    if (pages.length === 0) { return true }
    for (let i = 0; i < pages.length; i++) {
      if (page === pages[i]) return true
      else status = false
    }
    return status
  }

  function getNavPath(currentStatus) {
    const path =
      currentStatus === 'Need Info' && onPages([1, 2]) ? 'request_details' :
        currentStatus === 'Open' && onPages([1, 2, 3]) ? 'request_details' :
          currentStatus === 'Processing' && onPages([2, 3]) ? 'request_details' :
            currentStatus === 'Approved' && onPages([3]) ? 'request_details' :
              currentStatus === 'Closed' && onPages([3]) ? 'request_details' :
                currentStatus === 'Rejected' && onPages([3]) ? 'request_details' :
                  currentStatus === 'Forwarded' && onPages([3]) ? 'request_details' :
                    ''
    return path
  }

  function buttonFilter(page, currentStatus) {
    const status =
      currentStatus === 'Open' ? 1 :
        currentStatus === 'Forwarded' ? 2 :
          currentStatus === 'Approved' ? 3 :
            currentStatus === 'Closed' ? 4 :
              currentStatus === 'Rejected' ? 5 :
                currentStatus === 'Processing' ? 6 :
                  currentStatus === 'Need Info' ? 7 : 0

    const Close = () => <i><img src="/assets/icons/Close_circle.svg" alt="" /></i>
    const NotEditable = () => <i><img src="/assets/icons/Group 12235.svg" alt="" /></i>
    const Editable = () => <Link to={'request_details'}><i><img src="/assets/icons/Group 12236.svg" alt="" /></i></Link>
    const Download = () => <i><img src="/assets/icons/Group 12237.svg" alt="" /></i>
    const Viewable = () => <Link to={getNavPath(currentStatus)}><i><img src="/assets/icons/Eye-Open.svg" alt="" /></i></Link>

    switch (page + "" + status) {
      case '11': return <> <NotEditable /> <Close /> </>
      case '13': return <> <NotEditable /> <Download /> </>
      case '15': return <NotEditable />
      case '16': return <NotEditable />
      case '17': return <Editable />
      case '21': return <> <Viewable /> <Editable /> </>
      case '26': return <> <Viewable /> <Editable /> </>
      case '27': return <> <Viewable /> <NotEditable /> </>
      case '31': return <Viewable />
      case '32': return <Viewable />
      case '33': return <> <Viewable /><Download /> </>
      case '34': return <Viewable />
      case '35': return <Viewable />
      case '36': return <Viewable />
      case '37': return <Viewable />
      default:
    }
  }

  useEffect(() => {
    window.addEventListener('load', (e) => setWindowSize(e.currentTarget.innerWidth))
    window.addEventListener('resize', (e) => setWindowSize(e.target.innerWidth))
  }, [])

  return (
    <div id='Table1' style={{ width: (windowSize <= 1200 ? windowSize - 83 : windowSize - 331) }}>
      <table>
        <thead>
          <tr>
            {
              titles.map((title, i) => (
                <th key={i} className={title.sort ? 'sort' : ''}>{title.title}
                  {title.sort && <i>
                    <span>
                      <img className='sort-arrow up' src="/assets/icons/Icon material-arrow-drop-down1.svg" alt="" />
                      <img className='sort-arrow down' src="/assets/icons/Icon material-arrow-drop-down1.svg" alt="" />
                    </span>
                  </i>} </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            data.map((row, i) => (
              <tr key={i}>
                {
                  titles.map((title, si) => (
                    <td key={si} style={{ width: title.width }} className={title.key} >
                      {
                        title.key === 'status' ? <button className={row[title.key] + ' style-5'}> {row[title.key]}</button> :
                          title.key === 'action' ? <span> {buttonFilter(page, row.status)} </span> :
                            row[title.key]
                      }
                    </td>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </table>
    </div >
  )
}
