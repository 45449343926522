import React, { useEffect, useState } from "react";
import "../style.scss";
import { Form, Spinner, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdvancedSearch from "../EducationAllowance/AdvancedSearch";
import {
  users_list,
  users_list_loading,
  users_list_page_status,
  users_list_total_count,
} from "../../../redux/commonReducer";
import {
  getAllIncidentUsers, getEmployeelist, syncAd
} from "../../../redux/commonActionCreator";
import Pagination from "../../../components/Pagination/Pagination";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import useTrans from "../../../utils/useTrans";
import { roles } from "../../../constants/configuration";
import { toast } from "react-toastify";

function UsersListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTrans()

  const usersList = useSelector(users_list);
  const loading = useSelector(users_list_loading);
  const pageCount = useSelector(users_list_total_count);
  const pageStatus = useSelector(users_list_page_status);

  const [filterData, setFilterData] = useState({
    department: "",
    section: "",
    employee_name: "",
    role: "",
    user_status: "",
    keyword: "",
    entry: 10
  });
  const [page, setPage] = useState(0);
  const [customShow, setCustomShow] = useState(10);
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortAscending, setSortAscending] = useState(false);

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem('userlistingfilter'))
    setFilterData(data)
    setCustomShow(data?.entry || 10)
    setPage(data?.page || 0);
    getDataHandler(data);
  }, []);

  const getDataHandler = (data) => {
    let formData = new FormData();
    formData.append("department", data?.department?._id || "");
    formData.append("section", data?.section?._id || "");
    formData.append("employee_name", data?.employee_name?.name || "");
    formData.append("role", data?.role?.key || "");
    formData.append("keyword", data?.keyword || "");
    formData.append("user_status", data?.user_status?.value || "");
    formData.append("page", data?.page || 0);
    formData.append("perPage", data?.entry || 10);
    formData.append("sort_key", data?.sort_key || "createdAt");
    formData.append("sort_order", data?.sort_order || -1);
    dispatch(getAllIncidentUsers(formData));
  };

  const tableHeaders = [
    { name: t("Employee ID"), sort_key: "employee_id" },
    { name: t("Employee Name"), sort_key: "name" },
    { name: t("Department"), sort_key: "department" },
    { name: t("Section"), sort_key: "section" },
    { name: t("Role"), sort_key: "role" },
    { name: t("Email"), sort_key: "email" },
    { name: t("Phone"), sort_key: "phone" },
    { name: t("Status"), sort_key: "user_status" },
    { name: t("Action"), sort_key: "" },
  ];

  const getSortingClassName = (title) => {
    let className = "";
    if (sortColumn === title) {
      if (sortAscending) {
        className = "active";
      } else {
        className = "inactive";
      }
    }
    return className;
  };

  const clearFilter = () => {
    setFilterData({
      department: "",
      section: "",
      employee_name: "",
      role: "",
      user_status: "",
      keyword: "",
    });
    setPage(0);
    setSortAscending(false);
    setSortColumn("createdAt");
    let data = {
      department: "",
      section: "",
    };
    dispatch(getEmployeelist(data));
    getDataHandler();
  };

  const applySearch = () => {
    setPage(0);
    let formData = new FormData();
    formData.append("department", filterData?.department?._id ?? "");
    formData.append("section", filterData?.section?._id ?? "");
    formData.append("employee_name", filterData?.employee_name?.name ?? "");
    formData.append("role", filterData?.role?.key ?? "");
    formData.append("keyword", filterData?.keyword?.trim() || '');
    formData.append("user_status", filterData?.user_status?.value ?? "");
    formData.append("page", 0);
    formData.append("perPage", customShow ? customShow : 10);
    formData.append("sort_order", sortAscending ? 1 : -1);
    formData.append("sort_key", sortColumn);
    localStorage.setItem('userlistingfilter', JSON.stringify(filterData))
    dispatch(getAllIncidentUsers(formData));
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    let formData = new FormData();
    formData.append("department", filterData?.department?._id ?? "");
    formData.append("section", filterData?.section?._id ?? "");
    formData.append("employee_name", filterData?.employee_name?.name ?? "");
    formData.append("role", filterData?.role?.key ?? "");
    formData.append("keyword", filterData?.keyword?.trim() || '');
    formData.append("user_status", filterData?.user_status?.value ?? "");
    formData.append("page", e.selected);
    formData.append("perPage", customShow ? customShow : 10);
    formData.append("sort_order", sortAscending ? 1 : -1);
    formData.append("sort_key", sortColumn);
    let obj = { ...filterData, page: e.selected }
    localStorage.setItem('userlistingfilter', JSON.stringify(obj))
    dispatch(getAllIncidentUsers(formData));
  };

  const onSortHandler = (type) => {
    setSortAscending(!sortAscending);
    setSortColumn(type);
    let formData = new FormData();
    formData.append("department", filterData?.department?._id ?? "");
    formData.append("section", filterData?.section?._id ?? "");
    formData.append("employee_name", filterData?.employee_name?.name ?? "");
    formData.append("role", filterData?.role?.key ?? "");
    formData.append("keyword", filterData?.keyword.trim());
    formData.append("user_status", filterData?.user_status?.value ?? "");
    formData.append("page", page);
    formData.append("perPage", customShow ? customShow : 10);
    formData.append("sort_key", type);
    formData.append("sort_order", !sortAscending ? 1 : -1);
    dispatch(getAllIncidentUsers(formData));
  };

  const onLimitChangeHandler = (value) => {
    setCustomShow(parseInt(value));
    setPage(0);
    setFilterData(s => {
      let obj = { ...s, entry: parseInt(value) }
      localStorage.setItem('userlistingfilter', JSON.stringify(obj))
      return obj
    })
    let formData = new FormData();
    formData.append("department", filterData?.department?._id ?? "");
    formData.append("section", filterData?.section?._id ?? "");
    formData.append("employee_name", filterData?.employee_name?.name ?? "");
    formData.append("role", filterData?.role?.key ?? "");
    formData.append("keyword", filterData?.keyword?.trim() || '');
    formData.append("user_status", filterData?.user_status?.value ?? "");
    formData.append("page", 0);
    formData.append("perPage", value ? parseInt(value) : 10);
    formData.append("sort_order", sortAscending ? 1 : -1);
    formData.append("sort_key", sortColumn);
    dispatch(getAllIncidentUsers(formData));
  };

  const onViewHandler = (ele) => {
    navigate(`/admin/users/user-log/${ele._id}`);
  };

  const onSyncHandler = () => {
    dispatch(syncAd(() => {
      clearFilter()
    }));
  }

  return (
    <div className="container-fluid">
      <div className="manage__incidents">
        <div className="mb-4">

          <div className="section__header">
            <div className="breadcrumbs">
              <h1>{t('Users')}</h1>
              <p>{t('Home')} / {t('Users')}</p>
            </div>
            <div className="header-btn">
              <button className="btn-incident" onClick={() => onSyncHandler()}>
                <img src="/assets/icons/sync-svgrepo-com.svg" alt="" /> {t('Sync Users')}
              </button>
            </div>
          </div>
        </div>
        <section className="common__table pt-0 pb-0">
          <AdvancedSearch
            page="incident_users_listing"
            filterData={filterData}
            setFilterData={setFilterData}
            clearFilter={clearFilter}
            applySearch={applySearch}
          />

          <div className="search__options mt-4">
            <div className="search">
              <Form.Control
                type="text"
                placeholder={t("Search")}
                value={filterData?.keyword || ''}
                onChange={(e) => {
                  setFilterData(s => {
                    let obj = { ...filterData, keyword: e.target.value }
                    localStorage.setItem('userlistingfilter', JSON.stringify(obj))
                    return obj
                  })
                }}
              />
              <button className="btn btn-search">
                <img
                  src="/assets/icons/Search1.svg"
                  alt=""
                  onClick={applySearch}
                />
              </button>
            </div>
            <div className="show">
              <span>{t('Entries')}</span>
              <Form.Control
                type="number"
                // disabled={loading}
                min={1}
                value={customShow}
                onChange={(e) => {
                  if (Number(e.target.value) > 100) {
                    toast.error(t("Entry should not be greater than 100"), {
                      position: "bottom-center",
                      autoClose: 3000,
                    });
                    return;
                  }
                  if (e.target.value == 0) {
                    onLimitChangeHandler(e.target.value);
                  } else {
                    onLimitChangeHandler(e.target.value);
                  }
                }}
              />
              <span>{t('Show')}</span>
            </div>
          </div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="table-responsive p-2">
              <Table bordered hover>
                <thead>
                  <tr>
                    {tableHeaders.map((header) => (
                      <th
                        className={
                          header.sort_key != ""
                            ? `sorting cursor-pointer ${getSortingClassName(
                              header.sort_key
                            )}`
                            : ""
                        }
                        onClick={() => onSortHandler(header.sort_key)}
                      >
                        {header.name}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {usersList?.map((ele, i) => {
                    return (
                      <tr>
                        <td>
                          {ele?.employee_id}
                        </td>
                        <td>{`${ele?.name}`}</td>
                        <td>{ele?.department[0]?.department_name ?? "-"}</td>
                        <td>{ele?.section[0]?.section_name ?? "-"}</td>
                        <td>
                          {/* {ele?.role} */}
                          {roles[ele?.role]}
                        </td>
                        <td>{ele?.email ?? "-"}</td>
                        <td>{ele?.phone}</td>
                        <td>{t(ele.user_status == 1 ? "Active" : "Inactive")}</td>
                        <td>
                          <img
                            src="/assets/icons/log.svg"
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() => onViewHandler(ele)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              {usersList?.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h6>{t('No Data')}</h6>
                </div>
              )}
            </div>
          )}
        </section>
        {!loading && !!usersList?.length && (
          <div
            className="align-between"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ direction: "ltr", color: "#0d7b58" }}>{pageStatus}</p>
            <Pagination
              page={page}
              pageCount={pageCount}
              handlePageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default UsersListing;
