import axios from 'axios';
import { toast } from 'react-toastify';
import { API_BASE_URL } from "../constants/configuration";
import axiosInstance from "../utils/axiosInterceptor";
import axiosFormInstance from '../utils/axiosFormInterceptor';
import { updateHrRedux } from './hrReducer';
// import { data } from 'jquery';

// const formHeader={
//   headers: {
//     'Content-Type': 'multipart/form-data',
//     'Authorization' : `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDI1NGZlNTRjMDRkN2I4NjgyNGIxYmMiLCJpYXQiOjE2ODAxODAyMDIsImV4cCI6MTY4MDI2NjYwMn0._2vTRyPPMTUkoSsSoojI04sdTQhR2QHys_MdHj2_lwk`
//   },
// }

// const header={
//   headers: {
//     'Authorization' : `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDI1NGZlNTRjMDRkN2I4NjgyNGIxYmMiLCJpYXQiOjE2ODAxODAyMDIsImV4cCI6MTY4MDI2NjYwMn0._2vTRyPPMTUkoSsSoojI04sdTQhR2QHys_MdHj2_lwk`
//   },
// }

// export const login = (data, callback) => () => {
//   axios.post(`${API_BASE_URL}admin/checkLogin`, data).then(res => {
//   if (res.data.status == true) {
//     localStorage.setItem('token',res.data.accessToken)
//     if(callback) {
//       callback(res.data.status)
//     }
//   }else {
//     toast.error("Please check your username or password", {
//       position: "bottom-center",
//       autoClose: 3000
//     })
//     if(callback) {
//       callback(res.data.status)
//     }
//   }
// }).catch((err) => {
//   console.log(err)
// })
// }



export const getRequestType = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getRequestType`).then((res)=>{
    if(res.data.status) {
      dispatch(updateHrRedux({key : "request_type_details", value : res.data.request_type_details}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const getRequestStatus = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getRequestStatus`).then((res)=>{
    if(res.data.status) {
      dispatch(updateHrRedux({key : "request_status_details", value : res.data.request_status_details}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}

export const addAdminRequest = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertCertificateRequest`, formData)
  .then(res => {
    if (res.data.status == true) {
      let data = {
        perPage : 10,
        page : 1
      }
      dispatch(getCertificateRequests(data))
      if(callback) {
        callback(res.data.status);
      }
    }else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err);
  })
}

export const getCertificateRequests = (data) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}admin/getCertificateRequests`, data).then((res)=>{
    if(res.data.status) {
      dispatch(updateHrRedux({key : "certificate_list", value : res.data.certificate_list}))
      dispatch(updateHrRedux({key : "admin_certificate_path", value : res.data.path}))
      dispatch(updateHrRedux({key : "admin_certificate_pages", value : res.data.pages}))
      dispatch(updateHrRedux({key : "admin_certificate_page_status", value : res.data.page_status}))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err)
  })
}
