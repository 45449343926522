import React, { useEffect, useState } from "react";
import "../style.scss";
import "./user.scss";
import { Form, Spinner, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  log_from_date,
  log_list_loading,
  log_page,
  log_per_page,
  log_sort_ascending,
  log_sort_column,
  log_to_date,
  log_user_data,
  updateRedux,
} from "../../../redux/commonReducer";
import Pagination from "../../../components/Pagination/Pagination";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SessionLogData from "./sessionLogTable";
import ActivityLogData from "./activityLogTable";
import {
  getUserActivityLog,
  getUserSessionLog,
} from "../../../redux/commonActionCreator";
import moment from "moment";
import useTrans from "../../../utils/useTrans";
import { toast } from "react-toastify";

function UsersLog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTrans()

  const loading = useSelector(log_list_loading);
  const fromDate = useSelector(log_from_date);
  const toDate = useSelector(log_to_date);
  const page = useSelector(log_page);
  const sortColumn = useSelector(log_sort_column);
  const sortAscending = useSelector(log_sort_ascending);
  const perPage = useSelector(log_per_page);
  const userData = useSelector(log_user_data);

  const [logType, setLogType] = useState("activity_log");

  const clearFilter = () => {
    dispatch(updateRedux({ key: "log_per_page", value: 10 }));
    dispatch(updateRedux({ key: "log_page", value: 0 }));
    dispatch(updateRedux({ key: "log_sort_ascending", value: false }));
    dispatch(updateRedux({ key: "log_sort_column", value: "createdAt" }));
    dispatch(updateRedux({ key: "log_from_date", value: "" }));
    dispatch(updateRedux({ key: "log_to_date", value: "" }));
    let value = {
      userId: id,
      page: 0,
      from_date: "",
      to_date: "",
      sort_key: "createdAt",
      sort_order: false,
      perPage: 10,
    };
    if (logType == "activity_log") {
      dispatch(getUserActivityLog(value));
    } else {
      dispatch(getUserSessionLog(value));
    }
  };
  // moment(toDate).format("YYYY-MM-DD hh:mm:ss")?.split(" ")[0] +" 23:59:59";
  // new Date(fromDate).toISOString()
  const applySearch = () => {
    dispatch(updateRedux({ key: "log_page", value: 0 }));
    let value = {
      userId: id,
      page: 0,
      from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
      to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
      sort_key: sortColumn,
      sort_order: !sortAscending ? "1" : "-1",
      perPage: perPage ? perPage: 10,
    };
    console.log(value);
    if (logType == "activity_log") {
      dispatch(getUserActivityLog(value));
    } else {
      dispatch(getUserSessionLog(value));
    }
  };

  const onLimitChangeHandler = (value) => {
    dispatch(updateRedux({ key: "log_per_page", value: parseInt(value) }));
    dispatch(updateRedux({ key: "log_page", value: 0 }));
    let dataValue = {
      userId: id,
      page: 0,
      from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
      to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
      sort_key: sortColumn,
      sort_order: sortAscending ? "1" : -"1",
      perPage: value ? parseInt(value) : 10,
    };
    if (logType == "activity_log") {
      dispatch(getUserActivityLog(dataValue));
    } else {
      dispatch(getUserSessionLog(dataValue));
    }
  };

  const logTypeChangeHandler = (type) => {
    dispatch(updateRedux({ key: "log_per_page", value: 10 }));
    dispatch(updateRedux({ key: "log_page", value: 0 }));
    dispatch(updateRedux({ key: "log_sort_ascending", value: false }));
    dispatch(updateRedux({ key: "log_sort_column", value: "createdAt" }));
    setLogType(type);
  };

  return (
    (
      <div className="container-fluid">
        <div className="manage__incidents">
          <div className="mb-4">
            <div className="section__header">
              <div className="breadcrumbs">
                <h1>{t('Users Log')}</h1>
                <p>
                  <Link to={"/admin/users"}>{t('Home')}</Link> /{" "}
                  <Link to={"/admin/users"}>{t('Users Listing')}</Link> /{" "}
                  {t('Log')}
                </p>
              </div>
              <div className="header-btn">
                <button className="btn-incident">
                  <img src="/assets/icons/sync-svgrepo-com.svg" alt="" /> {t('Sync Users')}
                </button>
              </div>
            </div>
          </div>
          <div className="advanced__search">
            <div className="card">
              <div className="card-body p-0">
                <div className="row justify-content-between align-items-end">
                  <div className="row col-4 d-flex align-items-end">
                    <div className="col">
                      <div className="mb-3 position-relative">
                        <label className="form-label rtl">{t('Date')}</label>
                        <DatePicker
                          selected={fromDate}
                          onChange={(date) => {
                            dispatch(
                              updateRedux({ key: "log_from_date", value: date })
                            );
                          }}
                          placeholderText={t("From")}
                          dateFormat="dd/MM/yyyy"
                          maxDate={toDate ? toDate : new Date()}
                        />
                        <img
                          className="form-icon"
                          src="/assets/icons/icon-calendar.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col pr-0">
                      <div className="mb-3 position-relative">
                        <DatePicker
                          selected={toDate}
                          showIcon
                          onChange={(date) => {
                            dispatch(
                              updateRedux({ key: "log_to_date", value: date })
                            );
                          }}
                          placeholderText={t("To")}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                          minDate={fromDate && fromDate}
                        />
                        <img
                          className="form-icon"
                          src="/assets/icons/icon-calendar.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-2 d-flex align-items-baseline">
                    <div className="mb-3 position-relative">
                      <button className="btn btn-primary" onClick={applySearch}>
                        {t('Search')}
                      </button>
                    </div>
                    {fromDate || toDate ? (
                      <div className="col-md-1">
                        <div className="position-relative">
                          <button
                            className="btn btn-primary btn-clear"
                            onClick={clearFilter}
                          >
                            <img src="./assets/icons/clear.svg" alt="X" />
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* <div className="col">
                    <div className="mb-3 position-relative">
                      <button className="btn btn-primary" onClick={clearFilter}>
                        Clear
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="search__options mt-4">
            <div className="show">
              <span>{t('Entries')}</span>
              <Form.Control
                type="number"
                disabled={loading}
                min={1}
                value={perPage}
                onChange={(e) => {
                  if (Number(e.target.value) > 100) {
                    toast.error(t("Entry should not be greater than 100"), {
                      position: "bottom-center",
                      autoClose: 3000,
                    });
                    return;
                  }
                  if (e.target.value == 0) {
                    onLimitChangeHandler(e.target.value);
                  } else {
                    onLimitChangeHandler(e.target.value);
                  }
                }}
              />
              <span>{t('Show')}</span>
            </div>
            <div className="d-flex gap-2">
              <div className="mb-3">
                <button
                  className={`btn btn-${logType == "activity_log" ? "primary" : "secondary"
                    }`}
                  onClick={() => logTypeChangeHandler("activity_log")}
                >
                  {t('Activity Log')}
                </button>
              </div>
              <div className="mb-3">
                <button
                  className={`btn btn-${logType == "session_log" ? "primary" : "secondary"}`}
                  onClick={() => logTypeChangeHandler("session_log")}
                >
                  {t('Session Log')}
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12 user_detail d-flex gap-2">
            <div>
              <span>{userData?.name}</span>
              <b> : {t('User Name')} </b>
            </div>
            <div>
              <span>{userData?.department[0]?.department_name ?? ""}</span>
              <b> : {t('Department')}</b>
            </div>
          </div>
          {logType == "activity_log" ? <ActivityLogData /> : <SessionLogData />}
          <div className="row mb-5 pb-5">
            <div className="footer__btns mb-5">
              <div className="back-btns">
                <button
                  className="btn btn-outline"
                  onClick={() => navigate("/admin/users")}
                >
                  <img
                    src="/assets/icons/Back.svg"
                    alt=""
                    style={{ marginLeft: "7px" }}
                  />
                  {t('Back')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default UsersLog;