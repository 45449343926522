import moment from "moment";

export const modulePermission = () => {
    const permission = localStorage.getItem('permission');
    return permission;
};

export const trackInactivityAndLogout = () => {
    const MAX_INACTIVE_TIME = 1 //in hour

    if (localStorage.getItem("last_activity")) {
        var start = moment(localStorage.getItem("last_activity")); 
        var end = moment(new Date()); 
        var duration = moment.duration(end.diff(start));
        var hours = duration.asHours();
        if (hours < MAX_INACTIVE_TIME) {
            return false
        } else {
            return true
        }
    } else {
        return true
    }
}

export const getUserName = () => {
    let user = JSON.parse(localStorage.getItem("user"))
    return `${user?.firstname} ${user?.lastname}`
}