import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useTrans from '../../utils/useTrans';

const ConfirmationDialog = ({ show, handleClose, title, modalHeader, description, handleSubmit, submitLoading }) => {

    const { t } = useTrans()

    return (
        <>
            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{description}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit} disabled={submitLoading}>
                        {submitLoading ? <Spinner animation="border" role="status" size='sm'></Spinner>: 'Confirm'}
                    </Button>
                </Modal.Footer>
            </Modal> */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    {modalHeader && <p className='modal-head'>{modalHeader}</p>}
                    <div className="actions__modals">
                        <h2
                            className="mb-3"
                            style={{ color: "#474747", textAlign: "right" }}
                        >
                            {title}
                        </h2>
                        <Modal.Body className='p-0'><h6 style={{ textAlign: "right" }}>{description}</h6></Modal.Body>
                        <div className="footer__btns">
                            <button
                                className="btn btn-confirm"
                                onClick={handleClose}
                            >
                                {t('Cancel')}
                            </button>
                            <button
                                className="btn btn-confirm"
                                onClick={handleSubmit}
                                disabled={submitLoading}
                                style={{ height: 41.2 }}
                            >
                                {submitLoading ?
                                    <Spinner animation="border" role="status" size='sm'></Spinner> :
                                    t('Confirm')
                                }
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ConfirmationDialog