import React, { useState } from 'react'
import './PaginationSearch.scss'

export default function PaginationSearch() {

  const [entries, setEntries] = useState(10)

  return (
    <div id='PaginationSearch'>
      <div className='pages'>
        <button>Show</button>
        <input type="number" value={entries} onChange={(e) => setEntries(e.target.value)} min='1' />
        <label htmlFor="">entries</label>
      </div>
      <div className='search'>
        <i><img src="/assets/icons/Search1.svg" alt="" /></i>
        <input type="text" placeholder='Generic Search' />
      </div>
    </div>
  )
}
