import { cloneElement } from "react";
import { PERMISSIONS } from "./permissions-maps";
import useGetRole from "./useGetRole";

const hasPermission = ({ permittedUsers, role }) => {
  return permittedUsers.some(item => item === role);
};

export default function PermissionsGate({
  children,
  permittedUsers = []
}) {
  const { role } = useGetRole();

  const permissionGranted = hasPermission({ permittedUsers, role });

  if (!permissionGranted)
    return
    
  return <>{children}</>;
}