import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './NavItemsListing.scss'
import { ADMIN, INCIDENT_MANAGER, POOL_ADMIN, POOL_USER, SUPER_ADMIN, USER, VIEWER } from '../../constants/configuration'
import useGetRole from '../../utils/useGetRole'
import { useTranslation } from 'react-i18next'
import useTrans from '../../utils/useTrans'

export default function NavItemsListing({ selected, setSelected }) {

  const { role } = useGetRole()

  const location = useLocation()
  const { t } = useTrans()

  const navItems = [
    {
      id: 1,
      item: 'Dashboard',
      icon: 'dashboard',
      path: '',
      // roles: [SUPER_ADMIN, ADMIN, INCIDENT_MANAGER, POOL_ADMIN, POOL_USER, USER, VIEWER]
      roles: []
    },
    // {
    //   id: 2,
    //   item: 'Human Resource',
    //   icon: 'human_resource',
    //   path: 'human_resource/certificate_requests',
    //   subItems: [
    //     {
    //       id: 2.1,
    //       item: 'Certificate Requests',
    //       icon: 'certificate_requests',
    //       path: 'human_resource/certificate_requests'
    //     },
    //     {
    //       id: 2.2,
    //       item: 'Educational Allowance',
    //       icon: 'educational_allowance',
    //       path: 'human_resource/educational_allowance'
    //     },
    //     {
    //       id: 2.3,
    //       item: 'Deputation Allowance',
    //       icon: 'deputation_allowance',
    //       path: 'human_resource/deputation_allowance'
    //     },
    //   ]
    // },
    // {
    //   id: 3,
    //   item: 'Report',
    //   icon: 'report',
    //   path: 'report/certificate_requests',
    //   subItems: [
    //     {
    //       id: 3.1,
    //       item: 'Certificate Requests',
    //       icon: 'certificate_requests',
    //       path: 'report/certificate_requests'
    //     },
    //     {
    //       id: 3.2,
    //       item: 'Educational Allowance',
    //       icon: 'educational_allowance',
    //       path: 'report/educational_allowance'
    //     },
    //     {
    //       id: 3.3,
    //       item: 'Deputation Allowance',
    //       icon: 'deputation_allowance',
    //       path: 'report/deputation_allowance'
    //     },
    //   ]
    // },
    {
      id: 4,
      item: 'Incident',
      icon: 'manage incidents',
      path: role !== VIEWER && 'incidents/incident_requests',
      // path: 'incidents/incident_requests',
      roles: [SUPER_ADMIN, VIEWER],
      subItems: [
        {
          id: 4.1,
          item: 'Incident Dashboard',
          icon: 'incidentsdashboard',
          path: 'incidents/dashboard',
          roles: [VIEWER],
        },
        {
          id: 4.2,
          item: 'Manage Incidents',
          icon: 'manageincidents',
          path: 'incidents/incident_requests',
          roles: [SUPER_ADMIN],
        },
        {
          id: 4.4,
          item: 'Settings',
          icon: 'incidentsettings',
          path: 'incidents/settings',
          roles: [SUPER_ADMIN],
        },
        {
          id: 4.5,
          item: 'Roles',
          icon: 'users',
          path: 'incidents/roles',
          roles: [SUPER_ADMIN],
        },
        // {
        //   id: 4.6,
        //   item: 'Incident Report',
        //   icon: 'incidentreport',
        //   // path: 'incidents/incident_report',
        //   roles: [SUPER_ADMIN],
        // },
        {
          id: 4.7,
          item: 'SMS Report',
          icon: 'smsreport',
          path: 'incidents/sms_report',
          roles: [SUPER_ADMIN],
        },
        {
          id: 4.8,
          item: 'Translation',
          icon: 'language',
          path: 'incidents/translation',
          roles: [SUPER_ADMIN],
        }
      ]
    },
    {
      id: 5,
      item: 'Manage Users',
      icon: 'manageusers',
      path: 'users',
      iconStyle: { height: "14px" },
      roles: [SUPER_ADMIN],
      subItems: [
        {
          id: 5.1,
          item: 'Users',
          icon: 'User',
          path: 'users',
          iconStyle: { height: "14px" },
          roles: [SUPER_ADMIN],
        },
      ]
    },
    {
      id: 6,
      item: 'Manage Incidents',
      icon: 'manage incidents',
      path: 'incidents/incident_requests',
      roles: [ADMIN, INCIDENT_MANAGER, POOL_ADMIN, POOL_USER],
      subItems: [
        {
          id: 6.1,
          item: 'Incident Requests',
          icon: 'manageincidents',
          // icon: 'certificate_requests',
          path: 'incidents/incident_requests',
          roles: [ADMIN, INCIDENT_MANAGER, POOL_ADMIN, POOL_USER],
        },
      ],
      adminOnly: true
    },
    {
      id: 7,
      item: 'Settings',
      icon: 'settings',
      path: 'incidents/roles',
      roles: [ADMIN, INCIDENT_MANAGER,],
      subItems: [
        {
          id: 7.1,
          item: 'Incident Type',
          icon: 'IncidentType',
          path: 'incident_type',
          roles: [],
        },
        {
          id: 7.2,
          item: 'Users',
          icon: 'users',
          path: 'users',
          roles: [],
        },
        {
          id: 7.3,
          item: 'Roles',
          icon: 'users',
          path: 'incidents/roles',
          roles: [ADMIN, INCIDENT_MANAGER],
        },
      ]
    },
    // {
    //   id: 8,
    //   item: 'Report',
    //   icon: 'report',
    //   path: 'report',
    //   roles: [ADMIN, INCIDENT_MANAGER, POOL_ADMIN, POOL_USER],
    //   subItems: [],
    //   adminOnly: true
    // },
  ]


  useEffect(() => {
    let [val1, val2] = pathSetter()
    setSelected({ item: val1, subItem: val2 })
  }, [location])

  function pathSetter() {
    let userRole = localStorage.getItem('user_type')
    let path = location.pathname.replace('/admin', '')
    if (path.includes('incidents/incident_details')) { path = '/incidents/incident_details' }
    if (path.includes('/users/user-log')) { path = '/users/user-log' }

    switch (path) {
      case '/': return [1, 1.1]
      case '/incidents/dashboard': return [4, 4.1]
      case '/incidents/incident_requests': 
      case '/incidents/incident_details':
      case '/incidents/add_incident': return [SUPER_ADMIN,VIEWER].includes(userRole) ? [4, 4.2] : [6, 6.1]
      case '/incidents/incident_type': return [4, 4.3]
      case '/incidents/settings': return [4, 4.4]
      case '/incidents/manage_users':
      case '/incidents/roles': return [INCIDENT_MANAGER, ADMIN].includes(userRole) ? [7, 7.3] : [4, 4.5]
      case '/incidents/incident_report': return [4, 4.6]
      case '/incidents/sms_report': return [4, 4.7]
      case '/incidents/translation': return [4, 4.8]
      case '/settings/incident_type': return [7, 7.1]
      case '/users/user-log':
      case '/users': return [INCIDENT_MANAGER, ADMIN].includes(userRole) ? [7, 7.2] : [5, 5.1]
      case '/report': return [8, 8.1]
      default: return [1, 1.1]
    }
  }


  const filteredMenuItems = navItems.filter(item => {
    // if (item.roles.includes(role)) {
    // console.log(item, !(!(item.subItems)), 'itelkjalskdjflkj')
    // if (item.subItems) {
    //   if (item.subItems.filter(sub => sub.roles?.includes(role)).length) {
    //     return true
    //   } else { return false }
    // } else { return true }
    // } else { return false }
    const path = location.pathname;
    const isAdmin = path.split("/")[1] === "admin"
    if (isAdmin) {
      return item.roles.includes(role)
    } else {
      if (item.adminOnly !== true) {
        return item.roles.includes(role)
      }
    }
  });

  // console.log({test: filteredMenuItems.length && filteredMenuItems[1].subItems?.filter(sub => sub?.roles?.includes(role))})


  return (
    <div id='NavItemsListing'>
      <ul>
        {
          filteredMenuItems.map((item, i) => (
            <li className='item' key={i}>
              <span className={'item-span' + (selected.item === item.id ? ' selected' : '')} onClick={() => item.path && setSelected({ item: item.id, subItem: item.id + 0.1 })}>
                <Link to={item.path && item.path}>
                  <i><img style={item.iconStyle} src={`/assets/icons/${item.icon}.svg`} alt="" /></i>
                  <p>{t(item.item)}</p>
                </Link>
              </span>
              {
                <ul className={(item.subItems && selected.item === item.id ? '' : 'hide') + ' animation fadeInUp animated'}>
                  {item?.subItems?.filter(sub => sub?.roles?.includes(role))?.map((subItem, si) => (
                    <li key={si} className='sub-item' onClick={() => subItem.path && setSelected((s) => ({ ...s, subItem: subItem.id }))}>
                      <Link to={subItem.path}>
                        {/* <i><img style={subItem.iconStyle} src={`/assets/icons/${subItem.icon}.svg`} alt="" /></i> */}
                        <p className={(selected.subItem === subItem.id ? 'selected' : '')}>{t(subItem.item)}</p>
                      </Link>
                    </li>
                  ))}
                </ul>
              }
            </li>
          ))
        }
      </ul>
    </div>
  )
}