import React from 'react'
import './AttachmentsPreview.scss'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PreviewData from "../../Preview.json";
import { useEffect } from 'react';
import { API_BASE_URL } from '../../constants/configuration';
import { incident_path } from '../../redux/incidentReducer';
import { useSelector } from 'react-redux';
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";

export default function AttachmentsPreview({ data = {} }) {
  let sliderLength = 4

  const url = useSelector(incident_path);

  const [highlightedFiles, setHighlightedFiles] = useState([])
  const [slicer, setSlicer] = useState({ from: 0, to: sliderLength, preview: 0 });
  const [current, setCurrent] = useState(0)
  const [files, setFiles] = useState([])

  useEffect(() => {
    // console.log(!(!data.endpoints), data, 'datomlksdjflsjadl')
    setFiles(data.endpoints ? data.endpoints.map(endpoint => ({ file: data.path + endpoint, endpoint: endpoint })) : [])
    // setFiles(PreviewData)
    return () => {
      // console.log('unmounting')
      setFiles([])
      setCurrent(0)
      setHighlightedFiles([])
    }
  }, [PreviewData, data])

  useEffect(() => {
    setHighlightedFiles(files.slice(0, sliderLength))
  }, [files])

  function sliceMover(value) {
    if (!(value === 1 && current === sliderLength - 1) && !(value === -1 && current === 0)) {
      setCurrent(current + value)
    } else if (!(value === -1 && slicer.from === 0) && !(value === 1 && slicer.to === files.length + 3)) {
      setSlicer({ from: slicer.from + value, to: slicer.to + value, preview: slicer.preview + value, })
      setHighlightedFiles(files.slice(slicer.from + value, slicer.to + value))
    }
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.label}
    </Tooltip>
  );

  function downloadHandler(file, name) {
    fetch(file)
      .then((response) => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = name
        document.body.appendChild(a);
        a.click();
      })
      .catch(err => console.log({ err }))
  }

  // console.log(highlightedFiles, 'files')

  return (
    <div id='AttachmentsPreview'>
      <div id="ViewAttachmentModal" className="modal-item">
        {highlightedFiles?.[current]?.file?.includes(".jpg") ? (
          <img
            className="preview"
            src={highlightedFiles?.[current]?.file}
            alt=""
          />
        ) : highlightedFiles?.[current]?.file?.includes(".jpeg") ? (
          <img
            className="preview"
            src={highlightedFiles?.[current]?.file}
            alt=""
          />
        ) : highlightedFiles?.[current]?.file?.includes(".png") ? (
          <img
            className="preview"
            src={highlightedFiles?.[current]?.file}
            alt=""
          />
        ) : highlightedFiles?.[current]?.file?.includes(".pdf") ? (
          <div style={{ height: '300px', width: '500px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
            <Link to={highlightedFiles?.[current]?.file} target="blank">
              <img style={{ height: '100px' }} src="/assets/icons/files/pdf.svg" alt="" />
            </Link>
            <p>{highlightedFiles?.[current]?.endpoint}</p>
          </div>
        ) : highlightedFiles?.[current]?.file?.includes(".docx") ? (
          <div style={{ height: '300px', width: '500px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
            {/* <Link to={highlightedFiles?.[current]?.file} target="blank"><button>open document</button></Link> */}
            <img style={{ height: '100px' }} src="/assets/icons/files/docx.svg" alt="" />
            <p>{highlightedFiles?.[current]?.endpoint}</p>
          </div>
        ) : highlightedFiles?.[current]?.file?.includes(".doc") ? (
          <div style={{ height: '300px', width: '500px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
            {/* <Link to={highlightedFiles?.[current]?.file} target="blank"><button>open document</button></Link> */}
            <img style={{ height: '100px' }} src="/assets/icons/files/doc.svg" alt="" />
            <p>{highlightedFiles?.[current]?.endpoint}</p>
          </div>
        ) : highlightedFiles?.[current]?.file?.includes(".mp4") ? (<video controls src={highlightedFiles?.[current]?.file} />)
          : (highlightedFiles?.[current]?.file?.includes(".MOV") || highlightedFiles?.[current]?.file?.includes(".mov")) && <video controls src={highlightedFiles?.[current]?.file} />
        }
        <section className="slider">
          <i className="arrow right">
            {files[slicer.preview - 1] && (
              <img
                src="/assets/icons/Icon ionic-ios-arrow-down white.svg"
                onClick={() => sliceMover(-1)}
                alt=""
              />
            )}
          </i>
          <div className="files">
            {files.slice(slicer.from, slicer.to).map((file, i) => {
              return (
                <span onClick={() => setCurrent(i)} key={i} className={i === current ? "preview" : ""}>
                  <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip({ label: file.endpoint.replace(' ', '') })} >
                    {
                      file.file?.includes(".jpg") ? (<img src={file.file} alt="" />)
                        : file.file?.includes(".jpeg") ? (<img src={file.file} alt="" />)
                          : file.file?.includes(".png") ? (<img src={file.file} alt="" />)
                            : file.file?.includes(".pdf") ? <img src="/assets/icons/files/pdf.svg" alt="" />
                              : file.file?.includes(".docx") ? <img src="/assets/icons/files/docx.svg" alt="" />
                                : file.file?.includes(".doc") ? <img src="/assets/icons/files/doc.svg" alt="" />
                                  : file.file?.includes(".mp4") ? <img src="/assets/icons/files/mp4.svg" alt="" />
                                    : file.file?.includes(".MOV") ? <img src="/assets/icons/files/mov.svg" alt="" />
                                      : file.file?.includes(".mov") ? <img src="/assets/icons/files/mov.svg" alt="" />
                                        : null
                    }
                  </OverlayTrigger>
                </span>
              );
            })}
          </div>
          <i className="arrow left">
            {files[slicer.preview + 4] && (
              <img
                src="/assets/icons/Icon ionic-ios-arrow-down white.svg"
                onClick={() => sliceMover(1)}
                alt=""
              />
            )}
          </i>
        </section>
        <button className='download'
          style={{ display: 'flex', gap: '10px', alignItems: 'center', padding: '3px 13px' }}
          onClick={() => downloadHandler(highlightedFiles?.[current]?.file, highlightedFiles?.[current]?.endpoint)}>
          <img style={{ height: '18px' }} src="/assets/icons/Download2.svg" alt="" />
          Download
        </button>
      </div>
    </div >
  )
}