import React, { useEffect, useState } from "react";
import "../style.scss";
import "../ManageIncidents/incidents.scss";
import { Form, Spinner, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  log_from_date,
  log_list_loading,
  log_page,
  log_per_page,
  log_sort_ascending,
  log_sort_column,
  log_to_date,
  session_log_list,
  session_log_page_status,
  session_log_total_count,
  updateRedux,
} from "../../../redux/commonReducer";
import { getUserSessionLog } from "../../../redux/commonActionCreator";
import Pagination from "../../../components/Pagination/Pagination";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import moment from "moment";
import useTrans from "../../../utils/useTrans";

const SessionLogData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTrans()

  const data = useSelector(session_log_list);
  const loading = useSelector(log_list_loading);
  const pageCount = useSelector(session_log_total_count);
  const pageStatus = useSelector(session_log_page_status);
  const sortColumn = useSelector(log_sort_column);
  const sortAscending = useSelector(log_sort_ascending);
  const page = useSelector(log_page);
  const fromDate = useSelector(log_from_date);
  const toDate = useSelector(log_to_date);
  const perPage = useSelector(log_per_page);

  useEffect(() => {
    if (id) {
      let value = {
        userId: id,
        page: 0,
        from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
        to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
        sort_key: sortColumn,
        sort_order: sortAscending ? "1" : "-1",
        perPage: perPage,
      };
      dispatch(getUserSessionLog(value));
    }
  }, [id]);

  const tableHeaders = [
    { name: t("Date"), sort_key: "login_date" },
    { name: t("Login Time"), sort_key: "login_time" },
    { name: t("Logout Time"), sort_key: "logout_time" },
    { name: t("Duration"), sort_key: "" },
    // { name: "Action", sort_key: "action" },
  ];

  const getSortingClassName = (title) => {
    let className = "";
    if (sortColumn === title) {
      if (sortAscending) {
        className = "active";
      } else {
        className = "inactive";
      }
    }
    return className;
  };

  const handlePageChange = (e) => {
    dispatch(updateRedux({ key: "log_page", value: e.selected }));
    let value = {
      userId: id,
      page: e.selected,
      from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
      to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
      sort_key: sortColumn,
      sort_order: sortAscending ? "1" : "-1",
      perPage: perPage,
    };
    dispatch(getUserSessionLog(value));
  };

  const onSortHandler = (type) => {
    dispatch(updateRedux({ key: "log_sort_ascending", value: !sortAscending }));
    dispatch(updateRedux({ key: "log_sort_column", value: type }));
    let value = {
      userId: id,
      page: page,
      from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
      to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
      sort_key: type,
      sort_order: !sortAscending ? "1" : "-1",
      perPage: perPage,
    };
    dispatch(getUserSessionLog(value));
  };

  return (
    <>
      <section className="common__table pt-0">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Table bordered hover>
              <thead>
                <tr>
                  {tableHeaders.map((header) => (
                    <th
                      className={
                        header.sort_key != ""
                          ? `sorting cursor-pointer ${getSortingClassName(
                            header.sort_key
                          )}`
                          : ""
                      }
                      onClick={() => onSortHandler(header.sort_key)}
                    >
                      {header.name}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {data?.map((ele, i) => {
                  return (
                    <tr>
                      <td style={{ textAlign: "right" }}>
                        <span dir="ltr">
                          {moment(ele?.login_time).format("DD MMM YYYY") ?? ""}
                        </span>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <span dir="ltr">
                          {moment(ele?.login_time?.split(".")[0]).format(
                            "hh:mm:ss a"
                          ) ?? ""}
                        </span>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <span dir="ltr">
                          {ele?.logout_time
                            ? moment(ele?.logout_time?.split(".")[0]).format(
                                "hh:mm:ss a"
                              )
                            : "-"}
                        </span>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <span dir="ltr">{ele?.duration?.length ? ele?.duration : ele?.logout_time ? "0 Secs" : "-"}</span>
                      </td>
                      {/* <td>{ele.action}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            {data?.length === 0 && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h6>{t('No Data')}</h6>
              </div>
            )}
          </>
        )}
      </section>
      {!loading && !!data?.length && (
        <div
          className="align-between"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p style={{ direction: "ltr", color: "#0d7b58" }}>{pageStatus}</p>
          <Pagination
            page={page}
            pageCount={pageCount}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default SessionLogData;
