import { useEffect } from "react";
import { Form, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  commonSlice,
  department_details,
  incident_list_page_status,
  normal_users_list,
  normal_users_list_count,
  normal_users_list_page_count,
  pool_normal_users_list_loading,
  section_details,
  updateRedux,
  user_list,
} from "../../../redux/commonReducer";
import {
  getAllNormalUsers,
  getAllUsers,
  getDepartment,
  getSearchAllNormalUsers,
  getSection,
} from "../../../redux/commonActionCreator";
import { useState } from "react";
import {
  addIncidentManager,
  addPoolAdmin,
  addPoolPrivilegeToUsers,
  addPoolPrivillageToUsers,
  addPoolUser,
  getSerachUsers,
} from "../../../redux/incidentActionCreator";
import {
  add_user_privilege_loading,
  dynamic_text_list,
  poolAdmin_list,
  poolUser_list,
} from "../../../redux/incidentReducer";
import { toast } from "react-toastify";
import Pagination from "../../../components/Pagination/Pagination";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { Link, useNavigate } from "react-router-dom";
import PermissionsGate from "../../../utils/PermissionsGate";
import {
  ADMIN,
  INCIDENT_MANAGER,
  POOL_ADMIN,
  SUPER_ADMIN,
} from "../../../constants/configuration";
// import translate from '../../../utils/translationUtils'
import { useTranslation } from 'react-i18next';
import { translate } from '../../../utils/translate';
import useTrans from '../../../utils/useTrans';

function ManageRoles() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTrans()

  const departmentLists = useSelector(department_details);
  const sectionLists = useSelector(section_details);
  const pooluserList = useSelector(poolUser_list);
  const poolAdminList = useSelector(poolAdmin_list);
  const allUsersList = useSelector(normal_users_list);
  const poolNormalUersCount = useSelector(normal_users_list_count);
  const loading = useSelector(pool_normal_users_list_loading);
  const addUserSubmitLoading = useSelector(add_user_privilege_loading);
  const pageCount = useSelector(normal_users_list_page_count);
  const pageStatus = useSelector(incident_list_page_status);
  const translateDetails = useSelector(dynamic_text_list);

  const [department, setDepartment] = useState("");
  const [section, setSection] = useState("");
  const [pollUsers, setpollUsers] = useState([]);
  const [entry, setEntry] = useState(10);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState("");

  const [users, setUsers] = useState({
    admin: [],
    incidentManager: [],
    poolAdmin: [],
    poolUser: [],
    viewer: []
  });

  useEffect(() => {
    if (department) {
      dispatch(getSection(department?._id));
    }
  }, [department]);

  useEffect(() => {
    dispatch(getDepartment());
    let formData = new FormData();
    formData.append("page", 0);
    formData.append("perPage", entry);
    formData.append("keyword", "");
    dispatch(getAllNormalUsers(formData));
  }, []);

  const clearFilter = () => {
    setDepartment("");
    setSection("");
    setKeyword("");
    let formData = new FormData();
    formData.append("page", 0);
    formData.append("perPage", entry);
    formData.append("keyword", "");
    dispatch(getAllNormalUsers(formData));
    dispatch(updateRedux({ key: "section_details", value: [] }))
  };

  const searchUsers = () => {
    let formData = new FormData();
    formData.append("page", page);
    formData.append("perPage", entry);
    formData.append("keyword", keyword);
    department && formData.append("department", department._id);
    section && formData.append("section", section._id);
    dispatch(getAllNormalUsers(formData));
  };

  const handleCheckboxChange = (e, item) => {
    console.log(e.target.value);
    const value = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      setpollUsers([...pollUsers, item]);
    } else {
      setpollUsers(pollUsers.filter((obj) => obj !== item));
    }
  };

  const addUser = (userType) => {
    if (pollUsers.length === 0) {
      toast.error(t("Please choose atleast one user"), {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
    switch (userType) {
      case "admin":
        setUsers({ ...users, admin: users.admin.concat(pollUsers) });
        break;
      case "pool_user":
        setUsers({ ...users, poolUser: users.poolUser.concat(pollUsers) });
        break;
      case "pool_admin":
        setUsers({ ...users, poolAdmin: users.poolAdmin.concat(pollUsers) });
        break;
      case "incident_manager":
        setUsers({
          ...users,
          incidentManager: users.incidentManager.concat(pollUsers),
        });
      case "viewer":
        setUsers({
          ...users,
          viewer: users.viewer.concat(pollUsers),
        });
        break;
    }
    setpollUsers([]);
  };

  const addPollUsers = () => {
    if (pollUsers.length) {
      let formData = new FormData();
      pollUsers?.forEach((ele) => formData.append("id[]", ele));
      dispatch(addPoolUser(formData));
    } else {
      toast.error("Please select user", {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  };

  const addPollAdmin = () => {
    let formData = new FormData();
    pollUsers?.forEach((ele) => formData.append("id[]", ele));
    dispatch(addPoolAdmin(formData));
  };

  const addAsPoolPrivilegeUser = () => {
    // if (pollUsers.length === 0) {
    //   toast.error('No users selected', { position: "bottom-center", autoClose: 3000, })
    //   return
    // }
    if (users.admin.length === 0 && users.incidentManager.length === 0 && users.poolAdmin.length === 0 && users.poolUser.length === 0 && users.viewer.length === 0) {
      toast.error(t('Users not added to any role'), { position: "bottom-center", autoClose: 3000, })
      return
    }
    let formData = new FormData();
    users.poolAdmin.forEach((ele, index) =>
      formData.append(`pool_admin_ids[${index}]`, ele._id)
    );
    users.poolUser.forEach((ele, index) =>
      formData.append(`pool_user_ids[${index}]`, ele._id)
    );
    users.admin.forEach((ele, index) =>
      formData.append(`admin_ids[${index}]`, ele._id)
    );
    users.incidentManager.forEach((ele, index) =>
      formData.append(`incident_manager_ids[${index}]`, ele._id)
    );
    users.viewer.forEach((ele, index) =>
      formData.append(`dashboard_view_users[${index}]`, ele._id)
    );
    dispatch(addPoolPrivilegeToUsers(formData, navigate, ({ status, message }) => {
      status && toast.success(t(message), {
        position: "bottom-center",
        autoClose: 3000
      })
      !status && toast.error(t(message), {
        position: "bottom-center",
        autoClose: 3000
      })
    }));
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    let formData = new FormData();
    formData.append("page", e.selected);
    formData.append("perPage", entry);
    formData.append("keyword", keyword);
    department && formData.append("department", department._id);
    section && formData.append("section", section._id);
    dispatch(getAllNormalUsers(formData));
  };

  const entryChange = (value) => {
    let formData = new FormData();
    formData.append("page", 0);
    console.log({ entry });
    formData.append("perPage", value);
    formData.append("keyword", keyword);
    department && formData.append("department", department._id);
    section && formData.append("section", section._id);
    dispatch(getAllNormalUsers(formData));
    setPage(0);
  };

  const removePoolAdmin = (id) => {
    setUsers({
      ...users,
      poolAdmin: users.poolAdmin.filter((item) => item._id !== id),
    });
  };

  const removePoolUser = (id) => {
    setUsers({
      ...users,
      poolUser: users.poolUser.filter((item) => item._id !== id),
    });
  };

  const removeIncidentManager = (id) => {
    setUsers({
      ...users,
      incidentManager: users.incidentManager.filter((item) => item._id !== id),
    });
  };

  const removeAdmin = (id) => {
    setUsers({
      ...users,
      admin: users.admin.filter((item) => item._id !== id),
    });
  };

  const removeViewer = (id) => {
    setUsers({
      ...users,
      viewer: users.viewer.filter((item) => item._id !== id),
    });
  };

  const checkDisabled = (id) => {
    let flag = false;
    if (users.poolAdmin.some((item) => item._id === id)) {
      flag = "Pool Admin";
    }
    if (users.poolUser.some((item) => item._id === id)) {
      flag = "Pool User";
    }
    if (users.admin.some((item) => item._id === id)) {
      flag = "Admin";
    }
    if (users.incidentManager.some((item) => item._id === id)) {
      flag = "Incident Manager";
    }
    if (users.viewer.some((item) => item._id === id)) {
      flag = "Viewer";
    }
    return flag;
  };

  const clearAll = () => {
    setUsers({
      admin: [],
      incidentManager: [],
      poolAdmin: [],
      poolUser: [],
      viewer: []
    });
    setpollUsers([]);
  };

  const handleEntry = (e) => {
    // if (e.target.value === '') return
    // if (e.target.value == 0 || !e.target.value) {
    //   toast.error(t("Please choose a valid entry"), {
    //     position: "bottom-center",
    //     autoClose: 3000,
    //   });
    //   return;
    // }
    if (Number(e.target.value) > 100) {
      toast.error(t("Entry should not be greater than 100"), {
        position: "bottom-center",
        autoClose: 3000,
      });
      return;
    }
    setEntry(e.target.value);
    entryChange(e.target.value);
  };

  const formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const tableHeaders = [
    { name: t("Employee No") },
    { name: t("Employee Name") },
    { name: t("Department") },
    { name: t("Section") },
    { name: "" },
  ];

  const selectedTableHeaders = [
    { name: t("Employee No") },
    { name: t("Employee Name") },
    { name: t("Department") },
    { name: t("Section") },
    { name: t("Action") },
  ];

  const translateText = (text) => {
    const selectedLanguage = localStorage.getItem("lang");
    let key = "english_text";
    if (selectedLanguage && selectedLanguage === "ar") {
      key = "arabic_text";
    }

    if (translateDetails) {
      return translateDetails?.filter((ele) => ele.title == text)?.length
        ? translateDetails?.find((ele) => ele.title == text)[key]
        : text;
    }
    return text;
  };

  console.log({ pollUsers })

  return (
    <>
      <div className="addusers request__details">
        <div className="container-fluid">
          <div className="section__header">
            <div className="breadcrumbs">
              <h1>{t("Manage User Roles")}</h1>
              <p>
                <Link to={"/admin/incidents/incident_requests"}>{t('Home')}</Link> /{" "}
                <Link to={"/admin/incidents/roles"}>{t('User Roles')}</Link> /{" "}
                {t('Manage')}
              </p>
              {/* <p>
                {t("Home")} /{t("Settings")} /{" "}
                {t("User Roles")}
              </p> */}
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="card-header">
                <h4>{t('Add User Roles')}</h4>
              </div>
              <div className="row align-items-end p-4 manage-roles-select">
                <div className="col-md-3">
                  <div className="form-group">
                    <Select
                      name="colors"
                      options={departmentLists}
                      getOptionLabel={(option) => option.department_name}
                      getOptionValue={(option) => option._id}
                      value={department}
                      onChange={(e) => setDepartment(e)}
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder={t("Select Department")}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <Select
                      name="colors"
                      options={department ? sectionLists : []}
                      getOptionLabel={(option) => option.section_name}
                      getOptionValue={(option) => option._id}
                      value={section}
                      onChange={(e) => setSection(e)}
                      className="basic-single border-0"
                      classNamePrefix="select"
                      placeholder={t("Select Section")}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <Form.Control
                    type="text"
                    placeholder={t("Search")}
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    style={{ background: '#ececec', height: 46 }}
                  />
                </div>
                <div className="col">
                  <button
                    className="btn btn-primary btn-search"
                    onClick={searchUsers}
                  >
                    {t("Search")}
                  </button>
                </div>
                {department || section || keyword ? (
                  <div className="col">
                    <div className="position-relative">
                      <button
                        className="btn btn-primary btn-clear"
                        onClick={clearFilter}
                        style={{
                          height: '45px',
                          borderRadius: '4px'
                        }}
                      >
                        <img src="./assets/icons/clear.svg" alt="X" />
                      </button>
                    </div>
                  </div>
                ) : null}
                <div
                  className="search__options col-md-3 mt-4"
                  style={{ marginBottom: 0, justifyContent: 'end' }}
                >
                  <div className="show">
                    <span>{t("Entries")}</span>
                    <Form.Control
                      // disabled={loading}
                      min={1}
                      type="number"
                      onKeyDown={formatInput}
                      value={entry}
                      onChange={handleEntry}
                    />
                    <span>{t("Show")}</span>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <Table bordered hover>
                      <thead>
                        <tr>
                          {tableHeaders.map((item) => (
                            <th>{item.name}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {allUsersList?.map((item, index) => (
                          <tr>
                            <td>{item.employee_id}</td>
                            <td>{item.name}</td>
                            <td>
                              {item.department.length
                                ? item.department[0].department_name
                                : "-"}
                            </td>
                            <td>
                              {item.section.length
                                ? item.section[0].section_name
                                : "-"}
                            </td>
                            {checkDisabled(item._id) ? (
                              <td>
                                {t("Added as")}{" "}
                                {checkDisabled(item._id)}
                              </td>
                            ) : (
                              <td>
                                <Form.Check
                                  checked={pollUsers.some(
                                    (obj) => obj._id === item._id
                                  )}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, item)
                                  }
                                />
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {allUsersList?.length === 0 && (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: '200px',
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h6>{t("No Data")}</h6>
                      </div>
                    )}

                    {!loading && !!allUsersList?.length && (
                      <div
                        className="align-between"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ direction: "ltr", color: "#0d7b58" }}>
                          {pageStatus}
                        </p>
                        <Pagination
                          page={page}
                          pageCount={pageCount}
                          handlePageChange={handlePageChange}
                        />
                      </div>
                    )}
                  </>
                )}

                <div className="row" style={{ marginTop: 50 }}>
                  <div className="footer__btns justify-content-end mt-0">
                    <div className="btn-actions">
                      <PermissionsGate permittedUsers={[SUPER_ADMIN]}>
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => addUser("viewer")}
                        >
                          {t("Add Dashboard Viewer")}
                        </button>
                      </PermissionsGate>
                      <PermissionsGate
                        permittedUsers={[SUPER_ADMIN, ADMIN, INCIDENT_MANAGER]}
                      >
                        <button
                          className="btn btn-outline-primary"
                          style={{ marginLeft: "20px" }}
                          onClick={() => addUser("pool_user")}
                        >
                          {t("Add Pool User")}
                        </button>
                      </PermissionsGate>
                      <PermissionsGate
                        permittedUsers={[SUPER_ADMIN, ADMIN, INCIDENT_MANAGER]}
                      >
                        <button
                          className="btn btn-outline-primary"
                          style={{ marginLeft: "20px" }}
                          onClick={() => addUser("pool_admin")}
                        >
                          {t("Add Pool Admin")}
                        </button>
                      </PermissionsGate>
                      {/* <PermissionsGate permittedUsers={[SUPER_ADMIN, ADMIN]}>
                        <button
                          className="btn btn-outline-primary"
                          style={{ marginLeft: "20px" }}
                          onClick={() => addUser("incident_manager")}
                        >
                          {t("Add Incident Manager")}
                        </button>
                      </PermissionsGate> */}
                      <PermissionsGate permittedUsers={[SUPER_ADMIN]}>
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => addUser("admin")}
                        >
                          {t("Add Admin")}
                        </button>
                      </PermissionsGate>
                    </div>
                  </div>
                </div>
              </div>

              {!!users.admin.length && (
                <div className="mt-5">
                  <div className="card-header border-0 mb-3">
                    <h4>{t("Admin")}</h4>
                  </div>
                  <Table bordered hover className="pool-admin">
                    <thead>
                      <tr>
                        {selectedTableHeaders.map((item) => (
                          <th>{item.name}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {users.admin.map((item) => (
                        <tr>
                          <td>{item.employee_id}</td>
                          <td>{item.name}</td>
                          <td>
                            {item.department.length
                              ? item.department[0].department_name
                              : "-"}
                          </td>
                          <td>
                            {item.section.length
                              ? item.section[0].section_name
                              : "-"}
                          </td>
                          <td>
                            <img
                              src="/assets/icons/user_delete.svg"
                              alt=""
                              style={{ cursor: "pointer" }}
                              onClick={() => removeAdmin(item._id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}

              {!!users.incidentManager.length && (
                <div className="mt-5">
                  <div className="card-header border-0 mb-3">
                    <h4>{t("Incident Managers")}</h4>
                  </div>
                  <Table bordered hover className="pool-admin">
                    <thead>
                      <tr>
                        <th>{t("Employee No")}</th>
                        <th>{t("Employee Name")}</th>
                        <th>{t("Department")}</th>
                        <th>{t("Section")}</th>
                        <th>{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.incidentManager.map((item) => (
                        <tr>
                          <td>{item.employee_id}</td>
                          <td>{item.name}</td>
                          <td>
                            {item.department.length
                              ? item.department[0].department_name
                              : "-"}
                          </td>
                          <td>
                            {item.section.length
                              ? item.section[0].section_name
                              : "-"}
                          </td>
                          <td>
                            <img
                              src="/assets/icons/user_delete.svg"
                              alt=""
                              style={{ cursor: "pointer" }}
                              onClick={() => removeIncidentManager(item._id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
              {!!users.poolAdmin.length && (
                <div className="mt-5">
                  <div className="card-header border-0 mb-3">
                    <h4>{t("Pool Admin")}</h4>
                  </div>
                  <Table bordered hover className="pool-admin">
                    <thead>
                      <tr>
                        <th>{t("Employee No")}</th>
                        <th>{t("Employee Name")}</th>
                        <th>{t("Department")}</th>
                        <th>{t("Section")}</th>
                        <th>{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.poolAdmin.map((item) => (
                        <tr>
                          <td>{item.employee_id}</td>
                          <td>{item.name}</td>
                          <td>
                            {item.department.length
                              ? item.department[0].department_name
                              : "-"}
                          </td>
                          <td>
                            {item.section.length
                              ? item.section[0].section_name
                              : "-"}
                          </td>
                          <td>
                            <img
                              src="/assets/icons/user_delete.svg"
                              alt=""
                              style={{ cursor: "pointer" }}
                              onClick={() => removePoolAdmin(item._id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}

              {!!users.poolUser.length && (
                <div className="mt-5">
                  <div className="card-header border-0 mb-3">
                    <h4>{t("Pool Users")}</h4>
                  </div>
                  <Table bordered hover className="pool-admin">
                    <thead>
                      <tr>
                        <th>{t("Employee No")}</th>
                        <th>{t("Employee Name")}</th>
                        <th>{t("Department")}</th>
                        <th>{t("Section")}</th>
                        <th>{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.poolUser.map((item) => (
                        <tr>
                          <td>{item.employee_id}</td>
                          <td>{item.name}</td>
                          <td>
                            {item.department.length
                              ? item.department[0].department_name
                              : "-"}
                          </td>
                          <td>
                            {item.section.length
                              ? item.section[0].section_name
                              : "-"}
                          </td>
                          <td>
                            <img
                              src="/assets/icons/user_delete.svg"
                              alt=""
                              style={{ cursor: "pointer" }}
                              onClick={() => removePoolUser(item._id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}

              {!!users.viewer.length && (
                <div className="mt-5">
                  <div className="card-header border-0 mb-3">
                    <h4>{t("Viewer")}</h4>
                  </div>
                  <Table bordered hover className="pool-admin">
                    <thead>
                      <tr>
                        <th>{t("Employee No")}</th>
                        <th>{t("Employee Name")}</th>
                        <th>{t("Department")}</th>
                        <th>{t("Section")}</th>
                        <th>{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.viewer.map((item) => (
                        <tr>
                          <td>{item.employee_id}</td>
                          <td>{item.name}</td>
                          <td>
                            {item.department.length
                              ? item.department[0].department_name
                              : "-"}
                          </td>
                          <td>
                            {item.section.length
                              ? item.section[0].section_name
                              : "-"}
                          </td>
                          <td>
                            <img
                              src="/assets/icons/user_delete.svg"
                              alt=""
                              style={{ cursor: "pointer" }}
                              onClick={() => removeViewer(item._id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}

              <div className="row mb-5 pb-5">
                <div className="footer__btns mb-5">
                  <div className="back-btns">
                    <Link to={"/admin/incidents/roles"}>
                      <button className="btn btn-outline-primary">
                        {" "}
                        <img src="/assets/icons/Back.svg" alt="" />{" "}
                        {t("Back")}
                      </button>
                    </Link>
                  </div>
                  <div className="btn-actions">
                    <button
                      className="btn btn-outline-primary"
                      onClick={addAsPoolPrivilegeUser}
                      disabled={addUserSubmitLoading}
                    >
                      {addUserSubmitLoading ? (
                        <Spinner
                          animation="border"
                          role="status"
                          size="sm"
                        ></Spinner>
                      ) : (
                        t("Submit")
                      )}
                    </button>
                    <button
                      className="btn btn-outline-primary"
                      onClick={clearAll}
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageRoles;
