import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './style.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';
import "./i18n";
import i18n from "i18next";
import { I18nextProvider } from "react-i18next";

localStorage.setItem('incidentlistingfilter', null)
localStorage.setItem('userlistingfilter', null)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store} >
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
