import React from 'react'
import './HistoryMenu.scss'
import { useState } from 'react'
import { useEffect } from 'react'

export default function HistoryMenu({ Items = [], eventData = [], onClick }) {

    const [items, setItems] = useState([])
    const [selected, setSelected] = useState(0)

    useEffect(() => {
        Items && setItems(Items)
        return () => {
            setItems([])
            setSelected(0)
        }
    }, [Items])

    function selectionHandler(index) {
        setSelected(index)
        onClick && items.length > 0 && onClick({ index: index, item: items[index] })
    }

    return (
        <ul id='HistoryMenu'>
            {items.map((item, i) => {
                return (
                    <li
                        key={i}
                        className={(selected === i ? 'active ' : '') + (item.className || '')}
                        onClick={() => selectionHandler(i)}
                    >
                        {item.title}
                    </li>
                )
            })}
        </ul>
    )
}
