import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import Select from "react-select";
import useGetRole from '../../../utils/useGetRole';
import PermissionsGate from '../../../utils/PermissionsGate';
import { ADMIN, INCIDENT_MANAGER, POOL_ADMIN, POOL_USER, RE_OPEN } from '../../../constants/configuration';
import { toast } from 'react-toastify';
import { customStyles } from '../../../utils/styles';
// import { translate } from "../../../utils/translate";
import useTrans from '../../../utils/useTrans';
import { useSelector } from 'react-redux';
import { incident_task_status_change_loading, poolUser_list_loading, upload_zip_loading } from '../../../redux/incidentReducer';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';

const IncidentModal = ({
    onHide,
    modalType,
    departmentList,
    forwardDept,
    setForwardDept,
    setErr,
    err,
    saveHandler,
    incidentStatusChangeLoading,
    forwardType,
    setForwardType,
    returnComment,
    setReturnComment,
    forwardData,
    setForwardData,
    sectionLists,
    searchUsers,
    assignData,
    setAssignData,
    onDeleteOldAttachment,
    onDeleteNewAttachment,
    userSearchParams,
    setUserSearchParams,
    assignExcludeList,
    tasks,
    forwardedBy,
    currentTaskSubTasks,
    incident_data,
    handleSubmitTask,
    isTaskSelected,
    handleCloseTask,
    receivedTask,
    taskno,
    departmentListHavingUsers,
    sectionListsHavingusers,
    file,
    setFile,
    modalErr,
    onConfirm,
    setShow,
    setModalErr
}) => {

    const { role } = useGetRole()
    const { t } = useTrans()
    const usersLoading = useSelector(poolUser_list_loading)
    const incidentTaskStatusChangeLoading = useSelector(incident_task_status_change_loading)
    const uploadZipLoading = useSelector(upload_zip_loading)

    const forwardToPoolUserValidator = () => {
        let flag = false;
        let error = {}
        if (forwardType === "") {
            toast.error(t('Please choose a forward type'), {
                position: "bottom-center",
                autoClose: 3000
            })
        } else {
            if (forwardType === "Pool User") {
                if (!(forwardData.user)) {
                    error = { ...error, user: t('Please select a user') }
                    flag = false
                } else {
                    flag = true
                }
            }
            if (forwardType === "Other Department") {
                console.log(forwardData)
                if (!(forwardData.department)) {
                    error = { ...error, department: t('Please select a department') }
                    console.log(error)
                    flag = false
                } else {
                    flag = true
                }
            }
            if (forwardType === "Pool Admin" || forwardType === "Admin" || forwardType === "Incident Managers") {
                flag = true
            }
        }
        return { flag, error }
    }

    const assignUserValidator = () => {
        let flag = false;
        let error = {}
        if (!(assignData.type)) {
            toast.error(t('Please choose a assign type'), {
                position: "bottom-center",
                autoClose: 3000
            })
        } else {
            if (assignData.type === "Pool User") {
                if (!(assignData.user)) {
                    error = { ...error, user: t('Please select a user') }
                    flag = false
                } else {
                    flag = true
                }
            }
            if (assignData.type === "Other Department") {
                if (!(assignData.department)) {
                    error = { ...error, department: t('Please select a department') }
                    console.log(error)
                    flag = false
                } else {
                    flag = true
                }
            }

        }
        return { flag, error }
    }

    const assignSubTaskUserValidator = () => {
        let flag = false;
        let error = {}
        if (!(assignData.user)) {
            error = { ...error, user: t('Please select a user') }
            flag = false
        } else {
            flag = true
        }
        return { flag, error }
    }

    const assignUsersList = () => {

        let excludeArray = []
        if (incident_data?.is_incident_assigned) {
            excludeArray = (assignExcludeList && assignExcludeList?.incident) ? assignExcludeList?.incident : []
        }
        if (incident_data?.is_task_assigned && taskno) {
            excludeArray = assignExcludeList ? ((assignExcludeList?.task && assignExcludeList?.task[taskno]) ? assignExcludeList?.task[taskno] : []) : []
        }
        console.log(excludeArray)
        let users = []
        users = searchUsers.filter(item => {
            return (
                item._id != localStorage.getItem("user_id") && //remove current user from list
                excludeArray?.every(obj => obj !== item._id) && //remove already assigned user
                (incident_data?.forwarded_to ? (item._id != incident_data?.forwarded_to?._id) : true)) //remove the user who got the incident by forward
        })
        return users
    }

    let modalContent = ""
    if (modalType === "admin_user_forward") {
        modalContent =
            <Modal.Body>
                <p className='modal-head'>{t('Forward')}</p>
                <div className="actions__modals">
                    <h2 className="mb-2" style={{ color: "#474747" }}>
                        {t("Select the Department to forward")}
                    </h2>
                    <Select
                        name="colors"
                        options={departmentListHavingUsers?.filter(item => (incident_data?.forwarded_dept_to && incident_data?.incident_status?.handle_key !== RE_OPEN) ? item._id !== incident_data?.forwarded_dept_to : ((incident_data?.created_by_dept?.length && incident_data?.created_by_dept[0] && incident_data?.incident_status?.handle_key !== RE_OPEN && incident_data?.forwarded_to_role !== ADMIN && incident_data?.forwarded_to_role !== INCIDENT_MANAGER) ? item._id !== (incident_data?.created_by_dept[0]) : true))}
                        isRtl={true}
                        value={forwardDept}
                        getOptionLabel={(option) => option.department_name}
                        getOptionValue={(option) => option._id}
                        className="basic-single w-100"
                        classNamePrefix="select"
                        placeholder={t("Select Department")}
                        onChange={(e) => {
                            setForwardDept(e);
                            setErr({ ...err, forwardDept: "" });
                        }}
                        styles={customStyles}
                    />
                    <div className="text-danger">
                        <small>{t(err?.forwardDept)}</small>
                    </div>
                    <div className="footer__btns">
                        <button
                            className="btn btn-confirm"
                            onClick={onHide}
                        >
                            {t('Cancel')}
                        </button>
                        <button
                            className="btn btn-confirm"
                            onClick={() => {
                                if (forwardDept != "") {
                                    saveHandler();
                                } else {
                                    setErr({
                                        ...err,
                                        forwardDept: t("Please select department"),
                                    });
                                }
                            }}
                            disabled={incidentStatusChangeLoading}
                        >
                            {t(incidentStatusChangeLoading ? 'loading' : 'Confirm')}
                        </button>
                    </div>
                </div>
            </Modal.Body>
    }
    if (modalType === "pool_forward") {
        modalContent =
            <Modal.Body>
                <p className='modal-head'>{t('Forward')}</p>
                <div className="actions__modals">
                    <h2 className="mb-3" style={{ color: "#474747", textAlign: 'right' }}>
                        {t("Select the user to forward the incident ticket")}
                    </h2>
                    <h2 className="mb-3" style={{ color: "#474747", textAlign: 'right', color: 'red', fontSize: 16 }}>
                        {(!(incident_data?.forwarded_to) && incident_data?.sub_task?.filter(item => item.is_consider !== false).length) ?
                            t("Assigned tasks will be closed if you proceed to forward") : ""
                        }
                    </h2>
                    <Container>
                        <Row>
                            <Col>
                                <Form>
                                    <PermissionsGate permittedUsers={[POOL_ADMIN]}>
                                        <div key={`inline-radio`} className="mb-3" style={{ textAlign: 'right' }}>
                                            {(forwardedBy !== INCIDENT_MANAGER) && <Form.Check
                                                inline
                                                label={t("Admin")}
                                                name="group1"
                                                type="radio"
                                                id={`inline-radio-1`}
                                                checked={forwardType === "Admin"}
                                                value="Admin"
                                                onChange={e => setForwardType(e.target.value)}
                                            />}
                                            {/* {(forwardedBy !== ADMIN) && <Form.Check
                                                inline
                                                label={t("Incident Manager")}
                                                value="Incident Managers"
                                                name="group1"
                                                type="radio"
                                                id={`inline-radio-2`}
                                                checked={forwardType === "Incident Managers"}
                                                onChange={e => setForwardType(e.target.value)}
                                            />} */}
                                            <Form.Check
                                                inline
                                                label={t("Other Department")}
                                                value="Other Department"
                                                name="group1"
                                                type="radio"
                                                id={`inline-radio-3`}
                                                checked={forwardType === "Other Department"}
                                                onChange={e => setForwardType(e.target.value)}
                                            />
                                            <Form.Check
                                                inline
                                                label={t("Pool User")}
                                                value="Pool User"
                                                name="group1"
                                                type="radio"
                                                id={`inline-radio-4`}
                                                checked={forwardType === "Pool User"}
                                                onChange={e => setForwardType(e.target.value)}
                                            />
                                        </div>
                                    </PermissionsGate>
                                    <PermissionsGate permittedUsers={[POOL_USER]}>
                                        <div key={`inline-radio`} className="mb-3" style={{ textAlign: 'right' }}>
                                            <Form.Check
                                                inline
                                                label={t("Pool Admin")}
                                                name="group1"
                                                type="radio"
                                                id={`inline-radio-1`}
                                                checked={forwardType === "Pool Admin"}
                                                value="Pool Admin"
                                                onChange={e => setForwardType(e.target.value)}
                                            />
                                        </div>
                                    </PermissionsGate>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                    {forwardType === "Pool User" && <Row>
                        <Col sm={4}>
                            <Select
                                name="colors"
                                options={searchUsers.filter(item => (incident_data?.forwarded_to && incident_data?.incident_status?.handle_key !== RE_OPEN) ? (item._id !== incident_data?.forwarded_to?._id) : ((incident_data?.incident_status?.handle_key !== RE_OPEN && !(incident_data?.forwarded_to)) ? item._id !== incident_data?.created_by?._id : true))}
                                isRtl={true}
                                value={forwardData.user}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option._id}
                                className="basic-single w-100"
                                classNamePrefix="select"
                                placeholder={t("Select User")}
                                onChange={(e) => {
                                    setForwardData({ forwardData, user: e });
                                    setErr({ ...err, user: "" });
                                }}
                            />
                            <div className="text-danger">
                                <small>{t(err?.user)}</small>
                            </div>
                            {usersLoading ?
                                <div dir='rtl'>
                                    <Spinner animation="border" role="status" size="sm"></Spinner>&nbsp;
                                    {t("Loading Users")}
                                </div> : ""}
                        </Col>
                        <Col sm={4}>
                            <Select
                                name="colors"
                                options={sectionListsHavingusers}
                                isRtl={true}
                                value={userSearchParams.section}
                                getOptionLabel={(option) => option.section_name}
                                getOptionValue={(option) => option._id}
                                className="basic-single w-100"
                                classNamePrefix="select"
                                placeholder={t("Select Section")}
                                onChange={(e) => {
                                    setUserSearchParams({ ...userSearchParams, section: e });
                                    setForwardData({ ...forwardData, user: '' })
                                    setErr({ ...err, forwardDept: "" });
                                }}
                            />
                        </Col>
                        <Col sm={4}>
                            <input dir='rtl' disabled value={JSON.parse(localStorage.getItem("department")).department_name} className="basic-single w-100 disabled-select" />
                        </Col>

                    </Row>}
                    {forwardType === "Other Department" && <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                        <Col sm={4}>
                            <Select
                                name="colors"
                                options={departmentListHavingUsers.filter(item => item._id != JSON.parse(localStorage.getItem("department"))._id)}
                                isRtl={true}
                                value={forwardData.department}
                                getOptionLabel={(option) => option.department_name}
                                getOptionValue={(option) => option._id}
                                className="basic-single w-100"
                                classNamePrefix="select"
                                placeholder={t("Select Department")}
                                onChange={(e) => {
                                    setForwardData({ forwardData, department: e });
                                    setErr({ ...err, department: "" });
                                }}
                            />
                            <div className="text-danger">
                                <small>{t(err?.department)}</small>
                            </div>
                        </Col>
                    </Row>}
                    <div className="footer__btns">
                        <button
                            className="btn btn-confirm"
                            onClick={onHide}
                        >
                            {t('Cancel')}
                        </button>
                        <button
                            className="btn btn-confirm"
                            onClick={() => {
                                let { flag, error } = forwardToPoolUserValidator()
                                if (flag) {
                                    saveHandler();
                                } else {
                                    setErr({ ...err, ...error });
                                }
                            }}
                            disabled={incidentStatusChangeLoading}
                        >
                            {t(incidentStatusChangeLoading ? 'loading' : 'Confirm')}
                        </button>
                    </div>
                </div>
            </Modal.Body>
    }
    if (modalType === "assign") {
        modalContent =
            <Modal.Body>
                <p className='modal-head'>{t('Assign task')}</p>
                <div className="actions__modals">
                    <h2 className="mb-3" style={{ color: "#474747", textAlign: 'right' }}>
                        {t('Select the user to assign the incident task')}
                    </h2>
                    <Container>
                        <Row>
                            <Col>
                                <Form>
                                    <div key={`inline-radio`} className="mb-3" style={{ textAlign: 'right' }}>
                                        <Form.Check
                                            inline
                                            label={t("Other Department")}
                                            value="Other Department"
                                            name="group1"
                                            type="radio"
                                            id={`inline-radio-3`}
                                            checked={assignData.type === "Other Department"}
                                            onChange={e => setAssignData({ ...assignData, type: e.target.value })}
                                        />
                                        <Form.Check
                                            inline
                                            label={t("Pool User")}
                                            value="Pool User"
                                            name="group1"
                                            type="radio"
                                            id={`inline-radio-4`}
                                            checked={assignData.type === "Pool User"}
                                            onChange={e => setAssignData({ ...assignData, type: e.target.value })}
                                        />
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                    {assignData.type === "Pool User" &&
                        <Row >

                            <Col sm={4}>
                                <Select
                                    name="colors"
                                    options={assignUsersList()}
                                    isRtl={true}
                                    value={assignData.user}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option._id}
                                    className="basic-single w-100"
                                    classNamePrefix="select"
                                    placeholder={t("Select User")}
                                    onChange={(e) => {
                                        setAssignData({ ...assignData, user: e });
                                        setErr({ ...err, user: "" });
                                    }}
                                />
                                <div className="text-danger">
                                    <small>{t(err?.user)}</small>
                                </div>
                                {usersLoading ?
                                    <div dir='rtl'>
                                        <Spinner animation="border" role="status" size="sm"></Spinner>&nbsp;
                                        {t("Loading Users")}
                                    </div> : ""}
                            </Col>
                            <Col sm={4}>
                                <Select
                                    name="colors"
                                    options={sectionListsHavingusers}
                                    isRtl={true}
                                    value={userSearchParams.section}
                                    getOptionLabel={(option) => option.section_name}
                                    getOptionValue={(option) => option._id}
                                    className="basic-single w-100"
                                    classNamePrefix="select"
                                    placeholder={t("Select Section")}
                                    onChange={(e) => {
                                        setUserSearchParams({ ...userSearchParams, section: e });
                                        setAssignData({ ...assignData, user: '' });
                                    }}
                                />
                            </Col>
                            <Col sm={4}>
                                <input dir='rtl' disabled value={JSON.parse(localStorage.getItem("department")).department_name} className="basic-single w-100 disabled-select" />

                            </Col>
                        </Row>}
                    {assignData.type === "Other Department" && <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>

                        <Col sm={4}>
                            <Select
                                name="colors"
                                options={departmentListHavingUsers.filter(item => item._id != JSON.parse(localStorage.getItem("department"))._id)}
                                isRtl={true}
                                value={assignData.department}
                                getOptionLabel={(option) => option.department_name}
                                getOptionValue={(option) => option._id}
                                className="basic-single w-100"
                                classNamePrefix="select"
                                placeholder={t("Select Department")}
                                onChange={(e) => {
                                    setAssignData({ ...assignData, department: e });
                                    setErr({ ...err, department: "" });
                                }}
                            />
                            <div className="text-danger">
                                <small>{t(err?.department)}</small>
                            </div>
                        </Col>
                    </Row>}
                    <div className="footer__btns">
                        <button
                            className="btn btn-confirm"
                            onClick={onHide}
                        >
                            {t('Cancel')}
                        </button>
                        <button
                            className="btn btn-confirm"
                            onClick={() => {
                                let { flag, error } = assignUserValidator()
                                if (flag) {
                                    saveHandler();
                                } else {
                                    setErr({ ...err, ...error });
                                }
                            }}
                            disabled={incidentStatusChangeLoading}
                        >
                            {t(incidentStatusChangeLoading ? 'loading' : 'Confirm')}
                        </button>
                    </div>
                </div>
            </Modal.Body>
    }
    if (modalType === "assign_subtask") {
        modalContent =
            <Modal.Body>
                <p className='modal-head'>{t('Assign subtask')}</p>
                <div className="actions__modals">
                    <h2 className="mb-3" style={{ color: "#474747", textAlign: 'right' }}>
                        {t('Select the user to assign the incident subtask')}
                    </h2>
                    <Row >

                        <Col sm={4}>
                            <Select
                                name="colors"
                                options={assignUsersList()}
                                isRtl={true}
                                value={assignData.user}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option._id}
                                className="basic-single w-100"
                                classNamePrefix="select"
                                placeholder={t("Select User")}
                                onChange={(e) => {
                                    setAssignData({ ...assignData, user: e });
                                    setErr({ ...err, user: "" });
                                }}
                            />
                            <div className="text-danger">
                                <small>{t(err?.user)}</small>
                            </div>
                            {usersLoading ?
                                <div dir='rtl'>
                                    <Spinner animation="border" role="status" size="sm"></Spinner>&nbsp;
                                    {t("Loading Users")}
                                </div> : ""}
                        </Col>
                        <Col sm={4}>
                            <Select
                                name="colors"
                                options={sectionListsHavingusers}
                                isRtl={true}
                                value={userSearchParams.section}
                                getOptionLabel={(option) => option.section_name}
                                getOptionValue={(option) => option._id}
                                className="basic-single w-100"
                                classNamePrefix="select"
                                placeholder={t("Select Section")}
                                onChange={(e) => {
                                    setUserSearchParams({ ...userSearchParams, section: e });
                                    setAssignData({ ...assignData, user: '' });
                                }}
                            />
                        </Col>
                        <Col sm={4}>
                            <input dir='rtl' disabled value={JSON.parse(localStorage.getItem("department")).department_name} className="basic-single w-100 disabled-select" />

                        </Col>
                    </Row>
                    <div className="footer__btns">
                        <button
                            className="btn btn-confirm"
                            onClick={onHide}
                        >
                            {t('Cancel')}
                        </button>
                        <button
                            className="btn btn-confirm"
                            onClick={() => {
                                let { flag, error } = assignSubTaskUserValidator()
                                if (flag) {
                                    saveHandler();
                                } else {
                                    setErr({ ...err, ...error });
                                }
                            }}
                            disabled={incidentStatusChangeLoading}
                        >
                            {t(incidentStatusChangeLoading ? 'loading' : 'Confirm')}
                        </button>
                    </div>
                </div>
            </Modal.Body>
    }
    if (modalType === "other_department_assign") {
        modalContent =
            <Modal.Body>
                <p className='modal-head'>{t('Transfer task')}</p>
                <div className="actions__modals">
                    <h2 className="mb-3" style={{ color: "#474747", textAlign: 'right' }}>
                        {t('Select the user to transfer the incident task')}
                    </h2>
                    <Row >

                        <Col sm={4}>
                            <Select
                                name="colors"
                                options={assignUsersList()}
                                isRtl={true}
                                value={assignData.user}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option._id}
                                className="basic-single w-100"
                                classNamePrefix="select"
                                placeholder={t("Select User")}
                                onChange={(e) => {
                                    setAssignData({ ...assignData, user: e });
                                    setErr({ ...err, user: "" });
                                }}
                            />
                            <div className="text-danger">
                                <small>{t(err?.user)}</small>
                            </div>
                            {usersLoading ?
                                <div dir='rtl'>
                                    <Spinner animation="border" role="status" size="sm"></Spinner>&nbsp;
                                    {t("Loading Users")}
                                </div> : ""}
                        </Col>
                        <Col sm={4}>
                            <Select
                                name="colors"
                                options={sectionListsHavingusers}
                                isRtl={true}
                                value={userSearchParams.section}
                                getOptionLabel={(option) => option.section_name}
                                getOptionValue={(option) => option._id}
                                className="basic-single w-100"
                                classNamePrefix="select"
                                placeholder={t("Select Section")}
                                onChange={(e) => {
                                    setUserSearchParams({ ...userSearchParams, section: e });
                                    setAssignData({ ...assignData, user: '' });
                                }}
                            />
                        </Col>
                        <Col sm={4}>
                            <input dir='rtl' disabled value={JSON.parse(localStorage.getItem("department")).department_name} className="basic-single w-100 disabled-select" />

                        </Col>
                    </Row>
                    <div className="footer__btns">
                        <button
                            className="btn btn-confirm"
                            onClick={onHide}
                        >
                            {t('Cancel')}
                        </button>
                        <button
                            className="btn btn-confirm"
                            onClick={() => {
                                let { flag, error } = assignSubTaskUserValidator()
                                if (flag) {
                                    saveHandler();
                                } else {
                                    setErr({ ...err, ...error });
                                }
                            }}
                            disabled={incidentStatusChangeLoading}
                        >
                            {t(incidentStatusChangeLoading ? 'loading' : 'Confirm')}
                        </button>
                    </div>
                </div>
            </Modal.Body>
    }
    if (modalType === "return") {
        modalContent =
            <Modal.Body>
                <p className='modal-head'>{t('Return')}</p>
                <div className="actions__modals">
                    <h2
                        className="mb-3"
                        style={{ color: "#474747", textAlign: "right" }}
                    >
                        {t('Return to Reporter')}
                    </h2>
                    <textarea
                        name=""
                        id=""
                        rows="3"
                        cols='5'
                        value={returnComment}
                        className="form-control"
                        placeholder="comment"
                        style={{ backgroundColor: '#ececec', textAlign: 'right' }}
                        onChange={(e) => {
                            setReturnComment(e.target.value);
                            setErr({ ...err, return_comment: "" });
                        }}
                    ></textarea>
                    <div className="text-danger">
                        <small>{t(err?.return_comment)}</small>
                    </div>
                    <div className="footer__btns">
                        <button
                            className="btn btn-confirm"
                            onClick={onHide}
                        >
                            {t('Cancel')}
                        </button>
                        <button
                            className="btn btn-confirm"
                            onClick={() => {
                                if (returnComment != "") {
                                    saveHandler();
                                } else {
                                    setErr({
                                        ...err,
                                        return_comment: t("Please enter comment"),
                                    });
                                }
                            }}
                        >
                            {t('Submit')}
                        </button>
                    </div>
                </div>
            </Modal.Body>
    }
    if (modalType === "delete") {
        modalContent =
            <Modal.Body>
                <p className='modal-head'>{t('Delete draft')}</p>
                <div className="actions__modals">
                    <h2
                        className="mb-3"
                        style={{ color: "#474747", textAlign: "center" }}
                    >
                        {t('Do you want to delete the draft?')}
                    </h2>

                    <div className="text-danger">
                        <small>{t(err?.return_comment)}</small>
                    </div>
                    <div className="footer__btns" style={{ justifyContent: 'center' }}>

                        <button
                            className="btn btn-confirm"
                            onClick={onHide}
                        >
                            {t('Cancel')}
                        </button>
                        <button
                            className="btn btn-confirm"
                            onClick={saveHandler}
                            disabled={incidentStatusChangeLoading}
                        >
                            {t(incidentStatusChangeLoading ? 'loading' : 'Confirm')}
                        </button>
                    </div>
                </div>
            </Modal.Body>
    }
    if (modalType === "del_old_attachment") {
        modalContent =
            <Modal.Body>
                <p className='modal-head'>{t('Delete')}</p>
                <div className="actions__modals">
                    <h2
                        className="mb-3"
                        style={{ color: "#474747", textAlign: "right" }}
                    >
                        Are you sure, you want to delete ?
                    </h2>
                    <div className="footer__btns">
                        <button
                            className="btn btn-confirm"
                            onClick={onHide}
                        >
                            {t('Cancel')}
                        </button>
                        <button
                            className="btn btn-confirm"
                            onClick={() => {
                                onDeleteOldAttachment();
                            }}
                        >
                            {t('Confirm')}
                        </button>
                    </div>
                </div>
            </Modal.Body>
    }
    if (modalType === "del_new_attachment") {
        modalContent =
            <Modal.Body>
                <p className='modal-head'>{t('Delete')}</p>
                <div className="actions__modals">
                    <h2
                        className="mb-3"
                        style={{ color: "#474747", textAlign: "right" }}
                    >
                        {t('Are you sure, you want to delete ?')}
                    </h2>
                    <div className="footer__btns">
                        <button
                            className="btn btn-confirm"
                            onClick={onHide}
                        >
                            {t('Cancel')}
                        </button>
                        <button
                            className="btn btn-confirm"
                            onClick={() => {
                                onDeleteNewAttachment();
                            }}
                        >
                            {t('Confirm')}
                        </button>
                    </div>
                </div>
            </Modal.Body>
    }
    if (modalType === "closeincident_with_unfinished_tasks") {
        modalContent =
            <Modal.Body>
                <p className='modal-head'>{t('Close incident')}</p>
                <div className="actions__modals">
                    <h2
                        className="mb-3"
                        style={{ color: "#474747", textAlign: "center" }}
                    >
                        {t(incident_data?.is_task_assigned ? "This task has unfinished subtasks. Do you want to proceed ?" : "This incident has unfinished tasks. Do you want to proceed ?")}
                    </h2>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <table style={{ width: 400 }}>
                            <tr dir='rtl' style={{ backgroundColor: '#f4ece8', }}>
                                <th style={{ textAlign: 'center' }}>{t('Assigned To')}</th>
                                <th style={{ textAlign: 'center' }}>{t(incident_data?.is_task_assigned ? "Subtask No" : "Task No")}</th>
                            </tr>
                            {currentTaskSubTasks?.map(item =>
                                <tr dir='rtl'>
                                    <td style={{ textAlign: 'center' }}>
                                        {item.assigned_to ? item.assigned_to.name : (item.assigned_dept ? item.assigned_dept.department_name : '')}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {item.task_no}
                                    </td>
                                </tr>)}
                        </table>
                    </div>
                    <div className="text-danger">
                        <small>{t(err?.return_comment)}</small>
                    </div>
                    <div className="footer__btns" style={{ justifyContent: 'center' }}>

                        <button
                            className="btn btn-confirm"
                            onClick={onHide}
                        >
                            {t('Cancel')}
                        </button>
                        <button
                            className="btn btn-confirm"
                            onClick={() => {
                                if (isTaskSelected) {
                                    handleCloseTask()
                                } else {
                                    if (incident_data?.is_task_assigned) {
                                        if ((role === POOL_ADMIN && receivedTask && receivedTask.is_transfered === "1")) {
                                            handleCloseTask()
                                        } else {
                                            handleSubmitTask()
                                        }
                                    } else {
                                        saveHandler()
                                    }
                                }
                            }}
                            disabled={incidentStatusChangeLoading || incidentTaskStatusChangeLoading}
                        >
                            {t((incidentStatusChangeLoading || incidentTaskStatusChangeLoading) ? 'loading' : 'Confirm')}
                        </button>
                    </div>
                </div>
            </Modal.Body>
    }
    if (modalType === "upload_zip_file") {
        modalContent =
            <Modal.Body>
                <div className="actions__modals">
                    <h2
                        className="mb-3"
                        style={{ color: "#474747", textAlign: "center" }}
                    >
                        {t("Upload zip file")}
                    </h2>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <section
                            className={"upload-attachment" + (file ? " file-active" : "")}
                        >

                            {file ? (
                                <>{file ? file.name : ''}</>
                            ) : (
                                <label htmlFor="certificate-upload">
                                    <span className="button style-2">
                                        <input
                                            type="file"
                                            id="certificate-upload"
                                            hidden
                                            accept="application/zip"
                                            onClick={(e) => e.target.value = null}
                                            onChange={(e) => {
                                                console.log(e.target.files[0])
                                                setFile(e.target.files[0])
                                                setModalErr("")
                                            }}
                                        />
                                        <i>
                                            <img style={{cursor: 'pointer'}} src="/assets/icons/Add Attachments.svg" alt="" className='upload-zip-image' />
                                        </i>
                                    </span>
                                </label>
                            )}
                        </section>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="text-danger">
                        <small>{t(modalErr)}</small>
                    </div>
                    <div className="footer__btns" style={{ justifyContent: 'center' }}>

                        <button
                            className="btn btn-confirm"
                            onClick={onHide}
                        >
                            {t('Cancel')}
                        </button>
                        <button
                            className="btn btn-confirm"
                            onClick={onConfirm}
                            disabled={uploadZipLoading}
                        >
                            {t((uploadZipLoading) ? 'loading' : 'Confirm')}
                        </button>
                    </div>
                </div>
            </Modal.Body>
    }

    const modalClassName = () => {
        let classname = "modal-large";
        if (modalType === "admin_user_forward" || modalType === "delete") {
            classname = ""
        }
        return classname
    }

    return (
        <Modal show={modalType} onHide={onHide} centered dialogClassName={modalClassName()}>
            {modalContent}
        </Modal>
    )
}

export default IncidentModal