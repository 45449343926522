import React, { useEffect } from "react";
import TitleBar from "../../../components/TitleBar/TitleBar";
import HistoryMenu from "../../../components/HistoryMenu/HistoryMenu";
import Table3 from "../../../components/Table3/Table3";
import SubTitleBar from "../../../components/SubTitleBar/SubTitleBar";
import CommentContent from "../../../components/CommentContent/CommentContent";
import AttachmentContent from "../../../components/AttachmentContent/AttachmentContent";
import { useState } from "react";
import { DateFormatter } from "../../../utils/Date";
import { updateRedux } from "../../../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL, IMAGE_BASE_URL, POOL_ADMIN } from "../../../constants/configuration";
import { incident_path } from "../../../redux/incidentReducer";
import useTrans from "../../../utils/useTrans";
import { Capitalize } from "../../../utils/dateFormat";
import useGetRole from "../../../utils/useGetRole";
import { useParams } from "react-router-dom";

export default function IncidentHistory({ incident_data, taskno }) {

  const dispatch = useDispatch()
  const url = useSelector(incident_path);
  const { id, displaytype } = useParams();
  const { t } = useTrans()
  const lang = localStorage.getItem('lang')
  const { role } = useGetRole()

  const userType = localStorage.getItem("user_type");
  const userID = localStorage.getItem("user_id");
  const userDepartment = JSON.parse(localStorage.getItem("department"));

  const [menuItems1, setMenuItems1] = useState(null);
  const [menuItems2, setMenuItems2] = useState(null);
  const [userTask, setUserTask] = useState(null)
  const [taskDetails, setTaskDetails] = useState(null);
  const [tableContent, setTableContent] = useState([]);
  const [menuItems3, setMenuItems3] = useState({})

  useEffect(() => {
    incident_data?.incident_departments?.length > 0 &&
      setMenuItems1([
        { title: incident_data.is_task_assigned === 1 ? t('Main Task') : t("Main Request"), type: 0 },
        ...incident_data?.incident_departments
          .filter((item) => !item.tasks[0].task.is_assigned_department)
          .map((item, i) => ({
            title: Capitalize(item.department.department_name) + ` - ${isCurrentTaskIsSubtask(item)}`,
            type: "department",
            date: new Date(item.tasks[0].task.received_date),
            tasks: item.tasks,
            order: item.tasks[0].task.task_no.slice(-3),
            className: item.tasks[0].task.is_consider ? null : 'closed__task',
          })),
        ...incident_data?.incident_departments
          .filter((item) => item.tasks[0].task.is_assigned_department)
          .flatMap((item) => item.tasks)
          .map((item) => ({
            title: (item.task.task_no ? '(' + item.task.task_no + ') ' : '') + Capitalize(item?.task?.is_assigned_department === '1' ? item?.task?.assigned_dept?.department_name : item.task.assigned_to?.name) + ` - ${isCurrentTaskIsSubtask(item)}`,
            type: "user",
            date: new Date(item.task.received_date),
            details: item,
            order: item.task.task_no.slice(-3),
            className: item.task.is_consider ? null : 'closed__task',
          }))
      ].sort((a, b) => { if (a.order > b.order) { return 1 } else if (a.order < b.order) { return -1 } else { return 0 } }));

    let data = incident_data?.current_user_tasks?.[0]

    if (incident_data?.current_user_tasks?.length === 0 && incident_data?.is_task_assigned) {
      data = incident_data?.sub_task?.find(item => item.task_no === taskno)
    }
    data && setUserTask(taskDetailsSetter({
      item: {
        details: {
          task: {
            task_no: data.task_no,
            comment: data.comment,
            attachments: data.attachments
          }
        }
      }
    }))

    setTableContent(tableContentSetter(incident_data?.actions));

    return () => {
      setMenuItems1(null);
      setMenuItems2(null);
      setTaskDetails(null);
      setTableContent(null);
      setUserTask(null)
      dispatch(updateRedux({ key: 'task_selected', value: null }))
    };
  }, [incident_data, lang]);

  useEffect(() => {
    if (incident_data?.is_task_assigned === 1 && userType === 'pool_admin' && incident_data?.incident_departments.length) {
      let arr = displaytype === 'task' ? incident_data?.incident_departments[0].tasks : displaytype === 'subtask' ? incident_data?.incident_departments[0].tasks[0].sub_task : []
      let result = arr.map((item) => ({
        title: (item.task.task_no ? '(' + item.task.task_no + ') ' : '') + (item?.task?.is_assigned_department === '1' ? item?.task?.assigned_dept?.department_name : item.task.assigned_to?.name) + ` - ${isCurrentTaskIsSubtask(item)}`,
        type: "user",
        date: new Date(item.task.received_date),
        details: item,
        order: item.task.task_no.slice(-3),
        className: item.task.is_consider ? null : 'closed__task'
      }))
      let taskDetails = incident_data?.actions.find(item => item.task_no === taskno && item.is_transfered === "1")
      if (taskDetails) {
        result.unshift({ title: t('Main Task'), details: { task: taskDetails } })
        result[0].title = taskDetails.assigned_task_to?.name + ' - ' + result[0].title
      }
      if (!taskDetails) {
        taskDetails = incident_data?.actions.find(item => item.task_no === taskno && item.is_transfered === "0")
        result.unshift({ title: t('Main Task'), details: { task: taskDetails } })
        if (taskDetails?.is_transfered === '1' && displaytype === 'subtask') {
          result[0].title = taskDetails.assigned_to.name + ' - ' + result[0].title
        }
      }
      setMenuItems1(result)
      setTableContent(tableContentSetter(incident_data?.actions))
      dispatch(updateRedux({ key: 'task_selected', value: { item: result[0] } }))
    }
  }, [incident_data, userType, lang])

  useEffect(() => {
    let task = incident_data?.sub_task?.find(item => item.task_no === taskno)
    let subtask = incident_data?.sub_task?.find(item => item.parent_sub_task === taskno)
    if (task) {
      if (task.is_transfered === '1' && task.assigned_to._id !== userID && !subtask) {
        setMenuItems3([{
          title: task?.assigned_to?.name + ' - ' + t('Main Task'),
          details: task
        }])
      }
    }
  }, [incident_data, lang])

  function onMenuChange1(e) {
    if (e.item.title.includes(t('Main Task')) && incident_data?.is_task_assigned === 1 && userType === 'pool_admin') {
      onMenuChange2(e)
      setTaskDetails(null)
      setTableContent(tableContentSetter(incident_data?.actions));
    } else {
      if (e.item.type === 0 || e.item.type === 'user') dispatch(updateRedux({ key: 'task_selected', value: e }))
      if (e.item.type === 0) {
        setTableContent(tableContentSetter(incident_data?.actions));
        setTaskDetails(null);
        setMenuItems2(null);
      } else if (e.item.type === "department") {
        setMenuItems2(menuItemsSetter(e.item.tasks))
        setTaskDetails(taskDetailsSetter({ item: { details: e.item.tasks[0] } }));
        setTableContent(tableContentSetter(e.item.tasks[0].actions))
        dispatch(updateRedux({ key: 'task_selected', value: { index: 0, item: { title: '', details: e.item.tasks[0] } } }))
      } else if (e.item.type === "user") {
        setTaskDetails(taskDetailsSetter(e));
        setTableContent(tableContentSetter(e.item.details.actions))
        if (e.item.details?.sub_task?.length > 0) {
          setMenuItems2(menuItemsSetter(e.item.details.sub_task, e.item.details))
        } else {
          setMenuItems2(null);
        }
      }
    }
  }

  function onMenuChange2(e) {
    dispatch(updateRedux({ key: 'task_selected', value: e }))
    setTaskDetails(taskDetailsSetter(e))
    setTableContent(tableContentSetter(e.item.details.actions))
  }


  function menuItemsSetter(tasks, userTask) {
    const result = tasks.map((item) => {
      if (item?.task?.is_assigned_department === "1" && item.actions.length > 1)
        return { title: t('Main Task'), details: item };
      else if (item?.task?.is_assigned_department === "0") {
        return {
          title: (item.task.task_no ? '(' + item.task.task_no + ') ' : '') + item?.task?.assigned_to?.name + ` - ${isCurrentTaskIsSubtask(item)}`,
          details: item,
          className: item.task.is_consider ? null : 'closed__task'
        }
      }
    }).filter(item => item !== undefined)
    if (userTask) result.unshift({ title: t('Main Task'), details: userTask, className: userTask.task.is_consider ? null : 'closed__task' })
    if (userTask.task.is_transfered === '1') {
      result[0].title = userTask.task.assigned_to.name + ' - ' + result[0].title
    }
    return result
  }

  function taskDetailsSetter(e) {
    // console.log(e.item.details.task)
    if (!e.item?.details || !e.item.details.task) return null
    let result = {
      name: ((e.item.details.task?.is_assigned_department === "1" || (incident_data?.is_task_assigned === 1 && !e.item.details.task.assigned_to?.name))
        ? e.item.details.task.is_transfered === '1'
          ? Capitalize(e.item.details.task.assigned_to?.name) + " - "
          : e.item.details.task.is_transfered === '0'
            ? Capitalize(e.item.details.task.assigned_dept.department_name) + " - "
            : ""
        : (e.item.details.task.assigned_to ? (e.item.details.task.assigned_to?.name + " - ") : "")) + isCurrentTaskIsSubtask(e.item.details) + " (" + e.item.details.task?.task_no + ")",
      comment: e.item.details.task?.comment,
      attachments: e.item.details.task?.attachments,
    };
    return result
  }

  function tableContentSetter(actions) {
    if (!actions) return [];
    return actions.map((item, index, arr) => {
      let assignedTo =
        item.returned_user?.name
          ? item.returned_user?.name
          : item.returned_to_user
            ? item.returned_to_user
            : item.is_assigned_department === "1"
              ? item.assigned_dept.department_name
              : item.forwarded_dept_to
                ? item.forwarded_dept_to.department_name
                : item.forwarded_to?.name
                  ? item.forwarded_to?.name
                  : item.forwarded_to_role === 'admin'
                    ? "Admin Pool"
                    : item.forwarded_to_role === 'incident_manager'
                      ? "Incident Pool"
                      : item.assigned_task_to?.name
                        ? item.assigned_task_to?.name
                        : item.assigned_to?.name
                          ? item.assigned_to?.name
                          : '-'
      return {
        sl_no: arr.length - index,
        received_date: DateFormatter(item.received_date),
        handled_by: item.handled_by?.name || item?.returned_to_user,
        assigned_to: Capitalize(assignedTo === item.handled_by?.name ? '-' : assignedTo),
        last_update: DateFormatter(item.last_update),
        attachment: {
          endpoints: (userType === "incident_manager" && item.handled_by?._id !== userID) ? [] : item.attachments,
          path: `${IMAGE_BASE_URL}${url}/`,
        },
        status: t(statusNaming(item.status?.['incident_status_en'], item.task_no)),
        statusClass: statusNaming(item.status?.incident_status_en, item.task_no),
        comment: (userType === "incident_manager" && item.handled_by?._id !== userID) ? "" : item.comment,
      }
    });
  }

  function statusNaming(status, taskNo) {
    let taskObject = incident_data?.sub_task?.find(item => item.task_no === taskNo)
    let isSubTask = taskNo && taskObject && taskObject.parent_sub_task != null
    switch (status) {
      case 'Save Draft': return 'Draft'
      case 'Reopen task': return isSubTask ? 'Subtask Reopened' : 'Task Reopened'
      case 'Close Task': return isSubTask ? 'Subtask Closed' : 'Task Closed'
      case 'Submit Task': return isSubTask ? 'Subtask Closed' : 'Task Closed'
      case 'Assign Task': return 'Task Assigned'
      case 'Assign Subtask': return 'Subtask Assigned'
      case 'Open': return isSubTask ? 'Subtask Open' : (taskNo ? 'Task Open' : 'Open')
      case 'Reopen': return 'Reopened'
      case 'Close': return 'Closed'
      case 'Processing': return 'Processing'
      case 'Pending': return 'Pending'
      case 'Return': return 'Returned'
      case 'Forward': return 'Forwarded'
      default: return status;
    }
  }

  const isCurrentTaskIsSubtask = (task) => {
    let taskObject = incident_data?.sub_task?.find(item => item.task_no === task?.task?.task_no)
    let isSubTask = taskObject && taskObject.parent_sub_task != null
    return isSubTask ? t("Subtask") : t("Task")
  }

  return (
    <>

      {userTask && <TaskSection className='main-task' details={userTask} />}

      {incident_data?.actions && incident_data?.actions?.length > 0 &&
        <TitleBar className='table3' TitleBar Title={t("History")} ToggleButton DefaultToggle={true}>
          {menuItems1?.length > 1 && (<HistoryMenu Items={menuItems1} onClick={(e) => onMenuChange1(e)} />)}
          {menuItems2?.length > 1 && (<HistoryMenu Items={menuItems2} onClick={(e) => onMenuChange2(e)} />)}
          {menuItems3?.length > 0 && (<HistoryMenu Items={menuItems3} />)}

          {taskDetails && <TaskSection details={taskDetails} />}

          {tableContent && (
            <Table3
              Titles={[
                { title: t("Sl No"), key: "sl_no", width: '6%' },
                { title: t("Received Date"), key: "received_date", width: '12%' },
                { title: t("Handled By"), key: "handled_by", width: '11%' },
                { title: t("Assigned To"), key: "assigned_to", width: '11%' },
                { title: t("Last Update"), key: "last_update", width: '11%' },
                { title: t("Attachments"), key: "attachment", width: '8%' },
                { title: t("Status"), key: "status", width: '12%' },
                { title: t("Comments"), key: "comment", width: '31%' },
              ]}
              Content={tableContent}
            />
          )}

        </TitleBar>
      }

    </>
  )
}


const TaskSection = ({ details, className }) => {

  const { t } = useTrans()

  const url = useSelector(incident_path);

  return (
    <section className={'mb-4 ' + className || ''}>

      <TitleBar Title={details.name} />

      {details.comment && (
        <div className="box-design">
          <SubTitleBar SubTitle={t("Comment")} />
          <CommentContent Content={details.comment} />
        </div>
      )}

      {details.attachments?.length > 0 && (
        <div className="attachmentsview">
          <SubTitleBar SubTitle={t("Attachment")} />
          <AttachmentContent
            BaseUrl={`${IMAGE_BASE_URL}${url}/`}
            Files={details.attachments.map((item) => {
              return {
                type: item.split(".").reverse()[0],
                name: item,
                imageUrl: `${IMAGE_BASE_URL}${url}/` + item,
                file: item,
              };
            })}
          />
        </div>
      )}

    </section>
  )
}