import React from 'react'
import './StatusHeaderButton.scss'

export default function StatusHeaderButton() {
  return (
    <div id='StatusHeaderButton'>
      <span>
        <p>Current Status</p>
        <button className={'style-3' + (' Need Info')}>Need Info</button>
      </span>
    </div>
  )
}
