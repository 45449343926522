import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import useTrans from "../../../utils/useTrans";

const IncidentCommentSection = ({
  comment,
  setComment,
  setReturnComment,
  setErr,
  err,
  onFileChangeHandler,
  uploadFiles,
  renderTooltip,
  fileUploadViewHandler,
  setUploadFiles,
}) => {

  const { t } = useTrans()

  return (
    (
      <>
        <div className="comments">
          <div className="mb-2 mt-4">
            <label
              className="form-label mr-2 comment-head"
              style={{ fontSize: "1rem", opacity: "1" }}
            >
              {t('Comment')}
            </label>
            <textarea
              className="form-control"
              placeholder={t("Comment here")}
              rows="3"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
                setReturnComment(e.target.value);
                setErr({ ...err, comment: "" });
              }}
            ></textarea>
            <div className="text-danger">
              <small>{err?.comment}</small>
            </div>
          </div>
        </div>
        <div className="attachments">
          <div className="upload-btn-wrapper">
            <label className="btn btn-attachment " htmlFor='myfile'>
              {t('Add Attachments')}
              <img
                src="/assets/icons/Icon-upload.svg"
                alt=""
                className="mr-2"
              />{" "}
            </label>
            <input
              type="file"
              name="myfile"
              id="myfile"
              multiple
              hidden
              onClick={(e) => e.target.value = null}
              accept=".jpeg, .jpg, .png, .pdf, .PDF, .mp4, .docx, .doc, .mov"
              onChange={(e) => onFileChangeHandler(e)}
            />
            <p className="supported__formats d-flex align-items-center mt-2">
              <img style={{ height: '20px', marginLeft: '5px' }} src="/assets/icons/info.svg" alt="" />
              {t('Supported formats : jpeg, jpg, png, pdf, mp4, docx, doc, mov and Size upto 100 MB')}
            </p>
          </div>
        </div>
        <div className="attachment-files">
          {uploadFiles &&
            uploadFiles?.map((file, i) => {
              console.log(file)
              return (
                <>
                  {file.type === "image/jpeg" ||
                    file.type === "image/jpg" ||
                    file.type === "image/webp" ||
                    file.type === "image/png" ? (
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip({
                        label: file.name,
                      })}
                    >
                      <span className="file-container">
                        <img
                          className="file"
                          src={URL.createObjectURL(file)}
                          alt=""
                          onClick={() => fileUploadViewHandler(file)}
                        />
                        <img
                          className="file-close"
                          src="/assets/icons/Group 11563.svg"
                          alt=""
                          onClick={() => {
                            setUploadFiles(
                              uploadFiles.filter((item) => file != item)
                            );
                            setErr({
                              ...err,
                              uploadFiles: "",
                            });
                          }}
                        />
                      </span>
                    </OverlayTrigger>
                  ) : file.type === "application/pdf" ? (
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip({
                        label: file.name,
                      })}
                    >
                      <span className="file-container">
                        <a
                          href={`${URL.createObjectURL(file)}`}
                          target="_blank"
                        >
                          <img
                            className="file"
                            src="/assets/icons/files/pdf.svg"
                            alt=""
                          />
                        </a>
                        <img
                          className="file-close"
                          src="/assets/icons/Group 11563.svg"
                          alt=""
                          onClick={() => {
                            setUploadFiles(
                              uploadFiles.filter((item) => file != item)
                            );
                            setErr({
                              ...err,
                              uploadFiles: "",
                            });
                          }}
                        />
                      </span>
                    </OverlayTrigger>
                  ) : file.type === "video/mp4" ? (
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip({
                        label: file.name,
                      })}
                    >
                      <span className="file-container">
                        <img
                          className="file"
                          src="/assets/icons/files/mp4.svg"
                          alt=""
                          onClick={() => fileUploadViewHandler(file)}
                        />
                        <img
                          className="file-close"
                          src="/assets/icons/Group 11563.svg"
                          alt=""
                          onClick={() => {
                            setUploadFiles(
                              uploadFiles.filter((item) => file != item)
                            );
                            setErr({
                              ...err,
                              uploadFiles: "",
                            });
                          }}
                        />
                      </span>
                    </OverlayTrigger>
                  ) : file.type === "video/quicktime" ? (
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip({
                        label: file.name,
                      })}
                    >
                      <span className="file-container">
                        <img
                          className="file"
                          src="/assets/icons/files/mov.svg"
                          alt=""
                          onClick={() => fileUploadViewHandler(file)}
                        />
                        <img
                          className="file-close"
                          src="/assets/icons/Group 11563.svg"
                          alt=""
                          onClick={() => {
                            setUploadFiles(
                              uploadFiles.filter((item) => file != item)
                            );
                            setErr({
                              ...err,
                              uploadFiles: "",
                            });
                          }}
                        />
                      </span>
                    </OverlayTrigger>
                  ) : file.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    "application/msword"
                    ? (
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip({
                          label: file.name,
                        })}
                      >
                        <span className="file-container">
                          <a
                            href={`${URL.createObjectURL(file)}`}
                            target="_blank"
                          >
                            <img
                              className="file"
                              src={file.type === "application/msword"
                                ? "/assets/icons/files/doc.svg"
                                : "/assets/icons/files/docx.svg"}
                              alt=""
                            />
                          </a>
                          <img
                            className="file-close"
                            src="/assets/icons/Group 11563.svg"
                            alt=""
                            onClick={() => {
                              setUploadFiles(
                                uploadFiles.filter((item) => file != item)
                              );
                              setErr({
                                ...err,
                                uploadFiles: "",
                              });
                            }}
                          />
                        </span>
                      </OverlayTrigger>
                    ) : null}
                </>
              );
            })}
        </div>
        <div className="text-danger">
          <small>{t(err?.uploadFiles)}</small>
        </div>
      </>
    )
  );
};

export default IncidentCommentSection;
