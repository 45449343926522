import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import AddRequest_admin from "../pages/admin/hr/AddRequest_admin";
import CertificateRequests_admin_report from "../pages/admin/hr/CertificateRequests_admin_report";
import HumanResource_admin from "../pages/admin/hr/HumanResource_admin";
import RequestDetails_admin from "../pages/admin/hr/RequestDetails_admin";
import HrEducationAllowance from "../pages/admin/EducationAllowance/HrEducationAllowance";
import HrRequestDetails from "../pages/admin/EducationAllowance/HrRequestDetails";

import AddRequest from "../pages/user/AddRequest";
import EduAddRequest from "../pages/user/EducationAllowance/EduAddRequest";
import CertificateRequests from "../pages/user/CertificateRequests";
import RequestDetails from "../pages/user/RequestDetails";

import Layout from "../components/Layout/Layout";
import Login from "../pages/general/Login";

import Dashboard from "../pages/general/Dashboard";
import PageNotFound from "../pages/general/PageNotFound";
import DeputationAllowance from "../pages/general/DeputationAllowance";
import PrivateRoutes from "../utils/PrivateRoutes";
import EducationalAllowance from "../pages/user/EducationAllowance/EducationalAllowance";
import IncidentRequests from "../pages/admin/ManageIncidents/IncidentRequests";
import IncidentDetails from "../pages/admin/ManageIncidents/IncidentDetails";
import AddIncident from "../pages/admin/ManageIncidents/AddIncident";
import ManageRoles from "../pages/admin/Settings/manageRoles";
import RolesListing from "../pages/admin/Settings/rolesListing";
import SmsReport from "../pages/admin/Settings/sms_report";
import DynamicTexts, { ImportDynamicText } from "../pages/admin/DynamicTexts";
import PermissionsGate from "../utils/PermissionsGate";
import {
  ADMIN,
  INCIDENT_MANAGER,
  SUPER_ADMIN,
  POOL_ADMIN,
  POOL_USER,
  VIEWER,
} from "../constants/configuration";
import useGetRole from "../utils/useGetRole";
import { useSelector } from "react-redux";
import { user_role } from "../redux/commonReducer";
import Settings from "../pages/user/Settings/Settings";
import UsersListing from "../pages/admin/ManageUsers/users";
import UsersLog from "../pages/admin/ManageUsers/userLog";

function RouteHandler() {
  const [role, setRole] = useState("");
  const roles = useSelector(user_role);

  useEffect(() => {
    setRole(localStorage.getItem("user_type"));
  }, [localStorage.getItem("user_type"), roles]);

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard role={"User"} />} />
            <Route
              path="human_resource/certificate_requests"
              element={<CertificateRequests />}
            />
            <Route
              path="human_resource/certificate_requests/request_details"
              element={<RequestDetails />}
            />
            <Route
              path="human_resource/certificate_requests/add_request"
              element={<AddRequest />}
            />
            <Route
              path="human_resource/educational_allowance"
              element={<PageNotFound />}
            />
            <Route
              path="human_resource/deputation_allowance"
              element={<PageNotFound />}
            />
            <Route
              path="report/certificate_requests"
              element={<PageNotFound />}
            />
            <Route
              path="report/educational_allowance"
              element={<PageNotFound />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Route>
          <Route path="/admin" element={<Layout />}>
            <Route index element={<Dashboard role={"Admin"} />} />
            <Route
              path="human_resource/certificate_requests"
              element={<HumanResource_admin />}
            />
            <Route
              path="human_resource/certificate_requests/request_details"
              element={<RequestDetails_admin />}
            />
            <Route
              path="human_resource/certificate_requests/add_request"
              element={<AddRequest_admin />}
            />
            <Route
              path="human_resource/educational_allowance"
              element={<PageNotFound />}
            />
            <Route
              path="human_resource/deputation_allowance"
              element={<PageNotFound />}
            />
            <Route
              path="report/certificate_requests"
              element={<CertificateRequests_admin_report />}
            />
            {/* INCIDENT */}
            {[SUPER_ADMIN, VIEWER].includes(role) ? <Route
              path="incidents/dashboard"
              element={<IncidentRequests />}
            /> : null}
            {[SUPER_ADMIN, ADMIN, INCIDENT_MANAGER, POOL_ADMIN, POOL_USER, VIEWER].includes(role) ? <Route
              path="incidents/incident_requests"
              element={<IncidentRequests />}
            /> : null}
            {[SUPER_ADMIN, ADMIN, INCIDENT_MANAGER, POOL_ADMIN, POOL_USER].includes(role) ? <Route

              path="incidents/incident_details/:id/:displaytype/:taskno?"
              element={<IncidentDetails />}
            /> : null}
            {[SUPER_ADMIN, ADMIN, INCIDENT_MANAGER, POOL_ADMIN, POOL_USER].includes(role) ?

              <Route path="incidents/add_incident" element={<AddIncident />} /> : null}

            {[SUPER_ADMIN, ADMIN, INCIDENT_MANAGER, POOL_ADMIN, POOL_USER].includes(role) ?

              <Route path="incidents/update_incident/:id" element={<AddIncident />} /> : null}

            {[SUPER_ADMIN].includes(role) ? (
              <Route path="incidents/settings" element={<Settings />} />
            ) : null}

            {/* SETTINGS */}
            {[SUPER_ADMIN, ADMIN, INCIDENT_MANAGER].includes(role) ? (
              <Route path="incidents/roles" element={<RolesListing />} />
            ) : null}
            {[SUPER_ADMIN, ADMIN, INCIDENT_MANAGER].includes(role) ? (
              <Route path="incidents/sms_report" element={<SmsReport />} />
            ) : null}
            <Route path="incidents/translation" element={<DynamicTexts />} />
            <Route
              path="incidents/dynamicTextImport"
              element={<ImportDynamicText />}
            />
            {[SUPER_ADMIN, ADMIN, INCIDENT_MANAGER].includes(role) ? (
              <Route path="incidents/manage_users" element={<ManageRoles />} />
            ) : null}

            {/* MANAGE USERS */}
            {[SUPER_ADMIN, INCIDENT_MANAGER, ADMIN].includes(role) ? (
              <>
                <Route path="users" element={<UsersListing />} />
                <Route path="users/user-log/:id" element={<UsersLog />} />
              </>
            ) : null}

            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default RouteHandler;
