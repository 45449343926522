import React, { useState } from 'react'
import './Search.scss'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import SearchSelect, { Option } from '../../Tools/SearchSelect/SearchSelect';

export default function Search({ currentPage }) {

  const [date, setDate] = useState({ to: new Date(), from: new Date() });

  const page =
    currentPage === 'CertificateRequests' ? 1 :
      currentPage === 'HumanResource_admin' ? 2 :
        currentPage === 'CertificateRequests_admin_report' ? 3 :
        currentPage === 'EducationalAllowance' ? 4 :
          false

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div id='Search' className={page === 3 ? 'three' : ''}>
      <div>
        <section className='first'>
          <div className='inputs'>
            <article className='date'>
              <label htmlFor="">Date</label>
              <div>
                <span>
                  {/* <input type="text" className='date' placeholder='From' /> */}
                  <span dir='ltr'>
                    <DatePicker
                      selected={date.from}
                      onChange={(date) => setDate(s => ({ ...s, from: date }))}
                      minDate={new Date()}
                      filterTime={filterPassedTime}
                      dateFormat="dd/MM/yy"
                    />
                  </span>
                  <i><img src="/assets/icons/calendar.svg" alt="" /></i>
                </span>
                <span>
                  {/* <input type="text" className='date' placeholder='To' /> */}
                  <span dir='ltr'>
                    <DatePicker
                      selected={date.to}
                      onChange={(date) => setDate(s => ({ ...s, to: date }))}
                      minDate={new Date()}
                      filterTime={filterPassedTime}
                      dateFormat="dd/MM/yy"
                    />
                  </span>
                  <i><img src="/assets/icons/calendar.svg" alt="" /></i>
                </span>
              </div>
            </article>
            <article className='type'>
              <label htmlFor="">Request Type</label>
              <span>
                {/* <select>
                  <option value="" selected hidden>Select</option>
                  <option value="">option 2</option>
                </select> */}
                <SearchSelect>
                  <Option>Option 1</Option>
                  <Option>Option 2</Option>
                </SearchSelect>
                <i>
                  <img src="/assets/icons/Search.svg" alt="" />
                  <img className='down-arrow' src="/assets/icons/Icon material-arrow-drop-down1.svg" alt="" />
                </i>
              </span>
            </article>
            {
              (page === 2 || page === 3) &&
              <>
                <article className='dept'>
                  <label htmlFor="">Department</label>
                  <span>
                    <SearchSelect>
                      <Option>Option 1</Option>
                      <Option>Option 2</Option>
                    </SearchSelect>
                    <i>
                      <img className='down-arrow' src="/assets/icons/Icon material-arrow-drop-down1.svg" alt="" />
                    </i>
                  </span>
                </article>
                <article className='empl'>
                  <label htmlFor="">Employee</label>
                  <span>
                    <SearchSelect>
                      <Option>Option 1</Option>
                      <Option>Option 2</Option>
                    </SearchSelect>
                    <i>
                      <img className='down-arrow' src="/assets/icons/Icon material-arrow-drop-down1.svg" alt="" />
                    </i>
                  </span>
                </article>
              </>
            }
            <article className='status'>
              <label htmlFor="">Status</label>
              <span>
                <SearchSelect>
                  <Option>Option 1</Option>
                  <Option>Option 2</Option>
                </SearchSelect>
                <i>
                  <img src="/assets/icons/Search.svg" alt="" />
                  <img className='down-arrow' src="/assets/icons/Icon material-arrow-drop-down1.svg" alt="" />
                </i>
              </span>
            </article>
          </div>
        </section>
        {
          page === 3 &&
          <section className='second'>
            <label htmlFor="">Advance Filter</label>
            <div className='input'>
              <article className='select'>
                <span>
                  <SearchSelect title={'Select fields to display'}>
                    <Option>Option 1</Option>
                    <Option>Option 2</Option>
                  </SearchSelect>
                  <i>
                    <img className='down-arrow' src="/assets/icons/Icon material-arrow-drop-down1.svg" alt="" />
                  </i>
                </span>
              </article>
              <article className='items'>
                <span> Request No <i><img src="/assets/icons/close.svg" alt="" /></i></span>
                <span> Employee <i><img src="/assets/icons/close.svg" alt="" /></i></span>
                <span> Department <i><img src="/assets/icons/close.svg" alt="" /></i></span>
                <span> Request Type <i><img src="/assets/icons/close.svg" alt="" /></i></span>
              </article>
            </div>
          </section>
        }
      </div>
      <button className={'style-4 ' + (page !== 3 ? ' move-down' : '')}>Search</button>
    </div>
  )
}
