import React, { useState } from 'react'
import './CommentsWithAttachments.scss'

export default function CommentsWithAttachments() {

  const [input, setInput] = useState({ files: [] })

  return (
    <div id='CommentsWithAttachments'>
      <p>Comment</p>
      <textarea name="" id="" cols="30" rows="7" placeholder='Comment here'></textarea>
      <div>

        {
          <label htmlFor="certificate-upload">
            <span className='button style-2'>
              <input type="file" id="certificate-upload" hidden multiple onClick={(e) => e.target.value = null}
                onChange={(e) => setInput((s) => ({ ...s, files: [...s.files, ...Array.from(e.target.files)] }))} />
              Add Attachments
              <i><img src="/assets/icons/Add Attachments.svg" alt="" /></i>
            </span>
          </label>
        }
        <div className="attachment-files">
          {/* {
            input.files && input.files.map((file, i) => {
              return (
                <>
                  {file.type === 'image/jpeg' || file.type === 'image/webp' || file.type === 'image/png' ?
                    <span className='file-container'>
                      <img className='file' src={URL.createObjectURL(file)} alt="" />
                      <img className='file-close' src="/assets/icons/Group 11563.svg" alt="" />
                    </span> :
                    file.type === 'application/pdf' ?
                      <span className='file-container'>
                        <img className='file' src='./assets/icons/Group 11807.png' alt="" />
                        <img className='file-close' src="/assets/icons/Group 11563.svg" alt="" />
                      </span> :
                      null
                  }
                </>
              )
            })
          } */}
        </div>
      </div>
    </div>
  )
}
