import { useEffect, useState } from 'react';
import { trackInactivityAndLogout } from './helpers';

const useInactiveTimer = (timeInMilliseconds, onInactive) => {
  useEffect(() => {
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(onInactive, timeInMilliseconds);
    };

    const handleUserActivity = () => {
      let inactive = trackInactivityAndLogout();

      if (!(inactive)) {
        localStorage.setItem("last_activity", new Date());
        resetTimer();
      }
    };

    // Add event listeners for user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Initialize the timer
    resetTimer();

    // Clean up the event listeners
    return () => {
      clearTimeout(timer);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [timeInMilliseconds, onInactive]);
};

export default useInactiveTimer;
