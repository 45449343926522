import React from 'react'
import StatusHeaderButton from '../../components/StatusHeaderButton/StatusHeaderButton'
import TitleAndPath from '../../components/TitleAndPath/TitleAndPath'
import RequestDetailsData from '../../components/RequestDetailsData/RequestDetailsData'
import CommentsWithAttachments from '../../components/CommentsWithAttachments/CommentsWithAttachments'
import Table2 from '../../components/Table2/Table2'
import FooterButtons from '../../components/FooterButtons/FooterButtons'

export default function RequestDetails() {

    const data = [
        {
            sl_no: '01',
            received_date: 'Tue Mar 28 2023	',
            last_update: 'Tue Mar 28 2023	',
            handled_by: 'Mona',
            assigned_to: '',
            attachments: '',
            status: 'Need Info',
            comments: 'Please mention why you need salary certificate'
        }
    ]
    return (
        <div id='RequestDetails'>
            <section className='heading'>
                <TitleAndPath title={'Request Details'} path={'Home / HR / Certificate Requests'} />
                <StatusHeaderButton />
            </section>
            <section className="details">
                <RequestDetailsData />
            </section>
            <section className='update'>
                <Table2 currentPage={'RequestDetails'} data={data} />
            </section>
            <section className='comment'>
                <CommentsWithAttachments />
            </section>
            <section className='buttons'>
                <FooterButtons backPath={'/human_resource/certificate_requests'} />
            </section>
        </div>
    )
}
