import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

function PrivateRoutes() {
  const location = useLocation()
  const navigate = useNavigate()
  const token = localStorage.getItem('token')

  useEffect(() => {
    let path = location.pathname
    if (!path.includes('/admin/incidents/incident_details/') && !path.includes('/admin/incidents/incident_requests')) {
      let isInUsersPage = localStorage.getItem('incidentlistingfilter')
      isInUsersPage && localStorage.setItem('incidentlistingfilter', null)
    }
    if (!path.includes('/admin/users/user-log/') && !path.includes('/admin/users')) {
      let isInUsersPage = localStorage.getItem('userlistingfilter')
      isInUsersPage && localStorage.setItem('userlistingfilter', null)
    }
    if(!(path.includes("admin"))){
      navigate("/admin")
    }
  }, [location])

  let auth = { 'token': token }

  return (
    auth.token ? <Outlet /> : <Navigate to='/login' />
  )
}

export default PrivateRoutes