import React, { useEffect, useState } from "react";
import { HashRouter, BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import RouteHandler from "./router/routes";
import useInactiveTimer from "./utils/useInactiveTimer";
import ResponseModal from "./components/ResponseModal/ResponseModal";
import { useDispatch } from "react-redux";
import { logout } from "./redux/commonActionCreator";
import { getDynamicTexts } from "./redux/incidentActionCreator";
import { trackInactivityAndLogout } from "./utils/helpers";

export const LangSwitch = React.createContext()

function App() {
  const dispatch = useDispatch()

  const [currentLanguage, changeLanguage] = useState('en')

  const handleInactive = () => {
    if (!(window.location.hash.includes("login"))) {
      dispatch(logout(() => {
        localStorage.removeItem('token');
        localStorage.removeItem("username");
        localStorage.removeItem("user");
        localStorage.removeItem("user_type");
        // localstorage.removeItem("lang");
        window.location = "/login"
      }))
    }
  };

  useInactiveTimer(3600000, handleInactive);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(getDynamicTexts())
      // console.log(trackInactivityAndLogout(),"15")
      if (trackInactivityAndLogout()) {
        handleInactive()
      }
    }
  }, [])

  return (
    <div className="App">
      <LangSwitch.Provider value={{ changeLanguage, currentLanguage }}>
        <HashRouter>
          <ToastContainer />
          <ResponseModal />
          <RouteHandler />
        </HashRouter>
      </LangSwitch.Provider>
    </div>
  );
}

export default App;
