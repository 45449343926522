import React from "react";
import { useState } from "react";
import FooterButtons from "../../components/FooterButtons/FooterButtons";
import RequestDetailsForm from "../../components/RequestDetailsForm/RequestDetailsForm";
import TitleAndPath from "../../components/TitleAndPath/TitleAndPath";

export default function AddRequest() {
  const [data, setData] = useState({
    address: "",
    comment: "",
  });
  const [certificate, setCerificate] = useState("");
  const [language, setLanguage] = useState("");
  const [err, setErr] = useState({
    // certificate: "err",
    // language: "err",
    // address: "err",
    // comment: "err",
  });

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const validate = () => {
    let temp = {};
    if (data.address?.trim()?.length == 0) {
      temp = { ...temp, address: "Please Add Address" };
    }
    if (data.comment?.trim()?.length == 0) {
      temp = { ...temp, comment: "Please Add Comment" };
    }
    if (certificate?.trim().length == 0) {
      temp = { ...temp, certificate: "Please Select Certificate" };
    }
    if (language?.trim().length == 0) {
      temp = { ...temp, language: "Please Select Language" };
    }
    if (Object.values(temp).length != 0) {
      console.log(err);
      setErr(temp);
      return false;
    } else {
      return true;
    }
  };

  const onSaveHandler = () => {};

  return (
    console.log(data),
    (
      <div id="AddRequest">
        <section className="heading">
          <TitleAndPath
            title={"Add Request"}
            path={"Home / HR / Add Request"}
          />
        </section>
        <section className="details">
          <RequestDetailsForm
            data={data}
            onChangeHandler={onChangeHandler}
            certificate={certificate}
            setCerificate={setCerificate}
            language={language}
            setLanguage={setLanguage}
            err={err}
          />
        </section>
        <section className="buttons">
          <FooterButtons
            backPath={"/human_resource/certificate_requests"}
            onSaveHandler={onSaveHandler}
          />
        </section>
      </div>
    )
  );
}
