import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsers,
  getDepartment,
  getSection,
} from "../../redux/commonActionCreator";
import {
  department_details,
  section_details,
  updateRedux,
  user_list,
} from "../../redux/commonReducer";
import SearchSelect, { Option } from "../../Tools/SearchSelect/SearchSelect";
import "./SelectEmployee.scss";

export default function SelectEmployee({
  department,
  setDepartment,
  section,
  setSection,
  user,
  setUser,
  err,
}) {
  const dispatch = useDispatch();

  const departmentList = useSelector(department_details);
  const sectionList = useSelector(section_details);
  const userList = useSelector(user_list);

  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getAllUsers());
  }, []);

  const onDepartmentChangeHandler = (value) => {
    setDepartment(value);
    setSection("");
    dispatch(updateRedux({ key: "section_details", value: [] }));
    dispatch(getSection(value.value));
  };

  return (
    (
      <div id="SelectEmployee">
        <div className="title top">
          <h2>Select Employee</h2>
        </div>
        <div className="selects">
          <div>
            <span>
              {departmentList?.length != 0 ? (
                <>
                {console.log("12")}
                  <SearchSelect
                    title="Select Department*"
                    onChange={(e) => onDepartmentChangeHandler(e)}
                    value={department}
                  >
                    {departmentList.map((ele, i) => {
                      return (
                        <Option value={ele._id} key={i}>
                          {ele.department_name}
                        </Option>
                      );
                    })}
                  </SearchSelect>
                  <i>
                    <img
                      src="/assets/icons/Icon ionic-ios-arrow-down.svg"
                      alt=""
                    />
                  </i>
                </>
              ) : (
                <>
                  <SearchSelect title={"Select Department*"} disabled={true}>
                    <Option>item 1</Option>
                  </SearchSelect>
                  <i>
                    <img
                      src="/assets/icons/Icon ionic-ios-arrow-down.svg"
                      alt=""
                    />
                  </i>
                </>
              )}
            </span>
            <div className="text-danger">
              <small>{err.department}</small>
            </div>
          </div>
          <div>
            <span>
              {sectionList?.length ? (
                <>
                  {console.log("13")}
                  <SearchSelect
                    title="Select Section*"
                    onChange={setSection}
                    value={section}
                  >
                    {sectionList?.map((ele, i) => {
                      return (
                        <Option value={ele._id} key={i}>
                          {ele.section_name}
                        </Option>
                      );
                    })}
                  </SearchSelect>
                  <i>
                    <img
                      src="/assets/icons/Icon ionic-ios-arrow-down.svg"
                      alt=""
                    />
                  </i>
                </>
              ) : (
                <>
                  <SearchSelect title={"Select Section*"} disabled={true}>
                    <Option>item 1</Option>
                  </SearchSelect>
                  <i>
                    <img
                      src="/assets/icons/Icon ionic-ios-arrow-down.svg"
                      alt=""
                    />
                  </i>
                </>
              )}
            </span>
            <div className="text-danger">
              <small>{err.section}</small>
            </div>
          </div>

          <div>
            <span>
              {userList?.length ? (
                <>
                  {console.log("14")}
                  <SearchSelect
                    title="Select User*"
                    onChange={setUser}
                    value={user}
                  >
                    {userList?.map((ele, i) => {
                      return (
                        <Option value={ele._id} key={i}>
                          {ele.name}
                        </Option>
                      );
                    })}
                  </SearchSelect>
                  <i>
                    <img
                      src="/assets/icons/Icon ionic-ios-arrow-down.svg"
                      alt=""
                    />
                  </i>
                </>
              ) : (
                <>
                  <SearchSelect title={"Select User*"} disabled>
                    <Option>item 1</Option>
                  </SearchSelect>
                  <i>
                    <img
                      src="/assets/icons/Icon ionic-ios-arrow-down.svg"
                      alt=""
                    />
                  </i>
                </>
              )}
            </span>
            <div className="text-danger">
              <small>{err.user}</small>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
