import React, { useEffect, useState, useId } from "react";
import "./SearchSelect.scss";

export default function SearchSelect({
  onChange,
  title,
  searchInput = true,
  children,
  disabled
}) {
  const key = useId();
  const [isDropdown, setIsDropdown] = useState(false);
  const [filteredChildren, setFilteredChildren] = useState(children);
  const [selected, setSelected] = useState({ text: title || "Select...", value: null });
  const [input, setInput] = useState("");

  useEffect(() => {
    let dropdown = document.getElementById("search-select-dropdown" + key);
    document.addEventListener("click", (e) => {
      if (
        !(dropdown?.contains(e.target) || e.target.id === "SearchSelect" + key)
      ) {
        setInput("");
        setFilteredChildren(children);
        setIsDropdown(false);
      }
    });
  }, []);

  function filterFunction(value) {
    setInput(value);
    setFilteredChildren([]);
    React.Children.forEach(children, (child, i) => {
      if (child.props.children.toLowerCase().includes(value)) {
        setFilteredChildren((s) => [...s, child]);
      }
    });
  }

  function selectionHandle(value) {
    setSelected(value);
    setIsDropdown(false);
    setInput("");
    onChange && onChange(value);
  }

  return (
    <div id="SearchSelect-wrapper" >
      <section
        id={"SearchSelect" + key}
        className="SearchSelect"
        onClick={() => !disabled && setIsDropdown((s) => !s)}
      >
        {selected.text}
      </section>
      <section
        id={"search-select-dropdown" + key}
        className={"select-dropdown" + (isDropdown ? " active" : "")}
      >
        {searchInput && (
          <span>
            <input
              type="text"
              placeholder="Search"
              value={input}
              onChange={(e) => filterFunction(e.target.value)}
            />
            <i>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.691"
                height="16.691"
                viewBox="0 0 16.691 16.691"
              >
                <g
                  id="Group_12241"
                  data-name="Group 12241"
                  transform="translate(-750.293 -475.908)"
                >
                  <path
                    id="Path_1379"
                    data-name="Path 1379"
                    d="M4.5,11.382A6.882,6.882,0,1,0,11.382,4.5,6.882,6.882,0,0,0,4.5,11.382Z"
                    transform="translate(748.221 471.908)"
                    fill="none"
                    stroke="#A8A8A8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_1380"
                    data-name="Path 1380"
                    d="M24.975,28.717l3.742-3.742"
                    transform="translate(726.025 463.175)"
                    fill="none"
                    stroke="#A8A8A8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </i>
          </span>
        )}
        <ul onClick={(e) => selectionHandle({ text: e.target.innerText, value: e.target.dataset.value })}>
          {filteredChildren}
        </ul>
      </section>
    </div>
  );
}

export const Option = ({ value, children }) => {
  return <li data-value={value}> {children}</li>;
};
